import { Space } from "antd";
import SCARDocument from "./documentsScar";
import SCARDocumentTasks from "./documentsTask";

export default function SCARDetailDocumentsTab(props) {
  const { scarDetails, taskList, setScarDetails, setTaskList } = props;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <SCARDocument scarDetails={scarDetails} setScarDetails={setScarDetails} />
      <SCARDocumentTasks taskList={taskList} setTaskList={setTaskList} scarDetails={scarDetails} />
    </Space>
  );
}
