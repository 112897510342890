import React, { useState } from 'react';
import { Button, Divider, Table, Tooltip } from "antd";
import styles from '../styles/inspecionTable.module.css';
import { SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import { inspectionTableCols } from '../utils/inspecionTableUtils';
import ResizableTitle from './resize';
import ExportColsModal from '../../../../../common/components/exportColsModal/exportColsModal';
import { useNavigate, useParams } from 'react-router-dom';

export default function InspectionBody(props) {

    const params = useParams();
    const navigate = useNavigate();

    const { inspectionData, loading, totalRecords, onTableChange, currentPage, onRefresh, onInspectionClick, currentPageSize, onPaginationChange, setLoading } = props;
    const [columns, setColumns] = useState(inspectionTableCols(onInspectionClick, params, navigate));
    const [showColModal, setShowColModal] = useState(false);

    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = {
                    ...newColumns[index],
                    width: size.width,
                };
                setColumns(newColumns);
            };
    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
            minWidth: column.minWidth,
        }),
    }));

    const handleDownload = () => {
        setShowColModal(true);
    }

    return (
        <>
            <div>
                <div className={styles.containerHeader}>
                    <div className={styles.containerHeaderCol1}>
                        <span style={{ fontWeight: "bold" }}>Inspections</span> <span className={styles.containerHeaderCol1Span}>({totalRecords})</span>
                    </div>
                    <div className={styles.containerHeaderCol2}>
                        <Tooltip title="Refresh">
                            <Button icon={<SyncOutlined spin={loading} tool />} onClick={onRefresh} disabled={loading} />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip title="Download">
                            <Button icon={<DownloadOutlined />} onClick={handleDownload} disabled={loading} />
                        </Tooltip>

                    </div>
                </div>
                <div className={styles.containerBody}>
                    <ExportColsModal
                        openModal={showColModal}
                        setOpenModal={setShowColModal}
                        columns={mergeColumns}
                        dataSource={inspectionData}
                        setLoader={setLoading}
                        filename='inspections.csv'
                    />
                    <Table
                        bordered
                        loading={loading}
                        onChange={onTableChange}
                        columns={mergeColumns}
                        dataSource={inspectionData}
                        pagination={{
                            pageSize: currentPageSize,
                            total: totalRecords,
                            current: currentPage,
                            showSizeChanger: true,
                            onChange: onPaginationChange,
                        }}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            },
                        }}
                        scroll={{ x: 1200, y: 585 }}
                    />
                </div>
            </div>
        </>
    );
};