import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "../../../styles/sideNav.module.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import React, { useState, useReducer } from "react";
import ListSettingsLineIcon from "remixicon-react/ListSettingsLineIcon";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import ArrowUpSLineIcon from "remixicon-react/ArrowUpSLineIcon";
import FlashlightLineIcon from "remixicon-react/FlashlightLineIcon";
import FieldProperties from "../../fieldProperties/ui/fieldProperties.js";
import Triggers from "../../triggers/ui/triggers.js";
import { fieldDetailsAcceptedType } from "../data/models/fieldDetailsAcceptedType";
import { FieldPropertiesReducers } from "../../fieldProperties/ui/state/fieldPropertiesReducers.js";
import { TriggersReducers } from "../../triggers/ui/state/triggersReducers.js";
import { fieldPropertiesActionType } from "../../fieldProperties/data/models/fieldPropertiesActionType.js";
import { triggersActionType } from "../../triggers/data/models/triggersActionType.js";
import ToolTip from "../../toolTip.js";
import { ApiClientType } from "../../../../../clients/api-client.js";
import { ApiClient } from "../../../../../clients/api-client.js";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton";

export default function SideNav(props) {

  let field = props.value.selectedField?.field ? props.value.selectedField?.field : props.value.selectedField?.editField?.data;
  let editField = props.value.selectedField?.editField;

  const [fieldName, setFieldName] = useState(field?.properties?.title);
  const [isMandatory, setIsMandatory] = useState(field?.properties?.isRequired);
  const [isObservation, setIsObservation] = useState(field?.properties?.requireObservation);
  const [isAttachment, setIsAttachment] = useState(field?.properties?.requireAttachment);
  const [minimumReadingCount, setMinimumReadingCount] = useState(field?.properties?.minimumReadingCount);
  const [fieldDescription, setFieldDescription] = useState(field?.properties?.description);
  const [fieldProperties, dispatchFieldProperties] = useReducer(
    FieldPropertiesReducers, field?.properties?.data?.options ? field?.properties?.data?.options : []
  );
  const [triggers, dispatchTriggers] = useReducer(
    TriggersReducers, field?.triggers?.triggers ? field?.triggers?.triggers : []
  );

  const [openCollapse1, setOpenCollapse1] = useState(props.value.isPreview ? true : false);
  const [openCollapse2, setOpenCollapse2] = useState(props.value.isPreview ? true : false);
  const [buttonId1, setButtonId1] = useState(styles.collapseButton);
  const [buttonId2, setButtonId2] = useState(styles.collapseButton);
  const [isButtonIdTrue1, setIsButtonIdTrue1] = useState(true)
  const [isButtonIdTrue2, setIsButtonIdTrue2] = useState(true)

  const handleClick = () => {
    setOpenCollapse1(!openCollapse1);
    setIsButtonIdTrue1(!isButtonIdTrue1)
    if (isButtonIdTrue1) {
      setButtonId1(styles.collapseButtonOnClick);
    }
    else {
      setButtonId1(styles.collapseButton);
    }
  };

  const handleClickTrigger = () => {
    setOpenCollapse2(!openCollapse2);
    setIsButtonIdTrue2(!isButtonIdTrue2)
    if (isButtonIdTrue2) {
      setButtonId2(styles.collapseButtonOnClick);
    }
    else {
      setButtonId2(styles.collapseButton);
    }

  };

  const onEditField = async (fieldData) => {
    if (editField) {
      let body = {
        type: "ADD_FIELD",
        data: fieldData
      }
      let response = await ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets/${props.value.sheetId}/edit/${editField.id}`,
        body
      );
      if (!response.isError) {
        props.getSheetData(props.value.sheetId);
        props.value.onToggle(false);
      }
    } else {
      let body = {
        type: "UPDATE_FIELD",
        data: fieldData
      }
      let response = await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets/${props.value.sheetId}/edit`,
        body
      );
      if (!response.isError) {
        props.getSheetData(props.value.sheetId);
        props.value.onToggle(false);
      }
    }
  }

  const saveChanges = () => {
    if (field?.isActive) {
      let fieldInfo = {
        fieldId: field.fieldId ? field.fieldId : field.id,
        sectionId: props.value.sectionId,
        type: props.value.selectedField?.icon?.id,
        title: fieldName,
        description: fieldDescription,
        minimumReadingCount: minimumReadingCount,
        requireObservation: isObservation,
        isRequired: isMandatory,
        requireAttachment: isAttachment,
        triggers: triggers,
      };
      if (props.value.selectedField?.icon?.id === 'SELECTION' ||
        props.value.selectedField?.icon?.id === 'MULTI_SELECTION') {
        fieldInfo['data'] = {
          options: fieldProperties,
        }
      }
      onEditField(fieldInfo);
    } else {
      props.value.onToggle(false);
    }
  };

  const handleClose = () => {
    props.value.onToggle(false);
  }

  return (
    <Offcanvas
      id={styles.sideNav}
      show={props.value.show}
      onHide={handleClose}
      placement="end"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <div id={styles.sectorNo}>
            Sector -{" "}
            {props.value.sectionNumber >= 10
              ? props.value.sectionNumber
              : `0${props.value.sectionNumber}`}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form
          onSubmit={(e) => e.preventDefault()}
          style={{ pointerEvents: props.value.isPreview ? "none" : "auto" }}
        >
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              {props.value.selectedField?.icon?.icon}
            </InputGroup.Text>
            <Form.Control
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
              placeholder="Field Name"
              aria-label="Field Name"
            />
          </InputGroup>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "#7D7676" }}>
              Field Description
            </Form.Label>
            <Form.Control
              value={fieldDescription}
              onChange={(e) => setFieldDescription(e.target.value)}
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <button
            type="file"
            style={{
              fontSize: "14px",
              border: "none",
              background: "none",
              float: "right",
              marginTop: "2.5px",
            }}
          >
            <AddCircleLineIcon
              color="#009AFF"
              size={15}
              style={{ marginTop: "-5px", marginRight: "2px" }}
            />
            Attach Reference
          </button>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "#7D7676" }}>References</Form.Label>
            <Form.Control as="textarea" rows={2} />
          </Form.Group>
          {fieldDetailsAcceptedType.triggers.includes(
            props.value.selectedField?.icon?.id
          ) ? (
            <Form.Label style={{ color: "#7D7676" }}>Field Details</Form.Label>
          ) : null}
          {fieldDetailsAcceptedType.fieldProperties.includes(
            props.value.selectedField?.icon?.id
          ) ? (
            <Form.Group className="mb-3">
              <Button
                id={buttonId1}
                onClick={handleClick}
                aria-controls="example-collapse-text"
                aria-expanded={openCollapse1}
              >
                <ListSettingsLineIcon color="#7D7676" />
                <span style={{ marginLeft: "10px" }}>Field Properties</span>
                {openCollapse1 ? (
                  <ArrowUpSLineIcon
                    onClick={() => setOpenCollapse1(!openCollapse1)}
                    color="#7D7676"
                    style={{
                      float: "right",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ArrowDownSLineIcon
                    onClick={() => setOpenCollapse1(!openCollapse1)}
                    color="#7D7676"
                    style={{
                      float: "right",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Button>
              <Collapse in={openCollapse1}>
                <div id="example-collapse-text">
                  <FieldProperties
                    value={{
                      fieldProperties,
                      isMandatory,
                      isObservation,
                      isAttachment,
                      minimumReadingCount,
                      setIsMandatory: (value) => {
                        setIsMandatory(value);
                      },
                      setIsObservation: (value) => {
                        setIsObservation(value);
                      },
                      setIsAttachment: (value) => {
                        setIsAttachment(value);
                      },
                      setMinimumReadingCount: (value) => {
                        setMinimumReadingCount(value);
                      },
                      onAddProperty: () => {
                        dispatchFieldProperties({
                          type: fieldPropertiesActionType.addFieldProperties,
                        });
                      },
                      onEditProperty: (index, property) => {
                        dispatchFieldProperties({
                          type: fieldPropertiesActionType.editFieldProperties,
                          data: { index: index, property: property },
                        });
                      },
                      onRemoveProperty: (index) => {
                        dispatchFieldProperties({
                          type: fieldPropertiesActionType.removeFieldProperties,
                          data: index,
                        });
                      },
                    }}
                  />
                </div>
              </Collapse>
            </Form.Group>
          ) : null}
          {fieldDetailsAcceptedType.triggers.includes(
            props.value.selectedField?.icon?.id
          ) ? (
            <Form.Group className="mb-3">
              <Button
                id={buttonId2}
                onClick={handleClickTrigger}
                aria-controls="example-collapse-text"
                aria-expanded={openCollapse2}
              >
                <FlashlightLineIcon color="#7D7676" />
                <span style={{ marginLeft: "10px" }}>Triggers</span>

                {openCollapse2 ? (
                  <ArrowUpSLineIcon
                    onClick={() => setOpenCollapse2(!openCollapse2)}
                    color="#7D7676"
                    style={{
                      float: "right",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <ArrowDownSLineIcon
                    onClick={() => setOpenCollapse2(!openCollapse2)}
                    color="#7D7676"
                    style={{
                      float: "right",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Button>
              <Collapse in={openCollapse2}>
                <div id="example-collapse-text">
                  <Triggers
                    value={{
                      triggers,
                      fieldProperties,
                      fieldType: props.value.selectedField?.icon,
                      onAddTrigger: () => {
                        dispatchTriggers({
                          type: triggersActionType.addTriggers,
                        });
                      },
                      onRemoveTrigger: (trigger) => {
                        dispatchTriggers({
                          type: triggersActionType.removeTriggers,
                          data: trigger,
                        });
                      },
                      onEditTrigger: (index, trigger) => {
                        dispatchTriggers({
                          type: triggersActionType.editTriggers,
                          data: { index: index, trigger: trigger },
                        });
                      },
                    }}
                  />
                </div>
              </Collapse>
            </Form.Group>
          ) : null}
        </Form>
        {
          !props.value.isPreview &&
          <div>
            <PrimaryButton
              value={{
                type: "submit",
                child: "Save Changes",
                onClick: saveChanges,
                buttonStyle: "modalCTA"
              }}
            />
          </div>
        }
      </Offcanvas.Body>
    </Offcanvas>
  );
}
