import { React, useEffect, useState } from "react";
import {
  DEFAULT_OFFSET,
  PAGE_SIZE,
} from "../../../../../clients/api-constants.js";
import { getIncomingPartsAPI } from "../../../apiCalls/suppliersApis.js";
import { getIcPartApi } from "../../../../../common/apis/commonApis.js";
import { IncomingPartsModal } from "../incomingPartsModal/incomingPartsModal.js";
import IncomingPartsBody from "./components/body.js";

export default function IncomingPartsTable(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);

  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [incomingPartDetails, setIncomingPartDetails] = useState({});

  const [incomingPartsList, setIncomingPartsList] = useState([]);

  const fetchIncomingParts = async (
    pageOffset = offset,
    pageLimit = PAGE_SIZE
  ) => {
    setIsLoading(true);
    let response = await getIncomingPartsAPI(pageOffset, pageLimit);
    if (!response.isError) {
      setTotalCount(response?.totalCount);
      setIncomingPartsList(response);
    }
    setIsLoading(false);
  };
  const onIncomingPartClick = async (id) => {
    let response = await getIcPartApi(id);
    if (!response?.isError) {
      setIncomingPartDetails(response?.data);
      setIsEdit(true);
      setShowModal(true);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchIncomingParts(0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchIncomingParts(currentPageSize * (page - 1), currentPageSize);
    }
  };

  const onRefresh = () => {
    fetchIncomingParts(offset);
  };

  useEffect(() => {
    fetchIncomingParts();
  }, []);

  return (
    <>
      <IncomingPartsModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        incomingPartDetails={incomingPartDetails}
        fetchIncomingPartsData={fetchIncomingParts}
      />
      <div>
        <IncomingPartsBody
          incomingPartsListData={incomingPartsList?.data}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          onIncomingPartClicked={onIncomingPartClick}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
          setShowModal={setShowModal}
          setIsEdit={setIsEdit}
        />
      </div>
    </>
  );
}
