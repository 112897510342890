import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import  "./assets/fonts/GlacialIndifference-Regular.otf";
import {BrowserRouter as Router,} from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

SuperTokens.init({
  appInfo: {
    appName: process.env.REACT_APP_APP_NAME,
    apiDomain: process.env.REACT_APP_API_DOMAIN,
    websiteDomain: window.location.origin,
    apiBasePath: process.env.REACT_APP_APP_BASE_PATH,
    websiteBasePath: process.env.REACT_APP_WEBSITE_BASE_PATH,
  },
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        signInForm: {
          style: `
                        [data-supertokens~=headerSubtitle] {
                            display: none;
                        }
                        [data-supertokens~=button] {
                            background: linear-gradient( 90deg, rgba(0, 198, 251, 0.9) 0%, rgba(0, 91, 234, 0.9) 100% ), linear-gradient(0deg, #dadada, #dadada);
                            border-color: grey;
                        }
                        [data-supertokens~="superTokensBranding"]{
                            display: none;
                        }
                    `,
        }
      },
    }),
    Session.init({ tokenTransferMethod: "cookie" }),
  ],
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <SuperTokensWrapper>
      <Router>
        <App />
      </Router>
    </SuperTokensWrapper>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
