import styles from "../calibrationItem/styles/calibrationItem.module.css";
import { getDateFun } from "../../../../common/components/getDateFun/getDateFun";
import EmptyTableMsgs from "../../../../common/components/emptyTableMsgs/emptyTableMsgs";
import { Row, Col } from "react-bootstrap";

export default function DetailedCalibration(props) {
  const {
    setSideNavOpen,
    setColSize,
    colSize,
    calibrationDetailedList,
    sideNavOpen,
    setClickedRow,
    setDueDate,
    clickedRow,
    setAgencyName,
  } = props.values;

  return (
    <>
      {calibrationDetailedList?.length === 0 ? (
        <EmptyTableMsgs emptyMessage="No calibrations" isTable={false} />
      ) : (
        calibrationDetailedList.map((item, key) => {
          const dueDateTime = new Date(item?.dueDateTime);
          const isDelayed = dueDateTime < new Date();

          return (
            <Row
              key={key}
              className={styles.filtersRowTableHeadingData}
              onClick={() => {
                setClickedRow(item);
                setDueDate(item?.dueDateTime);
                setAgencyName(item?.agency);
                setSideNavOpen(true);
                setColSize(4);
              }}
              id={styles.clickedRowItem}
              style={{
                border:
                  clickedRow?.id === item?.id && sideNavOpen
                    ? "1px solid #009AFF"
                    : "",
                background:
                  clickedRow?.id === item?.id && sideNavOpen ? "#D0EBFF59" : "",
              }}
            >
              <Col className={styles.topRowCol} md={colSize}>
                <span
                  className={
                    item?.status !== "CLOSED"
                      ? styles.openStatus
                      : styles.closedStatus
                  }
                  style={{
                    marginLeft: isDelayed ? "6.7rem" : "",
                  }}
                >
                  {item?.status}
                </span>
                {isDelayed && (
                  <span className={styles.delayedStatus}>DELAYED</span>
                )}
              </Col>
              <Col md={colSize} className={styles.topRowCol}>
                <div>
                  <span>
                    {item?.dueDateTime ? getDateFun(item?.dueDateTime) : "-"}
                  </span>
                </div>
              </Col>
              <Col md={colSize} className={styles.topRowCol}>
                <div>
                  <span>{item?.agency ? item.agency : "-"}</span>
                </div>
              </Col>
            </Row>
          );
        })
      )}
    </>
  );
}
