import styles from "../styles/toggleButtonMaster.module.css";
import { useEffect, useState } from "react";

export default function ToggleButtonMaster(props) {
// disabling operations ....
  const {setStations, setMachines, setOperations, setProducts, setEquipment} = props;
  const [toggle, setToggle] = useState([true, false, false, false])

  useEffect(()=>{
    if(toggle[0]) {
      setStations(true);
      setMachines(false);
      setProducts(false);
      setEquipment(false);
    } else if(toggle[1]) {
      setStations(false);
      setMachines(true);
      setOperations(false);
      setProducts(false);
      setEquipment(false);
    } else if(toggle[2]) {
      setStations(false);
      setMachines(false);
      setProducts(true);
      setEquipment(false)
    } else {
      setStations(false);
      setMachines(false);
      setProducts(false);
      setEquipment(true)
    }
  },[toggle])

  return (
    <div id={styles.toggleButton}>
      <div
        onClick={()=>setToggle([true, false, false, false])}
        id={styles.option1}
        className={toggle[0] ? styles.selected : ''}
      >
        Stations
      </div>
      <div
        onClick={()=>setToggle([false, true, false, false])}
        id={styles.option2}
        className={toggle[1] ? styles.selected : ''}
      >
        Machines
      </div>
      {/*<div*/}
      {/*  onClick={()=>setToggle([false, false, true, false])}*/}
      {/*  id={styles.option3}*/}
      {/*  className={toggle[2] ? styles.selected : ''}*/}
      {/*>*/}
      {/*  Operations*/}
      {/*</div>*/}
      <div
        id={styles.option4}
        onClick={()=>setToggle([false, false, true, false])}
        className={toggle[2] ? styles.selected : ''}
      >
        Products
      </div>

      <div
        id={styles.option5}
        onClick={()=>setToggle([false, false, false, true])}
        className={toggle[3] ? styles.selected : ''}
      >
        Equipment
      </div>
    </div>
  );
}
