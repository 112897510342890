import { useDrop } from "react-dnd";
import styles from "../../../styles/canvas.module.css";
import { dragDropCanvas } from "../../../../../common/assets/images/dragDropCanvas.js";
import Section from "../../section/ui/section.js";
import { useState, useEffect, useReducer } from "react";
import { CanvasReducers } from "./state/canvasReducers.js";
import { canvasActionType } from "../data/models/canvasActionType.js";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ToolTip from "../../toolTip.js";
import { ApiClientType } from "../../../../../clients/api-client.js";
import { ApiClient } from "../../../../../clients/api-client.js";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import ArrowGoBackLineIcon from "remixicon-react/ArrowGoBackLineIcon";
import DeleteRevertToolTip from "../../deleteRevertTooltip";

export default function Canvas(props) {
  const [sections, dispatch] = useReducer(CanvasReducers, []);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSectionId, setSelectedSectionId] = useState(-1);
  const [sectionName, setSectionName] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");

  const [publishedSections, setPublishedSections] = useState([]);
  const [sheetEdits, setSheetEdits] = useState([]);
  const [sectionEdits, setSectionEdits] = useState([]);
  const [fieldEdits, setFieldEdits] = useState([]);

  useEffect(() => {
    let sheetEditsData = props.value.sheetEdits;
    dispatch({
      type: canvasActionType.addSections,
      data: props.value.sections,
    });
    if (sheetEditsData?.length > 0) {
      setSheetEdits(sheetEditsData)
    } else {
      setSheetEdits([]);
    }
  }, [props.value.sections, props.value.sheetEdits]);

  useEffect(() => {
    let sectionEditsData = [],
      fieldEditsData = [],
      publishedSectionsData = [];
    if (sheetEdits?.length > 0) {
      sheetEdits.map((edit, index) => {
        if (edit.type.includes('SECTION')) {
          sectionEditsData.push(edit);
        } else {
          fieldEditsData.push(edit);
        }
      })
      setFieldEdits(fieldEditsData);
      setSectionEdits(sectionEditsData);
    } else {
      setFieldEdits([]);
      setSectionEdits([]);
    }
    let editIds = sectionEditsData.map(edit => edit.data.sectionId);
    publishedSectionsData = sections.filter((item, index) => {
      if (!editIds.includes(item.id)) {
        return item
      }
    })
    setPublishedSections(publishedSectionsData);
  }, [sheetEdits, sections])


  const onCreateSection = async (params) => {
    let body = {
      "type": "ADD_SECTION",
      "data": {
        "name": "Section Name",
        "description": "Section Description"
      }
    }
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${params}/edit`,
      body
    );
    if (response) {
      getSheetData(params);
    }
  };

  const onEditSection = async (edit) => {
    let body = {
      "type": "ADD_SECTION",
      "data": {
        "sheetId": edit.sheetId,
        "name": sectionName,
        "sectionId": edit.data.sectionId,
        "description": sectionDescription
      }
    }
    let response = await ApiClient(
      ApiClientType.patch,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${edit.sheetId}/edit/${edit.id}`,
      body
    )
    getSheetData(edit.sheetId);
  }

  const getSheetData = async (sheetId) => {
    let response = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `${`/sheets/${sheetId}?includeSections=true&includeSheetEdits=true&includeFields=true`}`,
      {}
    );
    if (response) {
      setSheetEdits(response.data.sheetEdits);
    }
  }

  const onUpdateSection = async (index) => {
    let body = {
      "type": "UPDATE_SECTION",
      "data": {
        "name": sectionName,
        "sectionId": selectedSectionId,
        "description": sectionDescription
      }
    }
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${props.value.sheetId}/edit`,
      body
    );
    getSheetData(props.value.sheetId);
  }

  const onDeleteSection = async (item, index) => {
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheet/section/${item.is_active ? "deactivate" : "activate"}`,
      {
        sheet_id: props.value.sheetId,
        section_id: item.id,
      }
    );
    if (response) {
      dispatch({
        type: canvasActionType.replaceSection,
        data: {
          index: index,
          section: response.data,
        },
      });
    }
  };

  const deleteSection = async (item) => {
    let body = {
      type: "DELETE_SECTION",
      data: {
        sectionId: item.id,
        sheetId: item.sheetId
      }
    }
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${props.value.sheetId}/edit`,
      body
    );
    if (response) {
      getSheetData(props.value.sheetId);
    }
  }

  const deleteSectionEdit = async (edit) => {
    let response = await ApiClient(
      ApiClientType.delete,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${props.value.sheetId}/edit/${edit.id}`,
    );
    if (response) {
      getSheetData(props.value.sheetId);
    }
  }

  const [collectedProps, drop] = useDrop(() => ({
    accept: "field",
    drop: (item, monitor) => {
      if (item.name == "Section") {
        onCreateSection(item.sheetId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div
      id={styles.canvas}
      ref={drop}
      data-testid="canvas"
      style={{
        opacity: collectedProps.isOver && collectedProps.canDrop ? 0.5 : 1,
      }}
    >
      {publishedSections?.length != 0 || sectionEdits?.length != 0 ? (
        <>
          {publishedSections?.length > 0 && publishedSections?.map((item, index) => {
            return (
              <div key={item.id} id={styles.sector}>
                <div id={styles.sectorNo}>
                  Sector - {index + 1 >= 10 ? index + 1 : `0${index + 1}`}
                </div>
                <div id={styles.header}>
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: "2.8rem",
                    }}
                  >
                    {selectedIndex == index && !props.value.isPreview ? (
                      <InputGroup
                        className="mb-3"
                        style={{
                          maxWidth: "400px",
                          margin: "15px 0 0 0",
                        }}
                      >
                        <Form.Control
                          defaultValue={item.name}
                          onChange={(e) => setSectionName(e.target.value)}
                          placeholder="Section Name"
                        />
                      </InputGroup>
                    ) : (
                      <div
                        className="boldFont"
                        id={styles.heading}
                        onClick={() => {
                          if (!props.value.isPreview) {
                            setSelectedIndex(index);
                            setSelectedSectionId(item.id);
                            setSectionName(item.name);
                            setSectionDescription(item.description);
                          }
                        }}
                      >
                        {item.name ? item.name : <> Section Name </>}
                      </div>
                    )}
                    {selectedIndex == index && !props.value.isPreview ? (
                      <InputGroup
                        className="mb-3"
                        style={{
                          maxWidth: "400px",
                          margin: "15px 0 0 0",
                        }}
                      >
                        <Form.Control
                          defaultValue={item.description}
                          onChange={(e) =>
                            setSectionDescription(e.target.value)
                          }
                          placeholder="Section Description"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            onUpdateSection(index);
                            setSelectedIndex(-1);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Save
                        </InputGroup.Text>
                      </InputGroup>
                    ) : (
                      <div
                        id={styles.desc}
                        onClick={() => {
                          if (!props.value.isPreview) {
                            setSelectedIndex(index);
                            setSelectedSectionId(item.id);
                            setSectionName(item.name);
                            setSectionDescription(item.description);
                          }
                        }}
                      >
                        {item.description ? (
                          item.description
                        ) : (
                          <>Section Description</>
                        )}
                      </div>
                    )}
                  </div>
                  <div style={{ display: "inline-block", float: "right" }}>
                    {!props.value.isPreview ?
                      <DeleteRevertToolTip
                        type={"delete"}
                        clickHandler={() => deleteSection(item)}
                      />
                      : null
                    }
                  </div>
                </div>
                <div id={styles.body} >
                  <Section
                    getSheetData={getSheetData}
                    value={{
                      isPreview: props.value.isPreview,
                      fields: item.fields,
                      sectionId: item.id,
                      sheetId: props.value.sheetId,
                      fieldEdits: fieldEdits,
                      sheetEdits: sheetEdits,
                      sectionIndex: index,
                      sectionData: item
                    }}
                  />
                </div>
              </div>
            );
            // }
          })}
          {!props.value.isPreview && sectionEdits?.length != 0 && sectionEdits?.map((edit, index) => {
            let i = 0;
            return (
              <>
                <div key={edit.sectionId} id={styles.sector}>
                  <div id={!edit.type.includes('DELETE') ? styles.editSectorNo : styles.deleteSectorNo}>
                    Sector - {index + 1 >= 10 ? index + 1 : `0${index + 1}`}
                  </div>
                  <div id={!edit.type.includes('DELETE') ? styles.header : styles.deletedHeader}>
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "2.8rem",
                      }}
                    >
                      {selectedIndex == index && !props.value.isPreview && !edit.type.includes('DELETE') ? (
                        <InputGroup
                          className="mb-3"
                          style={{
                            maxWidth: "400px",
                            margin: "15px 0 0 0",
                          }}
                        >
                          <Form.Control
                            defaultValue={edit.data.name}
                            onChange={(e) => setSectionName(e.target.value)}
                            placeholder="Section Name"
                          />
                        </InputGroup>
                      ) : (
                        <div
                          className="boldFont"
                          id={styles.heading}
                          onClick={() => {
                            if (!props.value.isPreview && !edit.type.includes('DELETE')) {
                              setSelectedIndex(index);
                              setSelectedSectionId(edit.data.sectionId);
                              setSectionName(edit.data.name);
                              setSectionDescription(edit.data.description);
                            }
                          }}
                        >
                          {edit.data.name ? edit.data.name : <> Section Name </>}
                        </div>
                      )}
                      {selectedIndex == index && !props.value.isPreview && !edit.type.includes('DELETE') ? (
                        <InputGroup
                          className="mb-3"
                          style={{
                            maxWidth: "400px",
                            margin: "15px 0 0 0",
                          }}
                        >
                          <Form.Control
                            defaultValue={edit.data.description}
                            onChange={(e) =>
                              setSectionDescription(e.target.value)
                            }
                            placeholder="Section Description"
                          />
                          <InputGroup.Text
                            onClick={() => {
                              onEditSection(edit)
                              setSelectedIndex(-1);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Save
                          </InputGroup.Text>
                        </InputGroup>
                      ) : (
                        <div
                          id={styles.desc}
                          onClick={() => {
                            if (!props.value.isPreview && !edit.type.includes('DELETE')) {
                              setSelectedIndex(index);
                              setSelectedSectionId(edit.sectionId);
                              setSectionName(edit.data.name);
                              setSectionDescription(edit.data.description);
                            }
                          }}
                        >
                          {edit.data.description ? (
                            edit.data.description
                          ) : (
                            <>Section Description</>
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{ display: "inline-block", float: "right" }}>
                      {!props.value.isPreview && !edit.type.includes('DELETE') ? (
                        <Form.Select
                          onChange={(e) => {
                            onDeleteSection(edit, index);
                          }}
                          value={edit.data.isActive ? "ACTIVE" : "INACTIVE"}
                        >
                          <option value="INACTIVE">Inactive</option>
                          <option value="ACTIVE">Active</option>
                        </Form.Select>
                      ) : null}
                      {!props.value.isPreview && !edit.type.includes('DELETE') ?
                        <div>
                          <DeleteRevertToolTip
                            type="delete"
                            clickHandler={() => deleteSectionEdit(edit)}
                          />
                        </div>
                        : null
                      }
                      {!props.value.isPreview && edit.type.includes('DELETE') ?
                        <div>
                          <DeleteRevertToolTip
                            type="revert"
                            clickHandler={() => deleteSectionEdit(edit)}
                          />
                        </div>
                        : null
                      }
                    </div>
                  </div>
                  <div id={!edit.type.includes('DELETE') ? styles.body : styles.deletedBody}>
                    <Section
                      getSheetData={getSheetData}
                      value={{
                        isPreview: props.value.isPreview,
                        sections: sections,
                        fieldEdits: fieldEdits,
                        sheetEdits: sheetEdits,
                        sectionId: edit.data.sectionId,
                        sheetId: props.value.sheetId,
                        sectionIndex: index,
                        sectionData: edit
                      }}
                    />
                  </div>
                </div>
              </>
            )
          })}
        </>
      ) : (
        <div id={styles.image}>{!props.value.isPreview ? dragDropCanvas : 'Nothing here, yet.'}</div>
      )}
    </div>
  );
}
