import { Button, Collapse, Space } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import SCARTaskModal from "./taskModal";
import SCARTaskDetails from "./taskDetails";
import { getSheetsAPI, getUsersAPI } from "../../../../../../../common/apis/commonApis";
import { showToastError } from "../../../../../../../common/toasters/toasterMessages";
import { useParams } from "react-router-dom";
import TaskActions from "./taskActions";


export default function SCARDetailTasksTab(props) {
    const { isLoading, taskList, createSCARTask, editSCARTask, deleteSCARTask } = props;

    const params = useParams();
    const appId = params.appId;

    const [showModal, setShowModal] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [totalCountUsers, setTotalCountUsers] = useState(0);
    const [usersLoading, setusersLoading] = useState(false);

    const [sheetList, setSheetList] = useState([]);
    const [totalCountSheet, setTotalCountSheet] = useState(0);
    const [sheetsLoading, setSheetsLoading] = useState(false);

    const [selectedTask, setSelectedTask] = useState(undefined);

    const fetchSheets = async () => {
        setSheetsLoading(true);
        let response = await getSheetsAPI(appId, sheetList.length, '', 10);
        if (!response.isError) {
            const sheets = sheetList;
            response?.data?.forEach(sheet => sheets.push({ value: sheet?.id, label: sheet?.name }));
            setSheetList(sheets);
            setTotalCountSheet(response?.totalCount);
        } else {
            showToastError(response.displayableMessage);
        }
        setSheetsLoading(false);
    };

    const fetchUsers = async () => {
        setusersLoading(true);
        let response = await getUsersAPI(usersList?.length, '', 10);
        if (!response.isError) {
            const users = usersList;
            response?.data?.forEach(user => users.push({ value: user?.userId, label: user?.user?.name }));
            setUsersList(users);
            setTotalCountUsers(response?.totalCount);
        } else {
            showToastError(response.displayableMessage);
        }
        setusersLoading(false);
    };
    const editTaskDetails = (task) => {
        setSelectedTask(task);
        setShowModal(true);
    };

    const genExtra = (task) => {
        return (<TaskActions task={task} editTask={editTaskDetails} deleteTask={deleteSCARTask} />);
    }

    useEffect(() => {
        fetchUsers();
        fetchSheets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SCARTaskModal open={showModal} setOpen={setShowModal} createSCARTask={createSCARTask}
                setSelectedTask={setSelectedTask} selectedTask={selectedTask} editSCARTask={editSCARTask}
                usersList={usersList} totalCountUsers={totalCountUsers} usersLoading={usersLoading} fetchUsers={fetchUsers}
                sheetList={sheetList} totalCountSheet={totalCountSheet} sheetsLoading={sheetsLoading} fetchSheets={fetchSheets} />
            <Space direction="vertical" style={{ width: '100%' }} size='large'>
                <div style={{ width: '100%' }}>
                    <div style={{ float: "right" }}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => { setShowModal(true) }} disabled={isLoading}>New Task</Button>
                    </div>
                </div>
                <Space direction="vertical" style={{ width: '100%' }}>
                    {
                        taskList?.map(task => (
                            <Collapse items={[{
                                key: task?.id,
                                label: (<span style={{ fontWeight: 'bold' }}>
                                    {task?.title}
                                </span>),
                                children: <SCARTaskDetails taskData={task} editSCARTask={editSCARTask} />,
                                extra: genExtra(task),
                            }]} />))
                    }
                </Space>

            </Space>
        </>
    );
}