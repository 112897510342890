import { Input } from "antd";
const { Search } = Input;
export default function SearchInstruments(props) {
  const { searchInstruments } = props;
  return (
    <Search
      placeholder="Search instrument"
      allowClear
      onChange={(e) => {
        searchInstruments(e.target.value);
      }}
      style={{ width: 304 }}
    />
  );
}
