import { React, useEffect, useState } from "react";
import { showToastError } from "../../../../common/toasters/toasterMessages.js";
import {
  DEFAULT_OFFSET,
  PAGE_SIZE,
} from "../../../../clients/api-constants.js";
import {
  getEquipmentList,
  getDetailedEquipment,
} from "../../apiCalls/productsApis.js";
import EquipmentBody from "./components/body.js";
import { EquipmentModal } from "./components/equipmentModal/equipmentModal.js";

export default function EquipmentTable() {
  const [equipmentsData, setEquipmentsData] = useState([]);
  const [equipmentData, setEquipmentData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);

  const [showModal, setShowModal] = useState(false);
  let [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    void fetchEquipments(offset);
  }, []);

  const fetchEquipments = async (
    pageOffset = offset,
    pageLimit = PAGE_SIZE
  ) => {
    setIsLoading(true);
    let equipmentResponse = await getEquipmentList({
      pageOffset,
      limit: pageLimit,
    });
    if (equipmentResponse.isError) {
      showToastError(equipmentResponse.displayableMessage);
    } else {
      setEquipmentsData(equipmentResponse.data);
      setTotalCount(equipmentResponse.totalCount);
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchEquipments(0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchEquipments(currentPageSize * (page - 1), currentPageSize);
    }
  };

  const onRefresh = () => {
    fetchEquipments(offset);
  };

  const handleEquipmentClick = async (id) => {
    let response = await getDetailedEquipment(id);
    if (!response?.isError) {
      setEquipmentData(response?.data);
    }
    setIsEdit(true);
  };

  return (
    <>
      <EquipmentModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        fetchEquipments={fetchEquipments}
        equipmentDetails={equipmentData}
      />
      <div>
        <EquipmentBody
          equipmentData={equipmentsData?.instruments}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          setShowModal={setShowModal}
          setIsEdit={setIsEdit}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          onEquipmentClick={handleEquipmentClick}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
        />
      </div>
    </>
  );
}
