import { useEffect, useState, useReducer } from "react";
import { DataFetchReducers } from "../../../common/states/dataFetch/dataFetchReducers";
import { dataFetchActionType } from "../../../common/states/dataFetch/dataFetchActionType";
import styles from "../styles/tableStyle.module.css";
import { ApiClientType } from "../../../clients/api-client";
import { ApiClient } from "../../../clients/api-client";
import PrimaryButton from "../../../common/crunches/primaryButton/primaryButton";
import ChangePassword from "./changePassword";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import { GetUserId } from "../../../utils/getUserId";
import { getUserDetailsAPI } from "../../../common/apis/commonApis";
import { updateUserDetailsAPI } from "../../../common/apis/commonApis";
import {
  showToastError,
  showToastSuccess,
} from "../../../common/toasters/toasterMessages";

export default function UserDetailsTable() {
  const userId = GetUserId();
  const [isEdit, setIsEditTrue] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [contact, setContact] = useState("9999999999");
  const [email, setEmail] = useState("");
  const [showChangePasswordModel, setShowChangePasswordModel] = useState(false);
  const [userDetails, dispatchUserDetails] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const [error, setError] = useState("");

  useEffect(() => {}, []);

  const handleFirstNameBlur = () => {
    if (
      firstName.trim() === "" ||
      !/^\d{10}$/.test(contact) ||
      contact.trim() === ""
    ) {
      setError("Enter valid details");
    } else {
      setError("");
    }
  };

  const handleSaveDetails = () => {
    console.log("Details saved successfully!");
  };

  const updateUserDetails = async (values) => {
    let response = await updateUserDetailsAPI(values);
    dispatchUserDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });
    if (!response.isError) {
      showToastSuccess(response.message);
    } else {
      showToastError(response.message);
    }
  };

  const handleChangePassword = async (values) => {
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_API_DOMAIN,
      `/users/change-password`,
      values
    );
  
    if (!response.isError) {
      showToastSuccess(response.message);
    } else {
      showToastError("Tru again");
    }
  };

  useEffect(() => {
    if (userDetails.data) {
      setFirstName(userDetails?.data?.name);
      setEmail(userDetails.data.email);
    }
  }, [userDetails.data]);

  useEffect(() => {
    const getUserDetails = async () => {
      let response = await getUserDetailsAPI(userId);
      dispatchUserDetails({
        type: dataFetchActionType.data,
        data: response.data,
      });
    };

    getUserDetails();
  }, [userId]);

  return (
    <form style={{ display: "flex", flexDirection: "column" }}>
      <ChangePassword
        value={{
          show: showChangePasswordModel,
          setShowIncomingPartModel: setShowChangePasswordModel,
          onSubmitModal: async (values) => {
            handleChangePassword(values);
          },
        }}
      />
      <div className={styles.editUserDetailsCTA}>
        {isEdit && error === "" ? (
          <PrimaryButton
            value={{
              child: (
                <>
                  <PencilLineIcon
                    style={{
                      marginRight: "5px",
                      marginTop: "-4px",
                    }}
                  />
                  Edit Details
                </>
              ),
              onClick: () => {
                setIsEditTrue(false);
              },
              style: {
                padding: "0.5rem 1rem",
                borderRadius: "7px",
                margin: "30px",
                marginBottom: "0px",
                fontSize: "14px",
              },
            }}
            type="button"
          />
        ) : (
          <PrimaryButton
            value={{
              child: <>Save Details</>,
              onClick: () => {
                if (error === "") {
                  setIsEditTrue(true);
                  updateUserDetails({
                    firstName: firstName,
                    id: userId,
                  });
                  handleSaveDetails();
                }
                handleFirstNameBlur();
              },
              style: {
                padding: "0.5rem 1rem",
                borderRadius: "7px",
                margin: "30px",
                marginBottom: "0px",
                fontSize: "14px",
              },
            }}
            type="button"
          />
        )}
      </div>
      <div className={styles.UserDetailsRow1}>
        <span className={styles.firstNameContainer}>
          <label htmlFor={styles.inputUserName}>Full name:</label>
          <input
            type="input"
            disabled={isEdit && error === ""}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            onBlur={handleFirstNameBlur}
            id={styles.inputUserName}
          ></input>
        </span>
      </div>
      <div className={styles.UserDetailsRow1}>
        <span className={styles.firstNameContainer}>
          <label
            htmlFor={styles.inputEmailID}
            className={styles.inputEmailIDLabel}
          >
            Email ID:
          </label>
          <input
            type="email"
            disabled
            value={email}
            id={styles.inputEmailID}
          ></input>
        </span>
        <span className={styles.lastNameContainer}>
          <label htmlFor={styles.inputLastName}>Phone number:</label>
          <input
            type="tel"
            pattern="[0-9]{10}"
            value={contact}
            onChange={(e) => {
              setContact(e.target.value);
            }}
            onBlur={handleFirstNameBlur}
            disabled={isEdit && error === ""}
            id={styles.inputLastName}
          ></input>
        </span>
      </div>
      <hr className="mt-4" />
      <div className="flex-center m-3 mt-0">
        <button
          onClick={() => {
            setShowChangePasswordModel(true);
          }}
          className={styles.changePasswordStyle}
          type="button"
        >
          Change password
        </button>
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </form>
  );
}
