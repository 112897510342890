import { Dropdown, Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import CheckDoubleFillIcon from "remixicon-react/CheckDoubleFillIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import More2LineIcon from "remixicon-react/More2LineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import { deleteScarAPI, downloadScarReportAPI } from "../../../../../IQCActivity/apiCalls/iqcAPIs";
import { showToastError, showToastSuccess } from "../../../../../../common/toasters/toasterMessages";
import { DownloadOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import PlayLineIcon from "remixicon-react/PlayLineIcon";


export default function SCARDetailedViewHeader(props) {
    const navigate = useNavigate();
    const { scarDetails, statusDetails, onCloseSCAR, onStartSCAR, setShowModal, setIsLoading } = props;
    const status = statusDetails?.filter(status => status?.mappedStatus === scarDetails?.status || status?.title === scarDetails?.status)[0]

    const [source, setSource] = useState(undefined);

    const onDelete = async () => {
        setIsLoading(true);
        let response = await deleteScarAPI(scarDetails?.id);
        if (!response.isError) {
            showToastSuccess(response.displayableMessage);
            navigate(-1);
        } else {
            showToastError(response.displayableMessage);
        }
        setIsLoading(false);

    };

    const downloadReport = async () => {
        setIsLoading(true);
        let response = await downloadScarReportAPI(source, scarDetails?.id);
        if (!response.isError) {
            saveAs(new Blob([response.data], { type: 'application/pdf' }), `${scarDetails?.key}-report`);
            showToastSuccess(response.displayableMessage);
        } else {
            showToastError(response.displayableMessage);
        }
        setIsLoading(false);
    }
    const items = [
        {
            key: 'start',
            label: "Start SCAR",
            icon: <PlayLineIcon size="20px" color={status?.title !== 'OPEN' ? '#00000040' :'#7d7676'} />,
            disabled: status?.title !== 'OPEN' && true,
            onClick: onStartSCAR,
        },
        {
            key: 'close',
            label: "Close SCAR",
            icon: <CheckDoubleFillIcon size="20px" color={status?.title === 'CLOSED' ? '#00000040' :'#7d7676'} />,
            disabled: status?.title === 'CLOSED' || status?.title === 'OPEN',
            onClick: onCloseSCAR,
        },
        {
            key: 'download',
            label: 'Download',
            icon: <DownloadOutlined style={{ fontSize: '20px' }} />,
            onClick: downloadReport
        },
        {
            key: 'edit',
            label: 'Edit SCAR',
            icon: <PencilLineIcon size="20px" />,
            onClick: () => { setShowModal(true); }
        },
        {
            key: 'delete',
            label: 'Delete SCAR',
            danger: true,
            icon: <DeleteBin6LineIcon size="20px" />,
            onClick: onDelete
        },
    ];

    useEffect(() => {
        // Create a CancelToken source
        const axiosSource = axios.CancelToken.source();
        setSource(axiosSource);
    }, []);

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <Space size='large'>
                        <ArrowLeftLineIcon color="#7D7676" onClick={() => navigate(-1)} />
                        <div style={{ fontWeight: 'bold' }}>{scarDetails?.key}</div>
                        <Tag color={status?.color}>{status?.title}</Tag>
                    </Space>
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <More2LineIcon style={{ cursor: "pointer" }} />
                    </Dropdown>
                </div>
            </div>
        </>

    );
}