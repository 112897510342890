import { ApiClient, ApiClientType } from "../../clients/api-client";

const host = process.env.REACT_APP_MASTER_BASE_URL;

export const getProductListApi = async (offset = 0, limit = '', name = '', id = '', includeProductCategory = false) => {
    return await ApiClient(
        ApiClientType.get,
        host,
        `/products?includeProductCategory=${includeProductCategory}&name=${name}&offset=${offset}&limit=${limit}&productCategoryId=${id}`
    );
};
