import styles from "../styles/navbar.module.css";
import Container from "react-bootstrap/Container";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { useState, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { dataFetchActionType } from "../../../states/dataFetch/dataFetchActionType";
import { useReducer } from "react";
import { DataFetchReducers } from "../../../states/dataFetch/dataFetchReducers";
import logout from "../../../../assets/icons/logout.svg";
import mainLogo from "../../../../assets/icons/mainLogo.svg";
import User3LineIcon from "remixicon-react/User3LineIcon";
import { GetUserId } from "../../../../utils/getUserId.js";
import { getTenantDetailsAPI, getUserDetailsAPI } from "../../../apis/commonApis";

export default function CustomNavbar(props) {
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(undefined);
  const userId = GetUserId();
  let navigate = useNavigate();
  const [userDetails, dispatchUserDetails] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const getUserDetails = async (id) => {
    let response = await getUserDetailsAPI(id);
    dispatchUserDetails({
      type: dataFetchActionType.data,
      data: response?.data,
    });
  };

  const getTenantDetails = async () => {
    let response = await getTenantDetailsAPI();
    if (!response.isError && response.data.length > 0) {
      setTenantDetails(response.data[0]);
    }
  };

  useEffect(() => {
    getUserDetails(userId);
    getTenantDetails();
  }, []);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowUserDropdown(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Navbar id={styles.navbar}>
      <Container fluid={true} style={{ margin: "10px 20px 10px 10px", width: "100%" }}>
        <Navbar.Brand>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Glacial Indifference",
            }}
          >
            <img src={mainLogo} alt="logo" />
            <h3
              style={{
                marginTop: "5px",
                fontStyle: "normal",
                fontFamily: "Glacial Indifference Bold",
                fontSize: "16px",
                letterSpacing: "-0.8px",
              }}
            >
              Workroom
            </h3>
            {tenantDetails && (
              <div style={{ margin: "10px 20px 10px 10px", borderLeft: "1px solid" }}>
                <img style={{ marginLeft: "10px", height: "40px", border: "0" }} className={"img-thumbnail"} src={tenantDetails?.meta?.logo?.url} alt={tenantDetails?.name} />
              </div>
            )}
          </div>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <div
              className={`${styles.userProfileContainer} flex-center`}
              tabIndex={0}
              onClick={() => {
                setShowUserDropdown(!showUserDropdown);
              }}
            >
              {userDetails.data && (
                <>
                  <div className="boldFont">
                    <div id={styles.userProfile} ref={dropdownRef}>
                      {userDetails?.data?.name?.charAt(0)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Navbar.Text>
          {showUserDropdown && (
            <div className={styles.userProfileDropdown}>
              <div className={styles.option1}>
                <p className={`boldFont ${styles.userName}`}>
                  {userDetails?.data?.name}
                </p>
                <p className={styles.emailStyle}>{userDetails.data.email}</p>
              </div>
              <hr className={styles.hrLine} />
              <div
                className={styles.option2}
                tabIndex={0}
                onClick={() => {
                  navigate("/workroom/userprofile");
                  setShowUserDropdown(!showUserDropdown);
                }}
              >
                <User3LineIcon
                  color="#7D7676"
                  style={{ height: "20px", width: "20px", marginRight: "5px" }}
                />
                My profile
              </div>
              <hr className={styles.hrLine} />
              <div
                className={styles.option3}
                tabIndex={0}
                onClick={async () => {
                  await signOut();
                  navigate("/auth");
                  setShowUserDropdown(!showUserDropdown);
                }}
              >
                <img
                  src={logout}
                  alt="logout"
                  style={{ width: "20px", height: "20px", marginRight: "5px" }}
                />
                Logout
              </div>
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
