import { getSheetDetailsAPI } from "../../../../../common/apis/commonApis";
import { useEffect, useState } from "react";
import { showToastError } from "../../../../../common/toasters/toasterMessages";
import { Table, Tooltip, Button, Divider, ConfigProvider, Popover, Tag } from "antd";
import styles from "../styles/inspectionLogs.module.css"
import { getInspectionSampleDataAPI } from "../../../apiCalls/iqcAPIs";
import ResizableTitle from "../../inspectionTable/components/resize";
import { SyncOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ExportColsModal from "../../../../../common/components/exportColsModal/exportColsModal";
import FileList2LineIcon from "remixicon-react/FileList2LineIcon";

export const TableHeader = (props) => {

    const {
        sheetName,
        handleRefresh,
        handleDownload,
        loading
    } = props.values;

    return (
        <div className={styles.tableHeaderDiv}>
            <div className={styles.tableHeader}>
                <FileList2LineIcon
                    color="#7d7676"
                />
                <span className={styles.tableHeaderTitle}>{sheetName}</span>
            </div>
            <div className={styles.btnsDiv}>
                <Tooltip title="Refresh">
                    <Button icon={<SyncOutlined spin={loading} tool />} onClick={handleRefresh} disabled={loading} />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Download">
                    <Button icon={<DownloadOutlined />} onClick={handleDownload} disabled={loading} />
                </Tooltip>

            </div>
        </div>
    )
}

export const SheetLogTable = (props) => {

    const { sheet, inspectionDetails } = props.values;

    const [sheetDetails, setSheetDetails] = useState({});

    const [cols, setCols] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [inspectionSampleData, setInspectionSampleData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [showColModal, setShowColModal] = useState(false);

    const generateDataSource = () => {
        const data = inspectionSampleData?.inspectionSampleData?.map(inspectionSample => {
            let sample = {
                key: inspectionSample?.inspectionSample?.id,
                title: inspectionSample?.inspectionSample?.title,
                verdict: inspectionSample?.inspectionSample?.verdict,
            }
            inspectionSample?.fillSheetOrders?.forEach(fillSheetOrder => {
                sample['fillSheetOrder'] = fillSheetOrder?.id;
                fillSheetOrder?.data?.fieldValues?.forEach(fieldValue => {
                    let displayableFieldValue = 
                        Array.isArray(fieldValue?.fieldValue) 
                        ? fieldValue?.fieldValue.join(", ")
                        : fieldValue?.fieldValue;
                    sample[fieldValue?.fieldId] = {
                        fieldValue: displayableFieldValue,
                        exportableValue: displayableFieldValue,
                    }
                    if (fieldValue?.isTriggered !== null && fieldValue?.isTriggered) {
                        sample[fieldValue?.fieldId]['triggerDetails'] = fieldValue?.triggerDetails;
                    }
                })
            });
            return sample;
        });
        setDataSource(data);
    };

    useEffect(() => {
        if (inspectionSampleData)
            generateDataSource();
    }, [inspectionSampleData]);

    const fetchInspectionSampleData = async () => {
        setLoading(true);
        const response = await getInspectionSampleDataAPI(inspectionDetails?.id, sheet.id);
        if (response.isError) {
            showToastError(response.displayableMessage)
        }
        setInspectionSampleData(response.data);
        setLoading(false);
    }

    useEffect(() => {
        if (inspectionDetails?.id && sheet.id)
            fetchInspectionSampleData();
    }, [inspectionDetails])

    const fetchSheetData = async () => {
        let response = await getSheetDetailsAPI(sheet.id, true, false, true);
        if (!response.isError) {
            setSheetDetails(response?.data)
        } else {
            showToastError(response?.displayableMessage)
        }
    }

    useEffect(() => {
        if (sheet.id?.length > 0) {
            fetchSheetData();
        }
    }, [sheet.id])

    const [mergeColumns, setMergeColumns] = useState([]);

    const handleResize = (index) =>
        (_, { size }) => {
            const newColumns = [...cols];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setCols(newColumns);
        };

    useEffect(() => {
        const mergeColumnData = cols?.map((col, index) => {
            col = {
                ...col,
                onHeaderCell: (column) => ({
                    width: column.width,
                    onResize: handleResize(index),
                    minWidth: column.minWidth,
                }),
            };
            return col;
        });
        setMergeColumns(mergeColumnData);
    }, [cols]);

    const createColumns = async () => {
        let columns = [
            {
                title: 'Title',
                fixed: 'left',
                width: 120,
                minWidth: 120,
                dataIndex: ['title'],
            },
            {
                title: 'Verdict',
                fixed: 'left',
                width: 80,
                minWidth: 80,
                dataIndex: ['verdict'],
                render: (text) => {
                    if (text === 'OK') {
                        return <Tag color="#27C052" style={{ fontWeight: "bold" }}>{text}</Tag>
                    } else if (text === 'NOK') {
                        return <Tag color="#ED0000" style={{ fontWeight: "bold" }}>{text}</Tag>
                    }
                },
            },
        ]
        const sheetColumns = sheetDetails?.sections?.flatMap((section) =>
            (section.fields || []).map((field) => ({
                title: field?.properties?.title,
                dataIndex: [field?.id],
                width: 300,
                minWidth: 300,
                render: (text) => (
                    {
                        props: {
                            style: text?.triggerDetails?.length > 0 && { background: "#ED000026" }
                        },
                        children:
                            <div className={styles.cellContent}>
                                <div className={styles.textContain}>
                                    {text?.fieldValue}
                                </div>
                                {
                                    text?.triggerDetails?.length > 0 &&
                                    <Popover content={text?.triggerDetails[0]?.triggerMessage} trigger={"click"}>
                                        <InfoCircleOutlined style={{ cursor: "pointer", color: "red", fontSize: "18px" }} />
                                    </Popover>
                                }
                            </div>
                    }
                )
            }))
        );
        columns = columns.concat(sheetColumns);
        setCols(columns);
    }

    useEffect(() => {
        if (sheetDetails)
            createColumns();
    }, [sheetDetails])

    const components = {
        header: {
            cell: ResizableTitle,
        },
    };

    const handleRefresh = () => {
        fetchSheetData();
        fetchInspectionSampleData();
    }

    const handleDownload = () => {
        setShowColModal(true);
    }

    return (
        <div>
            <ExportColsModal
                openModal={showColModal}
                setOpenModal={setShowColModal}
                columns={mergeColumns}
                dataSource={dataSource}
                setLoader={setLoading}
                filename={`${inspectionDetails?.key}-inspection-data.csv`}
                isObjectDataSource={true}
            />
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius: "0",
                            borderColor: '#dadada',
                            headerBg: "#f1f1f1",
                        }
                    }
                }}
            >
                <Table
                    loading={loading}
                    className={styles.tableCSS}
                    bordered
                    size="middle"
                    title={() => <TableHeader
                        values={{
                            sheetName: sheet.name,
                            handleRefresh,
                            handleDownload,
                            loading
                        }}
                    />}
                    components={components}
                    dataSource={dataSource}
                    columns={mergeColumns}
                    scroll={{ x: 1000, y: 500 }}
                    pagination={false}
                />
            </ConfigProvider>
        </div>
    )

}