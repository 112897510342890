import { useContext, useEffect, useState } from "react";
import {
  startInspectionAPI,
  closeInspectionAPI,
  deleteInspectionAPI,
  getInspectionByKeyAPI,
} from "../../apiCalls/iqcAPIs";
import styles from "./styles/inspectionDetailedView.module.css";
import {
  showToastError,
  showToastWarning,
  showToastSuccess,
  showToastLoading,
  showToastUpdate,
} from "../../../../common/toasters/toasterMessages";
import { InspectionModal } from "../inspectionModal/inspectionModal";
import InspectionDetailedViewHeader from "../inspectionDetailedViewHeader/inspectionDetailedViewHeader";
import InspectionDetailedViewBody from "../inspectionDetailedViewBody/inspectionDetailedViewBody";
import { useNavigate, useParams } from "react-router-dom";

export default function InspectionDetailedView() {

  const params = useParams();

  const navigate = useNavigate();
  const inspectionKey = params.inspectionKey;

  const [inspectionDetails, setInspectionDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const fetchInspectionDetailsByKey = async () => {
    setIsLoading(true);
    let response = await getInspectionByKeyAPI(inspectionKey);
    if(response?.data) {
      setInspectionDetails(response?.data[0])
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if(inspectionKey?.length > 0) fetchInspectionDetailsByKey();
  }, [inspectionKey])

  const closeInspection = async () => {
    setIsLoading(true);
    if (inspectionDetails?.verdict !== "") {
      let response = await closeInspectionAPI(inspectionDetails?.id);
      if (response.isError) {
        showToastError(response.displayableMessage);
        return; 
      } else {
        showToastSuccess(response.displayableMessage);
        fetchInspectionDetailsByKey();
      }
    } else {
      showToastWarning("Please select the lot status and close the inspection");
    }
    setIsLoading(false);
  };

  const startInspection = async () => {
    const startInspectionToaster = showToastLoading("Please wait while the inspection starts")
    let response = await startInspectionAPI(inspectionDetails?.id);
    if (response.isError) {
      showToastUpdate(startInspectionToaster, "error", response.displayableMessage)
      return;
    } else {
      showToastUpdate(startInspectionToaster, "success", response.displayableMessage)
      fetchInspectionDetailsByKey();
    }
  };

  const deleteInspection = async () => {
    setIsLoading(true);
    let response = await deleteInspectionAPI(inspectionDetails?.id);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      navigate(-1);
      showToastSuccess(response.displayableMessage);
    }
    setIsLoading(false);
  }

  return (
      <>
        <InspectionModal
          showModal={showEditModal}
          isEdit={true}
          setShowModal={setShowEditModal}
          fetchInspectionDetails={fetchInspectionDetailsByKey}
          inspectionDetails={inspectionDetails}
        />
        <div className={styles.detailsContainer}>
          <InspectionDetailedViewHeader
            values={{
              key: inspectionDetails?.key,
              status: inspectionDetails?.status,
              closeInspectionHandler: async () => {
                await closeInspection();
              },
              deleteInspectionHandler: async () => {
                await deleteInspection();
              },
              startInspectionHandler: startInspection,
              showInspectionModal: () => {
                setShowEditModal(true);
              },
            }}
          />
          <InspectionDetailedViewBody
            values={{
              isLoading,
              inspectionDetails,
              fetchInspectionDetailsByKey
            }}
          />
        </div>
      </>
  );
}
