import OpenDelayedComponent from "../components/openDelayed";
import { dateTimeFormat } from "../../../../../utils";
export const instrumentsTableCols = (onCalibrationClick, params, navigate) => [
  {
    title: "Instrument Name",
    dataIndex: ["instrument", "name"],
    key: "name",
    width: 150,
    minWidth: 100,
    fixed: "left",
    ellipsis: true,
    render: (text, record) => (
      <OpenDelayedComponent
        val={record}
        onCalibrationClick={onCalibrationClick}
        params={params}
        navigate={navigate}
      />
    ),
  },
  {
    title: "Serial Number",
    dataIndex: ["instrument", "code"],
    key: "code",
    width: 150,
    minWidth: 100,
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "Make",
    dataIndex: ["instrument", "make"],
    key: "make",
    width: 120,
    minWidth: 100,
  },
  {
    title: "Location",
    dataIndex: ["instrument", "station", "name"],
    key: "name",
    width: 100,
    minWidth: 100,
  },
  {
    title: "Last Calibrated Date",
    dataIndex: ["instrument", "lastCalibratedAt"],
    key: "lastCalibratedAt",
    width: 100,
    minWidth: 100,
    render: (text) => (text ? dateTimeFormat(text) : ""),
  },
];
