import React, { useEffect, useRef, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { getApplicationDetailsAPI, getDashboardToken } from "../../../common/apis/commonApis.js";
import { showToastError } from "../../../common/toasters/toasterMessages.js";
import { useParams } from "react-router-dom";
import { Result } from 'antd';
import styles from '../styles/dashboard.module.css';

export default function Dashboard() {

    let params = useParams();

    const supersetContainer = useRef(null);

    const [dashboardId, setDashboardId] = useState("");

    const getDashboardIdForApplication = async (appId) => {
        const response = await getApplicationDetailsAPI(appId);
        if (response?.isError) {
            showToastError(response.displayableMessage);
        } else {
            setDashboardId(response?.data?.meta?.analytics?.dashboard[0]?.id);
        }
    }

    useEffect(() => {
        if (dashboardId?.length > 0) {
            return
        } else {
            getDashboardIdForApplication(params.appId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId])

    const getToken = async (dashboardId) => {
        const response = await getDashboardToken(dashboardId);
        if (response?.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        return response?.data?.token
    }

    useEffect(() => {
        if (dashboardId?.length > 0) {
            embedDashboard({
                id: dashboardId, // given by the Superset embedding UI
                supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN,
                mountPoint: supersetContainer.current, // html element in which iframe render
                fetchGuestToken: () => getToken(dashboardId),
                dashboardUiConfig: {
                    hideTitle: true,
                    // hideChartControls: true,
                    hideTab: true,
                },
            });

            const iframe = supersetContainer.current.querySelector('iframe');

            if (iframe) {
                iframe.style.width = "100%";
                iframe.style.height = "800px";
            }
        }
    }, [supersetContainer, dashboardId]);

    return (
        <React.Fragment>
            {
                dashboardId?.length > 0
                    ? <div className={styles.supersetContainer} ref={supersetContainer}></div>
                    : <Result
                        status="500"
                        title="No Dashboard, yet."
                        subTitle="Kindly, contact your provider for the dashboard setup."
                    />
            }
        </React.Fragment>
    )
}