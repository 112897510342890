import { Divider, Space, message } from "antd";
import RMAHeader from "./header";
import RMABody from "./body";
import { useEffect, useState } from "react";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../../clients/api-constants";
import { getAFRDefectCategoriesApi, getAFRRepairCategoriesApi, searchAFRApi } from "../../apis/afrAPIs";
import { searchCustomerApi } from "../../../customers/apis/customerApis";
import { getProductListApi } from "../../../../common/apis/productApis";
import { getSuppliersAPI } from "../../../../common/apis/supplierApis";

export default function RMA(props) {
    const [afrList, setAfrList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [selectedAFR, setSelectedAFR] = useState(undefined);
    const [defectCategoryList, setDefectCategoryList] = useState([]);
    const [repairCategoryList, setRepairCategoryList] = useState([]);


    const [selectedFilters, setSelectedFilters] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(DEFAULT_OFFSET);

    const searchAFR = async (filters = {}, pageOffset = offset, limit = currentPageSize) => {
        setAfrList([]);
        setIsLoading(true);
        const response = await searchAFRApi(filters, pageOffset, limit);
        if (!response.isError) {
            setAfrList(response?.data);
            setTotalCount(response?.totalCount)
        } else {
            message.error(response.displayableMessage);
        }
        setIsLoading(false);
    };

    const getCustomerList = async (filters = {}) => {
        const response = await searchCustomerApi(filters);
        if (!response.isError) {
            setCustomerList(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
    };

    const getProductList = async (offset = 0, limit = '', name = '', id = '', includeProductCategory = true) => {
        const response = await getProductListApi(offset, limit, name, id, includeProductCategory);
        if (!response.isError) {
            setProductList(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
    };

    const getSupplierList = async () => {
        const response = await getSuppliersAPI();
        if (!response.isError) {
            setSupplierList(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
    };

    const getAFRDefectCategories = async () => {
        const response = await getAFRDefectCategoriesApi();
        if (!response.isError) {
            setDefectCategoryList(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
    }

    const getAFRRepairategories = async () => {
        const response = await getAFRRepairCategoriesApi();
        if (!response.isError) {
            setRepairCategoryList(response?.data);
        } else {
            message.error(response.displayableMessage);
        }
    }

    const handlePaginationChange = (page, pageSize) => {
        if (pageSize !== currentPageSize) {
            setCurrentPage(1);
            setOffset(0);
            setCurrentPageSize(pageSize);
            searchAFR(selectedFilters, 0, pageSize);
        } else {
            setOffset(currentPageSize * (page - 1));
            setCurrentPage(page);
            searchAFR(selectedFilters, (currentPageSize * (page - 1)), currentPageSize);
        }
    };

    const handleFilterChange = (filters) => {
        setSelectedFilters(filters);
        searchAFR(filters, 0, currentPageSize);
    }

    const handleRefresh = () => {
        searchAFR(selectedFilters, 0, currentPageSize);
    }

    useEffect(() => {
        searchAFR();
        getCustomerList();
        getProductList();
        getSupplierList();
        getAFRDefectCategories();
        getAFRRepairategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <RMAHeader customerData={customerList} searchAFR={searchAFR} selectedAFR={selectedAFR} setSelectedAFR={setSelectedAFR}
                productData={productList} supplierData={supplierList} onFilterChange={handleFilterChange} defectCategoryList={defectCategoryList}
                repairCategoryList={repairCategoryList}
            />
            <Divider />
            <RMABody afrData={afrList} onRefresh={handleRefresh} customerData={customerList} supplierData={supplierList}
                productData={productList} setSelectedAFR={setSelectedAFR} onPaginationChange={handlePaginationChange}
                totalRecords={totalCount} currentPageSize={currentPageSize} currentPage={currentPage} isLoading={isLoading} setLoading={setIsLoading} />
        </Space>
    )
}