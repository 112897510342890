import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ArrowGoBackLineIcon from "remixicon-react/ArrowGoBackLineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import MoreLineIcon from "remixicon-react/MoreLineIcon";

export default function DeleteRevertToolTip(props) {
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover id={`popover-positioned-bottom`}>
          <Popover.Body>
            {
                props.type === 'delete' && 
                <DeleteBin6LineIcon
                    size="20px"
                    style={{
                    color: "red",
                    cursor: "pointer"
                    }}
                    onClick={props.clickHandler}
                />
            }
            {
                props.type === 'revert' &&
                <ArrowGoBackLineIcon
                    size="20px"
                    style={{
                        color: "red",
                        marginLeft: "0.5rem",
                        cursor: "pointer"
                    }}
                    onClick={props.clickHandler}
                />
            }
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="secondary"
        style={{ border: "none", background: "none", float: "right" }}
      >
        <MoreLineIcon style={{ color: "grey", cursor: "pointer" }} />
      </Button>
    </OverlayTrigger>
  );
}
