import { Card } from "antd";
import SCARDetailedViewHeader from "./components/header";
import SCARDetailsTab from "./components/details/details";
import { useEffect, useState } from "react";
import SCARDetailTasksTab from "./components/task/tasks";
import SCARDetailDocumentsTab from "./components/documents/documents";
import { useParams } from "react-router-dom";
import { createScarTaskAPI, deleteTaskAPI, getScarDetailsByKeyAPI, getScarStatusAPI, getScarTasksAPI, updateScarAPI, updateScarTaskAPI } from "../../../apis/scarAPIs";
import { showToastError, showToastSuccess } from "../../../../../common/toasters/toasterMessages";
import { closeScarAPI, startScarAPI } from "../../../../IQCActivity/apiCalls/iqcAPIs";
import EditSCARModal from "./components/editScarModal";
import { getUserDetailsAPI } from "../../../../../common/apis/commonApis";
export default function SCARDetailedView(props) {
  const [activeTabKey, setActiveTabKey] = useState('details');
  const [isLoading, setIsLoading] = useState(false);
  const [scarDetails, setScarDetails] = useState(undefined);
  const [taskList, setTaskList] = useState([]);
  const [statusDetails, setStatusDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);


  const params = useParams();

  const scarKey = params.scarKey;

  const tabList = [
    {
      key: 'details',
      label: 'Details'
    },
    {
      key: 'tasks',
      label: 'Tasks'
    },
    {
      key: 'documents',
      label: 'Documents'
    },
  ];


  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const createSCARTask = async (data) => {
    setIsLoading(true);
    let response;
    response = await createScarTaskAPI(scarDetails?.id, data);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      fetchScarTasks(scarDetails?.id);
    }
    setIsLoading(false);
  };

  const editSCARTask = async (data) => {
    setIsLoading(true);
    let response;
    response = await updateScarTaskAPI(scarDetails?.id, data?.id, data);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      fetchScarTasks(scarDetails?.id);
    }
    setIsLoading(false);
  };

  const deleteSCARTask = async (taskId) => {
    setIsLoading(true);
    let response;
    response = await deleteTaskAPI(scarDetails?.id, taskId);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      showToastSuccess(`Task deleted successfully!`);
      fetchScarTasks(scarDetails?.id);
    }
    setIsLoading(false);
  };

  const fetchSCARByKey = async () => {
    setIsLoading(true);
    let response;
    response = await getScarDetailsByKeyAPI(scarKey);
    if (!response.isError && response?.data?.length > 0) {
      const scarData = response.data[0];
      if (response?.data[0]?.trailMeta?.intimatedBy) {
        const userDetails = await fetchUserFromId(response?.data[0]?.trailMeta?.intimatedBy);
        scarData.trailMeta['intimatedUser'] = userDetails;
      }
      setScarDetails(scarData);
    } else {
      showToastError(response.displayableMessage);
    }
    setIsLoading(false);
  }

  const fetchScarTasks = async (scarId) => {
    setIsLoading(true);
    let response;
    response = await getScarTasksAPI(scarId);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      setTaskList(response?.data);
    }
    setIsLoading(false);
  };

  const contentList = {
    details: <SCARDetailsTab scarDetails={scarDetails} />,
    tasks: <SCARDetailTasksTab scarDetails={scarDetails} setIsLoading={setIsLoading} editSCARTask={editSCARTask}
      isLoading={isLoading} taskList={taskList} createSCARTask={createSCARTask} deleteSCARTask={deleteSCARTask} />,
    documents: <SCARDetailDocumentsTab scarDetails={scarDetails} taskList={taskList} setIsLoading={setIsLoading} setScarDetails={setScarDetails} setTaskList={setTaskList} />
  };

  const getStatus = async () => {
    const response = await getScarStatusAPI();
    if (!response.isError) {
      setStatusDetails(response?.data);
    } else {
      showToastError(response?.displayableMessage);
    }
  };

  const onCloseSCAR = async () => {
    const response = await closeScarAPI(scarDetails?.id);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
      fetchSCARByKey();
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const onStartSCAR = async () => {
    const response = await startScarAPI(scarDetails?.id);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
      fetchSCARByKey();
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const updateSCAR = async (data) => {
    const response = await updateScarAPI(data?.id, data);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
      fetchSCARByKey();
    } else {
      showToastError(response?.displayableMessage);
    }
  }

  const fetchUserFromId = async (userId) => {
    setIsLoading(true);
    const response = await getUserDetailsAPI(userId);
    if (response?.isError) {
      showToastError(response.displayableMessage);
    } else {
      return response?.data;
    }
    setIsLoading(false);
    return undefined;
  };

  useEffect(() => {
    if (scarDetails && scarDetails?.id && activeTabKey !== "details") {
      fetchScarTasks(scarDetails?.id);
    }
  }, [scarDetails, activeTabKey]);

  useEffect(() => {
    if (scarKey?.length > 0) { fetchSCARByKey(); getStatus(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scarKey]);

  return (
    <>
      <EditSCARModal open={showModal} setOpen={setShowModal} scarDetails={scarDetails} updateSCAR={updateSCAR} />

      <Card title={<SCARDetailedViewHeader scarDetails={scarDetails} statusDetails={statusDetails}
        onCloseSCAR={onCloseSCAR} setShowModal={setShowModal} setIsLoading={setIsLoading} onStartSCAR={onStartSCAR} />}
        loading={isLoading}
        tabList={tabList}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={handleTabChange}
        style={{ width: '100%', marginTop: 25 }}>
        {contentList[activeTabKey]}
      </Card>
    </>
  );
}