import { Button, Space, Table, Tag, Typography } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import { dateTimeFormat } from "../../../../utils";
import { useNavigate, useParams } from "react-router-dom";


export default function SCARBody(props) {
    const params = useParams();
    const { scarsData, getchScars, isLoading } = props;
    const navigate = useNavigate();

    const cols = [
        {
            key: 'status',
            title: 'SCAR Status',
            align: 'center',
            width: '10%',
            dataIndex: 'status',
            render: (text) => {
                return (<>
                    {text === 'OPEN' ?
                        <Tag color="#7d7676" style={{ fontWeight: "bold" }}>{text}</Tag>
                        : text === 'ONGOING' ? <Tag color="orange-inverse" style={{ fontWeight: "bold" }}>{text}</Tag>
                            : text === 'SUBMITTED' ? <Tag color="blue-inverse" style={{ fontWeight: "bold" }}>{text}</Tag>
                                : text === 'CLOSED' ? <Tag color="green-inverse" style={{ fontWeight: "bold" }}>{text}</Tag> : text}
                </>)
            }
        },
        {
            key: 'id',
            title: 'SCAR ID',
            align: 'center',
            width: '5%',
            dataIndex: 'key',
            render: (text) =>
                <Button
                    type="link"
                    onClick={() => {
                        navigate(`/application/${params.appId}/scars/${text}/details`)
                    }}
                >{text}</Button>,
        },
        {
            key: 'title',
            title: 'SCAR Title',
            align: 'center',
            width: '15%',
            dataIndex: 'title'
        },
        {
            key: 'supplier',
            title: 'Supplier',
            align: 'center',
            width: '15%',
            dataIndex: ['supplier', 'name']
        },
        {
            key: 'customer',
            title: 'Customer',
            align: 'center',
            width: '15%',
            dataIndex: ['customer', 'name']
        },
        {
            key: 'qualityInspection',
            title: 'Quality Inspection',
            align: 'center',
            width: '10%',
            dataIndex: ['qualityInspection', 'key']
        },
        {
            key: 'incomingPart',
            title: 'IC Part Name',
            align: 'center',
            width: '10%',
            dataIndex: ['incomingPart', 'name']
        },
        {
            key: 'dueDateTime',
            title: ' Due by',
            align: 'center',
            width: '15%',
            dataIndex: 'dueDateTime',
            render: (_, record) => <span>{dateTimeFormat(record.dueDateTime)}</span>
        }
    ]
    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span>
                            <Typography.Text strong>SCARs </Typography.Text>
                            <Typography.Text type="secondary" strong>({scarsData?.length})</Typography.Text>
                        </span>
                    </div>
                    <div style={{ float: "right" }}>
                        <Button icon={<SyncOutlined spin={false} />} disabled={false} onClick={() => { getchScars(); }} />
                    </div>
                </div>
                <Table
                    size="small"
                    bordered
                    dataSource={scarsData}
                    columns={cols}
                    loading={isLoading}
                />
            </Space>
        </>
    );
}