import { Tabs } from "antd";
import RMA from "../components/rmaComponents/rma";

export default function AFR(props) {
    return (
        <>
            <Tabs defaultActiveKey="rma" items={[
                {
                    key: 'rma',
                    label: 'RMA',
                    children: <RMA />
                }
            ]} onChange={() => { }} />
        </>
    );
}