import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef, useReducer } from "react";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton.js";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import ImageAddLineIcon from "remixicon-react/ImageAddLineIcon";
import styles from "../../../styles/table.module.css";
import { DataFetchReducers } from "../../../../../common/states/dataFetch/dataFetchReducers.js";
import { dataFetchActionType } from "../../../../../common/states/dataFetch/dataFetchActionType.js";
import Image from "react-bootstrap/Image";
import Multiselect from "multiselect-react-dropdown";
import { uploadImage } from "../../../../../common/components/uploadImage/uploadImage.js";

export default function StationDetailsModal(props) {
  let station = props.value.selectedStation;
  const stationMachines = props.value.machinesList;

  const inputRef = useRef(null);
  const [stationName, setStationName] = useState("");
  const [stationDescription, setStationDescription] = useState("");
  const [stationCode, setStationCode] = useState("");
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedMachineNames, setSelectedMachineNames] = useState([]);
  const [stationImage, setStationImage] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [uploadStatus, dispatchUploadStatus] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const uploadImages = async () => {
    const folderPath = "stations";
    uploadImage(inputRef, setStationImage, dispatchUploadStatus, folderPath);
  };

  useEffect(() => {
    setStationName(station?.name);
    setStationCode(station?.code);
    setStationDescription(station?.description);
    setStationImage(station?.meta?.images ? station?.meta?.images : []);
    let selectedStationMachines = [],
      selectedStationMachineNames = [];
    if (station?.stationMachines) {
      station?.stationMachines.map((item, index) => {
        selectedStationMachines.push(item.machine.id);
        selectedStationMachineNames.push({
          name: item.machine.name,
          id: item.machine.id,
        });
      });

      setSelectedMachines(selectedStationMachines);
      setSelectedMachineNames(selectedStationMachineNames);
    }
  }, [props.value.selectedStation]);

  const onCloseModal = () => {
    setShowButton(false);
    setStationName("");
    setStationCode("");
    setStationDescription("");
    setSelectedMachines([]);
    dispatchUploadStatus({
      type: dataFetchActionType.data,
      data: null,
    });
    setStationImage([]);
    setSelectedMachineNames([]);
    props.value.onHide();
  };

  if (station) {
    return (
      <Modal
        show={props.value.show}
        onHide={onCloseModal}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              letterSpacing: "-0.4px",
            }}
          >
            <span className="boldFont modalHeading">Station Details</span>
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            props.value.onSubmitModal({
              name: stationName,
              description: stationDescription,
              code: stationCode,
              images: stationImage,
              stationMachines: selectedMachines,
            });
            onCloseModal();
          }}
        >
          <Modal.Body style={{ padding: "10px 30px 30px 30px" }}>
            <Form.Floating className="mb-3">
              <Form.Control
                onFocus={() => setShowButton(true)}
                value={stationName}
                onChange={(e) => setStationName(e.target.value)}
                placeholder="Station Name"
                required
              />
              <label
                htmlFor="floatingInputCustom"
                style={{ color: "#7D7676", width: "115%" }}
              >
                Station Name
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              </label>
            </Form.Floating>
            <Form.Floating className="mb-3">
              <Form.Control
                onFocus={() => setShowButton(true)}
                value={stationDescription}
                onChange={(e) => setStationDescription(e.target.value)}
                placeholder="Station Description"
              />
              <label
                htmlFor="floatingInputCustom"
                style={{ color: "#7D7676", width: "115%" }}
              >
                Station Description
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              </label>
            </Form.Floating>
            <Form.Floating className="mb-3">
              <Form.Control
                onFocus={() => setShowButton(true)}
                value={stationCode}
                onChange={(e) => setStationCode(e.target.value)}
                placeholder="Station Code"
              />
              <label
                htmlFor="floatingInputCustom"
                style={{ color: "#7D7676", width: "115%" }}
              >
                Station Code
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              </label>
            </Form.Floating>

            <div
              className="mb-3"
              style={{
                border: "1px solid #dadada",
                borderRadius: "7px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <input
                onClick={() => setShowButton(true)}
                ref={inputRef}
                onChange={uploadImages}
                className="d-none"
                type="file"
              />
              {uploadStatus.data || stationImage.length != 0 ? (
                <div>
                  <div
                    onClick={() => {
                      setShowButton(true);
                      setStationImage([]);
                      dispatchUploadStatus({
                        type: dataFetchActionType.data,
                        data: null,
                      });
                    }}
                    style={{
                      marginBottom: "-13px",
                      zIndex: 10,
                      padding: "2.5px",
                      borderRadius: "50px",
                      background: "#dadada",
                      float: "right",
                      width: "30px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CloseLineIcon size={16} style={{ marginBottom: "7px" }} />
                  </div>
                  <div>
                    <Image
                      onClick={handleUpload}
                      src={stationImage?.[0]}
                      rounded
                      style={{
                        maxHeight: "100%",
                        width: "200px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <button
                  style={{
                    border: "1px solid #dadada",
                    borderRadius: "10px",
                    padding: "10px",
                    background: "white",
                  }}
                  type="button"
                  onClick={handleUpload}
                  className="light"
                >
                  {uploadStatus.loading ? (
                    <div>
                      <ImageAddLineIcon
                        style={{
                          marginRight: "10px",
                          marginBottom: "3px",
                          color: "#7D7676",
                        }}
                      />
                      Uploading...
                    </div>
                  ) : (
                    <div style={{ color: "#7D7676" }}>
                      <ImageAddLineIcon
                        style={{
                          marginRight: "10px",
                          marginBottom: "3px",
                        }}
                      />
                      Upload Station Image
                    </div>
                  )}
                </button>
              )}
            </div>

            <Multiselect
              style={{ padding: "1rem" }}
              options={stationMachines?.map((item, index) => {
                return { name: item.name, id: item.id };
              })}
              closeIcon="cancel"
              placeholder="Linked Stations"
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                setShowButton(true);
                const index = selectedMachines?.indexOf(item.id);
                if (index > -1) {
                  selectedMachines?.splice(index, 1);
                }
              }}
              onSelect={(list, item) => {
                setShowButton(true);
                if (!selectedMachines.includes(item.id)) {
                  setSelectedMachines([...selectedMachines, item.id]);
                }
              }}
              selectedValues={selectedMachineNames}
            />
          </Modal.Body>

          {showButton ? (
            <Modal.Footer style={{ justifyContent: "center", border: "none" }}>
              <PrimaryButton
                value={{
                  type: "submit",
                  child: "Save Station",
                  buttonStyle: "modalCTA",
                }}
              />
            </Modal.Footer>
          ) : null}
        </Form>
      </Modal>
    );
  }
}
