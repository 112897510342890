import { useEffect, useState } from "react";
import { ApiClient, ApiClientType } from "../../../../../../../clients/api-client";
import { showToastError } from "../../../../../../../common/toasters/toasterMessages";
import { FileUploader } from "../../../../../../../common/components/fileUploader/fileUploader";
import axios from "axios";
import { updateScarTaskAPI } from "../../../../../apis/scarAPIs";

export default function TaskDocuments(props) {
    const { taskDetails, setTaskDetails } = props;
    const [fileList, setFileList] = useState([]);

    const isInUploadingState = (item) => {
        return item?.status === "uploading";
    };

    const convertToBackendMediaObject = (newFileList) => {
        const formattedFileList = [];
        newFileList?.forEach((ele) => {
            let fileObj = {};
            if (ele.status === "done" && ele.response?.data?.url) {
                fileObj = {
                    name: ele.name,
                    type: ele.type,
                    size: ele.size,
                    url: ele.response?.data?.url
                };
                formattedFileList.push(fileObj);
            } else if (ele.url !== "") {
                formattedFileList.push(ele);
            }
        });
        return {
            media: formattedFileList
        };
    };

    const updateTaskDetails = async (updateTaskDetails, imagesList) => {
        const data = {
            ...updateTaskDetails,
            taskAssignees: updateTaskDetails?.taskAssignees?.map(taskAssignees => taskAssignees?.assigneeId),
            media: imagesList?.[0]?.media
        };
        const response = await updateScarTaskAPI(updateTaskDetails?.sourceId, updateTaskDetails?.id, data);
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTaskDetails({
                ...taskDetails,
                meta: { ...taskDetails?.meta, media: data?.media }
            });
        }
    };

    const onChangeFileList = (newFileList) => {
        setFileList(newFileList);

        let isUploadingState = false;
        newFileList?.forEach((item) => {
            const isUploading = isInUploadingState(item);
            if (isUploading) {
                isUploadingState = true;
            }
        });
        if (isUploadingState) {
            return;
        }
        const metaObj = convertToBackendMediaObject(newFileList);
        void updateTaskDetails(taskDetails, [metaObj]);
    };

    const fileUpload = async ({ file, onSuccess, onError }) => {
        const urlExtension = `/tasks/${taskDetails?.id}/upload`;

        const response = await ApiClient(
            ApiClientType.post,
            process.env.REACT_APP_MASTER_BASE_URL,
            urlExtension,
            {}
        );

        if (!response.isError) {
            await axios
                .put(response?.data?.uploadLink?.url, file, {
                    headers: {
                        "Content-Type": file.type
                    }
                })
                .then((res) => {
                    onSuccess(response, file);
                })
                .catch((err) => {
                    onError(err, file);
                });
        } else {
            showToastError("Error in uploading media");
        }
    };

    useEffect(() => {
        setFileList(taskDetails?.meta?.media);
    }, [taskDetails])

    return (
        <>
            <FileUploader
                listType={"picture"}
                fileList={fileList}
                onChangeFileList={onChangeFileList}
                customRequest={fileUpload}
            />
        </>
    )
}