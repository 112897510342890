import { toast } from "react-toastify";

export const showToastSuccess = (message) => {
  toast.success(message);
};

export const showToastError = (message) => {
  toast.error(message);
};

export const showToastWarning = (message) => {
  toast.warning(message);
};

export const showToastInfo = (message) => {
  toast.info(message);
};

export const showToastLoading = (message) => {
  return toast.loading(message)
}

export const showToastUpdate = (id, type, message) => {
  toast.update(id, { 
    render: message, 
    type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, 
    isLoading: false, 
    autoClose: 2000, 
    closeButton: true
  })
}
