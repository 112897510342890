import { ApiClient, ApiClientType } from "../../../clients/api-client";

///// suppliers apis ///////////

export const getSuppliersAPI = async (pageOffset, pageLimit, ids = undefined) => {
  let url = `/suppliers?offset=${pageOffset}&limit=${pageLimit}`;
  ids?.forEach(id => {
    url += `&id[]=${id}`;
  });
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    url
  );
};

export const updateSupplierApi = async (body, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers/${id}`,
    body
  );
};

export const createSupplierApi = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers`,
    body
  );
};

////// incoming part apis ////////

export const getIncomingPartsAPI = async (pageOffset, pageLimit) => {
  return ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts?offset=${pageOffset}&limit=${pageLimit}&includeSuppliers=true&supplierId=`
  );
};

export const updateIncomingPartApi = async (body, id) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts/${id}`,
    body
  );
};

export const createIncomingPartApi = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts`,
    body
  );
};
