import { Card, Descriptions, Skeleton } from "antd";
import { dateTimeFormat } from "../../../../../../../utils";

export default function TaskInfo(props) {
    const { sheetsLoading, taskDetails, sheetNames, assignee } = props;

    const items = [
        {
            key: 'title',
            label: 'Task Name',
            children: taskDetails?.title,
            span: 2,
        },
        {
            key: 'assignee',
            label: 'Assignee(s)',
            children: assignee,
            span: 2
        },
        {
            key: 'sheets',
            label: 'Sheets Assigned',
            children: taskDetails?.meta?.data?.sheetIds?.map(sheetId => sheetNames?.get(sheetId)).join(' | '),
            span: 2
        },
        {
            key: 'dueBy',
            label: 'Due By',
            children: dateTimeFormat(taskDetails?.dueDateTime),
            span: 2
        },
        {
            key: 'status',
            label: 'Status',
            children: taskDetails?.status,
            span: 2
        },
    ];
    return (
        <>
            {sheetsLoading ? <Skeleton /> : <Descriptions size='middle' items={items} bordered />}
        </>
    )
}