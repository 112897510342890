import { useDrop } from "react-dnd";
import styles from "../../../styles/section.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect, useReducer } from "react";
import { SectionReducers } from "./state/sectionReducers.js";
import { sectionActionType } from "../data/models/sectionActionType.js";
import { fieldList } from "../../../data/models/fieldList.js";
import SideNav from "../../sideNav/ui/sideNav.js";
import { ApiClientType } from "../../../../../clients/api-client.js";
import { ApiClient } from "../../../../../clients/api-client.js";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import ArrowGoBackLineIcon from "remixicon-react/ArrowGoBackLineIcon";

export default function Section(props) {

  const { getSheetData } = props;

  const [show, setShow] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [sectionFieldEdits, setSectionFieldEdits] = useState([]);

  const [fields, dispatch] = useReducer(SectionReducers, []);

  const [fieldEdits, setFieldEdits] = useState([]);
  const [sheetEdits, setSheetEdits] = useState([]);

  useEffect(() => {
    dispatch({
      type: sectionActionType.addFieldList,
      data: props.value.fields,
    });
    if (props.value.fieldEdits) {
      setFieldEdits(props.value.fieldEdits)
    } else {

    }
    setSheetEdits(props.value.sheetEdits);
  }, [props.value.fields, props.value.fieldEdits, props.value.sheetEdits]);

  const onCreateField = async (params) => {
    let body = {
      "type": "ADD_FIELD",
      "data": {
        "sectionId": props.value.sectionId,
        "type": params.id,
        "title": "Field title",
        "description": "Field description",
        "minimumReadingCount": 1,
        "isRequired": false,
        "requireObservation": false,
        "requireAttachment": false,
      }
    };
    if (params.id === 'SELECTION' || params.id === 'MULTI_SELECTION') {
      body['data']['data'] = {
        "options": []
      }
    }
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${params.sheetId}/edit`,
      body
    );
    if (response) {
      dispatch({ type: sectionActionType.addField, data: response.data });
      setShow(true);
      setSelectedField({ icon: params, editField: response.data });
      getSheetData(props.value.sheetId)
    }
  };

  const [collectedProps, drop] = useDrop(() => ({
    accept: "field",
    drop: (item, monitor) => {
      if (item.name != "Section") {
        onCreateField(item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const deleteField = async (item) => {
    let body = {
      type: "DELETE_FIELD",
      data: {
        sectionId: item.sectionId,
        fieldId: item.id
      }
    }
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${props.value.sheetId}/edit`,
      body
    );
    if (response) {
      getSheetData(props.value.sheetId);
    }
  }

  const deleteFieldEdit = async (edit) => {
    let response = await ApiClient(
      ApiClientType.delete,
      process.env.REACT_APP_SHEETS_BASE_URL,
      `/sheets/${props.value.sheetId}/edit/${edit.id}`,
    );
    if (response) {
      getSheetData(props.value.sheetId);
    }
  }

  const [publishedFields, setPublishedFields] = useState([]);

  useEffect(() => {
    let sectionFieldEditData = [],
      publishedFieldsData = [];
    if (fieldEdits) {
      fieldEdits.map((edit, index) => {
        if (edit.data.sectionId === props.value.sectionId) {
          sectionFieldEditData.push(edit);
        }
      })
    }
    setSectionFieldEdits(sectionFieldEditData);
    let editIds = sectionFieldEditData.map(edit => edit.data.fieldId);
    if (fields.length > 0) {
      publishedFieldsData = fields.filter((item, index) => {
        if (!editIds.includes(item.id)) {
          return item
        }
      })
      setPublishedFields(publishedFieldsData);
    } else {
      props.value.sections?.map(item => {
        if (item.id === props.value.sectionData.data.sectionId) {
          setPublishedFields(item.fields);
        }
      })
    }
  }, [fieldEdits, sheetEdits])

  return (
    <div
      ref={drop}
      data-testid="section"
      style={{
        opacity: collectedProps.isOver && collectedProps.canDrop ? 0.5 : 1,
      }}
    >
      {show ? (
        <SideNav
          getSheetData={getSheetData}
          value={{
            isPreview: props.value.isPreview,
            onToggle: (show) => {
              setShow(show);
            },
            show,
            selectedField,
            sectionId: props.value.sectionId,
            sheetId: props.value.sheetId,
            sectionNumber: props.value.sectionIndex + 1,
            onSave: (fieldInfo, index) => {
              dispatch({
                type: sectionActionType.addFieldInfoToField,
                data: {
                  index: index != undefined ? index : fields.length - 1,
                  fieldInfo: fieldInfo,
                },
              });
            },
            onRemove: (field) => {
              dispatch({
                type: sectionActionType.removeField,
                data: field != undefined ? field : fields[fields.length - 1],
              });
              setShow(false);
            },
          }}
        />
      ) : null}

      <Container>
        <Row style={{ marginTop: "20px" }}>
          {publishedFields?.length != 0 || sectionFieldEdits?.length != 0 ? (
            <>
              {publishedFields?.length > 0 && publishedFields?.map((item, index) => {
                let obj = fieldList?.find((i) => i.id === item.properties?.type);
                return (
                  <Col key={item.id} md={4} >
                    <span>
                      {index + 1 >= 10 ? index + 1 : `0${index + 1}`}.
                    </span>
                    <span
                      onClick={() => {
                        if (props.value.sectionData.type !== 'DELETE_SECTION') {
                          setShow(true);
                          setSelectedField({
                            icon: obj,
                            field: item,
                            index: index,
                          });
                        }
                      }}
                    >
                      <div id={styles.fieldIcon}>{obj?.icon}</div>
                      <div id={styles.field}>
                        <p className={styles.fieldFixedText}>
                          {item.properties?.title}
                        </p>
                      </div>
                    </span>
                    {!props.value.isPreview && props.value.sectionData.type !== 'DELETE_SECTION' ?
                      <DeleteBin6LineIcon
                        size="20px"
                        style={{
                          color: "red",
                          marginLeft: "0.5rem",
                          cursor: "pointer"
                        }}
                        onClick={() => deleteField(item)}
                      /> : null
                    }
                  </Col>
                );
              })}
              {!props.value.isPreview && sectionFieldEdits?.length > 0 && sectionFieldEdits?.map((edit, index) => {
                let obj = fieldList.find((i) => i.id === edit.data?.properties?.type);
                let i = 0;
                if (edit) {
                  return (
                    <Col key={edit.id} md={4} >
                      <span>
                        {index + 1 >= 10 ? index + 1 : `0${index + 1}`}.
                      </span>
                      <span
                        onClick={() => {
                          if (edit.type !== 'DELETE_FIELD' && props.value.sectionData.type !== 'DELETE_SECTION') {
                            setShow(true);
                            setSelectedField({
                              icon: obj,
                              editField: edit,
                              index: index,
                            });
                          }
                        }}
                      >
                        <div id={styles.fieldIcon} style={{ background: edit.type !== 'DELETE_FIELD' ? "rgba(255, 220, 55, 0.911)" : "rgba(235, 17, 17, 0.911)" }}>
                          {obj?.icon}
                        </div>
                        <div id={styles.field} style={{ background: edit.type !== 'DELETE_FIELD' ? "rgba(255, 220, 55, 0.1)" : "rgba(235, 17, 17, 0.1)", width: "100px", }}>
                          <p className={styles.fieldFixedText}>
                            {edit.data?.properties?.title}
                          </p>
                        </div>
                      </span>
                      {!props.value.isPreview && edit.type !== 'DELETE_FIELD' && edit.type !== 'UPDATE_FIELD' ?
                        <DeleteBin6LineIcon
                          size="20px"
                          style={{
                            color: "red",
                            marginLeft: "0.5rem",
                            cursor: "pointer"
                          }}
                          onClick={() => deleteFieldEdit(edit)}
                        /> : null
                      }
                      {!props.value.isPreview && (edit.type === 'DELETE_FIELD' || edit.type === 'UPDATE_FIELD') ?
                        <ArrowGoBackLineIcon
                          size="20px"
                          style={{
                            color: "red",
                            marginLeft: "0.5rem",
                            cursor: "pointer"
                          }}
                          onClick={() => deleteFieldEdit(edit)}
                        />
                        : null
                      }
                    </Col>
                  );
                }
              })}
            </>
          ) : (
            <div style={{ padding: "20px" }}>{props.value.sectionData?.type !== 'DELETE_SECTION' ? "Drop Your Fields Here" : "Section is deleted. Once published, all of it would be gone orever."}</div>
          )}
        </Row>
      </Container>
    </div>
  );
}
