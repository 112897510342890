import ServerIcon from "remixicon-react/ServerLineIcon";
import User3LineIcon from "remixicon-react/User3LineIcon";
import BuildingLineIcon from "remixicon-react/BuildingLineIcon";
import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import Building2LineIcon from "remixicon-react/Building2LineIcon";

export default function SideNavRoutes() {
  return [
    {
      path: "/masterData/assets",
      name: "Assets",
      icon: <ServerIcon />,
      groupBy: "masterData",
      key: "assets/1"
    },
    {
      path: "/masterData/people",
      name: "People",
      icon: <User3LineIcon />,
      groupBy: "masterData",
      key: "people/1"
    },
    {
      path: "/masterData/suppliers",
      name: "Suppliers",
      icon: <Building2LineIcon />,
      groupBy: "masterData",
      key: "suppliers/1"
    },
    {
      path: `/workroom/userprofile`,
      name: "UserProfile",
      icon: <PencilRuler2LineIcon />,
      groupBy: "user",
      key: "user/1"
    },
    {
      path: "/masterData/customers",
      name: "Customers",
      icon: <BuildingLineIcon />,
      groupBy: "masterData",
      key: "customers/1"
    }
  ];
}