import { Button } from "antd";

export const incomingPartsTableCols = (onIncomingPartClicked) => [
  {
    title: "Incoming Part Name",
    dataIndex: ["name"],
    key: "name",
    width: 120,
    minWidth: 100,
    render: (text, record) => (
      <Button
        type="link"
        onClick={() => {
          onIncomingPartClicked(record?.id);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: "Part Code",
    dataIndex: ["code"],
    key: "code",
    width: 100,
    minWidth: 100,
  },
  {
    title: "Supliers",
    dataIndex: ["suppliers"],
    key: "suppliers",
    width: 100,
    minWidth: 100,
    render: (record) => {
      if (record) {
        const supplierNames = record?.map((item) => item?.supplier?.name);
        return supplierNames.join(", ");
      } else {
        return "";
      }
    },
  },
];
