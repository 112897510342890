import { Card } from "antd";
import { useEffect, useState } from "react";
import { getSheetDetailsAPI, getUserDetailsAPI } from "../../../../../../../common/apis/commonApis";
import { showToastError } from "../../../../../../../common/toasters/toasterMessages";
import { TaskLogs } from "./taskLogs";
import TaskInfo from "./taskInfo";
import TaskDocuments from "./taskDocuments";

export default function SCARTaskDetails(props) {
    const { taskData, editSCARTask } = props;
    const [loading, setLoading] = useState(false);
    const [sheetNames, setSheetNames] = useState(undefined);
    const [assignee, setAssignee] = useState(undefined);
    const [activeTabKey, setActiveTabKey] = useState('logs');
    const [taskDetails, setTaskDetails] = useState(undefined);


    const tabList = [
        {
            key: 'logs',
            label: 'Logs'
        },
        {
            key: 'docs',
            label: 'Docs'
        },
        {
            key: 'info',
            label: 'Info'
        },
    ];

    const contentList = {
        logs: taskDetails?.meta?.data?.sheetIds && (<TaskLogs taskDetails={taskDetails} />),
        docs: <TaskDocuments taskDetails={taskDetails} editSCARTask={editSCARTask} setTaskDetails={setTaskDetails} />,
        info: <TaskInfo sheetsLoading={loading} taskDetails={taskDetails} sheetNames={sheetNames} assignee={assignee} />
    };

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    const fetchSheetNames = async (sheets) => {
        setLoading(true);
        if (sheets?.length > 0) {
            let sheetNamesMap = new Map();
            await Promise.all(
                sheets.map(async sheetId => {
                    let response = await getSheetDetailsAPI(sheetId);
                    if (response?.data) {
                        sheetNamesMap.set(sheetId, response?.data?.name);
                    }
                })
            )
            setSheetNames(sheetNamesMap);
        }
        setLoading(false);
    }

    const fetchUserFromId = async (assigneeId) => {
        setLoading(true);
        let response = await getUserDetailsAPI(assigneeId);
        if (response?.isError) {
            showToastError(response.displayableMessage);
        } else {
            setAssignee(response?.data?.name);
        }
        setLoading(false);
    };



    useEffect(() => {
        setTaskDetails(taskData);
        if (taskData && taskData.meta && taskData.meta.data && taskData.meta.data.sheetIds && taskData.meta.data.sheetIds.length > 0) {
            fetchSheetNames(taskData?.meta?.data?.sheetIds);
        }
        if (taskData && taskData.taskAssignees && taskData.taskAssignees.length > 0 && taskData.taskAssignees[0].assigneeId) {
            fetchUserFromId(taskData?.taskAssignees[0]?.assigneeId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskData]);

    return (
        <>
            <Card
                loading={loading}
                tabList={tabList}
                bordered={false}
                activeTabKey={activeTabKey}
                onTabChange={handleTabChange}
                style={{ width: '100%' }}>
                {contentList[activeTabKey]}
            </Card>
        </>
    )
}