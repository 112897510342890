import { dateTimeFormat, onlyDate } from "../../../../../utils";

export const otherDetailsItems = (inspectionDetails, sheetNames, assignees) => [
  {
    key: 'invoiceNumber',
    label: 'Invoice Number',
    children: inspectionDetails?.meta?.invoiceNumber,
    span: 3,
  },
  {
    key: 'invoicDate',
    label: 'Invoice Date',
    children: onlyDate(inspectionDetails?.meta?.invoiceDate),
    span: 3,
  },
  {
    key: 'grnNumber',
    label: 'GRN Number',
    children: inspectionDetails?.meta?.grnNumber,
    span: 3,
  },
  {
    key: 'deliveryDateTime',
    label: 'GRN Date',
    children: onlyDate(inspectionDetails?.meta?.deliveryDateTime),
    span: 3,
  },
  {
    key: 'linkedSheets',
    label: 'Linked Sheet',
    children: sheetNames,
    span: 3,
  },
  {
    key: 'assignees',
    label: 'Assigned To',
    children: assignees,
    span: 3,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    children: dateTimeFormat(inspectionDetails?.meta?.createdAt),
    span: 3,
  },
  {
    key: 'startedAt',
    label: 'Started At',
    children: dateTimeFormat(inspectionDetails?.meta?.startedAt),
    span: 3,
  },
  {
    key: 'submittedAt',
    label: 'Submitted At',
    children: dateTimeFormat(inspectionDetails?.meta?.submittedAt),
    span: 3,
  },
  {
    key: 'closedAt',
    label: 'Closed At',
    children: dateTimeFormat(inspectionDetails?.meta?.closedAt),
    span: 3,
  },
];