import { ConfigProvider, Form, Input, Modal } from "antd";
import SearchSelect from "../incomingPartsTable/components/searchSelect";
import { useEffect, useState } from "react";
import {
  showToastError,
  showToastSuccess,
} from "../../../../../common/toasters/toasterMessages";
import styles from "./styles/incomingPartsModal.module.css";
import { getSuppliersAPI } from "../../../../../common/apis/commonApis";
import {
  updateIncomingPartApi,
  createIncomingPartApi,
} from "../../../apiCalls/suppliersApis";

export const IncomingPartsModal = (props) => {
  const {
    showModal,
    setShowModal,
    incomingPartDetails,
    fetchIncomingPartsData,
    isEdit,
  } = props;

  const [showButton, setShowButton] = useState(false);
  const [suppliersData, setSuppliersData] = useState([]);
  const [suppliersLoading, setSuppliersLoading] = useState(false);
  const [totalSuppliers, setTotalSuppliers] = useState(0);

  const SEARCH_MORE_TYPE = "searchMore";

  const [form] = Form.useForm();

  const createUpdateSupplier = async (body) => {
    let response;
    if (isEdit) {
      response = await updateIncomingPartApi(body, incomingPartDetails?.id);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchIncomingPartsData();
        showToastSuccess(response?.displayableMessage);
      }
    } else {
      let response = await createIncomingPartApi(body);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchIncomingPartsData();
        showToastSuccess(response?.displayableMessage);
      }
    }
  };

  const handleSave = async (values) => {
    setShowButton(false);
    let body = {
      name: values.incomingPartName,
      code: values.partCode,
      suppliers: values.suppliers,
    };
    await createUpdateSupplier(body);
    setShowModal(false);
    form.resetFields();
  };

  const handleSupplierSearch = async (searchVal) => {
    await fetchSuppliers(searchVal);
  };

  const fetchSuppliers = async (value, type = "") => {
    setSuppliersLoading(true);
    let suppliersResponse = await getSuppliersAPI(
      type === SEARCH_MORE_TYPE ? suppliersData.length : 0,
      value,
      10
    );
    if (suppliersResponse.isError) {
      showToastError(suppliersResponse.displayableMessage);
    } else {
      setTotalSuppliers(suppliersResponse.totalCount);
      let dataList = type === SEARCH_MORE_TYPE ? suppliersData : [];
      suppliersResponse.data.forEach((supplier) => {
        dataList.push({ value: supplier.id, label: supplier.name });
      });
      setSuppliersData(dataList);
    }
    setSuppliersLoading(false);
  };

  const fetchMoreSuppliers = async () => {
    fetchSuppliers("", SEARCH_MORE_TYPE);
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  useEffect(() => {
    if (showModal) {
      fetchSuppliers();
    }
  }, [showModal, isEdit]);

  useEffect(() => {
    if (isEdit) {
      setShowButton(false);
      const supplierNames = incomingPartDetails?.suppliers?.map(
        (item) => item?.supplier?.id
      );
      let values = {
        incomingPartName: incomingPartDetails?.name,
        partCode: incomingPartDetails?.code,
        suppliers: supplierNames,
      };
      form.setFieldsValue(values);
    } else {
      form.resetFields();
      setShowButton(true);
    }
  }, [isEdit, incomingPartDetails, showModal]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: "#7d7676",
          colorBorder: "#dadada",
        },
      }}
    >
      <Modal
        title={isEdit ? "Edit Incoming Part" : "New Incoming Part"}
        open={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        width={"640px"}
      >
        <Form
          onFinish={handleSave}
          form={form}
          layout="vertical"
          name="control-hooks"
        >
          <Form.Item
            className="mb-3"
            label="Incoming Part Name"
            name="incomingPartName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Incoming Part Name"
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="partCode"
            label="Part Code"
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              placeholder="Part Code"
              onWheel={(e) => e.target.blur()}
              onScroll={(e) => e.target.blur()}
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="suppliers"
            label="Suppliers"
            rules={[{ required: true }]}
          >
            <SearchSelect
              showSearch
              allowClear
              loading={suppliersLoading}
              placeholder="Select Suppliers"
              mode="multiple"
              filterOption={false}
              options={suppliersData}
              onClear={() => {
                fetchSuppliers();
              }}
              onSearch={handleSupplierSearch}
              loadMore={fetchMoreSuppliers}
              hasMoreData={totalSuppliers > suppliersData.length}
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item className={styles.btnFormItem}>
            {showButton && (
              <button type="submit" className={styles.createBtn}>
                {isEdit ? "Save changes" : "Create Incoming Part"}
              </button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};
