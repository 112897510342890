import { Select, Spin } from "antd";
const { Option } = Select;
export default function SearchSelect(props) {
    const { className, disabled, value, showSearch, allowClear, onSearch, placeholder, onChange,
        filterOption, options, loading, loadMore, hasMoreData, onClear, onFocus } = props;

    const handlePopupScroll = async e => {
        const target = e.target;
        // Detect if the user has scrolled to the bottom
        if (target.scrollTop + target.clientHeight === target.scrollHeight && hasMoreData && !loading) {
            await loadMore();
        }
    };
    return (
        <Select className={className}
            value={value}
            disabled={disabled}
            allowClear={allowClear}
            loading={loading}
            showSearch={showSearch}
            onSearch={onSearch}
            placeholder={placeholder}
            onChange={onChange}
            filterOption={filterOption}
            onPopupScroll={handlePopupScroll}
            onClear={onClear}
            onFocus={onFocus}
        >
            {options.map(item => (
                <Option key={item.value} value={item.value}>
                    {item.label}
                </Option>
            ))}
            {loading && hasMoreData && (
                <Option key="loading" disabled>
                    <Spin size="small" /> Loading
                </Option>
            )}
        </Select>
    );
}