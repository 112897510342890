import { ApiClient, ApiClientType } from "../../../clients/api-client";

export const createCustomerApi = async (body) => {
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/customers`,
        body
    );
}

export const patchCustomerApi = async (id, body) => {
    return await ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/customers/${id}`,
        body
    );
}

export const searchCustomerApi = async (filters, page = undefined) => {
    const conditionsArr = [];
    const data = page ? { page: page } : {};

    for (const key of Object.keys(filters)) {
        if (filters[key])
            conditionsArr.push({
                field: key,
                operation: key === 'name' ? 'like' : "in",
                value: key === 'name' ? filters[key] : Array.isArray(filters[key]) ? filters[key] : [filters[key]]
            })
    }
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/customers/search`,
        {
            ...data,
            filter: {
                association: `and`,
                conditions: conditionsArr
            }
        }
    );
}