import { Descriptions } from 'antd';
import { keyDetailsItems } from '../utils/keyDetailsItems';
import { useEffect, useState } from 'react';
import { updateVerdictApi, verdictApi } from '../../../apiCalls/iqcAPIs';
import { showToastError, showToastInfo } from '../../../../../common/toasters/toasterMessages';

export const KeyDetails = (props) => {

  const {inspectionDetails, fetchInspectionDetailsByKey} = props.values;
  
  const [items, setItems] = useState([])
  const [verdictStatusList, setVerdictStatusList] = useState([])
  const [isChanging, setIsChanging] = useState(false);
  const [verdict, setVerdict] = useState("No Status");
  
  const handleStatusChange = async (value) => {
    setIsChanging(true);
    setVerdict(value);
    let body = {
      verdict:  value,
    };
    let response = await updateVerdictApi(inspectionDetails?.id, body);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      showToastInfo(response.displayableMessage);
      fetchInspectionDetailsByKey();
    }
    setIsChanging(false);
  }
  
  const fetchVerdictStatuses = async () => {
    let response = await verdictApi();
    setVerdictStatusList(response?.data);
  }

  useEffect(()=>{
    fetchVerdictStatuses();
    if(inspectionDetails) {
      setItems(keyDetailsItems(verdict, isChanging, handleStatusChange, inspectionDetails))
    }
    if(inspectionDetails?.verdict !== "") {
      setVerdict(inspectionDetails?.verdict);
    }
  },[inspectionDetails])
  
  useEffect(() => {
    if(verdictStatusList.length > 0) {
      let verdictOptions = [];
      verdictStatusList?.forEach(verdict => {
        verdictOptions.push({
          value: verdict.title,
          label: verdict.title === "ACCEPTED" ?
                  "Accepted" : verdict.title === "REJECTED" ?
                    "Rejected" : verdict.title === "IN_REVIEW" ?
                      "In Review" : verdict.title === "CONDITIONALLY_APPROVED" ?
                          "Conditionally Approved" : "",
          color: verdict.color
        })
      })
      setItems(keyDetailsItems(verdict, isChanging, handleStatusChange, inspectionDetails, verdictOptions))
    }
  },[verdictStatusList, verdict, isChanging])

    return (
        <Descriptions bordered items={items} />
    )
};