import { ApiClient, ApiClientType } from "../../clients/api-client";

const host = process.env.REACT_APP_MASTER_BASE_URL;

export const getSuppliersAPI = async (pageOffset=0, pageLimit='', name='', code='',ids = undefined) => {
    let url = `/suppliers?name=${name}&code=${code}&offset=${pageOffset}&limit=${pageLimit}`;
    ids?.forEach(id => {
        url += `&id[]=${id}`;
    });
    return await ApiClient(
        ApiClientType.get,
        host,
        url
    );
};
