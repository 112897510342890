import { Tabs } from 'antd';
import { useEffect, useState } from "react";
import { TaskSheetLogTable } from './taskSheetLogTable';
import { getSheetDetailsAPI } from '../../../../../../../common/apis/commonApis';
import { TaskSheetLogDetails } from './taskSheetLogDetails';

export const TaskLogs = (props) => {

    const { taskDetails } = props;

    const linkedSheets = taskDetails?.meta?.data?.sheetIds;
    const [sheetNames, setSheetNames] = useState([]);
    const [tabSheets, setTabSheets] = useState([]);

    const fetchSheetNames = async () => {
        let sheets = linkedSheets;
        if (sheets?.length > 0) {
            let sheetNamesArr = []
            await Promise.all(
                sheets.map(async sheetId => {
                    let response = await getSheetDetailsAPI(sheetId);
                    if (response?.data) {
                        sheetNamesArr.push(
                            {
                                name: response?.data?.name,
                                id: sheetId,
                            })
                    }
                })
            )
            setSheetNames(sheetNamesArr);
        }
    }

    useEffect(() => {
        if (linkedSheets?.length > 0) {
            fetchSheetNames();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedSheets])

    useEffect(() => {
        if (sheetNames?.length > 0) {
            let items = sheetNames.map(sheet => {
                let sheetItem = {
                    key: sheet.id,
                    label: sheet.name,
                    children:
                        <TaskSheetLogTable
                            values={{
                                sheet,
                                taskDetails,
                            }}
                        />,
                }
                return sheetItem
            })
            setTabSheets(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheetNames])

    return (
        // <Tabs
        //     defaultActiveKey={"sheet1"}
        //     items={tabSheets}
        //     type='card'
        // />

        sheetNames.map(sheet =>
            // <TaskSheetLogTable
            //     values={{
            //         sheet,
            //         taskDetails,
            //     }}
            // />
            <TaskSheetLogDetails
                values={{
                    sheet,
                    taskDetails,
                }}
            />
        )
    )
}