import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import PrimaryButton from "../../../../common/crunches/primaryButton/primaryButton.js";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import styles from "./styles/calibrationModal.module.css";

export default function CalibrationModal(props) {
  const [calibrationAgencyName, setCalibrationAgencyName] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [date, setDate] = useState(dayjs(new Date()));

  useEffect(() => {
    setShowButton(true);
    setCalibrationAgencyName("");
    setDate();
  }, [props.values.show]);

  const onCloseModal = () => {
    setShowButton(false);
    props.values.onHide();
    setDate(dayjs(""));
    props.values.handleClose();
  };

  return (
    <Modal
      show={props.values.show}
      onHide={onCloseModal}
      dialogClassName={styles.modalWidth}
    >
      <Modal.Header
        closeButton
        style={{ border: "none", padding: "0rem 1.5rem", margin: "1rem 0" }}
      >
        <Modal.Title className="boldFont">
          <span style={{ marginLeft: "10px", fontSize: "20px" }}>
            {props.values.name}
            New Calibration
          </span>
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.values.onSubmitModal({
            dueDateTime: date,
            agency: calibrationAgencyName,
          });
          onCloseModal();
        }}
      >
        <Modal.Body style={{ padding: "0rem 2rem" }}>
          <DatePicker
            className="mb-3"
            sx={{
              width: "100%",
            }}
            label="Due date"
            required
            onChange={(newValue) => {
              const formattedDate = newValue
                .toISOString()
                .toLocaleString(undefined, {
                  timeZone: "Asia/Kolkata",
                });
              setDate(formattedDate);
            }}
            format="DD/MM/YYYY"
            value={date}
          />
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={calibrationAgencyName}
              onChange={(e) => setCalibrationAgencyName(e.target.value)}
              placeholder="Calibration agency name"
              type={"text"}
              min={1}
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Calibration agency name
            </label>
          </Form.Floating>
        </Modal.Body>

        {showButton ? (
          <Modal.Footer
            style={{
              justifyContent: "center",
              border: "none",
              padding: "0rem 2rem",
              margin: "1rem 0",
            }}
          >
            <PrimaryButton
              value={{
                type: "submit",
                child: "Sumbit",
                style: { padding: "0.5rem 1.5rem" },
              }}
            />
          </Modal.Footer>
        ) : null}
      </Form>
    </Modal>
  );
}
