import { Button, ConfigProvider, DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import SearchSelect from "../../../IQCActivity/components/inspectionTable/components/searchSelect";
import { useEffect, useState } from "react";
import { getIncomingPartsAPI } from "../../../IQCActivity/apiCalls/iqcAPIs";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import styles from "./styles/scarModal.module.css"
import { getUsersAPI } from "../../../../common/apis/commonApis";
import { searchCustomerApi } from "../../../../features/customers/apis/customerApis";
import dayjs from "dayjs";

function SCARModal(props) {
    const { open, setOpen, supplierOptions, createSCAR, fetchMoreSuppliers, totalCountSuppliers, suppliersLoading,
        totalCountInspections, inspectionsLoading, fetchInspections, inspectionList, setSuppliersList } = props;

    const SEARCH_MORE_TYPE = "searchMore";

    const [form] = Form.useForm();
    const [scarFor, setScarFor] = useState(undefined);
    const [scarType, setScarType] = useState(undefined);
    const [icPartOptions, setIcPartOptions] = useState([]);
    const [selectSupplier, setSelectSupplier] = useState(undefined);
    const [selectedInspection, setSelectedInspection] = useState(undefined);

    const [incomingpartsList, setIncomingpartsList] = useState([]);
    const [totalCountIncomingparts, setTotalCountIncomingparts] = useState(0);
    const [incomingpartsLoading, setIncomingpartsLoading] = useState(false);

    const [usersData, setUsersData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [usersLoading, setUsersLoading] = useState(false);

    const [customersData, setCustomersData] = useState([]);
    const [customersLoading, setCustomersLoading] = useState(false);
    const [totalcustomers, setTotalCustomers] = useState(0);

    const [inspectionOptions, setInspectionOptions] = useState([]);


    const handleOk = (values) => {
        setOpen(false);
        setScarType(undefined);
        setScarFor(undefined);
        setSelectedInspection(undefined);
        form.resetFields();
        createSCAR(values);
    };

    const handleCancel = () => {
        setScarFor(undefined);
        setScarType(undefined);
        setSelectedInspection(undefined);
        form.resetFields();
        setOpen(false);
    };

    const handleSCARForChange = (value) => {
        setScarFor(value);
    };

    const handleSCARTypeChange = (value) => {
        setScarType(value);
    };

    const handleInspectionChange = (value) => {
        const selectedInspection = inspectionList?.filter(inspection => inspection.id === value);
        if (selectedInspection.length >= 1) {
            setSelectedInspection(selectedInspection[0]);
            setIncomingpartsList([selectedInspection[0]?.incomingPart]);
            if (scarFor === 'Supplier') {
                form.setFieldValue('supplierId', selectedInspection[0]?.supplierId);
            }
            form.setFieldValue('incomingPartId', selectedInspection[0]?.incomingPartId);
            form.setFieldValue('partNo', selectedInspection[0]?.incomingPart?.code);
            form.setFieldValue('invoiceNumber', selectedInspection[0]?.meta?.invoiceNumber);
            form.setFieldValue('invoiceDate', dayjs(selectedInspection[0]?.meta?.invoiceDate));
        } else {
            setSelectedInspection(undefined);
            form.setFieldValue('supplierId', undefined);
            form.setFieldValue('incomingPartId', undefined);
            form.setFieldValue('partNo', undefined);
            form.setFieldValue('invoiceNumber', undefined);
            form.setFieldValue('invoiceDate', undefined);
        }
    };

    const handleInspectionPartChange = (value) => {
        const selectedPart = incomingpartsList?.filter(part => part.id === value);
        if (selectedPart.length === 1) {
            form.setFieldValue('partNo', selectedPart[0]?.code);
        }
    };

    const fetchIncomingParts = async (supplierId, isNew, value) => {
        setIncomingpartsLoading(true);
        const dataList = isNew ? [] : incomingpartsList;
        const icPartsResponse = await getIncomingPartsAPI(supplierId, dataList.length, 10, value);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalCountIncomingparts(icPartsResponse.totalCount);
            icPartsResponse?.data?.forEach(icPart => {
                dataList.push(icPart);
            });
            const icPartOptionData = dataList?.map(icPart => ({ value: icPart?.id, label: icPart?.name }));
            setIcPartOptions(icPartOptionData);
            setIncomingpartsList(dataList);
        }
        setIncomingpartsLoading(false);
    }

    const handleSupplierChange = async (value) => {
        setSelectSupplier(value);
        form.setFieldValue('incomingPartId', undefined);
        fetchIncomingParts(value, true, "");
    };

    
    const handleIncomingPartsSearch = async (searchVal) => {
        await fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, searchVal);
    };
    
    const fetchMoreIncomingParts = async (searchVal) => {
        await fetchIncomingParts(form.getFieldValue("supplierId") ?? "", false, searchVal);
    };

    const handleSearch = async (searchVal) => {
        if (searchVal?.length >= 5) {
          setInspectionOptions([]);
          await fetchInspections(searchVal, 0);
        }
    }

    const handleSupplierSearch = async (searchVal) => {
        await fetchMoreSuppliers(searchVal, 0);
    }
    const handleClear = async (searchVal) => {
        await fetchInspections('clear', 0);
    }

    const fetchUsers = async (value, type = "") => {
        setUsersLoading(true);
        const usersResponse = await getUsersAPI(type === SEARCH_MORE_TYPE ? usersData.length : 0, value, 10);
        if (usersResponse.isError) {
            showToastError(usersResponse.displayableMessage);
        } else {
            setTotalUsers(usersResponse.totalCount)
            const dataList = type === SEARCH_MORE_TYPE ? usersData : [];
            usersResponse.data.forEach(user => {
                dataList.push({ value: user?.user?.id, label: user?.user?.name });
            });
            setUsersData(dataList);
        }
        setUsersLoading(false);
    };

    const handleUserSearch = async (searchVal) => {
        setUsersData([]);
        await fetchUsers(searchVal);
    }

    const fetchMoreUsers = async () => {
        fetchUsers("", SEARCH_MORE_TYPE);
    }

    const handleCustomersSearch = async (searchVal) => {
        await fetchCustomers(searchVal);
    };

    const fetchCustomers = async (value, type = "") => {
        setCustomersLoading(true);
        const response = await searchCustomerApi({ name: value }, { limit: 10, offset: type === SEARCH_MORE_TYPE ? customersData.length : 0 });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTotalCustomers(response.totalCount);
            const dataList = type === SEARCH_MORE_TYPE ? customersData : [];
            response?.data?.forEach((customer) => {
                dataList.push({ label: customer.name, value: customer.id });
            });
            setCustomersData(dataList);
        }
        setCustomersLoading(false);
    };

    const fetchMoreCustomers = async () => {
        fetchCustomers("", SEARCH_MORE_TYPE);
    };

    useEffect(() => {
        if (open) {
            fetchUsers();
            fetchCustomers();
            fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    useEffect(() => {
        if (inspectionList) {
            const inspectionOptionData = inspectionList?.map(inspection => ({ value: inspection?.id, label: inspection?.key }));
            setInspectionOptions(inspectionOptionData);
        }
    }, [inspectionList]);

    useEffect(() => {
        if (incomingpartsList) {
            const icPartOptionData = incomingpartsList?.map(icPart => ({ value: icPart?.id, label: icPart?.name }));
            setIcPartOptions(icPartOptionData);
        }
    }, [incomingpartsList]);

    return (
        <>
            <Modal
                title="New SCAR"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[

                ]}
                styles={
                    { footer: { textAlign: 'center' } }
                }
            >
                <ConfigProvider
                    theme={{
                        components: {
                            Form: {
                                itemMarginBottom: 10
                            }
                        }
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleOk}
                    >
                        <Form.Item label="SCAR For">
                            <Select style={{ width: '100%' }} placeholder="Supplier / Customer" value={scarFor}
                                options={[{ label: 'Supplier', value: 'Supplier' }, { label: 'Customer', value: 'Customer' }]}
                                onChange={handleSCARForChange}
                                disabled={scarFor} />
                        </Form.Item>

                        {scarFor && (<Form.Item label="Type">
                            <Select style={{ width: '100%' }} placeholder="Select SCAR type" value={scarType}
                                options={[{ label: 'MANUAL', value: 'MANUAL' }, { label: 'INSPECTION', value: 'INSPECTION' }]} onChange={handleSCARTypeChange} />
                        </Form.Item>)}
                        {scarFor && scarType && (
                            <>
                                {scarType === 'INSPECTION' &&
                                    (<Form.Item label="Inspection" name="qualityInspectionId" required
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select valid inspection!'
                                            }
                                        ]}>
                                        <SearchSelect
                                            showSearch
                                            allowClear
                                            loading={inspectionsLoading}
                                            placeholder="Select Inspection"
                                            options={inspectionList?.map(inspection => ({ value: inspection?.id, label: inspection?.key }))}
                                            filterOption={false}
                                            loadMore={fetchInspections}
                                            onChange={handleInspectionChange}
                                            onSearch={handleSearch}
                                            onClear={handleClear}
                                            hasMoreData={totalCountInspections > inspectionOptions?.length}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>)}

                                {scarFor === 'Customer' &&
                                    (<Form.Item label="Customer" name="customerId" required
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select valid customer!'
                                            }
                                        ]}>
                                        <SearchSelect
                                            showSearch
                                            allowClear
                                            loading={customersLoading}
                                            placeholder="Select Customer"
                                            options={customersData}
                                            filterOption={false}
                                            loadMore={fetchMoreCustomers}
                                            onSearch={handleCustomersSearch}
                                            hasMoreData={totalcustomers > customersData?.length}
                                            style={{ width: '100%' }}
                                            onClear={() => {
                                                fetchCustomers();
                                            }}
                                        />
                                    </Form.Item>)}
                                {scarFor !== 'Customer' && (<Form.Item label="Supplier" name="supplierId" required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select valid Supplier!'
                                        }
                                    ]}
                                >
                                    <SearchSelect
                                        showSearch
                                        allowClear
                                        loading={suppliersLoading}
                                        placeholder="Select supplier"
                                        options={supplierOptions}
                                        filterOption={false}
                                        loadMore={fetchMoreSuppliers}
                                        hasMoreData={totalCountSuppliers > supplierOptions.length}
                                        style={{ width: '100%' }}
                                        disabled={scarType === 'INSPECTION'}
                                        onSearch={handleSupplierSearch}
                                        onChange={handleSupplierChange}
                                        onClear={() => {
                                            form.setFieldValue('incomingPartId', undefined);
                                            form.setFieldValue('partNo', undefined);
                                            fetchMoreSuppliers("", 0);
                                        }}
                                    />
                                </Form.Item>)}
                                <Form.Item label="Part" name="incomingPartId" required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select valid Incoming Part!'
                                        }
                                    ]}
                                >
                                    <SearchSelect
                                        showSearch
                                        allowClear
                                        loading={incomingpartsLoading}
                                        placeholder="Select Incoming Part"
                                        options={icPartOptions}
                                        filterOption={false}
                                        onSearch={handleIncomingPartsSearch}
                                        loadMore={fetchMoreIncomingParts}
                                        hasMoreData={totalCountIncomingparts > incomingpartsList.length}
                                        style={{ width: '100%' }}
                                        disabled={scarType === 'INSPECTION'}
                                        onChange={handleInspectionPartChange}
                                        onClear={async () => {
                                            await fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, "");
                                            form.setFieldValue('partNo', undefined);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Part No" name="partNo">
                                    <Input placeholder="Part No" disabled />
                                </Form.Item>
                                <div className={styles.samplingDiv}>
                                    <div className={styles.samplingType}>
                                        <Form.Item label="Invoice No" name="invoiceNumber">
                                            <Input placeholder="Invoice No" disabled={selectedInspection?.meta?.invoiceNumber} />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.widthHalf}>
                                        <Form.Item label="Invoice Date" name="invoiceDate">
                                            <DatePicker style={{ width: "100%" }} disabled={selectedInspection?.meta?.invoiceNumber} showTime />
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item label="Quantity" name="quantity">
                                    <InputNumber style={{ width: "100%" }} placeholder="Quantity" />
                                </Form.Item>
                                <Form.Item label="SCAR Title / Failure Detail" name="title" required    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter title!'
                                        }
                                    ]}>
                                    <Input placeholder="SCAR Title / Failure Detail" />
                                </Form.Item>
                                <Form.Item label="Intimated by" name="intimatedBy">
                                    <SearchSelect
                                        showSearch
                                        allowClear
                                        loading={usersLoading}
                                        placeholder="Intimated by"
                                        options={usersData}
                                        filterOption={false}
                                        onSearch={handleUserSearch}
                                        onClear={fetchUsers}
                                        loadMore={fetchMoreUsers}
                                        hasMoreData={totalUsers > usersData.length}
                                    />
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    <Button key="submit" type="primary" htmlType="submit" >
                                        Create SCAR
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form>
                </ConfigProvider>
            </Modal>
        </>
    );
}
export default SCARModal;