import { ConfigProvider, Space } from "antd";
import SCARHeader from "./components/scarHeader";
import SCARBody from "./components/scarBody";
import { useEffect, useState } from "react";
import { DEFAULT_OFFSET } from "../../../clients/api-constants";
import { createScarAPI, getScarStatusAPI, getScarsAPI, getSuppliersAPI } from "../apis/scarAPIs";
import { showToastError, showToastSuccess } from "../../../common/toasters/toasterMessages";
import { getIncomingPartsAPI, getInspections } from "../../IQCActivity/apiCalls/iqcAPIs";
import SCARModal from "./components/newScarModal";
import { searchCustomerApi } from "../../../features/customers/apis/customerApis";


export default function ScarOverview() {
    const [isLoading, setIsLoading] = useState(false);
    const [scarsList, setScarsList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [totalCountSuppliers, setTotalCountSuppliers] = useState(0);
    const [inspectionList, setInspectionList] = useState([]);
    const [totalCountInspections, setTotalCountInspections] = useState(0);
    const [suppliersLoading, setSuppliersLoading] = useState(false);
    const [inspectionsLoading, setInspectionsLoading] = useState(false);
    const [statusDetails, setStatusDetails] = useState([]);

    const [incomingpartsList, setIncomingpartsList] = useState([]);
    const [totalCountIncomingparts, setTotalCountIncomingparts] = useState(0);
    const [incomingpartsLoading, setIncomingpartsLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const fetchCustomers = async (value) => {
        setIsLoading(true);
        const response = await searchCustomerApi({ id: value });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            return response?.data;
        }
        setIsLoading(true);
        return undefined;
    };

    const fetchScars = async (supplier, inspection, customer, pageOffset = DEFAULT_OFFSET) => {
        setIsLoading(true);
        let response;
        response = await getScarsAPI(supplier, inspection, customer, pageOffset);
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            const customerList = await fetchCustomers(response?.data?.map(scar => scar?.customerId).filter(c => c));
            const cusIdMap = customerList?.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            let scarList = response?.data;
            if (cusIdMap) {
                scarList = response?.data?.map(scar => ({ ...scar, customer: cusIdMap[scar?.customerId] }))
            }
            setScarsList(scarList);
        }
        setIsLoading(false);
    };
    const createSCAR = async (scarData) => {
        const response = await createScarAPI({
            ...scarData
        });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            showToastSuccess(response.displayableMessage);
            fetchScars();
        }
    }

    const fetchMoreSuppliers = async (value, offset = suppliersList?.length ?? 0) => {
        setSuppliersLoading(true);
        const suppliersResponse = await getSuppliersAPI(offset, value, 10);
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setTotalCountSuppliers(suppliersResponse.totalCount);
            const dataList = offset === 0 ? [] : suppliersList;
            suppliersResponse.data.forEach(supplier => {
                dataList.push({ value: supplier.id, label: supplier.name });
            });
            setSuppliersList(dataList);
        }
        setSuppliersLoading(false);
    };

    const fetchInspections = async (inspectionKey, pageOffset = inspectionList.length) => {
        setInspectionsLoading(true);
        const inspectionsResponse = await getInspections({ inspectionKey: inspectionKey !== 'clear' ? inspectionKey : undefined, pageOffset: pageOffset, limit: 10 });
        if (inspectionsResponse.isError) {
            showToastError(inspectionsResponse.displayableMessage);
        } else {
            setTotalCountInspections(inspectionsResponse.totalCount);
            const dataList = inspectionKey ? [] : inspectionList;
            inspectionsResponse?.data?.forEach(inspection => {
                dataList.push(inspection);
            });
            setInspectionList(dataList);
        }
        setInspectionsLoading(false);
    }

    const fetchIncomingParts = async (supplierId) => {
        setIncomingpartsLoading(true);
        let icPartsResponse = await getIncomingPartsAPI(supplierId, incomingpartsList.length, 10);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalCountIncomingparts(icPartsResponse.totalCount);
            let dataList = incomingpartsList;
            icPartsResponse?.data?.forEach(icPart => {
                dataList.push(icPart);
            });
            setIncomingpartsList(dataList);
        }
        setIncomingpartsLoading(false);
    }

    const getStatus = async () => {
        let response = await getScarStatusAPI();
        if (!response.isError) {
            setStatusDetails(response?.data);
        } else {
            showToastError(response?.displayableMessage);
        }
    };


    useEffect(() => {
        getStatus();
        fetchScars();
        fetchMoreSuppliers('');
        fetchInspections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SCARModal open={showModal} setOpen={setShowModal} supplierOptions={suppliersList} setSuppliersList={setSuppliersList} createSCAR={createSCAR}
                fetchMoreSuppliers={fetchMoreSuppliers} totalCountSuppliers={totalCountSuppliers} suppliersLoading={suppliersLoading}
                totalCountInspections={totalCountInspections} inspectionsLoading={inspectionsLoading} fetchInspections={fetchInspections} inspectionList={inspectionList}
                fetchIncomingParts={fetchIncomingParts} incomingpartsList={incomingpartsList} totalCountIncomingparts={totalCountIncomingparts} incomingpartsLoading={incomingpartsLoading} />
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Arial, Helvetica, sans-serif',
                    },
                }}
            >
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <SCARHeader suppliers={suppliersList} createSCAR={createSCAR} fetchScars={fetchScars} isLoading={isLoading}
                        totalCountSuppliers={totalCountSuppliers} suppliersLoading={suppliersLoading} fetchMoreSuppliers={fetchMoreSuppliers}
                        totalCountInspections={totalCountInspections} inspectionsLoading={inspectionsLoading} fetchInspections={fetchInspections} inspections={inspectionList}
                        fetchIncomingParts={fetchIncomingParts} incomingpartsList={incomingpartsList}
                        totalCountIncomingparts={totalCountIncomingparts} incomingpartsLoading={incomingpartsLoading}
                        showModal={showModal} setShowModal={setShowModal} />
                    <hr />
                    <SCARBody scarsData={scarsList} getchScars={fetchScars} statusDetails={statusDetails} isLoading={isLoading} />
                </Space>
            </ConfigProvider >
        </>
    );
}