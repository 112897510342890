import { Button, Tag } from "antd";
import { dateTimeFormat, onlyDate } from "../../../../../utils";

export const inspectionTableCols = (onInspectionClick, params, navigate) => [
    {
        title: 'Inspection Status',
        width: 140,
        minWidth: 110,
        dataIndex: ['status'],
        fixed: 'left',
        key: 'status',
        render: (text) => (
            <>
                {text === 'OPEN' ?
                    <Tag color="#7d7676" style={{ fontWeight: "bold" }}>{text}</Tag>
                    : text === 'ONGOING' ? <Tag color="orange-inverse" style={{ fontWeight: "bold" }}>{text}</Tag>
                        : text === 'SUBMITTED' ? <Tag color="blue-inverse" style={{ fontWeight: "bold" }}>{text}</Tag>
                            : text === 'CLOSED' ? <Tag color="green-inverse" style={{ fontWeight: "bold" }}>{text}</Tag> : text}
            </>
        ),
    },
    {
        title: 'Inspection ID',
        width: 120,
        minWidth: 100,
        dataIndex: ['key'],
        key: 'key',
        fixed: 'left',
        render: (text, record) =>
            <Button
                type="link"
                onClick={() => {
                    onInspectionClick(record);
                    navigate(`/application/${params.appId}/inspections/${text}/details`)
                }}
            >{text}</Button>,
    },
    {
        title: 'Lot Status/Verdict',
        dataIndex: ['verdict'],
        width: 140,
        minWidth: 140,
        key: 'verdict',
        render: (text) => (
            <>
                {text === 'ACCEPTED' ? <span style={{ color: 'green', fontWeight: 'bold' }}>{text}</span>
                    : text === 'REJECTED' ? <span style={{ color: 'red', fontWeight: 'bold' }}>{text}</span>
                        : text === 'CONDITIONALLY_APPROVED' ? <span style={{ color: 'green', fontWeight: 'bold' }}>CONDITIONALLY APPROVED</span>
                            : text === 'IN_REVIEW' ? <span style={{ color: 'orange', fontWeight: 'bold' }}>IN REVIEW</span> : text}
            </>
        ),
    },
    {
        title: 'IC Part Name',
        dataIndex: ['incomingPart', 'name'],
        key: 'icPartName',
        width: 150,
        minWidth: 100,
        ellipsis: true
    },
    {
        title: 'Supplier Name',
        dataIndex: ['supplier', 'name'],
        key: 'supplierName',
        width: 150,
        minWidth: 100,
        ellipsis: true
    },
    {
        title: 'GRN Number',
        dataIndex: ['meta', 'grnNumber'],
        key: 'grnNumber',
        width: 120,
        minWidth: 100,
    },
    {
        title: 'Total Order Quantity',
        dataIndex: ['quantity'],
        key: 'quantity',
        width: 120,
        minWidth: 100,
    },
    {
        title: 'Sampling Size',
        dataIndex: ['meta', 'samplingDetails', 'number'],
        key: 'samplingSize',
        width: 100,
        minWidth: 100,
    },
    {
        title: 'Total OK',
        dataIndex: ['inspectionStats', 'totalOkSamples'],
        key: 'totalOK',
        width: 100,
        minWidth: 100,
        render: (text) => <span style={{ color: 'green' }}>{text}</span>
    },
    {
        title: 'Total NOK',
        dataIndex: ['inspectionStats', 'totalNokSamples'],
        key: 'totalNOK',
        width: 100,
        minWidth: 100,
        render: (text) => <span style={{ color: 'red' }}>{text}</span>
    },
    {
        title: 'Created At',
        dataIndex: ['created_at'],
        key: 'createdAt',
        width: 170,
        minWidth: 170,
        type: 'dateTime',
        render: (text) => text ? dateTimeFormat(text) : ""
    },
    {
        title: 'Started At',
        dataIndex: ['meta', 'startedAt'],
        key: 'startedAt',
        width: 170,
        minWidth: 170,
        type: 'dateTime',
        render: (text) => text ? dateTimeFormat(text) : ""
    },
    {
        title: 'Due by',
        dataIndex: ['dueDateTime'],
        key: 'dueBy',
        width: 170,
        minWidth: 170,
        type: 'date',
        render: (text) => text ? onlyDate(text) : ""
    },
    {
        title: 'Submitted At',
        key: 'submittedAt',
        dataIndex: ['meta', 'submittedAt'],
        width: 170,
        minWidth: 170,
        type: 'dateTime',
        render: (text) => text ? dateTimeFormat(text) : ""
    },
    {
        title: 'Closed At',
        dataIndex: ['meta', 'closedAt'],
        key: 'closedAt',
        width: 170,
        minWidth: 170,
        type: 'dateTime',
        render: (text) => text ? dateTimeFormat(text) : ""
    },
];