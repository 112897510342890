import React, { useState } from "react";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import EyeOffLineIcon from "remixicon-react/EyeOffLineIcon";
function EyeLineComponent(props) {
  const { showPassword, setShowPassword } = props;

  return (
    <>
      {showPassword ? (
        <label onClick={() => setShowPassword(!showPassword)}>
          <EyeLineIcon
            style={{
              color: "#7D7676",
              right: props.right,
              top: props.top,
              zIndex: "1",
              position: "absolute",
              cursor: "pointer",
            }}
          />
        </label>
      ) : (
        <label onClick={() => setShowPassword(!showPassword)}>
          <EyeOffLineIcon
            style={{
              color: "#7D7676",
              right: props.right,
              top: props.top,
              zIndex: "1",
              position: "absolute",
              cursor: "pointer",
            }}
          />
        </label>
      )}
    </>
  );
}

export default EyeLineComponent;
