import React from "react";
export default function CustomButton({
  background = "#FFFFFF",
  icon: Icon = null,
  padding = "0.5rem 1rem",
  title = "",
  color = "#00000",
  fontSize = "14px",
  width = "auto",
  fontWeight = "700",
  onClick = () => {},
  border = "1px solid #DADADA",
  height = "auto",
  borderRadius = "7px",
  margin = "0px",
  type = "",
}) {
  return (
    <button
    
      type={type}
      onClick={onClick}
      className="btn"
      style={{
        alignItems: "center",
        height: height,
        borderRadius: borderRadius,
        width: width,
        padding: padding,
        outline: "none",
        border: border,
        fontWeight: fontWeight,
        margin: margin,
        background:
          background === "gradient"
            ? "linear-gradient(90deg, #00C6FB 0%, #005BEA 100%)"
            : background,
      }}
    >
      {Icon && (
        <Icon
          color={background === "#FFFFFF" ? "#7D7676" : "white"}
          style={{ marginTop: "-4px" }}
        />
      )}
      {title && (
        <span
          style={{
            color: color,
            marginLeft: Icon ? "10px" : 0,
            fontSize: fontSize,
            
          }}
        >
          {title}
        </span>
      )}
    </button>
  );
}
