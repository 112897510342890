import { useEffect, useState } from "react";
import { getSheetList } from "../../apiCalls/sheetAPIs";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../../clients/api-constants";
import { CreateSheetModal } from "../sheetModal/sheetModal";
import SheetHeader from "./components/header";
import SheetBody from "./components/body";

export default function SheetTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(DEFAULT_OFFSET);

    const [sheetList, setSheetList] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(undefined);
    const [selectedIcPart, setSelectedIcPart] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [selectedStation, setSelectedStation] = useState(undefined);

    const [showModal, setShowModal] = useState(false);
    const fetchSheetList = async (supplierId, icPartId, productId, stationId, pageOffset = offset, pageLimit = PAGE_SIZE) => {
        setIsLoading(true);
        let sheetListResponse = await getSheetList({
            supplierId: supplierId,
            icPartId: icPartId,
            productId: productId,
            stationId: stationId,
            pageOffset: pageOffset,
            limit: pageLimit,
        });
        if (sheetListResponse.isError) {
            showToastError(sheetListResponse.displayableMessage);
        } else {
            setSheetList(sheetListResponse.data)
            setTotalCount(sheetListResponse.totalCount);
        }
        setIsLoading(false);
    };

    const handlePaginationChange = (page, pageSize) => {
        if (pageSize !== currentPageSize) {
            setCurrentPage(1);
            setOffset(0);
            setCurrentPageSize(pageSize);
            void fetchSheetList(selectedSupplier, selectedIcPart, selectedProduct, selectedStation, 0, pageSize);
        } else {
            setOffset(currentPageSize * (page - 1));
            setCurrentPage(page);
            void fetchSheetList(selectedSupplier, selectedIcPart, selectedProduct, selectedStation, (currentPageSize * (page - 1)), currentPageSize);
        }
    };

    const handleFilterChange = (supplier, icPart, product, station) => {
        setSelectedSupplier(supplier);
        setSelectedIcPart(icPart);
        setSelectedProduct(product);
        setSelectedStation(station)
        setCurrentPage(1);
        setCurrentPageSize(PAGE_SIZE);
        setOffset(DEFAULT_OFFSET);
        void fetchSheetList(supplier, icPart, product, station, DEFAULT_OFFSET);
    };

    const onRefresh = () => {
        void fetchSheetList(selectedSupplier, selectedIcPart, selectedProduct, selectedStation, offset);
    }

    useEffect(() => {
        void fetchSheetList(selectedSupplier, selectedIcPart, selectedProduct, selectedStation, offset);
    }, [])

    return (<div>
        <CreateSheetModal
            showModal={showModal}
            setShowModal={setShowModal}
            fetchSheetList={fetchSheetList}
        />
        <div>
            <SheetHeader
                onFilterChange={handleFilterChange}
                setShowModal={setShowModal}
                selectedSupplier={selectedSupplier}
                selectedIcPart={selectedIcPart}
                selectedStation={selectedStation}
                selectedProduct={selectedProduct}
            />
            <hr />
            <SheetBody
                data={sheetList}
                loading={isLoading}
                totalRecords={totalCount}
                currentPage={currentPage}
                currentPageSize={currentPageSize}
                onRefresh={onRefresh}
                onPaginationChange={handlePaginationChange}
                setLoading={setIsLoading}
            />
        </div>
    </div>);
}
