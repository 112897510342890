import styles from "./styles/iqcActivity.module.css";
import InspectionTable from "../components/inspectionTable/inspectionTable.js";

export default function IQCActivity() {

  return (
    <div className={styles.iqcContainer}>
      <InspectionTable />
    </div>
  );
}
