import { React, useEffect, useState } from "react";
import {
  getInspectionBySupplierId,
  statusAPI,
  verdictApi,
} from "../../apiCalls/iqcAPIs";
import {
  showToastError
} from "../../../../common/toasters/toasterMessages";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../../clients/api-constants";
import InspectionHeader from "./components/header.js";
import InspectionBody from "./components/body.js";
import { FILTER_TAGS_VAL_MAPPING, TAGS_IN_DATE_FILTER } from "./utils/utils.js";
import { InspectionModal } from "../inspectionModal/inspectionModal";
import { useSearchParams } from "react-router-dom";

export default function InspectionTable(props) {

  const [searchParams, setSearchParams] = useSearchParams();

  const isActiveParam = searchParams.get('active');

  const [inspectionStatusList, setInspectionStatusList] = useState([]);
  const [verdictStatusList, setVerdictStatusList] = useState([]);
  const [inspectionData, setInspectionData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);

  const [selectedSupplier, setSelectedSupplier] = useState(undefined);
  const [selectedIcPart, setSelectedIcPart] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedVerdict, setSelectedVerdict] = useState(undefined);
  const [selectedGrn, setSelectedGrn] = useState(undefined);
  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState(undefined);
  const [selectedDateFilterTags, setSelectedDateFilterTags] = useState(TAGS_IN_DATE_FILTER);
  const [selectedDateRange, setSelectedDateRange] = useState(undefined);
  const [selectedActiveFilter, setSelectedActiveFilter] = useState((isActiveParam === 'true' || isActiveParam == null));
  const [isActive, setIsActive] = useState(selectedActiveFilter);

  const [showInspectionDetailed, setShowInspectionDetailed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getStatus = async () => {
    let response = await statusAPI();
    if (!response.isError && response?.data) {
      let dataList = [];
      response.data.forEach(status => {
        dataList.push({ value: status.title, label: status.title });
      });
      setInspectionStatusList(dataList);
    }
  };

  const getVerdictApi = async () => {
    let response = await verdictApi();
    if (!response.isError && response?.data) {
      let dataList = [];
      response.data.forEach(verdict => {
        dataList.push({ value: verdict.title, label: verdict.title });
      });
      setVerdictStatusList(dataList);
    }
  };

  useEffect(() => {
    if (showInspectionDetailed) {
      return;
    }
    void getStatus();
    void getVerdictApi();
    void fetchSupplierInspections(selectedSupplier, selectedStatus, selectedVerdict, selectedGrn, selectedIcPart, selectedActiveFilter, selectedDateRange, offset);
  }, [showInspectionDetailed]);

  const fetchSupplierInspections = async (
    id,
    inspectionStatusValue,
    verdictStatusValue,
    grnValue,
    icPartValue,
    activeValue,
    dateFilterValue = { createdAt: "", startedAt: "", closedAt: "" },
    pageOffset = offset,
    pageLimit = PAGE_SIZE
  ) => {
    setIsLoading(true);
    const { createdAt, startedAt, closedAt } = dateFilterValue;
    let inspectionsResponse = await getInspectionBySupplierId(id ? id : "", {
      verdictStatusValue,
      grnValue,
      inspectionStatusValue,
      icPartValue,
      activeValue,
      createdAt, startedAt, closedAt,
      pageOffset,
      limit: pageLimit,
    });
    if (inspectionsResponse.isError) {
      showToastError(inspectionsResponse.displayableMessage);
    } else {
      setInspectionData(inspectionsResponse.data)
      setTotalCount(inspectionsResponse.totalCount);
    }
    setIsLoading(false);
  };


  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchSupplierInspections(selectedSupplier, selectedStatus, selectedVerdict, selectedGrn, selectedIcPart, selectedActiveFilter, selectedDateRange, 0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchSupplierInspections(selectedSupplier, selectedStatus, selectedVerdict, selectedGrn, selectedIcPart, selectedActiveFilter, selectedDateRange, (currentPageSize * (page - 1)), currentPageSize);
    }
  };

  const handleFilterChange = (supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, activeFilter) => {
    setSelectedSupplier(supplier);
    setSelectedIcPart(icPart);
    setSelectedStatus(status);
    setSelectedVerdict(verdict);
    setSelectedDateRange(dateRange);
    setSelectedDateRangeValue(dateRange);
    setSelectedActiveFilter(activeFilter);
    setSelectedDateFilterTags(dateFilterTags);
    setSelectedGrn(grn);

    setCurrentPage(1);
    setCurrentPageSize(PAGE_SIZE);
    setOffset(DEFAULT_OFFSET);

    let dateRangeFilter = {};
    if (dateRange) {
      dateFilterTags.forEach(tag => {
        dateRangeFilter[FILTER_TAGS_VAL_MAPPING[tag]] = { "dateTimeFrom": dateRange[0].toISOString(), "dateTimeTo": dateRange[1].toISOString() };
      });
      setSelectedDateRange(dateRangeFilter);
    }
    fetchSupplierInspections(supplier, status, verdict, grn, icPart, activeFilter, dateRangeFilter, DEFAULT_OFFSET);
  };

  const onRefresh = () => {
    fetchSupplierInspections(selectedSupplier, selectedStatus, selectedVerdict, selectedGrn, selectedIcPart, selectedActiveFilter, selectedDateRange, offset);
  }

  const handleInspectionClick = (inspection) => {
    setShowInspectionDetailed(true);
  };


  const handleActiveFilterChange = (val) => {
    setIsActive(val)
    setSearchParams({ active: val })
  }

  return (
    <>
      <InspectionModal
        showModal={showModal}
        setShowModal={setShowModal}
        fetchSupplierInspections={fetchSupplierInspections}
      />
      <div>
        <InspectionHeader
          inspectionStatusList={inspectionStatusList}
          verdictStatusList={verdictStatusList}
          onFilterChange={handleFilterChange}
          setShowModal={setShowModal}
          selectedSupplier={selectedSupplier}
          selectedIcPart={selectedIcPart}
          selectedStatus={selectedStatus}
          selectedVerdict={selectedVerdict}
          selectedDateRange={selectedDateRangeValue}
          selectedActiveFilter={selectedActiveFilter}
          setSelectedGrn={setSelectedGrn}
          selectedDateFilterTags={selectedDateFilterTags}
          isActive={isActive}
          setIsActive={handleActiveFilterChange}
        />
        <hr />
        <InspectionBody
          inspectionData={inspectionData}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          onInspectionClick={handleInspectionClick}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
        />
      </div>
    </>
  );
}
