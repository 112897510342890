import { Descriptions } from "antd";
import { dateTimeFormat, onlyDate } from "../../../../../../../utils";
import { useEffect } from "react";

export default function SCARKeyDetails(props) {
    const { scarDetails } = props;
    const items = [
        {
            key: 'title',
            label: 'Title',
            children: scarDetails?.title,
            span: 3
        },
        {
            key: 'supplier',
            label: 'Supplier',
            children: scarDetails?.supplier?.name,
            span: 3
        },
        {
            key: 'customer',
            label: 'Customer',
            children: scarDetails?.customer?.name,
            span: 3
        },
        {
            key: 'qualityInspection',
            label: 'Quality Inspection',
            children: scarDetails?.qualityInspection?.key,
            span: 3
        },
        {
            key: 'incomingPart',
            label: 'Incoming Part',
            children: scarDetails?.incomingPart?.name,
            span: 3
        },
        {
            key: 'invoiceNumber',
            label: 'Invoice Number',
            children: scarDetails?.trailMeta?.invoiceNumber,
            span: 3
        },
        {
            key: 'invoiceDate',
            label: 'Invoice Date',
            children: <span>{onlyDate(scarDetails?.trailMeta?.invoiceDate)}</span>,
            span: 3
        },
        {
            key: 'quantity',
            label: 'Quantity',
            children: scarDetails?.trailMeta?.quantity,
            span: 3
        },
        {
            key: 'intimatedUser',
            label: 'Intimated By',
            children: scarDetails?.trailMeta?.intimatedUser?.name,
            span: 3
        }
    ];

    return (
        <>
            <Descriptions bordered items={items} />
        </>
    );
}