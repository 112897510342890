import { Badge, Button, Space, Table, Tooltip, Typography } from "antd";
import { SyncOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { renderDownloadableData, rmaTableCols } from "./rmaUtils";
import { useState } from "react";
import { bulkDeleteAFRApi } from "../../apis/afrAPIs";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import ExportColsModal from "../../../../common/components/exportColsModal/exportColsModal";

export default function RMABody(props) {

    const { afrData, onRefresh, customerData, productData, setSelectedAFR, supplierData, onPaginationChange,
        currentPage, totalRecords, currentPageSize, isLoading, setLoading } = props;

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showColModal, setShowColModal] = useState(false);

    const handleRowClick = (record) => {
        setSelectedAFR(record);
    }

    const cols = rmaTableCols(customerData, productData, supplierData, handleRowClick);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }

    const handleDelete = async () => {
        const deleteAFR = afrData?.filter(afr => selectedRowKeys.includes(afr.key)).map(afr => afr.id);
        const response = await bulkDeleteAFRApi(deleteAFR);
        if (response.isError) {
            showToastError('Error deleting afr: ', response.displayableMessage);
        } else {
            setSelectedRowKeys([]);
            onRefresh();
        }
    }

    const handlePageChange = (page, pageSize) => {
        setSelectedRowKeys([]);
        onPaginationChange(page, pageSize);
    }
    const handleDownload = () => {
        setShowColModal(true);
    }

    return (
        <>
            <ExportColsModal
                openModal={showColModal}
                setOpenModal={setShowColModal}
                columns={cols}
                dataSource={renderDownloadableData(afrData, customerData, productData, supplierData)}
                setLoader={setLoading}
                filename='rma.csv'
            />
            <Space direction="vertical" size="small" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span>
                            AFR <Typography.Text type="secondary" strong>({totalRecords})</Typography.Text>
                        </span>
                    </div>
                    <div style={{ float: "right" }}>
                        <Space size='large'>
                            {selectedRowKeys?.length > 0 && (
                                <Badge count={selectedRowKeys?.length} size='small'>
                                    <Button type="primary" icon={<DeleteOutlined />} disabled={false} onClick={handleDelete} danger />
                                </Badge>

                            )}
                            <Button icon={<SyncOutlined spin={false} />} disabled={isLoading} onClick={onRefresh} />
                            <Tooltip title="Download">
                                <Button icon={<DownloadOutlined />} onClick={handleDownload} disabled={isLoading} />
                            </Tooltip>
                        </Space>

                    </div>
                </div>
                <Table
                    size="small"
                    bordered={true}
                    loading={isLoading}
                    columns={cols}
                    dataSource={afrData}
                    pagination={{
                        pageSize: currentPageSize,
                        total: totalRecords,
                        current: currentPage,
                        showSizeChanger: true,
                        onChange: handlePageChange
                    }}
                    scroll={{ x: '50vh', y: '60vh' }}
                    rowSelection={rowSelection}
                />
            </Space>
        </>
    )
}