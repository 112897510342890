import { Button, ConfigProvider, DatePicker, Form, Input, InputNumber, Modal, Select, } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getIncomingPartsAPI, getInspectionByKeyAPI, getInspections } from "../../../../../IQCActivity/apiCalls/iqcAPIs";
import { showToastError } from "../../../../../../common/toasters/toasterMessages";
import SearchSelect from "../../../../../IQCActivity/components/inspectionTable/components/searchSelect";
import { getUsersAPI } from "../../../../../../common/apis/commonApis";
import { searchCustomerApi } from "../../../../../../features/customers/apis/customerApis";
import styles from "../../styles/scarModal.module.css"
import { getSuppliersAPI } from "../../../../apis/scarAPIs";

export default function EditSCARModal(props) {
    const { open, setOpen, scarDetails, updateSCAR } = props;

    const SEARCH_MORE_TYPE = "searchMore";

    const [form] = Form.useForm();
    const [scarType, setScarType] = useState(undefined);
    const [icPartOptions, setIcPartOptions] = useState([]);
    const [inspectionOptions, setInspectionOptions] = useState([]);
    const [selectSupplier, setSelectSupplier] = useState(undefined);

    const [incomingpartsList, setIncomingpartsList] = useState([]);
    const [totalCountIncomingparts, setTotalCountIncomingparts] = useState(0);
    const [incomingpartsLoading, setIncomingpartsLoading] = useState(false);

    const [suppliersList, setSuppliersList] = useState([]);
    const [totalCountSuppliers, setTotalCountSuppliers] = useState(0);
    const [suppliersLoading, setSuppliersLoading] = useState(false);

    const [inspectionList, setInspectionList] = useState([]);
    const [totalCountInspections, setTotalCountInspections] = useState(0);
    const [inspectionsLoading, setInspectionsLoading] = useState(false);

    const [usersData, setUsersData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [usersLoading, setUsersLoading] = useState(false);

    const [customersData, setCustomersData] = useState([]);
    const [customersLoading, setCustomersLoading] = useState(false);
    const [totalcustomers, setTotalCustomers] = useState(0);

    const fetchInspections = async (inspections = inspectionList, inspectionKey) => {
        setInspectionsLoading(true);
        const inspectionsResponse = await getInspections({ inspectionKey: inspectionKey, pageOffset: inspections.length, limit: 10 });
        if (inspectionsResponse.isError) {
            showToastError(inspectionsResponse.displayableMessage);
        } else {
            setTotalCountInspections(inspectionsResponse.totalCount);
            const dataList = inspections;
            inspectionsResponse?.data?.forEach(inspection => {
                dataList.push(inspection);
            });
            const inspectionOptionData = dataList?.map(inspection => ({ value: inspection?.id, label: inspection?.key }));
            setInspectionOptions(inspectionOptionData);
            setInspectionList(dataList);
        }
        setInspectionsLoading(false);
    }

    const fetchInspectionByKey = async (key) => {
        setInspectionsLoading(true);
        const inspectionsResponse = await getInspectionByKeyAPI(key);
        if (inspectionsResponse.isError) {
            showToastError(inspectionsResponse.displayableMessage);
        } else {
            setTotalCountInspections(1);
            setInspectionList(inspectionsResponse?.data);
        }
        setInspectionsLoading(false);
    }

    const fetchMoreSuppliers = async (value, offest = suppliersList.length) => {
        setSuppliersLoading(true);
        const suppliersResponse = await getSuppliersAPI(offest, value, 10);
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setTotalCountSuppliers(suppliersResponse.totalCount)
            const dataList = offest === 0 ? [] : suppliersList;
            suppliersResponse.data.forEach(supplier => {
                dataList.push({ value: supplier.id, label: supplier.name });
            });
            setSuppliersList(dataList);
        }
        setSuppliersLoading(false);
    };


    const handleOk = (values) => {
        updateSCAR({
            ...values,
            id: scarDetails?.id,
            qualityInspectionId: values.qualityInspectionId ? values.qualityInspectionId : ''
        });
        setOpen(false);

    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSCARTypeChange = async (value) => {
        setScarType(value);
        if (value === 'INSPECTION') {
            await fetchInspections([]);
            form.setFieldValue('qualityInspectionId', scarDetails?.qualityInspectionId)
        }
        else {
            fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, "");
            form.setFieldValue('qualityInspectionId', undefined);
        }
    };

    const handleInspectionChange = async (value, _e, info) => {
        if (info?.source === 'clear' || !value) {
            setInspectionList([]);
            await fetchInspections([]);
            form.setFieldValue('supplierId', undefined);
            form.setFieldValue('incomingPartId', undefined);
            form.setFieldValue('partNo', undefined);
            form.setFieldValue('invoiceNumber', undefined);
            form.setFieldValue('invoiceDate', undefined);
        } else {
            const selectedInspection = inspectionList?.filter(inspection => inspection.id === value);
            if (selectedInspection.length === 1) {
                setIncomingpartsList([selectedInspection[0]?.incomingPart]);
                if (scarDetails?.supplierId) {
                    form.setFieldValue('supplierId', selectedInspection[0]?.supplierId);
                }
                form.setFieldValue('incomingPartId', selectedInspection[0]?.incomingPartId);
                form.setFieldValue('partNo', selectedInspection[0]?.incomingPart?.code);
                form.setFieldValue('invoiceNumber', selectedInspection[0]?.meta?.invoiceNumber);
                form.setFieldValue('invoiceDate', selectedInspection[0]?.meta?.invoiceDate ? dayjs(selectedInspection[0]?.meta?.invoiceDate) : undefined);
            }
        }
    };

    const fetchIncomingParts = async (supplierId, isNew, value) => {
        setIncomingpartsLoading(true);
        const dataList = isNew ? [] : incomingpartsList;
        const icPartsResponse = await getIncomingPartsAPI(supplierId, dataList.length, 10, value);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalCountIncomingparts(icPartsResponse.totalCount);
            icPartsResponse?.data?.forEach(icPart => {
                dataList.push(icPart);
            });
            const icPartOptionData = dataList?.map(icPart => ({ value: icPart?.id, label: icPart?.name }));
            setIcPartOptions(icPartOptionData);
            setIncomingpartsList(dataList);
        }
        setIncomingpartsLoading(false);
    }

    const handleInspectionPartChange = (value) => {
        const selectedPart = incomingpartsList?.filter(part => part.id === value);
        if (selectedPart.length === 1) {
            form.setFieldValue('partNo', selectedPart[0]?.code);
        }
    };

    const handleSupplierChange = async (value) => {
        setSelectSupplier(value);
        form.setFieldValue('incomingPartId', undefined);
        fetchIncomingParts(value, true, "");
    };

    const handleSupplierSearch = async (searchVal) => {
            setSuppliersList([]);
            await fetchMoreSuppliers(searchVal, 0);
    }

    const handleIncomingPartsSearch = async (searchVal) => {
        await fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, searchVal);
    };
    
    const fetchMoreIncomingParts = async (searchVal) => {
        await fetchIncomingParts(form.getFieldValue("supplierId") ?? "", false, searchVal);
    };

    const setupData = async () => {
        if (scarDetails?.qualityInspectionId) {
            await fetchInspectionByKey(scarDetails?.qualityInspection?.key);
        }
        if (scarDetails?.supplierId) {
            await fetchMoreSuppliers(scarDetails?.supplier?.name);
        }
        if (scarDetails?.incomingPartId) {
            await fetchIncomingParts(scarDetails?.supplierId, true, scarDetails?.incomingPart?.name);
        }
        form.setFieldsValue({
            ...scarDetails,
            intimatedBy: scarDetails?.trailMeta?.intimatedBy,
            partNo: scarDetails?.incomingPart?.code,
            invoiceNumber: scarDetails?.trailMeta?.invoiceNumber,
            invoiceDate: scarDetails?.trailMeta?.invoiceDate ? dayjs(scarDetails?.trailMeta?.invoiceDate) : null,
            quantity: scarDetails?.trailMeta?.quantity
        })
    }

    const handleSearch = async (searchVal) => {
        if (searchVal?.length >= 5) {
            setInspectionOptions([]);
            await fetchInspections([], searchVal);
        }
    }

    const fetchUsers = async (value, type = "") => {
        setUsersLoading(true);
        const usersResponse = await getUsersAPI(type === SEARCH_MORE_TYPE ? usersData.length : 0, value, 10);
        if (usersResponse.isError) {
            showToastError(usersResponse.displayableMessage);
        } else {
            setTotalUsers(usersResponse.totalCount)
            const dataList = type === SEARCH_MORE_TYPE ? usersData : [];
            usersResponse.data.forEach(user => {
                dataList.push({ value: user?.user?.id, label: user?.user?.name });
            });
            setUsersData(dataList);
        }
        setUsersLoading(false);
    };

    const handleUserSearch = async (searchVal) => {
        setUsersData([]);
        await fetchUsers(searchVal);
    }

    const fetchMoreUsers = async () => {
        fetchUsers("", SEARCH_MORE_TYPE);
    }


    const handleCustomersSearch = async (searchVal) => {
        await fetchCustomers(searchVal);
    };

    const fetchCustomers = async (value, type = "") => {
        setCustomersLoading(true);
        const response = await searchCustomerApi({ name: value }, { limit: 10, offset: type === SEARCH_MORE_TYPE ? customersData.length : 0 });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTotalCustomers(response.totalCount);
            const dataList = type === SEARCH_MORE_TYPE ? customersData : [];
            response?.data?.forEach((customer) => {
                dataList.push({ label: customer.name, value: customer.id });
            });
            setCustomersData(dataList);
        }
        setCustomersLoading(false);
    };

    const fetchMoreCustomers = async () => {
        fetchCustomers("", SEARCH_MORE_TYPE);
    };


    useEffect(() => {
        if (scarDetails) {
            fetchUsers();
            if (scarDetails?.customerId)
                fetchCustomers();
            setScarType(scarDetails?.qualityInspectionId ? 'INSPECTION' : 'MANUAL');
            setupData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scarDetails]);

    useEffect(() => {
        if (incomingpartsList) {
            const icPartOptionData = incomingpartsList?.map(icPart => ({ value: icPart?.id, label: icPart?.name }));
            setIcPartOptions(icPartOptionData);
        }
    }, [incomingpartsList]);

    useEffect(() => {
        if (inspectionList) {
            const inspectionOptionData = inspectionList?.map(inspection => ({ value: inspection?.id, label: inspection?.key }));
            setInspectionOptions(inspectionOptionData);
        }
    }, [inspectionList]);

    return (
        <>
            <Modal
                title="Edit SCAR"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[

                ]}
                styles={
                    { footer: { textAlign: 'center' } }
                }
            >
                <ConfigProvider
                    theme={{
                        components: {
                            Form: {
                                itemMarginBottom: 10
                            }
                        }
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleOk}
                    >
                        <Form.Item label="SCAR For">
                            <Select style={{ width: '100%' }} placeholder="Supplier / Customer" value={scarDetails?.customerId ? 'Customer' : 'Supplier'}
                                options={[{ label: 'Supplier', value: 'Supplier' }, { label: 'Customer', value: 'Customer' }]}
                                disabled />
                        </Form.Item>
                        <Form.Item label="Type">
                            <Select style={{ width: '100%' }} placeholder="Select SCAR type" value={scarType}
                                options={[{ label: 'MANUAL', value: 'MANUAL' }, { label: 'INSPECTION', value: 'INSPECTION' }]} onChange={handleSCARTypeChange} />
                        </Form.Item>

                        {scarType === 'INSPECTION' &&
                            (<Form.Item label="Inspection" name="qualityInspectionId" required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select valid inspection!'
                                    }
                                ]}>
                                <SearchSelect
                                    showSearch
                                    allowClear
                                    loading={inspectionsLoading}
                                    placeholder="Select Inspection"
                                    options={inspectionOptions}
                                    filterOption={false}
                                    loadMore={fetchInspections}
                                    onChange={handleInspectionChange}
                                    onSearch={handleSearch}
                                    hasMoreData={totalCountInspections > inspectionOptions?.length}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>)}
                        {scarDetails?.supplierId && (<Form.Item label="Supplier" name="supplierId" required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select valid Supplier!'
                                },
                            ]}
                        >
                            <SearchSelect
                                showSearch
                                allowClear
                                loading={suppliersLoading}
                                placeholder="Select supplier"
                                options={suppliersList}
                                filterOption={false}
                                loadMore={fetchMoreSuppliers}
                                hasMoreData={totalCountSuppliers > suppliersList?.length}
                                style={{ width: '100%' }}
                                disabled={scarType === 'INSPECTION'}
                                onChange={handleSupplierChange}
                                onSearch={handleSupplierSearch}
                                onClear={() => {
                                    form.setFieldValue('incomingPartId', undefined);
                                    form.setFieldValue('partNo', '');
                                    fetchMoreSuppliers("", 0);
                                }}
                            />
                        </Form.Item>)}
                        {scarDetails?.customerId &&
                            (<Form.Item label="Customer" name="customerId" required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select valid customer!'
                                    }
                                ]}>
                                <SearchSelect
                                    showSearch
                                    allowClear
                                    loading={customersLoading}
                                    placeholder="Select Customer"
                                    options={customersData}
                                    filterOption={false}
                                    loadMore={fetchMoreCustomers}
                                    onSearch={handleCustomersSearch}
                                    hasMoreData={totalcustomers > customersData?.length}
                                    style={{ width: '100%' }}
                                    onClear={() => {
                                        fetchCustomers();
                                    }}
                                />
                            </Form.Item>)}
                        <Form.Item label="Part" name="incomingPartId" required
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select valid Incoming Part!'
                                }
                            ]}
                        >
                            <SearchSelect
                                showSearch
                                allowClear
                                loading={incomingpartsLoading}
                                placeholder="Select Incoming Part"
                                options={icPartOptions}
                                filterOption={false}
                                onSearch={handleIncomingPartsSearch}
                                loadMore={fetchMoreIncomingParts}
                                hasMoreData={totalCountIncomingparts > icPartOptions?.length}
                                style={{ width: '100%' }}
                                disabled={scarType === 'INSPECTION'}
                                onChange={handleInspectionPartChange}
                                onClear={() => {
                                    fetchIncomingParts(form.getFieldValue("supplierId") ?? "", true, "");
                                    form.setFieldValue('partNo', undefined);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Part No" name="partNo">
                            <Input placeholder="Part No" disabled />
                        </Form.Item>
                        <div className={styles.samplingDiv}>
                            <div className={styles.samplingType}>
                                <Form.Item label="Invoice No" name="invoiceNumber">
                                    <Input placeholder="Invoice No" disabled={scarType === 'INSPECTION'} />
                                </Form.Item>
                            </div>
                            <div className={styles.widthHalf}>
                                <Form.Item label="Invoice Date" name="invoiceDate">
                                    <DatePicker style={{ width: "100%" }} disabled={scarType === 'INSPECTION'}
                                    showTime />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item label="Quantity" name="quantity">
                            <InputNumber style={{ width: "100%" }} placeholder="Quantity" />
                        </Form.Item>
                        <Form.Item label="SCAR Title / Failure Detail" name="title" required    
                        rules={[{
                                required: true,
                                message: 'Please enter title!'
                        }]}>
                            <Input placeholder="SCAR Title / Failure Detail" />
                        </Form.Item>
                        <Form.Item label="Intimated by" name="intimatedBy">
                            <SearchSelect
                                showSearch
                                allowClear
                                loading={usersLoading}
                                placeholder="Intimated by"
                                options={usersData}
                                filterOption={false}
                                onSearch={handleUserSearch}
                                onClear={fetchUsers}
                                loadMore={fetchMoreUsers}
                                hasMoreData={totalUsers > usersData.length}
                            />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button key="submit" type="primary" htmlType="submit" >
                                Update SCAR
                            </Button>
                        </Form.Item>
                    </Form>
                </ConfigProvider>
            </Modal>
        </>
    );
}