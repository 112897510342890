import axios from "axios";
import { ApiClient, ApiClientType } from "../../../clients/api-client.js";
import { dataFetchActionType } from "../../../common/states/dataFetch/dataFetchActionType.js";

export async function uploadImage(
  inputRef,
  setUploadedFileName,
  dispatchUploadStatus,
  folderPath
) {
  if (inputRef?.current?.files) {
    dispatchUploadStatus({
      type: dataFetchActionType.loading,
    });

    let requestBody = {
      folder: folderPath,
      ext: "",
    };
    let urlExtension = "/" + folderPath + "/upload";
    let response = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_MASTER_BASE_URL,
      urlExtension,
      requestBody
    );

    if (!response.isError) {
      let s3Upload = await axios.put(
        response?.data?.uploadLink?.url,
        inputRef.current?.files[0],
        {
          headers: {
            "Content-Type": inputRef?.current?.files[0]?.type,
          },
        }
      );

      if (s3Upload?.status == 200) {
        setUploadedFileName([response?.data?.url]);
        dispatchUploadStatus({
          type: dataFetchActionType.data,
          data: inputRef?.current?.files[0]?.name,
        });
      }
    }
  }
}
