import React, { useEffect, useState } from 'react';
import { Button, Divider, Table, Tooltip } from "antd";
import styles from '../styles/sheetTable.module.css';
import { SyncOutlined } from '@ant-design/icons';
import { sheetTableColumns } from '../utils/sheetTableUtils';
import ResizableTitle from './resize';
import { useNavigate, useParams } from "react-router-dom";
import { getSupplierByIdsApi, getIncomingPartsByIdsApi, getProductByIdsApi, getStationsByIdsApi } from '../../../../../common/apis/commonApis';
import { showToastError } from '../../../../../common/toasters/toasterMessages';

export default function SheetBody(props) {
    const params = useParams();
    const navigate = useNavigate();
    const { data, loading, totalRecords, onTableChange, currentPage, onRefresh, currentPageSize, onPaginationChange, setLoading } = props;
    const [suppliersData, setSuppliersData] = useState(undefined);
    const [productsData, setProductsData] = useState(undefined);
    const [icPartsData, setIcPartsData] = useState(undefined);
    const [stationsData, setStationsData] = useState(undefined);

    const [columns, setColumns] = useState([]);

    const fetchAllSupplierDetails = async (supplierIds) => {
        const response = await getSupplierByIdsApi(supplierIds);
        if (response.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        let suppliers = {}
        response?.data.map(supplier => suppliers[supplier.id] = supplier);
        setSuppliersData(suppliers);
    }
    const fetchAllProductDetails = async (productIds) => {
        const response = await getProductByIdsApi(productIds);
        if (response.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        let products = {}
        response?.data.map(product => products[product.id] = product);
        setProductsData(products);
    }
    const fetchAllICpartDetails = async (icPartIds) => {
        const response = await getIncomingPartsByIdsApi(icPartIds);
        if (response.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        let icParts = {}
        response?.data.map(icPart => icParts[icPart.id] = icPart);
        setIcPartsData(icParts);
    }

    const fetchAllStationDetails = async (stationIds) => {
        const response = await getStationsByIdsApi(stationIds);
        if (response.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        let stations = {}
        response?.data.map(station => stations[station.id] = station);
        setStationsData(stations);
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (data && data.length > 0) {
                const supplierIds = data?.filter(sheet => sheet?.meta?.supplierId !== "")
                    .map(sheet => sheet?.meta?.supplierId);
                await fetchAllSupplierDetails(supplierIds);
                const productIds = data?.filter(sheet => sheet?.meta?.productId !== "")
                    .map(sheet => sheet?.meta?.productId);
                await fetchAllProductDetails(productIds);
                const icPartIds = data?.filter(sheet => sheet?.meta?.incomingPartId !== "")
                    .map(sheet => sheet?.meta?.incomingPartId);
                await fetchAllICpartDetails(icPartIds);
                const stationIds = data?.filter(sheet => sheet?.meta?.stationId !== "")
                    .map(sheet => sheet?.meta?.stationId);
                await fetchAllStationDetails(stationIds);
            }
            setLoading(false);

        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (!loading && suppliersData && productsData && icPartsData && stationsData) {
            setColumns(sheetTableColumns(params, navigate, suppliersData, productsData, icPartsData, stationsData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suppliersData, productsData, icPartsData, stationsData]);

    const handleResize =
        (index) =>
            (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = {
                    ...newColumns[index],
                    width: size.width,
                };
                setColumns(newColumns);
            };
            
    const mergeColumns = columns?.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
            minWidth: column.minWidth,
        }),
    }));

    return (
        <>
            <div>
                <div className={styles.containerHeader}>
                    <div className={styles.containerHeaderCol1}>
                        <span style={{ fontWeight: "bold" }}>Sheets</span> <span className={styles.containerHeaderCol1Span}>({totalRecords})</span>
                    </div>
                    <div className={styles.containerHeaderCol2}>
                        <Tooltip title="Refresh">
                            <Button icon={<SyncOutlined spin={loading} tool />} onClick={onRefresh} disabled={loading} />
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.containerBody}>
                    <Table
                        bordered
                        loading={loading}
                        onChange={onTableChange}
                        columns={mergeColumns}
                        dataSource={data}
                        pagination={{
                            pageSize: currentPageSize,
                            total: totalRecords,
                            current: currentPage,
                            showSizeChanger: true,
                            onChange: onPaginationChange,
                        }}
                        components={{
                            header: {
                                cell: ResizableTitle,
                            },
                        }}
                        scroll={{ x: 1200, y: 585 }}
                    />
                </div>
            </div>
        </>
    );
};