import { ApiClient, ApiClientType, getNamespaceCode } from "../../clients/api-client";

export const getUserDetailsAPI = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_USER_BASE_URL,
    `/users/${id}`
  );
};

export const updateUserDetailsAPI = async (values) => {
  let body = {
    name: values.firstName,
  };
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_USER_BASE_URL,
    `/users/${values.id}`,
    body
  );
};

export const getSuppliersAPI = async (offset = 0, name = '', limit = 5) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers?name=${name}&limit=${limit}&offset=${offset}`
  );
};

export const getIncomingPartsAPI = async (offset = 0, name = '', limit = 5, supplierId = undefined) => {
  let path = `/incoming-parts?includeSuppliers=true&name=${name}&limit=${limit}&offset=${offset}`;
  path = supplierId ? path + `&supplierId=${supplierId}` : path;
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    path
  );
};

export const getProductsApi = async (
  offset = 0,
  name = '',
  limit = 5) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products?includeProductCategory=false&code=&productCategoryId=&name=${name}&limit=${limit}&offset=${offset}`
  );
};

export const getStationsApi = async (
  offset = 0,
  name = '',
  limit = 5) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations?name=${name}&limit=${limit}&offset=${offset}&code=&includeMachines=true&includeMachineOperations=false`
  );
};

export const getSheetsAPI = async (
  appId,
  offset = 0,
  name = '',
  limit = 5
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheets?applicationId=${appId}&name=${name}&limit=${limit}&offset=${offset}`
  );
}

export const searchSheetsAPI = async (
  searchVal = '',
  limit = '',
  offset = 0
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheets?name=${searchVal}&limit=${limit}&offset=${offset}`
  );
}

export const getUsersAPI = async (
  offset = 0,
  name = '',
  limit = 5
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_USER_BASE_URL,
    `/tenant-users?name=${name}&limit=${limit}&offset=${offset}&includeUser=true`
  );
}

export const getSheetDetailsAPI = async (
  id,
  includeSections = false,
  includeSheetEdits = false,
  includeFields = false
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheets/${id}?includeSections=${includeSections}&includeSheetEdits=${includeSheetEdits}&includeFields=${includeFields}`
  );
};


export const getSupplierApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers/${id}`
  );
}

export const getStationApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations/${id}`
  );
}

export const getProductApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products/${id}`
  );
}

export const getIcPartApi = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts/${id}?includeSuppliers=true`
  );
}

export const getTenantDetailsAPI = async (code) => {
  if (!code || code === "") {
    code = getNamespaceCode();
  }
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_USER_BASE_URL,
    `/tenants?code=${code}`
  );
}

export const getDashboardToken = async (dashboardId) => {
  let body = {
    dashboardId: [dashboardId],
  };
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/analytics/guest-token`,
    body
  );
}
export const getSupplierByIdsApi = async (idList) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers/search`,
    {
      id: idList
    }
  );
}

export const getProductByIdsApi = async (idList) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/products/search`,
    {
      id: idList
    }
  );
}

export const getIncomingPartsByIdsApi = async (idList) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts/search`,
    {
      id: idList
    }
  );
}

export const getStationsByIdsApi = async (idList) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/stations/search`,
    {
      id: idList
    }
  );
}

export const getApplicationDetailsAPI = async (appId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/applications/${appId}?includeWorkroom=false`,
  );
}
