import { Button, Tag } from "antd";
import dayjs from "dayjs";

export const rmaTableCols = (customerData, productData, supplierData, handleRowClick) => [
    {
        title: 'AFR ID',
        dataIndex: ['key'],
        align: 'center',
        key: 'key',
        fixed: 'left',
        width: 200,
        render: (_, record) => <Button type="link" onClick={() => handleRowClick(record)}>{record.key}</Button>
    },
    {
        title: 'Customer',
        dataIndex: ['customerId'],
        align: 'center',
        key: 'customerId',
        width: 250,
        render: (value) => <span>{customerData?.find(customer => customer.id === value)?.name}</span>
    },
    {
        title: 'Product Category',
        dataIndex: ['productCategory'],
        align: 'center',
        key: 'productCategory',
        width: 250,
        render: (_, record) => <span>{productData?.find(product => product.id === record.productId)?.productCategory?.name}</span>
    },
    {
        title: 'Model',
        dataIndex: ['productId'],
        align: 'center',
        key: 'productId',
        width: 250,
        render: (value) => <span>{productData?.find(product => product.id === value)?.name}</span>
    },
    {
        title: 'Supplier',
        dataIndex: ['supplierId'],
        align: 'center',
        key: 'supplierId',
        width: 250,
        render: (value) => <span>{supplierData?.find(supplier => supplier.id === value)?.name}</span>
    },
    {
        title: 'Unit Serial No.',
        dataIndex: ['serialNumber'],
        align: 'center',
        width: 250,
        key: 'serialNumber'
    },
    {
        title: 'Week Code',
        dataIndex: ['weekCode'],
        align: 'center',
        width: 250,
        key: 'weekCode'
    },
    {
        title: 'Defect Category',
        dataIndex: ['defectCategory'],
        align: 'center',
        key: 'defectCategory',
        width: 250,
        render: (text) => <Tag>{text}</Tag>
    },
    {
        title: 'Defect Description',
        dataIndex: ['description'],
        align: 'center',
        width: 300,
        key: 'description'
    },
    {
        title: 'Repair Category',
        dataIndex: ['repairCategory'],
        align: 'center',
        width: 250,
        key: 'repairCategory',
        render: (text) => <Tag color="green">{text}</Tag>
    },
    {
        title: 'Repair Description',
        dataIndex: ['repairDescription'],
        align: 'center',
        width: 300,
        key: 'repairDescription'
    },
    {
        title: 'Date',
        dataIndex: ['date'],
        align: 'center',
        key: 'date',
        fixed: 'right',
        width: 200,
        render: (text) => <span>{dayjs(text)?.format('DD-MM-YYYY')}</span>
    }
]

export const renderDownloadableData = (data, customerData, productData, supplierData) => data?.map(record => ({
    ...record,
    key: record.key,
    date: dayjs(record?.date)?.format('DD-MM-YYYY'),
    weekCode: '\'' + record?.weekCode + '\'',
    customerId: customerData?.find(customer => customer.id === record?.customerId)?.name,
    productCategory: productData?.find(product => product.id === record.productId)?.productCategory?.name,
    productId: productData?.find(product => product.id === record?.productId)?.name,
    supplierId: supplierData?.find(supplier => supplier.id === record?.supplierId)?.name
}))