import styles from "./styles/inspectionDetailedViewBody.module.css";
import { useEffect, useState } from "react";

import { Tabs } from 'antd';
import { tabItems } from "./utils/tabItems";
import { useNavigate, useParams } from "react-router-dom";

export default function InspectionDetailedViewBody(props) {

    const params = useParams();
    const navigate = useNavigate();

    const {isLoading, inspectionDetails, fetchInspectionDetailsByKey} = props.values;

    const [items, setItems] = useState([])

    useEffect(() => {
        if(inspectionDetails && fetchInspectionDetailsByKey) {
            setItems(tabItems(isLoading, inspectionDetails, fetchInspectionDetailsByKey))
        }
    },[inspectionDetails, fetchInspectionDetailsByKey])

    return (
        <div className={styles.detailedBody}>
            <Tabs 
                activeKey={params.activeKey} 
                items={items} 
                className={styles.customTabNavClass} 
                onChange={(activeKey => navigate(`/application/${params.appId}/inspections/${params.inspectionKey}/${activeKey}`, { replace: true }))}
            />
        </div>
    )
}
