import PencilRuler2LineIcon from "remixicon-react/PencilRuler2LineIcon";
import DraftLineIcon from "remixicon-react/DraftLineIcon";
import ArrowLeftRightLineIcon from "remixicon-react/ArrowLeftRightLineIcon";
import PictureInPictureLineIcon from "remixicon-react/PictureInPictureLineIcon";
import LineChartLineIcon from "remixicon-react/LineChartLineIcon";
import { OrderedListOutlined } from "@ant-design/icons";
import CheckboxMultipleLineIcon from "remixicon-react/CheckboxMultipleLineIcon";

export default function sideNavRoutes(props) {
  let { appId } = props;
  return [
    {
      path: `/application/${appId}/dashboard`,
      name: "Dashboard",
      icon: <LineChartLineIcon />,
      feature: "dashboard",
      groupBy: "application",
      key: "dashboard/1"
    },
    {
      path: `/application/${appId}/inspections`,
      name: "Inspections",
      icon: <DraftLineIcon />,
      feature: "inspections",
      groupBy: "application",
      key: "inspections/1"
    },
    {
      path: `/application/${appId}/sheets`,
      name: "Sheets",
      icon: <PencilRuler2LineIcon />,
      feature: "sheets",
      groupBy: "application",
      key: "sheets/1"
    },
    {
      path: `/application/${appId}/calibrations`,
      name: "Calibrations",
      icon: <PictureInPictureLineIcon />,
      feature: "calibrations",
      groupBy: "application",
      key: "calibrations/1"
    },
    {
      path: `/application/${appId}/supplier-ratings`,
      name: "Supplier Ratings",
      icon: <OrderedListOutlined />,
      feature: "supplier-ratings",
      groupBy: "application",
      key: "supplier-ratings/1"
    },
    {
      path: `/application/${appId}/scars`,
      name: "SCARs",
      icon: <CheckboxMultipleLineIcon />,
      feature: "scars",
      groupBy: "application",
      key: "scars/1"
    },
    {
      path: `/application/${appId}/afr`,
      name: "AFR",
      icon: <ArrowLeftRightLineIcon />,
      feature: "afr",
      groupBy: "application",
      key: "afr/1"
    }
  ];
}