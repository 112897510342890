import { ConfigProvider, Form, Input, Modal } from "antd"
import { useState } from "react";
import { showToastError, showToastSuccess } from "../../../../common/toasters/toasterMessages";
import { createSheetAPI } from "../../apiCalls/sheetAPIs";
import SupplierSearchSelect from "../../common/supplierSearchSelect";
import ICPartSearchSelect from "../../common/icPartSearchSelect";
import ProductSearchSelect from "../../common/productSearchSelect";
import StationSearchSelect from "../../common/stationSearchSelect";
import styles from "./styles/sheetModal.module.css";
import { useParams } from "react-router-dom";

export const CreateSheetModal = (props) => {

    const { showModal, setShowModal, fetchSheetList } = props;
    const params = useParams();
    let appId = params.appId;
    const [showButton, setShowButton] = useState(true);

    const [form] = Form.useForm();


    const createSheet = async (body) => {
        let response;
        response = await createSheetAPI(body);
        if (response.isError) {
            showToastError(response?.displayableMessage)
        } else {
            fetchSheetList();
            showToastSuccess(response?.displayableMessage);
        }
    }

    const handleSave = async (values) => {
        let body = {
            name: values.name,
            description: values.description,
            code: values.code,
            applicationId: appId,
            productId: values.product,
            stationId: values.station,
            supplierId: values.supplier,
            incomingPartId: values.icPart,
        }

        await createSheet(body);
        setShowModal(false);
        form.resetFields();
    };

    function handleCancel() {
        setShowModal(false);
        form.resetFields();
    }

    return (<ConfigProvider
        theme={{
            token: {
                colorTextPlaceholder: '#7d7676', colorBorder: '#dadada'
            }
        }}
    >
        <Modal
            title={"New Sheet"}
            open={showModal}
            onCancel={handleCancel}
            maskClosable={false}
            cancelButtonProps={{
                style: { display: 'none' }
            }}
            okButtonProps={{
                style: { display: 'none' }
            }}
            width={"640px"}
        >
            <Form
                onFinish={handleSave}
                form={form}
                layout="vertical"
                name="control-hooks"
            >
                <Form.Item
                    className="mb-3"
                    name="name"
                    label="Sheet Name"
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="Sheet Name"
                        onFocus={() => setShowButton(true)}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    label="Sheet Description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="Description"
                        onFocus={() => setShowButton(true)}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    label="Sheet Code"
                    name="code"
                    rules={[{required: true}]}
                >
                    <Input
                        placeholder="Sheet Code"
                        onFocus={() => setShowButton(true)}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    name="supplier"
                    label="Supplier"
                >
                    <SupplierSearchSelect
                        selected={form.getFieldValue('supplier')}
                        onFilterChange={(value) => form.setFieldsValue({ supplier: value })}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    name="icPart"
                    label="IC Part"
                >
                    <ICPartSearchSelect
                        selected={form.getFieldValue('icPart')}
                        onFilterChange={(value) => form.setFieldsValue({ icPart: value })}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    name="product"
                    label="Product"
                >
                    <ProductSearchSelect
                        selected={form.getFieldValue('product')}
                        onFilterChange={(value) => form.setFieldsValue({ product: value })}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    name="station"
                    label="Station"
                >
                    <StationSearchSelect
                        selected={form.getFieldValue('station')}
                        onFilterChange={(value) => form.setFieldsValue({ station: value })}
                    />
                </Form.Item>
                <Form.Item className={styles.btnFormItem}>
                    {showButton && (<button
                        type="submit"
                        className={styles.createBtn}
                    >
                        {"Create Sheet"}
                    </button>)}
                </Form.Item>
            </Form>
        </Modal>

    </ConfigProvider>)
}