import { Dropdown } from "react-bootstrap";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import More2LineIcon from "remixicon-react/More2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import CheckDoubleFillIcon from "remixicon-react/CheckDoubleFillIcon";
import styles from "./styles/dotOptionsDropdown.module.css";

export default function DotOptionsSideBar(props) {
  const { status, deleteCalibration, closeCalibration } = props.values;

  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          display: "table-cell",
          verticalAlign: "middle",
          width: "30px",
          marginRight: "1rem",
        }}
      >
        <div
          style={{
            background: "none",
            border: "none",
            color: "#7D7676",
          }}
        >
          <More2LineIcon
            style={{
              cursor: "pointer",
              position: "absolute",
              left: "-50%",
              top: "10%",
            }}
            transform="rotate(90 12 12)"
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id={styles.dropDownMenu}>
        {status === "OPEN" && (
          <>
            <Dropdown.Item eventKey="1" onClick={closeCalibration}>
              <div className={styles.closeCalibration}>
                <CheckDoubleFillIcon
                  size="20px"
                  className={styles.iconStyling}
                  color=" #2ca44d"
                />
                Close calibration
              </div>
            </Dropdown.Item>
            <hr style={{ margin: "10px" }} />
          </>
        )}

        {status !== "OPEN" && (
          <>
            <Dropdown.Item eventKey="2">
              <div className={styles.genereteReport}>
                <ExternalLinkLineIcon
                  size="20px"
                  className={styles.iconStyling}
                  color="#c4c4c4"
                />
                {props?.values?.name ? props.values.name : "Generate report"}
              </div>
            </Dropdown.Item>
            <hr style={{ margin: "10px" }} />
          </>
        )}
        <Dropdown.Item eventKey="3" onClick={deleteCalibration}>
          <div>
            <DeleteBin6LineIcon size="20px" className={styles.deleteBtn} />
            Delete Calibration
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
