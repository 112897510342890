import styles from "../../../styles/tablesStyling.module.css";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import More2LineIcon from "remixicon-react/More2LineIcon";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton.js";
import AddLineIcon from "remixicon-react/AddLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import SearchLineIcon from "remixicon-react/SearchLineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ProductsList from "../../productsList";
import defaultImage from "../../../../../assets/icons/defaultImage.svg";
import EmptyTableMsgs from "../../../../../common/components/emptyTableMsgs/emptyTableMsgs";
export default function ProductTable(props) {
  const { setEditProductModal } = props;

  let search;
  let productListDetails = props.value.productListDetails;
  let productCategoryDetails = props.value.productCategoryDetails;
  let products = productListDetails;

  const searchProductCategory = (value) => {
    clearTimeout(search);
    search = setTimeout(() => {
      props.value.searchProductCategory(value);
    }, 500);
  };

  const searchProduct = (value) => {
    clearTimeout(search);
    search = setTimeout(() => {
      props.value.searchProduct(value);
    }, 500);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (props.value.productCategoryList?.data?.length > 0) {
      const firstItemId = props.value.productCategoryList.data[0].id;
      setSelectedItem(firstItemId);
      props.value.onSelectProductCategory(firstItemId);
    }
  }, [props.value.productCategoryList]);

  return (
    <div id={styles.table}>
      <Row>
        <Col
          md={3}
          style={{
            borderRight: "1px solid #dadada",
            padding: "1rem 2.5rem",
          }}
        >
          <InputGroup
            style={{
              padding: "2px 10px 2px 10px",
              border: "1px solid #E5E5E5",
              background: "#F7F8FA",
              borderRadius: "38px",
              height: "45px",

              margin: "9px 5px 5px 5px",
            }}
          >
            <InputGroup.Text
              id="basic-addon1"
              style={{ background: "#F7F8FA", border: "none" }}
            >
              <SearchLineIcon
                size="20px"
                style={{
                  color: "#7D7676",
                  marginTop: "-3px",
                }}
              />
            </InputGroup.Text>
            <Form.Control
              onChange={(e) => searchProductCategory(e.target.value)}
              id={styles.searchField}
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </Col>
        <Col style={{ display: "table" }}>
          <p
            style={{
              margin: "0",
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            Product Details
          </p>
        </Col>
      </Row>

      <hr style={{ margin: "0px" }} />
      <Row style={{ height: "540px", overflowY: "auto" }}>
        <Col
          md={3}
          style={{
            // overflow: "auto",
            paddingBottom: "10px",
            paddingRight: "0px",
            borderRight: "1px solid #dadada",
          }}
        >
          {props.value.productCategoryList?.data?.length == 0 ? (
            <EmptyTableMsgs emptyMessage="No products" isTable={false} />
          ) : (
            props.value.productCategoryList?.data?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setSelectedItem(item.id);
                    props.value.onSelectProductCategory(item.id);
                  }}
                  key={item.id}
                  id={styles.product}
                  className={selectedItem === item.id ? styles.selected : ""}
                  style={{ height: "111px" }}
                >
                  <img
                    style={{
                      border: item?.meta?.images ? "1px solid #dadada" : "none",
                      borderRadius: "10px",
                      width: "80px",
                      height: "100px",
                    }}
                    src={
                      item?.meta?.images ? item.meta.images[0] : defaultImage
                    }
                    alt={item?.meta?.images ? "Product Image" : "Default Image"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginLeft: "25px",
                    }}
                  >
                    <span className="boldFont">{item.name}</span>
                  </div>
                </div>
              );
            })
          )}
        </Col>
        <Col
          style={{
            padding: "0 12px 0 0",
          }}
        >
          <div id={styles.productDetails}>
            <Row style={{ flexWrap: "nowrap" }}>
              <Col
                md={6}
                style={{
                  borderRight: "1px solid #dadada",
                  padding: "1rem",
                  height: "110px",
                }}
              >
                {productListDetails?.code ? (
                  <div style={{ marginLeft: "25px" }}>
                    <span className="boldFont">{productListDetails?.name}</span>
                    <p id={styles.productId}>
                      Product Id: {productListDetails.code}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "25px",
                      height: "96px",
                      overflowY: "auto",
                    }}
                  >
                    <p
                      className="boldFont"
                      style={{ fontSize: "20px", marginBottom: "0.5rem" }}
                    >
                      {productCategoryDetails
                        ? productCategoryDetails?.name
                        : "Product Category Name"}
                    </p>
                    <p className={styles.textColor}>
                      {productCategoryDetails
                        ? productCategoryDetails?.description
                        : "Clear description of the category including its specification details like dimensions, etc."}
                    </p>
                    <p></p>
                  </div>
                )}
              </Col>
              <Col
                md={5}
                style={{
                  textAlign: "center",
                  display: "table",
                  borderRight: "1px solid #dadada",
                }}
              >
                <span className={styles.textColor + " " + styles.totalProducts}>
                  Total Products:{" "}
                  <span className="boldFont" style={{ color: "#000000" }}>
                    {products?.length > 0 ? products.length : "0"}
                  </span>
                </span>
              </Col>
              <Col md={1} style={{ display: "table" }}>
                <Dropdown className={styles.totalProducts}>
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    style={{
                      float: "right",
                      padding: "0px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      paddingRight: "20px",
                      marginRight: "5px",
                      marginTop: "10px",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        background: "none",
                        border: "none",
                        color: "#7D7676",
                      }}
                    >
                      <More2LineIcon />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => props.value.onShowProductModal()}
                      >
                        <PencilLineIcon
                          size="20px"
                          style={{
                            color: "#7D7676",
                            marginRight: "10px",
                            marginBottom: "3px",
                          }}
                        />
                        Edit Details
                      </div>
                    </Dropdown.Item>
                    <hr style={{ margin: "10px" }} />
                    <Dropdown.Item className={styles.deleteBtn} eventKey="2">
                      <div style={{ color: "#d6d6d6", cursor: "default" }}>
                        <DeleteBin6LineIcon
                          size="20px"
                          style={{
                            color: "#d6d6d6",
                            marginRight: "10px",
                            marginBottom: "3px",
                          }}
                        />
                        Delete Product
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "19px",
            }}
          >
            <Col md={2}>
              <InputGroup
                style={{
                  border: "1px solid #E5E5E5",
                  background: "#F7F8FA",
                  borderRadius: "5px",
                  marginLeft: "15px",
                  width: "177px",
                }}
              >
                <InputGroup.Text
                  id="basic-addon1"
                  style={{
                    background: "#F7F8FA",
                    border: "none",
                    width: "fit-content",
                    padding: "0px",
                  }}
                >
                  <SearchLineIcon
                    size="20px"
                    style={{
                      color: "#7D7676",
                      marginTop: "-3px",
                      marginLeft: "10px",
                    }}
                  />
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => searchProduct(e.target.value)}
                  id={styles.searchField}
                  placeholder="Search a product"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <PrimaryButton
              value={{
                child: (
                  <>
                    <AddLineIcon
                      size="17px"
                      style={{
                        marginRight: "5px",
                        marginTop: "-4px",
                      }}
                    />
                    New Product
                  </>
                ),
                onClick: () => {
                  props.value.onShowProcessModal(null);
                },
                style: {
                  padding: "10px",
                  margin: "0 10px",
                },
              }}
            />
          </div>
          <br />
          {products?.length != 0 ? (
            <ProductsList
              setEditProductModal={setEditProductModal}
              showProcessModal={props.value.onShowProcessModal}
              productsList={products}
            />
          ) : (
            <EmptyTableMsgs emptyMessage="No products" isTable={false} />
          )}
        </Col>
      </Row>
    </div>
  );
}
