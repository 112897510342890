import { Button, Table, Tooltip } from "antd";
import SupplierRatingFilter from "../components/supplierRatingFilter/supplierRatingFilter";
import { SyncOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { supplierRatingTableCols } from "../utils/tableUtils";
import { useEffect, useState } from "react";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";
import { getSuppliersAPI } from "../../suppliers/apiCalls/suppliersApis";
import { getSupplierByIdsApi } from "../../../common/apis/commonApis";

export default function SupplierRating() {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);

  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState(undefined);

  const fetchSuppliers = async (pageOffset = offset, pageLimit = PAGE_SIZE, ids = undefined) => {
    setIsLoading(true);
    let response = await getSuppliersAPI(pageOffset, pageLimit, ids);
    if (!response.isError) {
      setTotalCount(response?.totalCount);
      setSupplierList(response.data);
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchSuppliers(0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchSuppliers(currentPageSize * (page - 1), currentPageSize);
    }
  };

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
    if (selectedSupplierId)
      fetchSuppliers(offset, PAGE_SIZE, [selectedSupplierId]); //TODO: should use fetchSuppliers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierId]);

  const onRefresh = () => {
    fetchSuppliers(offset);
  };

  return (
    <>
      <div style={{ marginTop: "15px" }}>
        <SupplierRatingFilter
          setSelectedSupplierId={setSelectedSupplierId} handleClear={fetchSuppliers} />
      </div>
      <hr />
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            <span>Supplier Rating</span> <span style={{ fontWeight: "bold", color: "darkgray" }}>({totalCount})</span>
          </div>
          {/* <div >
            <Tooltip title="Refresh">
              <Button icon={<SyncOutlined tool />} onClick={onRefresh} />
            </Tooltip>
          </div> */}
        </div>
        <div style={{ marginTop: "10px" }} >
          <Table
            size="small"
            loading={isLoading}
            columns={supplierRatingTableCols(params, navigate)}
            dataSource={supplierList}
            pagination={{
              pageSize: currentPageSize,
              total: totalCount,
              current: currentPage,
              showSizeChanger: true,
              onChange: handlePaginationChange,
            }}
            scroll={{ x: 1200, y: 600 }}
          />
        </div>
      </div>
    </>
  );
}
