import React, { useState, useEffect } from "react";
import WorkforceTable from "../components/workforceTable";
import { ApiClient, ApiClientType } from "../../../clients/api-client.js";
import WorkroomPagination from "workroom-pagination";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants.js";

export default function People() {
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);


  const getUserList = async (pageOffset = DEFAULT_OFFSET, pageLimit = PAGE_SIZE) => {
    setIsLoading(true);
    let response = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_USER_BASE_URL,
      `/tenant-users?&limit=${pageLimit}&offset=${pageOffset}&includeUser=true`,
    );
    if (!response.isError) {
      setUserList(response?.data?.map(tenantUser => tenantUser?.user))
      setTotalCount(response?.totalCount);
    }
    setIsLoading(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getUserList(PAGE_SIZE * (pageNumber - 1));
  };

  useEffect(() => {
    void getUserList();
  }, []);
  return (
    <div
      style={{
        margin: "30px 20px 20px 10px",
      }}
    >
      <div
        style={{
          border: isLoading ? "" : "1px solid #DADADA",
          marginTop: "18px",
          background: "white",
          borderRadius: "10px",
        }}
      >
        {!isLoading && (
          <WorkforceTable
            data={userList}
            loading={isLoading}
          />
        )}
      </div>
      {!isLoading && (
        <div style={{ height: 'auto', position: 'sticky', bottom: 0, padding: '10px' }}>
          <WorkroomPagination currentPage={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onPageChange={handlePageChange} />
        </div>
      )}

    </div>
  );
}
