import { DatePicker, Space } from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";
import { useState } from "react";
const { RangePicker } = DatePicker;


export default function FilterRangePicker(props) {
    const { rangePresets, handleOnChange, style, dateRange, dateFilterTags, className, tagsData } = props;
    const [filterTags, setFilterTags] = useState(dateFilterTags);

    const handleChange = (tag, checked) => {
        const nextFilterTags = checked
            ? [...filterTags, tag]
            : filterTags.filter((t) => t !== tag);
        if (nextFilterTags.length > 0) {
            setFilterTags(nextFilterTags);
        }
    };

    const renderExtraFooter = () => {
        return (
            <Space style={{ width: '100%' }} size={[0, 0]}>
                {tagsData.map((tag) => (
                    <CheckableTag
                        key={tag}
                        color="processing"
                        checked={filterTags.includes(tag)}
                        onChange={(checked) => handleChange(tag, checked)}
                    >
                        {tag}
                    </CheckableTag>
                ))}
            </Space>
        );
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            handleOnChange(dateStrings[0], dateStrings[1], filterTags);
        } else {
            handleOnChange(undefined, undefined, ['Closed at']);
        }
    };

    return (
        <RangePicker
            allowClear
            className={className}
            style={style}
            showToday={false}
            presets={rangePresets}
            onChange={onRangeChange}
            renderExtraFooter={renderExtraFooter}
            value={dateRange}
        />
    );
}
