import InspectionDetails from "../../inspectionDetails/inspectionDetails";
import InspectionDocuments from "../../inspectionDocuments/inspectionDocuments";
import { InspectionLogs } from "../../inspectionLogs/inspectionLogs";
// import InspectionScarPage from "../../inspectionScarPage/inspectionScarPage"

export const tabItems = (
    isLoading,
    inspectionDetails,
    fetchInspectionDetailsByKey
) => [
    {
        key: 'details',
        label: 'Details',
        children:
            <InspectionDetails
                values={{
                    isLoading,
                    inspectionDetails,
                    fetchInspectionDetailsByKey
                }}
            />,
    },
    {
        key: 'logs',
        label: 'Logs',
        children:
            <InspectionLogs
                values={{
                    inspectionDetails
                }}
            />,
    },
    {
        key: 'documents',
        label: 'Documents',
        children:
            <InspectionDocuments
                values={{
                    inspectionDetails
                }}
            />,
    },
    // {
    //     key: 'scars',
    //     label: 'SCARs',
    //     children:
    //     <InspectionScarPage
    //         values={{
    //             inspectionDetails
    //         }}
    //     />,
    // },
];