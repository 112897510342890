import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import styles from "./styles/statusButton.module.css";

export default function StatusButtons(props) {
  const { statusLabel, statusList, calibrationState, setCalibrationState } =
    props.values;
  const [list, setList] = useState(false);
  const [calibStatusList, setCalibStatusList] = useState({});

  const getStatus = () => {
    if (statusList?.length > 0)
      setCalibStatusList([
        { title: "All", color: "#F5F5F5", displayTitle: "All" },
        ...statusList,
      ]);
  };

  useEffect(() => {
    getStatus();
  }, [statusList]);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setList(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`flex-center`}
      ref={containerRef}
      style={{
        zIndex: "53",
        marginLeft: "1.5rem",
        marginRight: "1rem",
        ...props?.values?.styling,
      }}
    >
      <div className={styles.lotStatus} style={{ ...props?.values?.style }}>
        {statusLabel}
      </div>
      <div>
        <Form.Floating>
          <Form.Control
            onFocus={() => {
              setList(true);
            }}
            id={styles.statusDropdown}
            value={calibrationState?.displayTitle}
            style={{ ...props?.values?.style }}
            readOnly={true}
          />
          <span
            style={{
              background: calibrationState?.color
                ? calibrationState.color
                : "#F5F5F5",
            }}
            className={styles.circleStatusValue}
          ></span>
          {list && (
            <div className={styles.listDiv}>
              {calibStatusList?.length > 0 ? (
                calibStatusList.map((item, key) => (
                  <div
                    key={key}
                    className={styles.listItem}
                    onClick={() => {
                      if (item.displayTitle === "All") {
                        setCalibrationState(item);
                        setList(false);
                      } else {
                        setCalibrationState(item);
                        setList(false);
                      }
                    }}
                  >
                    <span
                      style={{
                        background: item?.color ? item.color : "#F5F5F5",
                      }}
                      className={styles.circleStatus}
                    ></span>
                    <span>
                      {item?.title?.charAt(0).toUpperCase() +
                        item?.title?.slice(1).toLowerCase()}
                    </span>
                  </div>
                ))
              ) : (
                <>No items</>
              )}
            </div>
          )}
          <ArrowDownSLineIcon
            size="20px"
            id={styles.arrowDownSLineIconStyle}
            onClick={() => {
              setList(true);
            }}
          />
        </Form.Floating>
      </div>
    </div>
  );
}
