import { Table, Tooltip, Button, Divider, ConfigProvider, Popover, Tag, Input, Form, InputNumber, Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { SyncOutlined, EditOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import FileList2LineIcon from "remixicon-react/FileList2LineIcon";
import styles from "../../styles/scarLogs.module.css"
import { showToastError } from "../../../../../../../common/toasters/toasterMessages";
import { getSheetDetailsAPI } from "../../../../../../../common/apis/commonApis";
import ResizableTitle from "../../../../../../IQCActivity/components/inspectionTable/components/resize";
import { getSheetOrdersForTaskAPI, submitSheetOrdersForTaskAPI } from "../../../../../apis/scarAPIs";
import ExportColsModal from "../../../../../../../common/components/exportColsModal/exportColsModal";
import dayjs from "dayjs";
import { dateTimeFormat } from "../../../../../../../utils";

export const TableHeader = (props) => {

    const {
        sheetName,
        handleRefresh,
        handleDownload,
        handleEdit,
        handleSave,
        editing,
        loading
    } = props.values;
    return (
        <div className={styles.tableHeaderDiv}>
            <div className={styles.tableHeader}>
                <FileList2LineIcon
                    color="#7d7676"
                />
                <span className={styles.tableHeaderTitle}>{sheetName}</span>
            </div>
            <div className={styles.btnsDiv}>
                <Tooltip title="Refresh">
                    <Button icon={<SyncOutlined spin={loading} tool />} onClick={handleRefresh} disabled={loading} />
                </Tooltip>
                <Divider type="vertical" />
                {!editing ? (<Tooltip title="Edit">
                    <Button icon={<EditOutlined />} onClick={handleEdit} disabled={loading} />
                </Tooltip>) :
                    (<Tooltip title="Save">
                        <Button icon={<SaveOutlined />} onClick={handleSave} disabled={loading} />
                    </Tooltip>)}
                {/*<Divider type="vertical" />*/}
                {/* <Tooltip title="Download">
                    <Button icon={<DownloadOutlined />} onClick={handleDownload} disabled={loading} />
                </Tooltip> */}
            </div>
        </div>
    )
}

export const TaskSheetLogTable = (props) => {

    const { sheet, taskDetails, sectionLogData, sectionData, sheetOrder, fetchSheetData, fieldNames,
        fetchSheetOrderForTask, loading, setLoading, fieldToSectionMap, sheetDetails } = props.values;
    const [form] = Form.useForm();

    const [cols, setCols] = useState([]);
    const [showColModal, setShowColModal] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleEdit = () => {
        setEditing(true);
    }
    const handleSave = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            const currentFields = Object.keys(values);
            const otherVals = sheetOrder?.data?.fieldValues?.filter(field => !currentFields.includes(field?.fieldId));
            const otherFields = otherVals ? otherVals?.map(field => field.fieldId) : [];
            let data = Object.entries(values).map(([fieldId, fieldValue]) => ({ fieldId, fieldValue, fieldName: fieldNames.get(fieldId), sectionId: sectionData?.id, sectionName: sectionData?.name }));
            const newValues = [...fieldToSectionMap?.keys()]?.filter(field => !otherFields.includes(field) && !currentFields.includes(field))
                .map(field => (
                    {
                        fieldId: field,
                        fieldValue: undefined,
                        fieldName: fieldNames.get(field),
                        sectionId: fieldToSectionMap.get(field)?.id,
                        sectionName: fieldToSectionMap.get(field)?.name
                    }
                ));
            data = otherVals ? data.concat(otherVals) : data;
            data = newValues && newValues.length > 0 ? data.concat(newValues) : data;
            data = data.map(field => (
                {
                    ...field,
                    sectionId: fieldToSectionMap.get(field?.fieldId)?.id,
                    sectionName: fieldToSectionMap.get(field?.fieldId)?.name
                }
            ));
            data = data.reduce((map, obj) => {
                map[obj.fieldId] = obj;
                return map;
            }, {});
            const updatedData = [];
            sheetDetails?.sections?.forEach((section) => {
                section?.fields?.forEach((field) => {
                    updatedData.push(data[field?.id]);
                });
            });

            const response = await submitSheetOrdersForTaskAPI(sheetOrder?.id, { data: { fieldValues: updatedData } });
            if (response.isError) {
                showToastError(response.displayableMessage)
            } else {
                setEditing(false);
                handleRefresh();
            }
        } catch (errorInfo) {
            console.error('Validation Failed:', errorInfo);
        } finally {
            setLoading(false);
        }
    }

    const [mergeColumns, setMergeColumns] = useState([]);

    const handleResize = (index) =>
        (_, { size }) => {
            const newColumns = [...cols];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width,
            };
            setCols(newColumns);
        };

    useEffect(() => {
        const mergeColumnData = cols?.map((col, index) => {
            col = {
                ...col,
                onHeaderCell: (column) => ({
                    width: column.width,
                    onResize: handleResize(index),
                    minWidth: column.minWidth,
                }),
            };
            return col;
        });
        setMergeColumns(mergeColumnData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cols]);

    const createColumns = async () => {
        let columns = []
        const sectionColumns = sectionData?.fields?.map((field) => {
            return ({
                title: field?.properties?.title,
                dataIndex: [field?.id],
                width: 300,
                minWidth: 300,
                render: (text) => (
                    !editing ? {
                        props: {
                            style: text?.triggerDetails?.length > 0 && { background: "#ED000026" }
                        },
                        children:
                            (<div className={styles.cellContent}>
                                <div className={styles.textContain}>
                                    {field?.properties?.type === "DATE_SELECTION" ? dateTimeFormat(text?.fieldValue)
                                        : field?.properties?.type === "MULTI_SELECTION" && Array.isArray(text?.fieldValue) ? text?.fieldValue?.join(" | ")
                                            : text?.fieldValue}
                                </div>
                                {
                                    text?.triggerDetails?.length > 0 &&
                                    <Popover content={text?.triggerDetails[0]?.triggerMessage} trigger={"click"}>
                                        <InfoCircleOutlined style={{ cursor: "pointer", color: "red", fontSize: "18px" }} />
                                    </Popover>
                                }
                            </div>)
                    } : (<>
                        <Form.Item name={field?.id}
                            initialValue={field?.properties?.type === "DATE_SELECTION" ? dayjs(text?.fieldValue) : text?.fieldValue}
                            style={{
                                margin: 0,
                                width: "100%",
                            }}
                            rules={[{ required: field?.properties?.isRequired, message: `${field?.properties?.title} is required!` }]}
                        >
                            {field?.properties?.type === "NUMBER" ? <InputNumber style={{ width: '100%' }} />
                                : field?.properties?.type === "SELECTION" || field?.properties?.type === "MULTI_SELECTION" ?
                                    <Select options={field?.properties?.data?.options?.map(option => ({ label: option, value: option }))} style={{ width: '100%' }}
                                        mode={field?.properties?.type === "MULTI_SELECTION" ? 'multiple' : 'single'} allowClear />
                                    : field?.properties?.type === "DATE_SELECTION" ? <DatePicker style={{ width: '100%' }} showTime={{
                                        format: 'hh:mm A',
                                        use12Hours: true
                                    }}
                                        format="YYYY-MM-DD hh:mm A" allowClear />
                                        : <Input />}
                        </Form.Item>
                    </>)
                )
            })
        });
        columns = columns.concat(sectionColumns);
        setCols(columns);
    }

    useEffect(() => {
        if (sectionData) {
            createColumns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionData, editing])

    const components = {
        header: {
            cell: ResizableTitle,
        },
    };

    const handleRefresh = () => {
        fetchSheetData();
        fetchSheetOrderForTask(taskDetails?.id, sheet?.id);
    }

    const handleDownload = () => {
        setShowColModal(true);
    }

    return (
        <div>
            <ExportColsModal
                openModal={showColModal}
                setOpenModal={setShowColModal}
                columns={mergeColumns}
                dataSource={[sectionLogData?.fieldValues]}
                setLoader={setLoading}
                filename={`${taskDetails?.key}-task-data.csv`}
                isObjectDataSource={true}
            />
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius: "0",
                            borderColor: '#dadada',
                            headerBg: "#f1f1f1",
                        }
                    }
                }}
            >
                <Form form={form} component={false}>
                    <Table
                        loading={loading}
                        className={styles.tableCSS}
                        bordered
                        size="middle"
                        title={() => <TableHeader
                            values={{
                                sheetName: sectionData?.name,
                                handleRefresh,
                                handleDownload,
                                handleEdit,
                                handleSave,
                                editing,
                                loading
                            }}
                        />}
                        components={components}
                        dataSource={[sectionLogData?.fieldValues]}
                        columns={mergeColumns}
                        scroll={{ x: 1000, y: 500 }}
                        pagination={false}
                    />
                </Form>
            </ConfigProvider>
        </div>
    )

}