import styles from "./styles/inspectionDetailedViewHeader.module.css";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import {Tag} from "antd";
import { useNavigate } from "react-router-dom";
import { MoreActionsToggle } from "../moreActionsToggle/moreActionsToggle";

export default function InspectionDetailedViewHeader (props) {

    const { key, status, closeInspectionHandler, deleteInspectionHandler, startInspectionHandler, showInspectionModal} = props.values;

    const navigate = useNavigate();

    return (
        <div className={styles.detailedViewHeader}>
          <div className={styles.headerFirstHalf}>
            <div className={styles.icon}>
              <ArrowLeftLineIcon color="#7D7676" onClick={() => navigate(-1)}/>
            </div>
            <div className={styles.inspId}>{key}</div>
            <div className={styles.inspStatus}>
                {status === 'OPEN' ?
                    <Tag color="#7d7676" style={{ fontWeight: "bold" }}>{status}</Tag>
                    : status === 'ONGOING' ? <Tag color="orange-inverse" style={{ fontWeight: "bold" }}>{status}</Tag>
                        : status === 'SUBMITTED' ? <Tag color="blue-inverse" style={{ fontWeight: "bold" }}>{status}</Tag>
                            : status === 'CLOSED' ? <Tag color="green-inverse" style={{ fontWeight: "bold" }}>{status}</Tag> : status}
            </div>
          </div>
          <div>
            <MoreActionsToggle 
              values={{
                status,
                closeInspectionHandler, 
                deleteInspectionHandler, 
                startInspectionHandler, 
                showInspectionModal
              }}
            />
          </div>
        </div>
    )
}
