import { Descriptions } from 'antd';
import { otherDetailsItems } from '../utils/otherDetailsItems';
import { useEffect, useState } from 'react';
import { getSheetDetailsAPI, getUserDetailsAPI } from '../../../../../common/apis/commonApis';

export const OtherDetails = (props) => {

    const {inspectionDetails} = props;
    const [items, setItems] = useState([])
    const [sheetNames, setSheetNames] = useState('');
    const [assignees, setAssignees] = useState('');

    const fetchSheetNames = async () => {
      let sheets = inspectionDetails?.meta?.linkedSheets;
      if(sheets?.length > 0) {
          let sheetNamesArr = []
          await Promise.all(
              sheets.map(async sheetId => {
                 let response = await getSheetDetailsAPI(sheetId);
                 if(response?.data){
                     sheetNamesArr.push(response?.data?.name)
                 }
              })
          )
          setSheetNames(sheetNamesArr.join(', '));
      }
    }

    const fetchAssigneeNames = async () => {
      let users = inspectionDetails?.qualityInspectionAssignees;
      if(users?.length > 0) {
          let assigneeNamesArr = []
          await Promise.all(
              users.map(async user => {
                 let response = await getUserDetailsAPI(user.assigneeId);
                 if(response?.data){
                     assigneeNamesArr.push(response?.data?.name)
                 }
              })
          )
          setAssignees(assigneeNamesArr.join(', '));
      }
    }

    useEffect(() => {
      if(inspectionDetails) {
        setItems(otherDetailsItems(inspectionDetails))
        fetchSheetNames();
        fetchAssigneeNames();
      }
    },[inspectionDetails])

    useEffect(()=>{
      if(sheetNames?.length > 0 || assignees?.length > 0) {
        setItems(otherDetailsItems(inspectionDetails, sheetNames, assignees))
      }
    },[sheetNames, assignees, inspectionDetails])

    return (
        <Descriptions bordered items={items} />
    )
};