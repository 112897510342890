import { ApiClient,ApiClientType } from "../../../clients/api-client";

export const getEquipmentsApi = async(value,offset,limit)=>{
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instruments?name=${value}&includeStation=true&includeCalibrationDetail=true&limit=${limit}&offset=${offset}`
      );
}

export const fileUploadApi =async (urlExtension)=>{
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        urlExtension,
        {}
      );
}

export const calibrationStatusApi  = async()=>{
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations/status`
      );
}

export const  updateCalibrationApi= async(body,id)=>{
    return await  ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations/${id}`,
        body
      );
}


export const createCalibrationApi=async(body)=>{
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations`,
        body
      );
}

export const deleteCalibrationApi = async(id)=>{
    return await ApiClient(
        ApiClientType.delete,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations/${id}`
      );
}

export const closeCalibrationApi = async (id)=>{
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations/${id}/close`,
        {}
      );
}


export const getCalibrationItemDetailsApi = async(urlExtension)=>{
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        urlExtension,
        {}
      );
}


export const getCalibrationItemApi =async (id)=>{
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instrument-calibrations/${id}`,
        {}
      );
}

export const getEquipmentByIdApi = async (id)=>{
    return await  ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/instruments/${id}?includeProductCategory=true`
      );
}