import React, { useState } from "react";
import { Button, Divider, Table, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "../styles/equipmentTable.module.css";
import { SyncOutlined } from "@ant-design/icons";
import { equipmentTableCols } from "../utils/equipmentTableUtils";
import ResizableTitle from "./resize";

export default function CalibrationBody(props) {
  const {
    equipmentData,
    loading,
    totalRecords,
    onTableChange,
    setShowModal,
    setIsEdit,
    currentPage,
    onRefresh,
    onEquipmentClick,
    currentPageSize,
    onPaginationChange,
  } = props;

  const [columns, setColumns] = useState(
    equipmentTableCols(onEquipmentClick, setShowModal)
  );

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumns(newColumns);
    };
  const mergeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
      minWidth: column.minWidth,
    }),
  }));

  return (
    <>
      <div>
        <div className={styles.containerHeader}>
          <div className={styles.containerHeaderCol1}>
            <span style={{ fontWeight: "bold" }}>Equipments</span>{" "}
            <span className={styles.containerHeaderCol1Span}>
              ({totalRecords})
            </span>
          </div>
          <div className={styles.containerHeaderCol2}>
            <Tooltip title="Refresh">
              <Button
                icon={<SyncOutlined spin={loading} tool />}
                onClick={onRefresh}
                disabled={loading}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModal(true);
                  setIsEdit(false);
                }}
              >
                New Equipment
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.containerBody}>
          <Table
            bordered
            size="small"
            loading={loading}
            onChange={onTableChange}
            columns={mergeColumns}
            dataSource={equipmentData}
            pagination={{
              pageSize: currentPageSize,
              total: totalRecords,
              current: currentPage,
              showSizeChanger: true,
              onChange: onPaginationChange,
            }}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            scroll={{ x: 1200, y: 500 }}
          />
        </div>
      </div>
    </>
  );
}
