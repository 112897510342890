import { Button } from "antd"

export const supplierRatingTableCols = (params, navigate) => [
    {
        title: 'Supplier',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) =>
            <Button
                type="link"
                onClick={() => {
                    navigate(`/application/${params.appId}/supplier-ratings/${record.id}`)
                }}
            >{text}</Button>,
    },
]

export const scorecardTableCols = [
    {
        title: `Criteria`,
        dataIndex: ['entityTitle'],
        key: `criteria`,
    },
    {
        title: `Attained Score`,
        dataIndex: ['data', 'value'],
        key: `criteria`,
    }
];

export const summaryTableCols = [
    {
        title: 'Supplier',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Dynamic Interval',
        dataIndex: 'interval',
        key: 'interval',
    }
]