import styles from "../../../styles/triggers.module.css";
import { useState, useEffect } from "react";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import { Button, Form, Collapse, Row, Col } from "react-bootstrap";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import ArrowUpSLineIcon from "remixicon-react/ArrowUpSLineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import TriggerConditions from "../../triggerConditions.js";
import { ApiClientType } from "../../../../../clients/api-client.js";
import { ApiClient } from "../../../../../clients/api-client.js";

export default function Triggers(props) {
  const [openCollapse, setOpenCollapse] = useState(true);
  const [conditionList, setConditionList] = useState([]);
  const [close, setClose] = useState(false);

  const toggleCollapse = (index) => {
    
    const updatedCollapseState = [...collapseState];
    updatedCollapseState[index] = !updatedCollapseState[index];
    setCollapseState(updatedCollapseState);
  };

  useEffect(() => {
    (async () => {
      let response = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets/canvas`,
        {}
      );
      setConditionList(
        response.data?.fieldToConditionMapping[props.value?.fieldType?.id]
      );
    })();
  }, []);
  const [collapseState, setCollapseState] = useState([]);

  return (
    <div id={styles.triggers}>
      <button
        type="button"
        style={{
          border: "none",
          background: "none",
          float: "right",
          marginTop: "10px",
        }}
        onClick={() => props.value.onAddTrigger()}
      >
        <AddCircleLineIcon
          color="#009AFF"
          style={{ marginTop: "-5px", marginRight: "2px" }}
        />
        Add a Trigger
      </button>
      <br />
      {props.value.triggers?.map((item, index) => {
        return (
          <div key={`${index}-${item}`}>
            <Button
              type="button"
              id={collapseState[index] ? styles.collapseButtonOnClick : styles.collapseButton}
              aria-controls={`example-collapse-text-${index}`}
              aria-expanded={collapseState[index]}
            >
              <span
                className="boldFont"
                onClick={() => toggleCollapse(index)}
                style={{
                  marginLeft: "10px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Trigger {index + 1}
              </span>
              {collapseState[index] ? (
                <ArrowUpSLineIcon
                onClick={() => toggleCollapse(index)}
                  color="#7D7676"
                  style={{
                    float: "right",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <ArrowDownSLineIcon
                onClick={() => toggleCollapse(index)}
                  color="#7D7676"
                  style={{
                    float: "right",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                />
              )}

              <DeleteBin6LineIcon
                onClick={() => props.value.onRemoveTrigger(item)}
                color="#ED0000"
                style={{
                  height: "20px",
                  width: "20px",
                  float: "right",
                  marginTop: "3px",
                  marginRight: "3px",
                  cursor: "pointer",
                }}
              />
            </Button>
            <Collapse in={collapseState[index]} id={styles.triggerBody}>
              <div >
                <b style={{ fontSize: "14px" }} className="boldFont">
                  Condition
                </b>
                <Form>
                  <Form.Label>When the value entered</Form.Label>

                  <Form.Group className="mb-3">
                    <Form.Select
                      value={item.conditionType}
                      onChange={(e) => {
                        item.conditionType = e.target.value;
                        props.value.onEditTrigger(index, item);
                      }}
                    >
                      <option value="">Select Condition</option>
                      {conditionList.map((value, index) => {
                        return (
                          <option value={value} key={`${index}-${value}`}>
                            {
                              TriggerConditions(
                                value,
                                item,
                                props.value.fieldType.id,
                                props.value.fieldProperties,
                                (selectedValue) => {}
                              ).name
                            }
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      {
                        TriggerConditions(
                          item?.conditionType,
                          item,
                          props.value.fieldType.id,
                          props.value.fieldProperties,
                          (selectedValue) => {
                            if(item?.conditionType === "BW" || item?.conditionType === "NBW") {
                              if (selectedValue.lowerLimit) {
                                item.condition["lowerLimit"] =
                                selectedValue.lowerLimit;
                              } else if (selectedValue.upperLimit) {
                                item.condition["upperLimit"] =
                                selectedValue.upperLimit;
                              }
                            } else {
                              item.condition = selectedValue;
                            }
                            props.value.onEditTrigger(index, item);
                          }
                        )?.fields
                      }
                    </Row>
                  </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "14px" }} className="boldFont">
                          Action
                        </b>
                      </Form.Label>
                      <div className="relative">
                        <Form.Select
                          value={item.actionType}
                          onChange={(e) => {
                            item.actionType = e.target.value;
                            props.value.onEditTrigger(index, item);
                          }}
                        >
                          <option value="">Select Action</option>
                          <option value="SEND_MAIL">Send Mail</option>
                        </Form.Select>
                        <div className="disabledLayover"></div>
                      </div>
                    </Form.Group>
                  {/* ----------- CURRENTLY NOT REQUIRED BUT MIGHT BE TAKEN UP IN THE FUTURE ---------- */}
                  {/* <Form.Label>To</Form.Label>
                  <Form.Group className="mb-3">
                    <Form.Control
                      defaultValue={item.action.to[0]}
                      aria-label="Field Name"
                      onChange={(e) => {
                        let a = e.target.value;
                        item.action.to = [a];
                        props.value.onEditTrigger(index, item);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        item.action.body = e.target.value;
                        props.value.onEditTrigger(index, item);
                      }}
                      defaultValue={item.action.body}
                      as="textarea"
                      rows={2}
                    />
                  </Form.Group> */}
                  {/* ----------- CURRENTLY NOT REQUIRED BUT MIGHT BE TAKEN UP IN THE FUTURE ---------- */}
                </Form>
              </div>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
}
