import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Divider, Modal, Row, message } from 'antd';
import { CSVLink } from 'react-csv';
import { flattenObject } from '../../../templates/IQCActivity/components/inspectionTable/utils/utils';
import { dateTimeFormat, onlyDate } from '../../../utils';

function ExportColsModal(props) {
  const { openModal, setOpenModal, columns, dataSource, filename, isObjectDataSource } = props;

  const createFlattenedData = () => {
    let flattenedData = dataSource.map((item) => flattenObject(item));
    if (isObjectDataSource) {
      flattenedData = flattenedData?.map((item) => {
        Object.keys(item).map((key) => {
          if (key.includes('exportableValue')) {
            item[key.split('.')[0]] = item[key];
          }
          return item;
        });
        return item;
      });
    }

    return flattenedData;
  }

  const exportableColumns = columns.map(col => ({ title: col.title, key: col.key, dataIndex: col.dataIndex?.join("."), type: col.type }));
  const exportTitles = exportableColumns.reduce((et, { title, dataIndex }) => {
    et[dataIndex] = title;
    return et;
  }, {});

  const [selectAll, setSelectAll] = useState(true);
  const [unselectAll, setUnselectAll] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [csvContent, setCsvContent] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setSelectedColumns(columns.map(col => col.dataIndex?.join(".")));
  }, [columns])
  const halfLength = Math.ceil(exportableColumns.length / 2);
  const firstColumnList = exportableColumns.slice(0, halfLength);
  const secondColumnList = exportableColumns.slice(halfLength);

  const handleOk = () => {
    setOpenModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectAll(true);
      setUnselectAll(false);
      setSelectedColumns(exportableColumns.map(col => col.dataIndex));
    } else {
      setSelectAll(false);
      setSelectedColumns([]);
    }
  }
  const handleUnselectAll = (checked) => {
    if (checked) {
      setUnselectAll(true);
      setSelectAll(false);
      setSelectedColumns([]);
    } else {
      setUnselectAll(false);
    }
  }
  const handleSelectColumnChange = (selected) => {
    setSelectedColumns(selected);
    if (selected.length > 0) {
      setUnselectAll(false);
    }
    if (selected.length !== exportableColumns.length) {
      setSelectAll(false);
    }
  };

  const handleExportCSV = () => {
    setLoading(true);
    const csvData = createFlattenedData()?.map((item) => {
      const filteredItem = Object.keys(item)
        .filter((key) => selectedColumns.includes(key))
        .reduce((obj, key) => {
          if (isObjectDataSource) {
            obj[exportTitles[key]] = item[key];
          } else {
            obj[exportTitles[key]] = item[key];
          }
          return obj;
        }, {});
      return {
        ...filteredItem,
      };
    });
    const reorderedData = csvData.map((item) => {
      const reorderedItem = {};
      exportableColumns.forEach((col) => {
        if (selectedColumns.includes(col.dataIndex)) {
          switch (col.type) {
            case 'dateTime':
              reorderedItem[col.title] = dateTimeFormat(item[col.title]);
              break;
            case 'date':
              reorderedItem[col.title] = onlyDate(item[col.title]);
              break;
            default:
              reorderedItem[col.title] = item[col.title];
              break;
          }
        }
      });
      return reorderedItem;
    });
    setCsvContent(reorderedData);
    setLoading(false);
  };

  useEffect(() => {
    if (csvContent.length > 0) {
      document.getElementById('csv-link').click();
    }
  }, [csvContent])

  return (
    <>
      <Modal title="Select Columns to export" open={openModal} onOk={handleOk} onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {selectedColumns.length > 0 && !loading && (
              <Button type='primary' onClick={handleExportCSV}>
                Export to CSV
              </Button>
            )}

            <CSVLink
              data={csvContent}
              filename={filename}
              id="csv-link"
              style={{ display: 'none' }}
              onClick={() => {
                setOpenModal(false);
                message.success("The file is downloading")
              }}
            >
              Download CSV
            </CSVLink>
          </>
        )}>
        <div style={{ margin: 16 }}>
          <Checkbox
            key='selectAll'
            checked={selectAll}
            onChange={handleSelectAll}
          >
            Select All
          </Checkbox>
          <Divider type="vertical" />
          <Checkbox
            style={{ marginLeft: '8px' }}
            key='UnselectAll'
            checked={unselectAll}
            onChange={handleUnselectAll}
          >
            Unselect All
          </Checkbox>
          <Divider />
          <Checkbox.Group value={selectedColumns} onChange={handleSelectColumnChange}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                {firstColumnList.map((column) => (
                  <Checkbox key={column.key} value={column.dataIndex} style={{ width: "100%" }}>
                    {column.title}
                  </Checkbox>
                ))}

              </Col>
              <Col span={12}>
                {secondColumnList.map((column) => (
                  <Checkbox key={column.key} value={column.dataIndex} style={{ width: "100%" }}>
                    {column.title}
                  </Checkbox>
                ))}
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
      </Modal>
    </>
  );
};

export default ExportColsModal;
