import styles from "../styles/toggleButton.module.css";
import { periodType } from "./CalibrationSettingsModal/data/periodType";

export default function ToggleButton(props) {
  return (
    <div id={styles.toggleButton}>
      <span
        onClick={() => {
          props.value.selectPeriodType(Object.keys(periodType)[0]);
        }}
        id={styles.option1}
        className={
          props.value.selectedPeriodType === Object.keys(periodType)[0]
            ? styles.selected
            : ""
        }
      >
        {Object.keys(periodType)[0]}
      </span>
      <span
        onClick={() => {
          props.value.selectPeriodType(Object.keys(periodType)[1]);
        }}
        id={styles.option3}
        className={
          props.value.selectedPeriodType === Object.keys(periodType)[1]
            ? styles.selected
            : ""
        }
      >
        {Object.keys(periodType)[1]}
      </span>
      <span
        onClick={() => {
          props.value.selectPeriodType(Object.keys(periodType)[2]);
        }}
        id={styles.option3}
        className={
          props.value.selectedPeriodType === Object.keys(periodType)[2]
            ? styles.selected
            : ""
        }
      >
        {Object.keys(periodType)[2]}
      </span>
      <span
        onClick={() => {
          props.value.selectPeriodType(Object.keys(periodType)[3]);
        }}
        id={styles.option4}
        className={
          props.value.selectedPeriodType === Object.keys(periodType)[3]
            ? styles.selected
            : ""
        }
      >
        {Object.keys(periodType)[3]}
      </span>
    </div>
  );
}
