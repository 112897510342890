import styles from "../styles/sideNav.module.css";
import HomeIcon from "remixicon-react/Home2LineIcon";
import { useNavigate, useLocation } from "react-router-dom";
import ExpandCollapse from "../../../../components/expandCollapse/expandCollapse";
import { useState } from "react";

export default function SideNav({ routes }) {
  let navigate = useNavigate();
  let location = useLocation();
  let parentLocation = location.pathname.split("/")[1];
  const [expand, setExpand] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverdHome, setIsHoverdHome] = useState(false);
  const [navIndex, setNavIndex] = useState();

  return (
    <div
      id={styles.sideNav}
      style={{
        width: expand ? "200px" : "100px",
      }}
    >
      <div
        className="flex-center"
        onClick={(e) => {
          navigate("/");
        }}
      >
        <div
          id={styles.homeIconContainer}
          onMouseEnter={() => {
            setIsHoverdHome(true);
          }}
          onMouseLeave={() => setIsHoverdHome(false)}
        >
          <HomeIcon
            id={styles.icon}
            style={{ color: isHoverdHome ? "white" : "" }}
          />
        </div>
        {expand && (
          <p id={styles.homeName} className={isHoverdHome ? "boldFont" : ""}>
            Home
          </p>
        )}
      </div>
      <ExpandCollapse
        value={{
          style: {
            marginRight: "100px",
            position: "relative",
            zIndex: "1",
            top: "2.5%",
            left: "-8.5%",
            marginLeft: expand ? "200px" : "85px",
          },
        }}
        expand={expand}
        setExpand={setExpand}
      />
      <hr id={styles.hrStyling} />
      {routes?.map((item, index) => {
        let routeMatch = location.pathname.includes(item.path)
        if (item.groupBy === parentLocation)
          return (
            <div id={styles.iconMainContainer} key={index}>
              <div
                className={routeMatch ? styles.activeIndicator : "flex-center"}
                key={index}
              >
                <div
                  className={routeMatch ? styles.activeContainer : ""}
                  onMouseEnter={() => {
                    setIsHovered(true);
                    setNavIndex(index);
                  }}
                  onMouseLeave={() => setIsHovered(false)}
                  key={item.name}
                  id={styles.iconContainer}
                  onClick={(e) => {
                    navigate(item.path);
                  }}
                >
                  <div
                    id={styles.icon}
                    style={{ color: routeMatch ? "white" : "" }}
                  >
                    {item.icon}
                  </div>
                </div>
                {expand && (
                  <p
                    title={item.name}
                    className={`${styles.sideNavNames} ${
                      routeMatch ? styles.activeText : ""
                    } ${isHovered && index === navIndex ? "boldFont" : ""}`}
                    onClick={(e) => {
                      navigate(item.path);
                    }}
                  >
                    {item.name}
                  </p>
                )}
              </div>
            </div>
          );
      })}
    </div>
  );
}
