import styles from "../styles/toggleButton.module.css";
import { userType } from "../data/userType";

export default function ToggleButton(props) {
  return (
    <div id={styles.toggleButton}>
      <span
        onClick={() => {
          props.value.selectUserType(Object.keys(userType)[0]);
        }}
        id={styles.option1}
        className={
          props.value.selectedUserType === Object.keys(userType)[0]
            ? styles.selected
            : ""
        }
      >
        {Object.keys(userType)[0]}
      </span>
      <span
        onClick={() => {
          props.value.selectUserType(Object.keys(userType)[1]);
        }}
        id={styles.option3}
        className={
          props.value.selectedUserType === Object.keys(userType)[1]
            ? styles.selected
            : ""
        }
      >
        Incoming Parts
      </span>
    </div>
  );
}
