import { Button, Form, Collapse, Row, Col } from "react-bootstrap";
import { fieldList } from "../data/models/fieldList.js";
import Multiselect from "multiselect-react-dropdown";
import styles from "../styles/triggers.module.css";
import dateFormat from "dateformat";

export default function TriggerConditions(
  type,
  item,
  fieldType,
  fieldProperties,
  onChange
) {
  let fields = {
    LT: {
      name: "Less Than",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={item.condition?.value}
            onChange={(e) => {
              let obj = {
                value: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            step="any"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    GT: {
      name: "Greater Than",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={item.condition?.value}
            onChange={(e) => {
              let obj = {
                value: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    LTE: {
      name: "Less Than And Equal To",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={item.condition?.value}
            onChange={(e) => {
              let obj = {
                value: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    GTE: {
      name: "Greater Than And Equal To",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={item.condition?.value}
            onChange={(e) => {
              let obj = {
                value: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    EQ: {
      name: "Equal To",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={
              fieldType == "NUMBER"
                ? item.condition?.value
                : dateFormat(item.condition?.dateTime, "yyyy-mm-dd")
            }
            onChange={(e) => {
              let obj;
              if (fieldType == "NUMBER") {
                obj = {
                  value: parseFloat(e.target.value),
                };
              } else {
                obj = {
                  dateTime: dateFormat(
                    e.target.value,
                    "yyyy-mm-dd'T'HH:MM:ss'Z'"
                  ),
                };
              }
              onChange(obj);
            }}
            type={fieldType == "NUMBER" ? "number" : "date"}
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    NEQ: {
      name: "Not Equal To",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={item.condition?.value}
            onChange={(e) => {
              let obj = {
                value: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    BW: {
      name: "Between",
      fields: [
        <Col>
          <Form.Label>Lower Limit</Form.Label>
          <Form.Control
            defaultValue={
              fieldType == "NUMBER"
                ? item.condition?.lowerLimit
                : dateFormat(item.condition?.lowerLimit, "yyyy-mm-dd")
            }
            onChange={(e) => {
              let obj = {
                lowerLimit:
                  fieldType == "NUMBER"
                    ? parseFloat(e.target.value)
                    : dateFormat(e.target.value, "yyyy-mm-dd'T'HH:MM:ss'Z'"),
              };
              onChange(obj);
            }}
            type={fieldType == "NUMBER" ? "number" : "date"}
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
        <Col>
          <Form.Label>Upper Limit</Form.Label>
          <Form.Control
            defaultValue={
              fieldType == "NUMBER"
                ? item.condition?.upperLimit
                : dateFormat(item.condition?.upperLimit, "yyyy-mm-dd")
            }
            onChange={(e) => {
              let obj = {
                upperLimit:
                  fieldType == "NUMBER"
                    ? parseFloat(e.target.value)
                    : dateFormat(e.target.value, "yyyy-mm-dd'T'HH:MM:ss'Z'"),
              };
              onChange(obj);
            }}
            type={fieldType == "NUMBER" ? "number" : "date"}
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
          />
        </Col>,
      ],
    },
    NBW: {
      name: "Not In Between",
      fields: [
        <Col>
          <Form.Label>Lower Limit</Form.Label>
          <Form.Control
            defaultValue={item.condition?.lowerLimit}
            onChange={(e) => {
              let obj = {
                lowerLimit: parseFloat(e.target.value),
              };
              onChange(obj);
            }}
            type="number"
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
            step="any"
            aria-label="Field Name"
            key={"limit"}
          />
        </Col>,
        <Col>
        <Form.Label>Upper Limit</Form.Label>
        <Form.Control
          defaultValue={item.condition?.upperLimit}
          onChange={(e) => {
            let obj = {
              upperLimit: parseFloat(e.target.value),
            };
            onChange(obj);
          }}
          type="number"
          onWheel={(e) => e.target.blur()}
          onScroll={(e) => e.target.blur()}
          step="any"
          aria-label="Field Name"
          key={"upper"}
        />
      </Col>,
      ],
    },
    SE: {
      name: "Selected",
      fields: [
        <Col>
          <Multiselect
            selectedValues={item.condition?.options?.map((item) => {
              return { name: item };
            })}
            options={fieldProperties.map((item, index) => {
              return { name: item, id: index };
            })}
            closeIcon="cancel"
            placeholder="Options"
            showArrow={true}
            closeOnSelect={false}
            displayValue="name"
            onRemove={(list, item) => {
              let obj = {
                options: list.map((item) => {
                  return item.name;
                }),
              };
              onChange(obj);
            }}
            onSelect={(list, item) => {
              let obj = {
                options: list.map((item) => {
                  return item.name;
                }),
              };
              onChange(obj);
            }}
          />
        </Col>,
      ],
    },
    NSE: {
      name: "NotSelected",
      fields: [
        <Col>
          <Multiselect
            selectedValues={item.condition?.options?.map((item) => {
              return { name: item };
            })}
            options={fieldProperties.map((item, index) => {
              return { name: item, id: index };
            })}
            closeIcon="cancel"
            placeholder="Options"
            showArrow={true}
            closeOnSelect={false}
            displayValue="name"
            onRemove={(list, item) => {
              let obj = {
                options: list.map((item) => {
                  return item.name;
                }),
              };
              onChange(obj);
            }}
            onSelect={(list, item) => {
              let obj = {
                options: list.map((item) => {
                  return item.name;
                }),
              };
              onChange(obj);
            }}
          />
        </Col>,
      ],
    },
    BF: {
      name: "Before",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={dateFormat(item.condition?.dateTime, "yyyy-mm-dd")}
            onChange={(e) => {
              let obj = {
                dateTime: dateFormat(
                  e.target.value,
                  "yyyy-mm-dd'T'HH:MM:ss'Z'"
                ),
              };
              onChange(obj);
            }}
            type="date"
            name="date"
          />
        </Col>,
      ],
    },
    AF: {
      name: "After",
      fields: [
        <Col>
          <Form.Label>Value</Form.Label>
          <Form.Control
            defaultValue={dateFormat(item.condition?.dateTime, "yyyy-mm-dd")}
            onChange={(e) => {
              let obj = {
                dateTime: dateFormat(
                  e.target.value,
                  "yyyy-mm-dd'T'HH:MM:ss'Z'"
                ),
              };
              onChange(obj);
            }}
            type="date"
            name="date"
          />
        </Col>,
      ],
    },
  };
  return fields[type];
}
