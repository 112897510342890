import { canvasActionType } from "../../data/models/canvasActionType.js";

export const CanvasReducers = (state, action) => {
  switch (action.type) {
    case canvasActionType.addSection:
      return [...state, action.data];
    case canvasActionType.addSections:
      return [...action.data];
    case canvasActionType.removeSection:
      let arr = [];
      state.filter((item, index) => {
        if (item != action.data) {
          arr.push(item);
        }
      });
      return [...arr];
    case canvasActionType.updateSection:
      state[action.data.index].name = action.data.name;
      state[action.data.index].description = action.data.description;
      return [...state];
    case canvasActionType.replaceSection:
      state[action.data.index] = action.data.section;
      return [...state];
    default:
      return state;
  }
};
