import Table from "react-bootstrap/Table";
import styles from "../styles/table.module.css";
import EmptyTableMsgs from "../../../common/components/emptyTableMsgs/emptyTableMsgs";

export default function WorkforceTable(props) {
  const { data, loading } = props;
  if (loading) {
    return "...loading";
  }
  return (
    <Table id={styles.table} hover responsive="md">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 ? (
          data?.map((item, index) => {
            return (
              <tr key={item.id} onClick={() => props.value.onShowDetails(item)}>
                <td>
                  {item.name}
                </td>
                <td>{item.email}</td>
                <td>{item.isActive ? "ACTIVE" : "INACTIVE"}</td>
              </tr>
            );
          })
        ) : (
          <EmptyTableMsgs emptyMessage="No workforces" cols="5" />
        )}
      </tbody>
    </Table>
  );
}
