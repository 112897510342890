import { Tabs } from 'antd';
import { useEffect, useState } from "react";
import { getSheetDetailsAPI } from '../../../../common/apis/commonApis';
import { SheetLogTable } from './components/sheetLogTable';

export const InspectionLogs = (props) => {

    const {inspectionDetails} = props.values;

    const linkedSheets = inspectionDetails?.meta?.linkedSheets;
    const [sheetNames, setSheetNames] = useState([]);
    const [tabSheets, setTabSheets] = useState([]);

    const fetchSheetNames = async () => {
        let sheets = linkedSheets;
        if(sheets?.length > 0) {
            let sheetNamesArr = []
            await Promise.all(
                sheets.map(async sheetId => {
                   let response = await getSheetDetailsAPI(sheetId);
                   if(response?.data){
                       sheetNamesArr.push(
                        {
                            name: response?.data?.name,
                            id: sheetId,
                        })
                   }
                })
            )
            setSheetNames(sheetNamesArr);
        }
    }

    useEffect(() => {
        if(linkedSheets?.length>0) {
            fetchSheetNames();
        }
    },[linkedSheets])

    useEffect(() => {
        if(sheetNames?.length > 0) {
            let items = sheetNames.map(sheet => {
                let sheetItem = {
                    key: sheet.id,
                    label: sheet.name,
                    children: 
                    <SheetLogTable
                        values={{
                            sheet,
                            inspectionDetails,
                        }} 
                    />,
                }
                return sheetItem
            })
            setTabSheets(items);
        }
    },[sheetNames])

    return (
        <Tabs 
            defaultActiveKey={"sheet1"} 
            items={tabSheets} 
            type='card'
        />
    )
}