import { Upload, Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function DocumentFileUpload({ fileList, showButton = true, showUploadList = true, onChangeFileList, customRequest }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [currentFile, setCurrentFile] = useState(null);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    setCurrentFile(file);
  };

  const handleChange = ({ fileList: newFileList }) => {
    onChangeFileList(newFileList);
  };

  const handleDownload = (file) => {
    const fileUrl = file.url || file.preview;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = file.name || fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleCancel();
  };

  const uploadButton = (
    <div
      className="flex-center"
      style={{
        border: "1px solid #dadada",
        padding: "0.1rem 0.5rem",
        borderRadius: "5px",
        width: "100%",
      }}
    >
      <PlusOutlined style={{ marginRight: "0.2rem" }} size={5} />
      <div>Upload</div>
    </div>
  );

  return (
    <>
      <Upload listType="picture" fileList={fileList} onPreview={handlePreview} onChange={handleChange} showUploadList={showUploadList} customRequest={customRequest}>
        {showButton && uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={[
          <Button key="download" onClick={() => handleDownload(currentFile)}>
            Download
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
