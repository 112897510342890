import styles from "./styles/sheetList.module.css";
import SheetTable from "../components/sheetTable/sheetTable";

export default function SheetList() {
  return (
      <div className={styles.sheetContainer}>
        <SheetTable />
      </div>
  );
}
