import {Collapse} from "antd";
import { OtherDetails } from "./otherDetails";

export const OtherDetailsContainer = (props) => {

    const {inspectionDetails} = props;

    const items = [
      {
        key: '1',
        label: 
        <span style={{fontWeight: 'bold'}}>
            Other Details
        </span>,
        children: 
        <OtherDetails
          inspectionDetails={inspectionDetails} 
        />,
      }
    ];

    return (
      <Collapse items={items} defaultActiveKey={[1]} />
    )
  }