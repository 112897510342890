import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef, useReducer } from "react";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton.js";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import Image from "react-bootstrap/Image";
import ImageAddLineIcon from "remixicon-react/ImageAddLineIcon";
import { DataFetchReducers } from "../../../../../common/states/dataFetch/dataFetchReducers.js";
import { dataFetchActionType } from "../../../../../common/states/dataFetch/dataFetchActionType.js";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import { uploadImage } from "../../../../../common/components/uploadImage/uploadImage.js";

export default function AddProductModal(props) {
  const productDetails = props.value.productDetails;

  const inputRef = useRef(null);

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [selectedStations, setSelectedStations] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [uploadStatus, dispatchUploadStatus] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const onCloseModal = () => {
    setProductName("");
    setProductDescription("");
    setProductCode("");
    setProductImage([]);
    setSelectedStations([]);
    setShowButton(false);
    props.value.onHide();
    props.value.removeEdit();
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const uploadImages = async () => {
    const folderPath = "products";
    uploadImage(inputRef, setProductImage, dispatchUploadStatus, folderPath);
  };

  useEffect(() => {
    if (props.value.isEdit) {
      setShowButton(false);
      setProductName(productDetails?.name);
      setProductCode(productDetails?.code);
      setProductDescription(productDetails?.description);
      setProductImage(
        productDetails?.meta?.images ? productDetails?.meta?.images : []
      );
    } else {
      setShowButton(true);
    }
  }, [props.value.show, props.value.isEdit, productDetails]);

  return (
    <Modal
      show={props.value.show}
      onHide={onCloseModal}
      style={{ marginTop: "150px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: "bold" }}>
          <span className="modalHeading boldFont">
            {props.value.isEdit
              ? "Edit Product Details"
              : "New Product Details"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (props.value.isEdit) {
            props.value.onEditModal({
              name: productName,
              code: productCode,
              description: productDescription,
              image: productImage,
            });
          } else {
            props.value.onSubmitModal({
              name: productName,
              code: productCode,
              description: productDescription,
              image: productImage,
            });
          }
          onCloseModal();
        }}
      >
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Product Name"
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Product Name
              {props.value.isEdit ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              placeholder="Product Description"
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Product Description
              {props.value.isEdit ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
              placeholder="Product Code"
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Product Code
              {props.value.isEdit ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <div
            style={{
              border: "1px solid #dadada",
              borderRadius: "7px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              onClick={() => setShowButton(true)}
              ref={inputRef}
              onChange={uploadImages}
              className="d-none"
              type="file"
            />
            {uploadStatus.data || productImage?.length != 0 ? (
              <div>
                <div
                  onClick={() => {
                    setShowButton(true);
                    setProductImage([]);
                    dispatchUploadStatus({
                      type: dataFetchActionType.data,
                      data: null,
                    });
                  }}
                  style={{
                    marginBottom: "-13px",
                    zIndex: 10,
                    padding: "2.5px",
                    borderRadius: "50px",
                    background: "#dadada",
                    float: "right",
                    width: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseLineIcon size={16} style={{ marginBottom: "7px" }} />
                </div>
                <div>
                  <Image
                    onClick={handleUpload}
                    src={productImage?.[0]}
                    rounded
                    style={{
                      maxHeight: "100%",
                      width: "200px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ) : (
              <button
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "10px",
                  padding: "10px",
                  background: "white",
                }}
                type="button"
                onClick={handleUpload}
                className="light"
              >
                {uploadStatus.loading ? (
                  <div>
                    <ImageAddLineIcon
                      style={{
                        marginRight: "10px",
                        marginBottom: "3px",
                        color: "#7D7676",
                      }}
                    />
                    Uploading...
                  </div>
                ) : (
                  <div style={{ color: "#7D7676" }}>
                    <ImageAddLineIcon
                      style={{
                        marginRight: "10px",
                        marginBottom: "3px",
                      }}
                    />
                    Upload Product Image
                  </div>
                )}
              </button>
            )}
          </div>
        </Modal.Body>

        {showButton ? (
          <Modal.Footer style={{ justifyContent: "center" }}>
            <PrimaryButton
              value={{
                type: "submit",
                child: props.value.isEdit ? "Save Changes" : "Create Product",
                buttonStyle: "modalCTA",
              }}
            />
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
}
