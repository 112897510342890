import {Collapse} from "antd";
import { KeyDetails } from "./keyDetails";

export const KeyDetailsContainer = (props) => {

    const {inspectionDetails, fetchInspectionDetailsByKey} = props.values;

    const items = [
      {
        key: '1',
        label: 
        <span style={{fontWeight: 'bold'}}>
            Key Details
        </span>,
        children: 
        <KeyDetails
          values={{
            inspectionDetails,
            fetchInspectionDetailsByKey
          }} 
        />,
      }
    ];

    return (
      <Collapse items={items} defaultActiveKey={[1]} />
    )
}