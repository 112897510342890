import { Button, Space } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import SearchSelect from "../../../IQCActivity/components/inspectionTable/components/searchSelect";
import { searchCustomerApi } from "../../../../features/customers/apis/customerApis";
import { showToastError } from "../../../../common/toasters/toasterMessages";

export default function SCARHeader(props) {
    const { suppliers, fetchScars, isLoading, setShowModal,
        totalCountSuppliers, suppliersLoading, fetchMoreSuppliers } = props;

    const [selectedSupplier, setSelectedSupplier] = useState(undefined);
    const [selectedCustomer, setSelectedCustomer] = useState(undefined);

    const [customerList, setCustomerList] = useState([]);
    const [totalCountCustomers, setTotalCountCustomers] = useState(0);
    const [customersLoading, setCustomerLoading] = useState(false);


    const handleSupplierChange = (value, _e, info) => {
        if (info?.source === 'clear' || !value) {
            setSelectedSupplier(undefined);
            fetchMoreSuppliers("", 0);
            fetchScars(undefined, undefined, selectedCustomer);
        } else {
            setSelectedSupplier(value);
            fetchScars(value, undefined, selectedCustomer);
        }
    }

    const fetchCustomers = async (value) => {
        setCustomerLoading(true);
        const response = await searchCustomerApi({ name: value }, { limit: 10, offest: customerList?.length });
        if (response.isError) {
            showToastError(response.displayableMessage);
        } else {
            setTotalCountCustomers(response.totalCount);
            setCustomerList(response?.data?.map(customer => ({ label: customer?.name, value: customer?.id })));
        }
        setCustomerLoading(false);
    };

    const handleCustomerChange = async (value, _e, info) => {
        if (info?.source === 'clear' || !value) {
            setSelectedCustomer(undefined);
            await fetchCustomers();
            await fetchScars(selectedSupplier);
        } else {
            setSelectedCustomer(value);
            await fetchScars(selectedSupplier, undefined, value);
        }
    }

    const handleCustomerSearch = async (value) => {
        await fetchCustomers(value);
    }

    const handleSupplierSearch = async (value) => {
        await fetchMoreSuppliers(value, 0);
    }

    useEffect(() => {
        fetchCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 25 }}>
                <div>
                    <Space>
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={suppliersLoading}
                            placeholder="Supplier"
                            onChange={handleSupplierChange}
                            options={suppliers}
                            filterOption={false}
                            loadMore={fetchMoreSuppliers}
                            hasMoreData={totalCountSuppliers > suppliers?.length}
                            style={{ width: 200 }}
                            onSearch={handleSupplierSearch}
                        />
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={customersLoading}
                            placeholder="Customer"
                            onChange={handleCustomerChange}
                            options={customerList}
                            filterOption={false}
                            loadMore={fetchCustomers}
                            hasMoreData={totalCountCustomers > customerList?.length}
                            style={{ width: 200 }}
                            onSearch={handleCustomerSearch}
                        />
                    </Space>
                </div>

                <div style={{ float: "right" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => { setShowModal(true) }} disabled={isLoading}>New SCAR</Button>
                </div>
            </div>
        </>
    );
}