import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import PrimaryButton from "../../../../common/crunches/primaryButton/primaryButton.js";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getIncomingPartsAPI } from "../../../../common/apis/commonApis.js";
import styles from "./styles/CalibrationSettingsModal.module.css";
import { periodType } from "./data/periodType.js";
import ToggleButton from "../toggleButton.js";
import {
  getSheetsAPI,
  getUsersAPI,
} from "../../../../common/apis/commonApis.js";

export default function CalibrationSettingsModal(props) {
  const { appId } = props.values;
  const [selectedPeriodType, setSelectedPeriodType] = useState(
    Object.keys(periodType)[0]
  );

  const inspectionDetails = props.values.inspection;
  const [product, setProduct] = useState();
  const [totQuant, setTotQuant] = useState();
  const [samplingType, setSamplingType] = useState();
  const [deliveryStatus, setDeliveryStatus] = useState();
  const [linkedSheet, setLinkedSheet] = useState();
  const [assigned, setAssigned] = useState();
  const [showButton, setShowButton] = useState(true);
  const [samplingNumber, setSamplingNumber] = useState();

  const todayDate = new Date()
    .toISOString()
    .toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
  const [date, setDate] = useState("");

  const [incomingParts, setIncomingParts] = useState([
    { name: "loading...", id: "" },
  ]);

  const [sheetList, setSheetList] = useState([]);
  const [totalCountSheet, setTotalCountSheet] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [totalCountUsers, setTotalCountUsers] = useState(0);

  const fetchSheets = async () => {
    let response = await getSheetsAPI(appId);
    setSheetList(response?.data);
    setTotalCountSheet(response?.totalCount);
  };

  const fetchUsers = async () => {
    let response = await getUsersAPI();
    setUsersList(response?.data);
    setTotalCountUsers(response?.totalCount);
  };

  useEffect(() => {
    fetchSheets();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchIncomingParts();
    if (props.values.isEdit) {
      setShowButton(false);
      setProduct(inspectionDetails?.incomingPart);
      setTotQuant(inspectionDetails?.quantity);
      setDeliveryStatus(inspectionDetails?.meta?.deliveryStatus);
      setAssigned(
        inspectionDetails?.qualityInspectionAssignees?.[0]?.assigneeId
      );
      setSamplingType(inspectionDetails?.samplingType);
      setSamplingNumber(inspectionDetails?.meta?.samplingDetails?.number);
      setLinkedSheet(inspectionDetails?.meta?.linkedSheets[0]);
      setDate(inspectionDetails?.dueDateTime);
    } else {
      setShowButton(true);
      setProduct("");
      setTotQuant("");
      setAssigned("");
      setDeliveryStatus("");
      setSamplingType("");
      setLinkedSheet("");
      setDate("");
      setDate(dayjs(todayDate));
    }
  }, [props.values.show, inspectionDetails, props.values.isEdit]);

  const onCloseModal = () => {
    setShowButton(false);
    props.values.onHide();
    setProduct("");
    setTotQuant("");
    setSamplingType("");
    setDeliveryStatus("");
    setLinkedSheet("");
    setAssigned("");
    setSamplingNumber("");
    setDate(dayjs(""));
    setIncomingParts([{ name: "loading...", id: "" }]);
    props.values.handleClose();
  };

  useEffect(() => {
    setSamplingNumber();
  }, [samplingType]);

  const fetchIncomingParts = async () => {
    let response = await getIncomingPartsAPI(props.values.id);
    let incomingPartArr = [];
    if (response?.data?.length > 0) {
      let data = response.data;
      data.map((item, index) => {
        incomingPartArr.push({ name: item.name, id: item.id });
      });
      setIncomingParts(incomingPartArr);
    } else {
      setIncomingParts([]);
    }
  };

  return (
    <Modal
      show={props.values.show}
      onHide={onCloseModal}
      dialogClassName={styles.modalWidth}
    >
      <Modal.Header
        closeButton
        style={{ border: "none", padding: "0rem 1.5rem", margin: "1rem 0" }}
      >
        <Modal.Title className="boldFont">
          <span style={{ marginLeft: "10px", fontSize: "20px" }}>
            {props.values.name}
            {props.values.isEdit
              ? " - Edit Inspection"
              : "Calibration Settings "}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          let incomingPartObj = incomingParts.find(
            (item) => item.name === product.name
          );

          if (props.values.isEdit) {
            props.values.onEditModal(inspectionDetails.id, {
              supplierId: props.values.id,
              incomingPartId: incomingPartObj?.id,
              quantity: totQuant,
              samplingType: samplingType?.id || samplingType,
              samplingNumber: samplingNumber,
              assignees: [assigned?.id || assigned],
              linkedSheets: [linkedSheet?.id || linkedSheet],
              dueDateTime: date,
              deliveryStatus: deliveryStatus?.id || deliveryStatus,
            });
          } else {
            props.values.onSubmitModal({
              supplierId: props.values.id,
              incomingPartId: incomingPartObj?.id,
              quantity: totQuant,
              samplingType: samplingType?.id,
              samplingNumber: samplingNumber,
              assignees: [assigned?.id],
              linkedSheets: [linkedSheet?.id],
              dueDateTime: date,
              deliveryStatus: deliveryStatus?.id,
            });
          }
          onCloseModal();
        }}
      >
        <div
          style={{ width: "100%", marginLeft: "2rem", marginBottom: "1.5rem" }}
        >
          <p className={styles.periodLable}>Frequency</p>
          <ToggleButton
            value={{
              selectPeriodType: (type) => {
                setSelectedPeriodType(type);
              },
              selectedPeriodType,
            }}
          />
        </div>
        <div
          style={{ width: "100%", marginLeft: "2rem", marginBottom: "1rem" }}
        >
          <span style={{ marginRight: "0.5rem" }}>Repeat every</span>
          <input className={styles.repeatNumber}></input>
          <span style={{ marginLeft: "0.5rem" }}>{selectedPeriodType}</span>
        </div>
        <Modal.Body style={{ padding: "0rem 2rem" }}>
          <DatePicker
            className="mb-3"
            sx={{
              width: "100%",
            }}
            label="Starting from"
            onChange={(newValue) =>
              setDate(
                newValue.toISOString().substring(8, 10) +
                  "/" +
                  newValue.toISOString().substring(5, 7) +
                  "/" +
                  newValue.toISOString().substring(0, 4)
              )
            }
            format="DD/MM/YYYY"
          />

          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              onChange={(e) => setTotQuant(e.target.valueAsNumber)}
              placeholder="Calibration agency name"
              type={"text"}
              min={1}
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Calibration agency name
              {props.values.isEdit ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
        </Modal.Body>

        {showButton ? (
          <Modal.Footer
            style={{
              justifyContent: "center",
              border: "none",
              padding: "0rem 2rem",
              margin: "1rem 0",
            }}
          >
            <PrimaryButton
              value={{
                type: "submit",
                child: props.values.isEdit ? "Submit" : "Submit",
                style: { padding: "7px 10px 7px 10px" },
              }}
            />
          </Modal.Footer>
        ) : null}
      </Form>
    </Modal>
  );
}
