import React, { useState, useEffect } from "react"; // Import React
import Button from "react-bootstrap/Button";
import styles from "../../../styles/newSheetDetailsModal.module.css";
import Modal from "react-bootstrap/Modal";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import SearchDropdown from "../../../../../common/components/searchDropdown/searchDropdown.js";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton.js";

// Import the necessary functions from the common APIs and components
import {
  getSuppliersAPI,
  getStationsApi,
  getProductsApi,
  getIncomingPartsAPI,
  getSupplierApi,
  getStationApi,
  getIcPartApi,
  getProductApi
} from "../../../../../common/apis/commonApis.js";

export default function NewSheetDetailsModal(props) {
  const [sheetName, setSheetName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProcess, setSelectedProcess] = useState({});
  const [selectedStation, setSelectedStation] = useState({});
  const [selectedIcPart, setSelectedIcPart] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [sheetDescription, setSheetDescription] = useState("");
  const [sheetCode, setSheetCode] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [supplierCount, setSupplierCount] = useState(0);
  const [icPartCount, setIcPartCount] = useState(0);
  const [stationCount, setStationCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [icPart, setIcPart] = useState([]);
  const [stations, setStations] = useState([]);

  const fetchApis = async () => {
    let response = await getSuppliersAPI();
    setSupplier(response?.data);
    setSupplierCount(response?.data?.totalCount);
    response = await getIncomingPartsAPI();
    setIcPart(response?.data);
    setIcPartCount(response?.data?.totalCount);
    response = await getStationsApi();
    setStations(response?.data);
    setStationCount(response?.data?.totalCount);
    response = await getProductsApi();
    setProducts(response?.data);
    setProductsCount(response?.data?.totalCount);
  };

  useEffect(() => {
    setSheetName(props.value.sheet?.name);
    setSheetCode(props.value.sheet?.code);
    setSheetDescription(props.value.sheet?.description);
    setSelectedProcess(props.value.sheet?.productId);

    const getValues = async (item) => {
      if(item?.productId){
        const product = await getProductApi(item?.productId);
        setSelectedProduct(product?.data);
      }

      if(item?.supplierId){
        const supplier = await getSupplierApi(item?.supplierId);
      setSelectedSupplier(supplier?.data);

      }
     
      if(item?.stationId){
        const station = await getStationApi(item?.stationId);
        setSelectedStation(station?.data);
      }
      
      if(item?.incomingPartId){
        const icPart = await getIcPartApi(item?.incomingPartId);
        setSelectedIcPart(icPart?.data);
      }  
    };

    if (props?.value?.sheet?.meta !== null) {
      getValues(props?.value?.sheet?.meta);
    }

    if (props.value.sheet.id) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [props.value.sheet]);

  useEffect(() => {
    fetchApis();
  }, []);

  const closeModal = () => {
    props.setShowModal(false);
  };

  return (
    <Modal
      {...props}
      onHide={closeModal}
      size="md"
      dialogClassName={styles.modalWidth}
      style={{ marginTop: "150px" }}
    >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <Modal.Title>
          <span className="boldFont modalHeading">
            {props.edit ? "Sheet Details" : "New Sheet Details"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setShowButton(false);
          props.value.onSubmitModal({
            name: sheetName,
            description: sheetDescription,
            code: sheetCode,
            applicationId: props.value.appId,
            productId: selectedProduct?.id,
            stationId: selectedStation?.id,
            supplierId: selectedSupplier?.id,
            incomingPartId: selectedIcPart?.id,
          });
        }}
      >
        <Modal.Body className="pb-0">
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              defaultValue={sheetName}
              onChange={(e) => setSheetName(e.target.value)}
              placeholder="Sheet Name"
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Sheet Name
              {props.value.sheet?.id ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              defaultValue={sheetDescription}
              onChange={(e) => setSheetDescription(e.target.value)}
              placeholder="Sheet Description"
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Sheet Description
              {props.value.sheet?.id ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              defaultValue={sheetCode}
              onChange={(e) => setSheetCode(e.target.value)}
              placeholder="Sheet Code"
              required
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Sheet Code
              {props.value.sheet?.id ? (
                <PencilLineIcon style={{ float: "right", marginTop: "13px" }} />
              ) : null}
            </label>
          </Form.Floating>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <SearchDropdown
                  isModal={true}
                  entity={selectedSupplier}
                  setEntity={setSelectedSupplier}
                  values={{
                    name: selectedSupplier?.id
                      ? selectedSupplier?.name
                      : selectedSupplier?.name || "Link Supplier",
                    list: supplier,
                    totalCount: supplierCount,
                    buttonStyle: {
                      borderRadius: "10px",
                      width: "100%",
                      color: selectedSupplier?.id ? "black":"#7D7676",
                    },
                    fetchMoreData: async () => {
                      let response = await getSuppliersAPI(supplier?.length);
                      setSupplier([...supplier, ...response?.data]);
                    },
                    fetchSearchData: async (name) => {
                      let response = await getSuppliersAPI(0, name);
                      setSupplier(response?.data);
                      setSupplierCount(response?.totalCount);
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <SearchDropdown
                  isModal={true}
                  entity={selectedIcPart}
                  setEntity={setSelectedIcPart}
                  values={{
                    name: selectedIcPart?.id
                      ? selectedIcPart?.name
                      : selectedIcPart?.name || "Link IC Part",
                    list: icPart,
                    totalCount: icPartCount,
                    buttonStyle: {
                      borderRadius: "10px",
                      width: "100%",
                      color: selectedIcPart?.id ? "black":"#7D7676",
                    },
                    fetchMoreData: async () => {
                      let response = await getIncomingPartsAPI(icPart?.length);
                      setIcPart([...icPart, ...response?.data]);
                    },
                    fetchSearchData: async (name) => {
                      let response = await getIncomingPartsAPI(0, name);
                      setIcPart(response?.data);
                      setIcPartCount(response?.totalCount);
                    },
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <SearchDropdown
                  isModal={true}
                  entity={selectedStation}
                  setEntity={setSelectedStation}
                  values={{
                    name: selectedStation?.id
                      ? selectedStation?.name
                      : selectedStation?.name || "Link Station",
                    list: stations,
                    totalCount: stationCount,
                    buttonStyle: {
                      borderRadius: "10px",
                      width: "100%",
                      color: selectedStation?.id ? "black":"#7D7676",
                    },
                    fetchMoreData: async () => {
                      let response = await getStationsApi(stations?.length);
                      setStations([...stations, ...response?.data]);
                    },
                    fetchSearchData: async (name) => {
                      let response = await getStationsApi(0, name);
                      setStations(response?.data);
                      setStationCount(response?.totalCount);
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <SearchDropdown
                  isModal={true}
                  entity={selectedProduct}
                  setEntity={setSelectedProduct}
                  values={{
                    name: selectedProduct?.id
                      ? selectedProduct?.name
                      : selectedProduct?.name || "Link Product",
                    list: products,
                    totalCount: productsCount,
                    buttonStyle: {
                      borderRadius: "10px",
                      width: "100%",
                      color: selectedProduct?.id ? "black":"#7D7676",
                    },
                    fetchMoreData: async () => {
                      let response = await getProductsApi(products?.length);
                      setProducts([...products, ...response?.data]);
                    },
                    fetchSearchData: async (name) => {
                      let response = await getProductsApi(0, name);
                      setProducts(response?.data);
                      setProductsCount(response?.totalCount);
                    },
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center", border: "none" }}>
          <PrimaryButton
            value={{
              type: "submit",
              child: props.value.sheet?.id ? "Save Changes" : "Start Authoring",
              buttonStyle: "modalCTA",
            }}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
