import { ConfigProvider, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  showToastError,
  showToastSuccess,
} from "../../../../../common/toasters/toasterMessages";
import {
  updateSupplierApi,
  createSupplierApi,
} from "../../../apiCalls/suppliersApis";
import styles from "./styles/supplierModal.module.css";

export const SupplierModal = (props) => {
  const {
    showModal,
    setShowModal,
    supplierDetails,
    fetchSuppliersData,
    isEdit,
  } = props;

  const [showButton, setShowButton] = useState(false);

  const [form] = Form.useForm();

  const createUpdateSupplier = async (body) => {
    let response;
    if (isEdit) {
      response = await updateSupplierApi(body, supplierDetails?.id);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchSuppliersData();
        showToastSuccess(response?.displayableMessage);
      }
    } else {
      let response = await createSupplierApi(body);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchSuppliersData();
        showToastSuccess(response?.displayableMessage);
      }
    }
  };

  const handleSave = async (values) => {
    setShowButton(false);
    let body = {
      name: values.supplierName,
      code: values.supplierCode,
      locations: values.location?.split(),
    };
    await createUpdateSupplier(body);
    setShowModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  useEffect(() => {
    if (showModal) {
    }
  }, [showModal, isEdit]);

  useEffect(() => {
    if (isEdit) {
      setShowButton(false);
      let values = {
        supplierName: supplierDetails?.name,
        supplierCode: supplierDetails?.code,
        location: supplierDetails?.meta?.locations?.join(", "),
      };
      form.setFieldsValue(values);
    } else {
      form.resetFields();
      setShowButton(true);
    }
  }, [isEdit, supplierDetails, showModal]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: "#7d7676",
          colorBorder: "#dadada",
        },
      }}
    >
      <Modal
        title={isEdit ? "Edit Supplier" : "New Supplier"}
        open={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        width={"640px"}
      >
        <Form
          onFinish={handleSave}
          form={form}
          layout="vertical"
          name="control-hooks"
        >
          <Form.Item
            className="mb-3"
            label="Supplier Name"
            name="supplierName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Supplier Name"
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="supplierCode"
            label="Supplier Code"
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              placeholder="Supplier Code"
              onWheel={(e) => e.target.blur()}
              onScroll={(e) => e.target.blur()}
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="location"
            label="Location"
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              placeholder="Location"
              onWheel={(e) => e.target.blur()}
              onScroll={(e) => e.target.blur()}
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item className={styles.btnFormItem}>
            {showButton && (
              <button type="submit" className={styles.createBtn}>
                {isEdit ? "Save changes" : "Create Supplier"}
              </button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};
