import { useEffect, useState } from "react";
import { showToastError } from "../../../../../../../common/toasters/toasterMessages";
import { getSheetDetailsAPI } from "../../../../../../../common/apis/commonApis";
import { getSheetOrdersForTaskAPI } from "../../../../../apis/scarAPIs";
import { TaskSheetLogTable } from "./taskSheetLogTable";

export const TaskSheetLogDetails = (props) => {

    const { sheet, taskDetails } = props.values;

    const [loading, setLoading] = useState(false);
    const [fieldsData, setFieldsData] = useState(undefined);
    const [fieldToSectionMap, setFieldToSectionMap] = useState(undefined);
    const [sectionMapData, setSectionMapData] = useState(undefined);
    const [sheetOrder, setSheetOrder] = useState(undefined);
    const [fieldNames, setFieldNames] = useState(undefined);
    const [sheetDetails, setSheetDetails] = useState(undefined);

    const fetchSheetOrderForTask = async (taskId, sheetId) => {
        setLoading(true);
        let response = await getSheetOrdersForTaskAPI(taskId, sheetId);
        if (response.isError) {
            showToastError(response.displayableMessage)
        } else {
            let sectionMap = {};
            sectionMapData?.forEach((value, key) => {
                sectionMap[key] = { id: value.id, name: value?.name, fieldValues: {} }
            })
            response?.data?.forEach(orderData => {
                orderData?.data?.fieldValues?.forEach(field => {
                    const sectionId = fieldToSectionMap?.get(field?.fieldId)?.id;
                    let sectionDetails = sectionMap[sectionId];
                    sectionDetails.fieldValues[field?.fieldId] = field;
                });
            });
            setSheetOrder(response?.data[0]);
            setFieldsData(sectionMap);
        }
        setLoading(false);
    }

    const fetchSheetData = async () => {
        setLoading(true);
        const response = await getSheetDetailsAPI(sheet.id, true, false, true);
        if (!response.isError) {
            const fieldSectionMap = new Map();
            const sectionMap = new Map();
            const fieldNameMap = new Map();
            response?.data?.sections?.forEach(section => {
                const sectionData = { id: section.id, name: section?.name };
                sectionMap.set(section.id, section);
                section?.fields?.forEach(field => {
                    fieldNameMap.set(field?.id, field?.properties?.title);
                    fieldSectionMap.set(field?.id, sectionData);
                });
            });
            setSheetDetails(response?.data);
            setFieldNames(fieldNameMap);
            setSectionMapData(sectionMap);
            setFieldToSectionMap(fieldSectionMap);
        } else {
            showToastError(response?.displayableMessage)
        }
        setLoading(false);

    }

    useEffect(() => {
        if (fieldToSectionMap)
            fetchSheetOrderForTask(taskDetails?.id, sheet?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldToSectionMap]);
    useEffect(() => {
        if (sheet.id?.length > 0) {
            fetchSheetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheet, taskDetails])

    return (
        <>
            {fieldsData && Object.values(fieldsData)?.map(sectionLogData =>
                <TaskSheetLogTable values={{
                    sheet,
                    taskDetails,
                    sectionLogData,
                    sheetOrder,
                    fieldToSectionMap,
                    fieldNames,
                    sheetDetails,
                    sectionData: sectionMapData.get(sectionLogData?.id),
                    fetchSheetData: fetchSheetData,
                    fetchSheetOrderForTask: fetchSheetOrderForTask,
                    loading: loading, setLoading: setLoading
                }} />
            )}
        </>

    );

}