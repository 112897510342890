import { ApiClientType, ApiClientWithSource, ApiFileClientWithSource } from "../../../clients/api-client";

export const fetchRatingConfig = async (source, entity, includeCriteria = false) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}/config?includeRatingCriteria=${includeCriteria}`
    );
};

export const fetchRatingData = async (source, entity, entityId, intervalType, interval) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}?entityId=${entityId}&intervalType=${intervalType}&interval=${JSON.stringify(interval)}`
    );
};

export const updateRatingData = async (source, entity, ratingDataId, data) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}/data/${ratingDataId}`,
        data
    );
};

export const createRatingData = async (source, entity, data) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}`,
        data
    );
};

export const bulkUpdateRatingData = async (source, entity, data) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}/data`,
        data
    );
};

export const downloadRatingData = async (source, entity, entityId, intervalType, interval) => {
    return await ApiFileClientWithSource(
        source,
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}/pdf`,
        { entityId: entityId, intervalType: intervalType, interval: JSON.stringify(interval) },
        {
            'Content-Type': 'application/pdf',
        },
        'arraybuffer'
    );
};

export const deleteRatingData = async (source, entity, entityId, intervalType, interval) => {
    return await ApiClientWithSource(
        source,
        ApiClientType.delete,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/ratings/${entity}?entityId=${entityId}&intervalType=${intervalType}&interval=${JSON.stringify(interval)}`
    );
};