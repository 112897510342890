import { axiosPrivate } from "./axios-interceptor";

export const ApiClientType = {
  post: "POST",
  get: "GET",
  patch: "PATCH",
  delete: "DELETE",
  put: "PUT",
};

function isLocalhost(clientName) {
  return clientName === "localhost";
}
export function getNamespaceCode() {
  let clientName = window.location.hostname.split('.')[0];
  return isLocalhost(clientName) ? 'workroom' : clientName
}

function getBasicApiHeaders() {
  return {
    "Content-Type": "application/json",
    CtxNamespaceKey: "x-namespace",
    "x-namespace": getNamespaceCode(),
  };
}

function customAxiosSuccessResponse(response) {
  return {
    isError: false,
    displayableMessage: response.data?.status?.message,
    data: response.data?.data,
    totalCount: response.data?.status?.totalCount,
  };
}

function customAxiosErrorResponse(response) {
  let axiosResponse = response.response
  let errorMessage = response.code
  if (axiosResponse?.data?.status?.message) {
    errorMessage = axiosResponse?.data?.status?.message
  }
  return {
    isError: true,
    displayableMessage: errorMessage,
  };
}

export async function ApiClient(
  type,
  baseUrl,
  urlExtension,
  params,
  headerParams
) {

  let headers = {
    ...getBasicApiHeaders(),
    ...(headerParams ? { ...headerParams } : null),
  };
  let requestOptions = {
    method: type,
    headers: headers,
  };
  if (type === ApiClientType.get) {
    requestOptions["params"] = params;
  } else {
    requestOptions["data"] = params;
  }
  requestOptions["withCredentials"] = true;
  try {
    let response = await axiosPrivate(`${baseUrl}${urlExtension}`, requestOptions);
    // Note: response interceptor is there on 2xx response, we don't need to check the status of response here
    // any non 2xx will go in catch block...
    return customAxiosSuccessResponse(response)
  } catch (e) {
    return customAxiosErrorResponse(e)
  }
}

export async function ApiClientWithSource(
  source,
  type,
  baseUrl,
  urlExtension,
  params,
  headerParams
) {
  let headers = {
    ...getBasicApiHeaders(),
    ...(headerParams ? { ...headerParams } : null),
  };
  let requestOptions = {
    method: type,
    headers: headers,
  };
  if (type === ApiClientType.get) {
    requestOptions["params"] = params;
  } else {
    requestOptions["data"] = params;
  }
  if (source)
    requestOptions["cancelToken"] = source.token;
  requestOptions["withCredentials"] = true;
  try {
    let response = await axiosPrivate(`${baseUrl}${urlExtension}`, requestOptions);
    // Note: response interceptor is there on 2xx response, we don't need to check the status of response here
    // any non 2xx will go in catch block...
    return customAxiosSuccessResponse(response)
  } catch (e) {
    return customAxiosErrorResponse(e)
  }
}

export async function ApiFileClientWithSource(
  source,
  type,
  baseUrl,
  urlExtension,
  params,
  headerParams,
  responseType
) {
  let headers = {
    ...getBasicApiHeaders(),
    ...(headerParams ? { ...headerParams } : null),
  };
  let requestOptions = {
    method: type,
    headers: headers,
  };
  if (responseType) {
    requestOptions['responseType'] = responseType
  }
  if (type === ApiClientType.get) {
    requestOptions["params"] = params;
  } else {
    requestOptions["data"] = params;
  }
  if (source)
    requestOptions["cancelToken"] = source.token;
  requestOptions["withCredentials"] = true;
  try {
    let response = await axiosPrivate(`${baseUrl}${urlExtension}`, requestOptions);
    // Note: response interceptor is there on 2xx response, we don't need to check the status of response here
    // any non 2xx will go in catch block...
    return response;
  } catch (e) {
    return customAxiosErrorResponse(e)
  }
}