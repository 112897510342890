import { useState, useEffect } from "react";
import { Button } from "antd";
import styles from "../styles/instrumentsTable.module.css";

export default function OpenDelayedComponent(props) {
  const { val, onCalibrationClick, params, navigate } = props;

  const [indicatorComponent, setIndicatorComponent] = useState();

  const getInstrumentDetails = async (val) => {
    if (
      val?.availableCalibrationCount === 0 &&
      val?.delayedCalibrationCount === 0
    ) {
      setIndicatorComponent(<p className={styles.emptyIndicator}></p>);
    } else if (
      val?.availableCalibrationCount - val.delayedCalibrationCount ===
      0
    ) {
      setIndicatorComponent(<p className={styles.redIndicator}></p>);
    } else if (val?.delayedCalibrationCount === 0) {
      setIndicatorComponent(<p className={styles.blueIndicator}></p>);
    } else {
      let value =
        (val?.availableCalibrationCount - val?.delayedCalibrationCount) *
        (160 / val?.availableCalibrationCount);
      setIndicatorComponent(
        <>
          <p className={styles.dynamicBlue} style={{ width: `${value}px` }}></p>
          <p
            className={styles.dynamicRed}
            style={{ width: `${160 - value}px` }}
          ></p>
        </>
      );
    }
  };
  useEffect(() => {
    getInstrumentDetails(val);
  }, []);
  return (
    <div id={styles.hoverdId}>
      <Button
        type="link"
        onClick={() => {
          onCalibrationClick(val);
          navigate(
            `/application/${params.appId}/calibrations/${val?.instrument?.id}`
          );
        }}
      >
        {val?.instrument?.name}
      </Button>
      <div style={{ display: "flex" }}>
        {indicatorComponent}
        {val?.availableCalibrationCount >= 1 && (
          <div className={`${styles.hoverComponent}`}>
            <div className={styles.hoverdBlue}>
              <p
                className={styles.blueIndicator}
                style={{ width: `16px`, margin: "0px" }}
              ></p>
              <p className={styles.OpenTextHover}>
                Open:{" "}
                {val?.availableCalibrationCount - val?.delayedCalibrationCount}
              </p>
            </div>
            <div className={styles.hoverdBlue}>
              <p
                className={styles.redIndicator}
                style={{ width: `16px`, margin: "0px" }}
              ></p>
              <p className={styles.OpenTextHover}>
                Delayed: {val?.delayedCalibrationCount}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
