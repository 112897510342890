import axios from "axios";
import { ApiClient, ApiClientType } from "../../../clients/api-client";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";

export const searchAFRApi = async (filters, pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
    const conditionsArr = [];
    for (const key of Object.keys(filters)) {
        if (filters[key]) {
            if (key === 'date' && filters[key]?.length === 2) {
                conditionsArr.push({
                    field: key,
                    operation: ">=",
                    value: filters[key][0]
                });
                conditionsArr.push({
                    field: key,
                    operation: "<=",
                    value: filters[key][1]
                });
            } else if (key === 'defect_category') {
                conditionsArr.push({
                    field: key,
                    operation: "like",
                    value: filters[key]
                });
            } else if (key === 'repair_category') {
                conditionsArr.push({
                    field: key,
                    operation: "like",
                    value: filters[key]
                });
            } else if (key === 'serial_number') {
                conditionsArr.push({
                    field: key,
                    operation: "like",
                    value: filters[key]
                });
            }
            else {
                conditionsArr.push({
                    field: key,
                    operation: "eq",
                    value: filters[key]
                });
            }
        }

    }
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/search`,
        {
            filter: {
                association: `and`,
                conditions: conditionsArr
            },
            page: {
                limit: limit,
                offset: pageOffset
            },
            sort: {
                field: "key",
                direction: "desc"
            }
        }
    );
}

export const createAFRApi = async (data) => {
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs`,
        data
    );
}

export const patchAFRApi = async (afrID, data) => {
    return await ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/${afrID}`,
        data
    );
}

export const getAFRDefectCategoriesApi = async () => {
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/defect-category`
    );
}

export const getAFRRepairCategoriesApi = async () => {
    return await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/repair-category`
    );
}

export const bulkCreateAFRApi = async (file) => {
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/bulk`,
        { file: file },
        { "Content-Type": 'multipart/form-data' }
    );
}

export const bulkDeleteAFRApi = async (ids = []) => {
    return await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/afrs/delete`,
        { id: ids }
    );
}