import React, { useState } from "react";
import ToggleButton from "../components/toggleButton.js";
import { userType } from "../data/userType.js";
import SupplierTable from "../components/suppliersComponent/supplierTable/supplierTable.js";
import IncomingPartsTable from "../components/incomingPartsComponent/incomingPartsTable/incomingPartsTable.js";

export default function SupplierComponent() {
  const [selectedUserType, setSelectedUserType] = useState(
    Object.keys(userType)[0]
  );

  return (
    <div
      style={{
        margin: "3rem 20px 20px 10px",
      }}
    >
      <div
        style={{
          alignItems: "baseline",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2rem",
        }}
      >
        <ToggleButton
          value={{
            selectUserType: (type) => {
              setSelectedUserType(type);
            },
            selectedUserType,
          }}
        />
      </div>
      <div>
        <hr />
        {selectedUserType === Object.keys(userType)[0] ? (
          <SupplierTable />
        ) : (
          <IncomingPartsTable />
        )}
      </div>
    </div>
  );
}
