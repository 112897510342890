import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, Select } from 'antd';
import styles from '../../styles/supplierRating.module.css';
import TextArea from 'antd/es/input/TextArea';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    type,
    meta,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(true);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && dataIndex && record) {
            form.setFieldsValue({
                [dataIndex[0]]: type === 'single_select' ? record[dataIndex[0]]?.data?.value?.value : record[dataIndex[0]]?.data?.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex[0]]: record[dataIndex[0]]?.data?.value,
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            record[dataIndex[0]]['data']['value'] =
                type === 'float64' ? parseFloat(values[dataIndex[0]]) :
                    type === 'single_select' ? meta?.options?.filter(option => option.value === values[dataIndex[0]])[0] : values[dataIndex[0]];
            handleSave({
                ...record,
            }, dataIndex[0]);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex[0]}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {(type === 'float64' || type === 'int' ?
                    <Input key={record.id} type='number' ref={inputRef} onPressEnter={save} onBlur={save} onWheel={(e) => e.target.blur()} onScroll={(e) => e.target.blur()} />
                    : type === 'string' ? <Input key={record.id} ref={inputRef} onPressEnter={save} onBlur={save} onWheel={(e) => e.target.blur()} onScroll={(e) => e.target.blur()} />
                        : type === 'single_select' ?
                            <Select key={record.id} ref={inputRef} onBlur={save}>
                                {meta?.options?.map((option) => (
                                    <Select.Option key={option.key} value={option.value}>
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                            : <TextArea ref={inputRef} onPressEnter={save} onBlur={save} autoSize={{ maxRows: 3 }} />
                )}
                {/* <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} /> */}
            </Form.Item>
        ) : (
            <div
            // className={styles.editableCellValueWrap}
            // onClick={toggleEdit}
            // style={{
            //     padding: "4px 11px",
            //     border: "1px solid #d9d9d9",
            //     borderRadius: "2px",
            //     cursor: "pointer",
            // }}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};