import { Select } from "antd";

export const keyDetailsItems = (
    verdict,
    isChanging,
    handleStatusChange, 
    inspectionDetails,
    verdictOptions
) => [
    {
      key: 'incomingPartName',
      label: 'IC Part Name',
      children: inspectionDetails?.incomingPart?.name,
      span: 3,
    },
    {
      key: 'supplierName',
      label: 'Supplier Name',
      children: inspectionDetails?.supplier?.name,
      span: 3,
    },
    {
      key: 'quantity',
      label: 'Total Order Quantity',
      children: inspectionDetails?.quantity,
      span: 3,
    },
    {
      key: 'samplingNumber',
      label: 'Sampling Size',
      children: inspectionDetails?.inspectionStats?.totalSamples,
      span: 3,
    },
    {
      key: 'totalOk',
      label: 'Total OK',
      children: 
        <span style={{color: "green", fontWeight: "bold"}}>
            {inspectionDetails?.inspectionStats?.totalOkSamples}
        </span>,
      span: 3,
    },
    {
      key: 'totalNok',
      label: 'Total NOK',
      children: 
        <span style={{color: "red", fontWeight: "bold"}}>
            {inspectionDetails?.inspectionStats?.totalNokSamples}
        </span>,
      span: 3,
    },
    {
      key: 'lotStatus',
      label: 'Lot Status',
      children: (
        inspectionDetails?.status !== 'OPEN' 
        ? <Select
            loading={isChanging}
            value={verdict}
            placeholder="Select status"
            style={{ width: 120 }}
            onChange={handleStatusChange}
            options={verdictOptions}
            disabled={inspectionDetails?.status === 'CLOSED' || isChanging ? true : false}
            optionRender={(option) => (
                <div role="img" style={{ fontWeight: "bold", color: option.data.color}}>
                  {option.data.label}
                </div>
            )}
          /> 
        : <></>
      ),
      span: 3,
    },
];