import styles from "../../styles/supplierRating.module.css";
import ICPartSearchSelect from "../../../sheetList/common/icPartSearchSelect";
import { INCOMING_PART_SUMMARY_GROUP } from "../../utils/helperUtils";

export default function SummaryGroupDetailFilters(props) {

  const {
    selectedFilters,
    setSelectedFilters,
    summaryGroup } = props;

  const handleICPartsChange = (icPartData) => {
    setSelectedFilters(icPartData);
  };

  return (
    <>
      {summaryGroup.filters.includes(INCOMING_PART_SUMMARY_GROUP) && (
        <div>
          <ICPartSearchSelect
            key={`ICPartSearchSelect` + summaryGroup}
            className={styles.supplierDropDown}
            onFilterChange={handleICPartsChange}

          />
        </div>
      )}
    </>
  );
}
