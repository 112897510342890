import React, { useState,useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styles from './styles/searchDropdown.module.css';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

export default function SearchDropdown (props) {
    const {name, list, fetchMoreData, fetchSearchData, setList, totalCount, isUser, dropdownMenu, onFocus} = props.values;
    const {entity, setEntity, isModal} = props;
    const [searchVal, setSearchVal] = useState('')
    const [loadMore, setLoadMore] = useState(true);
    const [selectedItem, setSelectedItem] = useState("");

    const extractDataFromList = (item) => {
        if(isUser) {
            return { id: item?.user?.id, name: item?.user?.name };
        } else {
            return {id: item.id, name: item.name};
        }
    }

    useEffect(() => {
        if(list?.length === totalCount) {
            setLoadMore(false);
        } else {
            setLoadMore(true);
        }
    },[list, totalCount])

    return (
        <Dropdown className={styles.searchableDropdown} onFocus={onFocus} >
            <Dropdown.Toggle className={isModal ? styles.searchableDropdownToggle2 : styles.searchableDropdownToggle} style={props?.values?.buttonStyle}>
            {name}
            <ArrowDownSLineIcon className={isModal ? styles.iconStyle2 : styles.iconStyle} color='#7D7676'/>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{width: isModal ? "100%" : "inherit",...dropdownMenu}}>
            <div className={styles.searchDiv}>
                <input
                    className={styles.searchField}
                    value={searchVal} 
                    onChange={(e) => {
                        setSearchVal(e.target.value); 
                        fetchSearchData(e.target.value);
                    }} 
                    type="text" 
                    style={{width: "100%" }}                  
                />
            </div>
            <div className={styles.dropdownMenu}>
                {
                    list?.map((item,index) => {
                        let data = extractDataFromList(item);
                        return (
                            <Dropdown.Item
                                key={index} 
                                eventKey={index}
                                onClick={() => { setEntity(data); setSelectedItem(index);}}
                            >
                                {data.name}
                            </Dropdown.Item>
                        )
                    })
                }
            </div>
            {
                loadMore && <Dropdown.ItemText 
                    className={styles.loadMore} 
                    onClick={() => {fetchMoreData();}}
                >
                    Load more
                </Dropdown.ItemText>
            }
            </Dropdown.Menu>
        </Dropdown>
    );
}