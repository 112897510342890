import { Collapse, Space } from "antd";
import SCARKeyDetails from "./keyDetails";
import SCAROtherDetails from "./otherDetails";

export default function SCARDetailsTab(props) {
    const { scarDetails } = props;

    const keyItems = [
        {
            key: '1',
            label:
                <span style={{ fontWeight: 'bold' }}>
                    Key Details
                </span>,
            children:
                <SCARKeyDetails scarDetails={scarDetails} />,
        }
    ];

    const otherItems = [
        {
            key: '1',
            label:
                <span style={{ fontWeight: 'bold' }}>
                    Other Details
                </span>,
            children:
                <SCAROtherDetails scarDetails={scarDetails} />,
        }
    ];


    return (
        <>
            <Space size='large' direction="vertical" style={{ width: '100%' }}>
                <Collapse items={keyItems} defaultActiveKey={[1]} />
                <Collapse items={otherItems} defaultActiveKey={[1]} />
            </Space>
        </>
    )
}