
export const CRITERION_SUMMARY_GROUP = `CRITERION`;
export const INCOMING_PART_SUMMARY_GROUP = `INCOMING_PART`;
export const EDITABLE_SOURCE_TYPE = `MANUAL`;

export const generateColsFromConfig = (configDetails, summaryGroup) => {
    const criteriaSummaryGroup = fetchSummaryGroupFromConfig(configDetails, summaryGroup);
    let cols = configDetails?.ratingCriteria
        ?.filter(criteria => criteria.meta?.summaryGroup === summaryGroup && !criteria.meta?.isVerdict)
        ?.sort((a, b) => a.position - b.position)
        ?.map(criteria => (
            {
                title: criteria.name,
                dataIndex: [criteria.id, 'data', 'value'],
                key: criteria.id,
                dataType: criteria.dataSource?.default?.dataType,
                sourceType: criteria.dataSource?.default?.sourceType,
                isVerdict: criteria.meta?.isVerdict,
                editable: criteria.dataSource?.default?.sourceType === EDITABLE_SOURCE_TYPE,
                overrides: criteria.dataSource?.overrides,
                postion: criteria.position,
                meta: criteria.dataSource?.default?.meta,
                render: (value) => value && value?.value ? <div style={addStylesFromData({}, value?.styles)}>{value?.value}</div> : value,
            }
        ));
    return [{
        title: criteriaSummaryGroup.entity,
        dataIndex: 'entityTitle',
        key: 'entityTitle',
        fixed: 'left',
    }, ...cols];
}

export const generateDataMapFromConfig = (ratingData, summaryGroup) => {
    const criterionDataMap = new Map();
    ratingData?.ratingCriterionData
        ?.filter(criterionData => criterionData.summaryGroup === summaryGroup)
        ?.forEach(criterionData => {
            const { ratingCriterionId } = criterionData;
            const criterionArray = criterionDataMap.get(ratingCriterionId) || [];
            criterionArray.push(criterionData);
            criterionDataMap.set(ratingCriterionId, criterionArray);
        });

    return criterionDataMap;
}

export const generateVerdictsFromConfig = (configDetails, summaryGroup) => {
    return configDetails?.ratingCriteria
        ?.filter(criteria => criteria.meta?.summaryGroup === summaryGroup && criteria.meta?.isVerdict)
}

export const generateVerdictData = (ratingDataList, summaryGroup, verdictCriteriaIds) => {
    let verdictData = [];
    ratingDataList?.forEach(ratingData => {
        verdictCriteriaIds.forEach(verdictCriteriaId => {
            verdictData.push(...ratingData?.ratingCriterionData
                ?.filter(criterionData => criterionData.summaryGroup === summaryGroup && criterionData.ratingCriterionId === verdictCriteriaId))
        })
    });
    return verdictData;
}

export const generateCriteriaIdMap = (configDetails, summaryGroup) => {
    const criterionDataMap = new Map();
    configDetails?.ratingCriteria
        ?.filter(criteria => criteria.meta?.summaryGroup === summaryGroup && !criteria.meta?.isVerdict)
        ?.forEach(criterionData => criterionDataMap.set(criterionData.id, criterionData));
    return criterionDataMap;
}

export const fetchSummaryGroupFromConfig = (configDetails, entity) => {
    const summaryGroupEntity = configDetails?.meta?.summaryGroups
        ?.filter(summaryGroup => summaryGroup.entity === entity);
    return summaryGroupEntity.length === 1 ? summaryGroupEntity[0] : [];
}

export const generateEntityDataMap = (ratingDataList, summaryGroup) => {
    const criterionDataMap = new Map();
    ratingDataList?.forEach(ratingData => {
        ratingData.ratingCriterionData
            ?.filter(criterionData => criterionData.summaryGroup === summaryGroup)
            ?.forEach(criterionData => {
                const { entityId } = criterionData;
                const criterionArray = criterionDataMap.get(entityId) || [];
                criterionArray.push(criterionData);
                criterionDataMap.set(entityId, criterionArray);
            });
    });


    return criterionDataMap;
}

export const generateSummaryGroupData = (configData, configDetails, summaryGroup) => {
    const generatedData = [];
    const verdictCriterias = generateVerdictsFromConfig(configDetails, summaryGroup).map(verdictCriteria => verdictCriteria.id);
    const entityIdMap = generateEntityDataMap(configData, summaryGroup);
    entityIdMap.forEach((value, key) => {
        let dataEntity = {};
        value.forEach(criterionData => {
            if (!verdictCriterias.includes(criterionData.ratingCriterionId)) {
                dataEntity['entityTitle'] = criterionData.entityTitle;
                dataEntity['entityId'] = criterionData.entityId;
                dataEntity[criterionData.ratingCriterionId] = criterionData;
            }
        });
        if (Object.keys(dataEntity).length > 0)
            generatedData.push(dataEntity);
    });
    return generatedData;
}

export const addStylesFromData = (styles, data) => {
    if (styles && data) return { ...styles, ...data };
    if (styles && !data) return styles;
    if (!styles && data) return data;
    if (!styles && !data) return {};
}