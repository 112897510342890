import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import axios from "axios";
import { updateScarAPI } from "../../../../../apis/scarAPIs";
import { showToastError, showToastSuccess } from "../../../../../../../common/toasters/toasterMessages";
import { ApiClient, ApiClientType } from "../../../../../../../clients/api-client";
import DocumentFileUpload from "./documentsFileUpload";

const { Panel } = Collapse;

export default function SCARDocument({ scarDetails, setScarDetails }) {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(
      scarDetails?.trailMeta?.media?.map((file) => ({
        uid: file.name,
        name: file.name,
        status: "done",
        url: file.url,
        type: file.type,
        size: file.size,
      })) || []
    );
  }, [scarDetails]);

  const isInUploadingState = (item) => item?.status === "uploading";

  const convertToBackendMediaObject = (newFileList) => {
    return newFileList
      ?.filter((ele) => (ele.status === "done" && ele.response?.data?.url) || ele.url)
      .map((ele) => ({
        name: ele.name,
        type: ele.type,
        size: ele.size,
        url: ele.response?.data?.url || ele.url,
      }));
  };

  const updateScarDetails = async (updateScarDetails, newMedia) => {
    const data = {
      ...updateScarDetails,
      media: newMedia,
    };

    const response = await updateScarAPI(updateScarDetails.id, data);
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      const updatedScarDetails = {
        ...updateScarDetails,
        trailMeta: {
          ...updateScarDetails.trailMeta,
          media: newMedia,
        },
      };
      showToastSuccess("SCAR updated successfully");
      setScarDetails(updatedScarDetails);
    }
  };

  const onChangeFileList = (newFileList) => {
    setFileList(newFileList);

    if (newFileList.some(isInUploadingState)) {
      return;
    }

    const newMedia = convertToBackendMediaObject(newFileList);
    updateScarDetails(scarDetails, newMedia);
  };

  const fileUpload = async ({ file, onSuccess, onError }) => {
    const urlExtension = `/scars/${scarDetails.id}/upload`;

    const response = await ApiClient(ApiClientType.post, process.env.REACT_APP_MASTER_BASE_URL, urlExtension, {});

    if (!response.isError) {
      await axios
        .put(response?.data?.uploadLink?.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((res) => {
          onSuccess(response, file);
        })
        .catch((err) => {
          onError(err, file);
        });
    } else {
      showToastError("Error in uploading media");
    }
  };

  const handleUploadClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Panel
        header={<span style={{ fontWeight: "bold" }}>SCAR Docs</span>}
        key="1"
        extra={
          <div onClick={handleUploadClick}>
            <DocumentFileUpload fileList={fileList} showButton={true} showUploadList={false} onChangeFileList={onChangeFileList} customRequest={fileUpload} k />
          </div>
        }
      >
        <DocumentFileUpload fileList={fileList} showButton={false} showUploadList={true} onChangeFileList={onChangeFileList} customRequest={fileUpload} />
      </Panel>
    </Collapse>
  );
}
