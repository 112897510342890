import { Dropdown, Space } from "antd";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import More2LineIcon from "remixicon-react/More2LineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";

export default function TaskActions(props) {
    const { task, editTask, deleteTask } = props;


    const items = [
        {
            key: 'edit',
            icon: <PencilLineIcon size="20px" onClick={(event) => { editTask(task); event.stopPropagation(); }} />,
        },
        {
            key: 'delete',
            danger: true,
            icon: <DeleteBin6LineIcon size="20px" onClick={(event) => { deleteTask(task?.id); event.stopPropagation(); }} />,
        },
    ];

    return (
        <>
            <Dropdown
                menu={{
                    items,
                }}
            >
                <Space>
                    <More2LineIcon style={{ cursor: "pointer" }} />
                </Space>
            </Dropdown>
        </>
    )
}