import React, { useState } from 'react';
import { Button } from "antd";
import styles from '../styles/sheetTable.module.css';
import { PlusOutlined } from "@ant-design/icons";
import SupplierSearchSelect from "../../../common/supplierSearchSelect";
import ICPartSearchSelect from "../../../common/icPartSearchSelect";
import StationSearchSelect from "../../../common/stationSearchSelect";
import ProductSearchSelect from "../../../common/productSearchSelect";

export default function SheetHeader(props) {

    const {
        onFilterChange, setShowModal, selectedSupplier, selectedIcPart, selectedStation, selectedProduct
    } = props;

    const [supplier, setSupplier] = useState(selectedSupplier);
    const [icPart, setIcPart] = useState(selectedIcPart);
    const [station, setStation] = useState(selectedStation);
    const [product, setProduct] = useState(selectedProduct);

    const handleSupplierChange = (supplier) => {
        setSupplier(supplier);
        onFilterChange(supplier, icPart, product, station);
    };

    const handleICPartsChange = (icPart) => {
        setIcPart(icPart)
        onFilterChange(supplier, icPart, product, station);
    };

    const handleStationChange = (station) => {
        setStation(station)
        onFilterChange(supplier, icPart, product, station);
    };

    const handleProductChange = (product) => {
        setProduct(product)
        onFilterChange(supplier, icPart, product, station);
    };

    return (<>
            <div>
                <div className={styles.headerRow2}>
                    <div className={styles.headerRow2Col1}>
                        <div className={styles.headerRow2Col1Container}>
                            <SupplierSearchSelect
                                onFilterChange={handleSupplierChange}
                                selected={selectedSupplier}
                            />
                            <ICPartSearchSelect
                                onFilterChange={handleICPartsChange}
                                selected={selectedIcPart}
                            />
                            <ProductSearchSelect
                                onFilterChange={handleProductChange}
                                selected={selectedProduct}
                            />
                            <StationSearchSelect
                                onFilterChange={handleStationChange}
                                selected={selectedStation}
                            />
                        </div>
                    </div>
                    <div className={styles.headerRow2Col2}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                            setShowModal(true);
                        }}>New Sheet</Button>
                    </div>
                </div>
            </div>
    </>);
};