import React, { useEffect, useState } from 'react';
import { Button, Input, Radio, Select } from "antd";
import styles from '../styles/inspecionTable.module.css';
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import FilterRangePicker from '../../../../../common/components/datePicker/filterDatePicker';
import SearchSelect from './searchSelect';
import { getIncomingPartsAPI, getSuppliersAPI } from '../../../../../common/apis/commonApis';
import { showToastError } from '../../../../../common/toasters/toasterMessages';
import { DATE_PRESET_RANGES, TAGS_IN_DATE_FILTER } from '../utils/utils';

export default function InspectionHeader(props) {
    const SEARCH_MORE_TYPE = "searchMore";

    const { inspectionStatusList, verdictStatusList, onFilterChange, setShowModal, setEditInspection,
        selectedSupplier, selectedIcPart, selectedStatus, selectedGrn, selectedVerdict, selectedDateRange, selectedActiveFilter, selectedDateFilterTags, isActive, setIsActive } = props;
    const [supplier, setSupplier] = useState(selectedSupplier);
    const [icPart, setIcPart] = useState(selectedIcPart);
    const [status, setStatus] = useState(selectedStatus);
    const [verdict, setVerdict] = useState(selectedVerdict);
    const [dateRange, setDateRange] = useState(selectedDateRange);
    const [dateFilterTags, setDateFilterTags] = useState(selectedDateFilterTags);
    const [suppliersLoading, setSuppliersLoading] = useState(false);
    const [icPartsLoading, setIcPartsLoading] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [totalSuppliers, setTotalSuppliers] = useState(0);
    const [icPartsData, setIcPartsData] = useState([]);
    const [totalICParts, setTotalICParts] = useState(0);
    const [grn, setGrn] = useState(selectedGrn);


    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleStatusChange = (status) => {
        setStatus(status)
        onFilterChange(supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, isActive);
    };

    const handleVerdictChange = (verdict) => {
        setVerdict(verdict)
        onFilterChange(supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, isActive);
    };

    const handleGrnChange = (value) => {
        const val = value.target.value;
        if (val && val.length < 3) {
            return;
        }
        setGrn(value.target.value)
        onFilterChange(supplier, icPart, status, verdict, value.target.value, dateRange, dateFilterTags, isActive);
    };

    const handleDateRangeChange = (fromDate, toDate, filterTags) => {
        setDateFilterTags(filterTags);
        if (fromDate && toDate) {
            const filterDateRange = [dayjs(fromDate).startOf('day'), dayjs(toDate).endOf('day')];
            setDateRange(filterDateRange);
            onFilterChange(supplier, icPart, status, verdict, grn, filterDateRange, filterTags, isActive);
        } else {
            setDateRange(undefined);
            onFilterChange(supplier, icPart, status, verdict, grn, undefined, filterTags, isActive);
        }

    };

    const handleActiveChange = (e) => {
        setIsActive(e.target.value);
        onFilterChange(supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, e.target.value);
    }

    // ---------------------* Supplier related functions *----------------------------------------------------------------
    const handleSupplierChange = (supplier) => {
        setSupplier(supplier);
        onFilterChange(supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, isActive);
    };

    const handleSupplierSearch = async (searchVal) => {
        setSupplierData([]);
        await fetchSuppliers(searchVal);
    }

    const fetchSuppliers = async (value, type = "") => {
        setSuppliersLoading(true);
        let suppliersResponse = await getSuppliersAPI(type === SEARCH_MORE_TYPE ? supplierData.length : 0, value, 10);
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setTotalSuppliers(suppliersResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? supplierData : [];
            suppliersResponse.data.forEach(supplier => {
                dataList.push({ value: supplier.id, label: supplier.name });
            });
            setSupplierData(dataList);
        }
        setSuppliersLoading(false);
    };

    const fetchMoreSuppliers = async () => {
        fetchSuppliers("", SEARCH_MORE_TYPE);
    }
    // ------------------------------------* END *------------------------------------------------------------------------

    // ---------------------* IC Part(s) related functions *--------------------------------------------------------------
    const handleICPartsChange = (icPart) => {
        setIcPart(icPart)
        onFilterChange(supplier, icPart, status, verdict, grn, dateRange, dateFilterTags, isActive);
    };

    const handleICPartSearch = async (searchVal) => {
        setIcPartsData([]);
        await fetchICParts(searchVal);
    }

    const fetchICParts = async (value, type = "") => {
        setIcPartsLoading(true);
        let icPartsResponse = await getIncomingPartsAPI(type === SEARCH_MORE_TYPE ? icPartsData.length : 0, value, 10);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalICParts(icPartsResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? icPartsData : [];
            icPartsResponse.data.forEach(icPart => {
                dataList.push({ value: icPart.id, label: icPart.name });
            });
            setIcPartsData(dataList);
        }
        setIcPartsLoading(false);
    };

    const fetchMoreICParts = async () => {
        fetchICParts("", SEARCH_MORE_TYPE);
    }
    // ------------------------------------* END *------------------------------------------------------------------------

    useEffect(() => {
        fetchSuppliers();
        fetchICParts();
    }, []);

    useEffect(() => {
        setIsActive(selectedActiveFilter)
    }, [selectedActiveFilter])

    return (
        <>
            <div>
                <div>
                    <Radio.Group name="radiogroup" value={isActive} onChange={handleActiveChange}>
                        <Radio value={false}>All</Radio>
                        <Radio value={true}>Active</Radio>
                    </Radio.Group>
                </div>
                <div className={styles.headerRow2}>
                    <div className={styles.headerRow2Col1}>
                        <div className={styles.headerRow2Col1Container}>
                            <SearchSelect className={styles.headerRow2Col1Item1}
                                showSearch
                                allowClear
                                loading={suppliersLoading}
                                value={supplier}
                                placeholder="Supplier"
                                onChange={handleSupplierChange}
                                options={supplierData}
                                filterOption={false}
                                onSearch={handleSupplierSearch}
                                onClear={fetchSuppliers}
                                loadMore={fetchMoreSuppliers}
                                hasMoreData={totalSuppliers > supplierData.length}
                            />
                            <SearchSelect className={styles.headerRow2Col1Item}
                                showSearch
                                allowClear
                                loading={icPartsLoading}
                                value={icPart}
                                placeholder="IC Part (s)"
                                onChange={handleICPartsChange}
                                options={icPartsData}
                                filterOption={false}
                                onSearch={handleICPartSearch}
                                onClear={fetchICParts}
                                loadMore={fetchMoreICParts}
                                hasMoreData={totalICParts > icPartsData.length}
                            />
                            <Select className={styles.headerRow2Col1Item}
                                showSearch
                                allowClear
                                value={status}
                                placeholder="Inspection Status"
                                onChange={handleStatusChange}
                                options={inspectionStatusList}
                                filterOption={filterOption}
                            />
                            <Select className={styles.headerRow2Col1Item}
                                showSearch
                                allowClear
                                value={verdict}
                                placeholder="Lot Status"
                                onChange={handleVerdictChange}
                                options={verdictStatusList}
                                filterOption={filterOption}
                            />
                            <Input className={styles.headerRow2Col1Item}
                                placeholder="GRN Number"
                                onChange={handleGrnChange}
                                allowClear
                            />
                            <FilterRangePicker
                                className={styles.headerRow2Col1Item}
                                rangePresets={DATE_PRESET_RANGES}
                                dateRange={dateRange}
                                dateFilterTags={dateFilterTags}
                                handleOnChange={handleDateRangeChange}
                                tagsData={TAGS_IN_DATE_FILTER}
                            />
                        </div>
                    </div>
                    <div className={styles.headerRow2Col2}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                            setShowModal(true);
                            setEditInspection(false);
                        }}>New Inspection</Button>
                    </div>
                </div>
            </div>
        </>
    );
};