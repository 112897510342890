import { sheetActionType } from "../../data/models/sheetActionType.js";

export const SheetReducers = (state, action) => {
  switch (action.type) {
    case sheetActionType.addSheetDetails:
      return action.data;
    case sheetActionType.editSheetDetails:
      let var1 = state;
      var1["name"] = action.data.name;
      var1["description"] = action.data.description;
      var1["externalCode"] = action.data.externalCode;
      return { ...var1 };
    case sheetActionType.addSectionsToSheet:
      state.sections = action.data;
    case sheetActionType.addSheetEditsToSheet:
      state.sheetEdits = action.data;
      return state;
    default:
      return state;
  }
};
