import { ConfigProvider, DatePicker, Form, Input, Modal, Select } from "antd"
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { showToastError, showToastSuccess } from "../../../../common/toasters/toasterMessages";
import { createInspectionAPI, getIncomingPartsAPI, updateInspectionAPI } from "../../apiCalls/iqcAPIs";
import { getSuppliersAPI, getUsersAPI, searchSheetsAPI } from "../../../../common/apis/commonApis";
import SearchSelect from "../inspectionTable/components/searchSelect";
import styles from "./styles/inspectionModal.module.css"

export const InspectionModal = (props) => {

    const { showModal, setShowModal, fetchSupplierInspections, isEdit, inspectionDetails, fetchInspectionDetails } = props;

    const SEARCH_MORE_TYPE = "searchMore";
    const SAMPLING_TYPES = [
        // {
        //     value: "STANDARD",
        //     label: "Standard"
        // },
        {
            value: "CUSTOM",
            label: "Custom"
        }
    ];

    const [samplingTypes, setSamplingTypes] = useState(SAMPLING_TYPES)
    const [selectedSupplierId, setSelectedSupplierId] = useState("");
    const [selectedSamplingType, setSelectedSamplingType] = useState("");

    const [suppliersData, setSuppliersData] = useState([]);
    const [totalSuppliers, setTotalSuppliers] = useState(0);
    const [suppliersLoading, setSuppliersLoading] = useState(false);

    const [icPartsData, setIcPartsData] = useState([]);
    const [totalIcParts, setTotalIcParts] = useState(0);
    const [icPartsLoading, setIcPartsLoading] = useState(false);

    const [sheetsData, setSheetsData] = useState([]);
    const [totalSheets, setTotalSheets] = useState(0);
    const [sheetsLoading, setSheetsLoading] = useState(false);

    const [usersData, setUsersData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [usersLoading, setUsersLoading] = useState(false);

    const [showButton, setShowButton] = useState(false);

    const [form] = Form.useForm();

    const createUpdateInspection = async (body) => {
        let response;
        if (isEdit) {
            response = await updateInspectionAPI(inspectionDetails?.id, body);
            if (response.isError) {
                showToastError(response?.displayableMessage)
            } else {
                // fetchSupplierInspections();
                fetchInspectionDetails();
                showToastSuccess(response?.displayableMessage);
            }
        } else {
            response = await createInspectionAPI(body);
            if (response.isError) {
                showToastError(response?.displayableMessage)
            } else {
                fetchSupplierInspections();
                showToastSuccess(response?.displayableMessage);
            }
        }
    }

    const handleSave = async (values) => {
        setShowButton(false);
        values.dueDateTime = values.dueDateTime ? new Date(values.dueDateTime.toDate().setHours(0, 0, 0, 0)).toISOString() : undefined;
        values.invoiceDate = values.invoiceDate ? new Date(values.invoiceDate.toDate().setHours(0, 0, 0, 0)).toISOString() : undefined;
        values.deliveryDateTime = values.deliveryDateTime ? new Date(values.deliveryDateTime.toDate().setHours(0, 0, 0, 0)).toISOString() : undefined;
        const body = {
            ...values,
            quantity: Number(values.quantity),
            samplingNumber: Number(values.samplingNumber),
            linkedSheets: [values.linkedSheets],
            assignees: [values.assignees]
        }
        await createUpdateInspection(body);
        setShowModal(false);
        form.resetFields();
    };

    const handleCancel = () => {
        setShowModal(false);
        // setSelectedSupplierId("");
        // setSelectedSamplingType("");
        form.resetFields();
    };

    /* 
        Supplier functions
    */
    const handleSupplierSearch = async (searchVal) => {
        setSuppliersData([]);
        await fetchSuppliers(searchVal);
    }

    const fetchSuppliers = async (value, type = "") => {
        setSuppliersLoading(true);
        let suppliersResponse = await getSuppliersAPI(type === SEARCH_MORE_TYPE ? suppliersData.length : 0, value, 10);
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setTotalSuppliers(suppliersResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? suppliersData : [];
            suppliersResponse.data.forEach(supplier => {
                dataList.push({ value: supplier.id, label: supplier.name });
            });
            setSuppliersData(dataList);
        }
        setSuppliersLoading(false);
    };

    const fetchMoreSuppliers = async () => {
        fetchSuppliers("", SEARCH_MORE_TYPE);
    }

    /* 
        IcPart functions
    */
    const handleIcPartSearch = async (searchVal) => {
        setIcPartsData([]);
        await fetchIcParts(searchVal);
    }

    const fetchIcParts = async (value, type = "") => {
        setIcPartsLoading(true);
        let icPartsResponse = await getIncomingPartsAPI(selectedSupplierId, type === SEARCH_MORE_TYPE ? icPartsData.length : 0, 10, value);
        if (icPartsResponse.isError) {
            showToastError(icPartsResponse.displayableMessage);
        } else {
            setTotalIcParts(icPartsResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? icPartsData : [];
            icPartsResponse.data.forEach(icPart => {
                dataList.push({ value: icPart.id, label: icPart.name });
            });
            setIcPartsData(dataList);
        }
        setIcPartsLoading(false);
    };

    const fetchMoreIcParts = async () => {
        fetchIcParts("", SEARCH_MORE_TYPE);
    }

    /* 
        Sheet functions
    */
    const handleSheetSearch = async (searchVal) => {
        setSheetsData([]);
        await fetchSheets(searchVal);
    }

    const fetchSheets = async (value, type = "") => {
        setSheetsLoading(true);
        let sheetsResponse = await searchSheetsAPI(value, 10, type === SEARCH_MORE_TYPE ? sheetsData.length : 0);
        if (sheetsResponse.isError) {
            showToastError(sheetsResponse.displayableMessage);
        } else {
            setTotalSheets(sheetsResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? sheetsData : [];
            sheetsResponse.data.forEach(sheet => {
                dataList.push({ value: sheet.id, label: sheet.name });
            });
            setSheetsData(dataList);
        }
        setSheetsLoading(false);
    };

    const fetchMoreSheets = async () => {
        fetchSheets("", SEARCH_MORE_TYPE);
    }

    /* 
        User functions
    */
    const handleUserSearch = async (searchVal) => {
        setUsersData([]);
        await fetchUsers(searchVal);
    }

    const fetchUsers = async (value, type = "") => {
        setUsersLoading(true);
        let usersResponse = await getUsersAPI(type === SEARCH_MORE_TYPE ? usersData.length : 0, value, 10);
        if (usersResponse.isError) {
            showToastError(usersResponse.displayableMessage);
        } else {
            setTotalUsers(usersResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? usersData : [];
            usersResponse.data.forEach(user => {
                dataList.push({ value: user?.user?.id, label: user?.user?.name });
            });
            setUsersData(dataList);
        }
        setUsersLoading(false);
    };

    const fetchMoreUsers = async () => {
        fetchUsers("", SEARCH_MORE_TYPE);
    }

    useEffect(() => {
        if (showModal) {
            fetchSuppliers();
            fetchSheets();
            fetchUsers();
        }
    }, [showModal, isEdit])

    useEffect(() => {
        if (selectedSupplierId?.length > 0) {
            fetchIcParts();
        }
    }, [selectedSupplierId])

    useEffect(() => {
        if (isEdit) {
            let assigneesList = [];
            inspectionDetails?.qualityInspectionAssignees?.map(inspection =>
                assigneesList.push(inspection?.assigneeId)
            );
            setShowButton(false);
            setSelectedSupplierId(inspectionDetails?.supplier?.id);
            setSelectedSamplingType(inspectionDetails?.samplingType);
            let values = {
                supplierId: inspectionDetails?.supplier?.id,
                incomingPartId: inspectionDetails?.incomingPart?.id,
                invoiceNumber: inspectionDetails?.meta?.invoiceNumber,
                invoiceDate: inspectionDetails?.meta?.invoiceDate ? dayjs(inspectionDetails?.meta?.invoiceDate) : '',
                quantity: inspectionDetails?.quantity,
                deliveryDateTime: inspectionDetails?.meta?.deliveryDateTime ? dayjs(inspectionDetails?.meta?.deliveryDateTime) : '',
                samplingType: inspectionDetails?.samplingType,
                samplingNumber: inspectionDetails?.meta?.samplingDetails?.number,
                linkedSheets: inspectionDetails?.meta?.linkedSheets?.join(", "),
                assignees: assigneesList.join(', '),
                dueDateTime: inspectionDetails?.dueDateTime ? dayjs(inspectionDetails?.dueDateTime) : ''
            }
            form.setFieldsValue(values)
        } else {
            setShowButton(true);
        }
    }, [isEdit, inspectionDetails, showModal])

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorTextPlaceholder: '#7d7676',
                    colorBorder: '#dadada'
                }
            }}
        >
            <Modal
                title={isEdit ? "Edit Inspection" : "New Inspection"}
                open={showModal}
                onCancel={handleCancel}
                maskClosable={false}
                style={{ top: 20 }}
                cancelButtonProps={{
                    style: { display: 'none' }
                }}
                okButtonProps={{
                    style: { display: 'none' }
                }}
                width={"640px"}
            >
                <Form
                    onFinish={handleSave}
                    form={form}
                    layout="vertical"
                    name="control-hooks"
                >
                    <Form.Item
                        className="mb-3"
                        name="supplierId"
                        label="Supplier"
                        rules={[{ required: true }]}
                    >
                        <SearchSelect
                            showSearch
                            allowClear
                            disabled={isEdit ? true : false}
                            loading={suppliersLoading}
                            placeholder={`Select supplier`}
                            onChange={(value) => {
                                setSelectedSupplierId(value)
                            }}
                            options={suppliersData}
                            filterOption={false}
                            onSearch={handleSupplierSearch}
                            onClear={() => {
                                fetchSuppliers();
                                setIcPartsData([]);
                            }}
                            loadMore={fetchMoreSuppliers}
                            hasMoreData={totalSuppliers > suppliersData.length}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        label="Incoming Part"
                        name="incomingPartId"
                        rules={[{ required: true }]}
                    >
                        <SearchSelect
                            showSearch
                            allowClear
                            disabled={isEdit ? true : false}
                            loading={icPartsLoading}
                            placeholder="Select IC part"
                            options={icPartsData}
                            filterOption={false}
                            onSearch={handleIcPartSearch}
                            onClear={fetchIcParts}
                            loadMore={fetchMoreIcParts}
                            hasMoreData={totalIcParts > icPartsData.length}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        label="Invoice Number"
                        name="invoiceNumber"
                    >
                        <Input
                            placeholder="Invoice number"
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        label="Invoice Date"
                        name="invoiceDate"
                    >
                        <DatePicker
                            placeholder="Invoice Date"
                            style={{ width: "100%" }}
                            showTime={false}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        name="quantity"
                        label="Quantity"
                        rules={[{ required: true }]}
                    >
                        <Input
                            type="number"
                            placeholder="Total order quantity"
                            onWheel={(e) => e.target.blur()}
                            onScroll={(e) => e.target.blur()}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        name="deliveryDateTime"
                        label="GRN Date"
                    >
                        <DatePicker
                            placeholder="GRN Date"
                            style={{ width: "100%" }}
                            showTime={false}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        label="GRN Number"
                        name="grnNumber"
                    >
                        <Input
                            placeholder="GRN number"
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <div className={styles.samplingDiv}>
                        <div className={styles.samplingType}>
                            <Form.Item
                                className="mb-3"
                                name="samplingType"
                                label="Sampling Type"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    disabled={isEdit ? true : false}
                                    placeholder="Sampling type"
                                    options={samplingTypes}
                                    onChange={(value) => {
                                        setSelectedSamplingType(value);
                                    }}
                                    onFocus={() => setShowButton(true)}
                                />
                            </Form.Item>
                        </div>
                        {
                            selectedSamplingType === 'CUSTOM' &&
                            <div className={styles.widthHalf}>
                                <Form.Item
                                    className="mb-3"
                                    name="samplingNumber"
                                    label="Sampling Number"
                                >
                                    <Input
                                        disabled={isEdit ? true : false}
                                        type="number"
                                        placeholder="Sampling quantity"
                                        onWheel={(e) => e.target.blur()}
                                        onScroll={(e) => e.target.blur()}
                                        onFocus={() => setShowButton(true)}
                                    />
                                </Form.Item>
                            </div>
                        }
                    </div>
                    <Form.Item
                        className="mb-3"
                        name="linkedSheets"
                        label="Sheet"
                        rules={[{ required: true }]}
                    >
                        <SearchSelect
                            disabled={isEdit ? true : false}
                            showSearch
                            allowClear
                            loading={sheetsLoading}
                            placeholder="Link inspection sheet"
                            options={sheetsData}
                            filterOption={false}
                            onSearch={handleSheetSearch}
                            onClear={fetchSheets}
                            loadMore={fetchMoreSheets}
                            hasMoreData={totalSheets > sheetsData.length}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        name="assignees"
                        label="Assignee"
                        rules={[{ required: true }]}
                    >
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={usersLoading}
                            placeholder="Link inspection user"
                            options={usersData}
                            filterOption={false}
                            onSearch={handleUserSearch}
                            onClear={fetchUsers}
                            loadMore={fetchMoreUsers}
                            hasMoreData={totalUsers > usersData.length}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3"
                        name="dueDateTime"
                        label="Due By"
                        rules={[{ required: true }]}
                    >
                        <DatePicker
                            placeholder="Due by"
                            style={{ width: "100%" }}
                            showTime={false}
                            onFocus={() => setShowButton(true)}
                        />
                    </Form.Item>
                    <Form.Item className={styles.btnFormItem}>
                        {
                            showButton &&
                            <button
                                type="submit"
                                className={styles.createBtn}
                            >
                                {isEdit ? "Save changes" : "Create Inspection"}
                            </button>
                        }
                    </Form.Item>
                </Form>
            </Modal>

        </ConfigProvider>
    )
}