import { sectionActionType } from "../../data/models/sectionActionType.js";

export const SectionReducers = (state, action) => {
  switch (action.type) {
    case sectionActionType.addField:
      return [...state, action.data];
    case sectionActionType.addFieldList:
      return action?.data ? [...action.data] : [];
    case sectionActionType.removeField:
      let arr = [];
      state.filter((item, index) => {
        if (item != action.data) {
          arr.push(item);
        }
      });
      return arr;
    case sectionActionType.addFieldInfoToField:
      state[action.data.index] = action.data.fieldInfo;
      return state;
    default:
      return state;
  }
};
