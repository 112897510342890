import { useState, useEffect, useReducer } from "react";
import CustomButton from "../../../components/button";
import LeftArrowIcon from "remixicon-react/ArrowLeftSLineIcon";
import BillLineIcon from "remixicon-react/BillLineIcon";
import LayoutTopIcon from "remixicon-react/LayoutTopLineIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import Canvas from "../components/canvas/ui/canvas.js";
import Field from "../components/field.js";
import { fields } from "../data/models/fields.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NewSheetDetailsModal from "../components/newSheetDetailsModal/ui/newSheetDetailsModal.js";
import { ApiClientType } from "../../../clients/api-client.js";
import { ApiClient } from "../../../clients/api-client.js";
import { SheetReducers } from "./state/sheetReducers.js";
import AddLineIcon from "remixicon-react/AddLineIcon";
import { sheetActionType } from "../data/models/sheetActionType.js";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../common/crunches/primaryButton/primaryButton";
import SecondaryButton from "../../../common/crunches/secondaryButton/secondaryButton.js";
import {
  showToastError,
  showToastSuccess,
} from "../../../common/toasters/toasterMessages";

export default function Sheets() {
  const params = useParams();
  let appId = params.appId;
  let navigate = new useNavigate();
  const [sheet, dispatch] = useReducer(SheetReducers, {});
  const fieldTypes = Object.keys(fields);
  const [showModal, setShowModal] = useState(
    params.sheetId != "new" ? false : true
  );
  const [isPreview, setIsPreview] = useState(true);
  const [sheetEdits, setSheetEdits] = useState([]);
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    (async () => {
      if (params.sheetId != "new") {
        let response = await ApiClient(
          ApiClientType.get,
          process.env.REACT_APP_SHEETS_BASE_URL,
          `${isPreview
            ? `/sheets/${params.sheetId}?includeSections=true&includeFields=true&includeSheetEdits=true`
            : `/sheets/${params.sheetId}?includeSections=true&includeFields=true&includeSheetEdits=true`
          }`,
          {}
        );
        if (response) {
          dispatch({
            type: sheetActionType.addSheetDetails,
            data: response.data,
          });
          setSheetEdits(response.data?.sheetEdits);
        }
      } else {
        setIsPreview(false);
      }
    })();
  }, [isPreview]);

  const onFinishAuthoring = async () => {
    if (sheet.id) {
      let response = await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets/${sheet.sheetId ? sheet.sheetId : sheet.id}/edit/publish`,
        {}
      );
      if (!response.isError) {
        showToastSuccess(response.message);
        setIsPreview(true);
      } else {
        showToastError(response.message);
      }
    }
  };

  const onUpdateSheet = async (params) => {
    if (sheet.id) {
      let response = await ApiClient(
        ApiClientType.patch,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets/${sheet.id}`,
        params
      );
      if (!response.isError) {
        showToastSuccess(response.message);
        dispatch({ type: sheetActionType.editSheetDetails, data: params });
      } else {
        showToastError(response.message);
      }
    } else {
      let response = await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_SHEETS_BASE_URL,
        `/sheets`,
        params
      );
      if (response) {
        dispatch({ type: sheetActionType.addSheetDetails, data: response });
        navigate(`/application/${appId}`);
      }
    }
  };

  return (
    <div style={{ padding: "22px 42px" }}>
      <NewSheetDetailsModal
        edit={true}
        show={showModal}
        setShowModal={setShowModal}
        value={{
          sheet,
          appId: params.appId,
          onSubmitModal: (params) => {
            setIsEdit(true);
            onUpdateSheet(params);
            setShowModal(false);
          },
        }}
      />
      <div className="d-flex justify-content-between">
        <div>
          <CustomButton
            margin="0 20px 0 0"
            height="45px"
            padding="0 10px"
            icon={() => <LeftArrowIcon color="#7D7676" />}
            onClick={() => navigate(-1)}
          />
          <SecondaryButton
            value={{
              onClick: () => {
                navigate(-1);
              },
              style: { marginRight: "20px" },
              child: (
                <>
                  <BillLineIcon
                    style={{
                      marginRight: "10px",
                      marginTop: "-5px",
                      color: "#7D7676",
                    }}
                  />
                  Sheets
                </>
              ),
            }}
          />
          <SecondaryButton
            value={{
              style: {
                background: "rgba(208, 235, 255, 0.35)",
                border: "1px solid #009AFF",
              },
              onClick: () => {
                setShowModal(true);
              },
              child: (
                <>
                  <BillLineIcon
                    style={{
                      marginRight: "10px",
                      marginTop: "-5px",
                    }}
                  />
                  {sheet.name}
                </>
              ),
            }}
          />
        </div>
        <div className="d-flex">
          {isPreview ? (
            <PrimaryButton
              value={{
                child: (
                  <>
                    {!isEdit ? (
                      <>
                        <span onClick={() => setIsEdit(false)}>
                          Start Authoring
                        </span>
                      </>
                    ) : (
                      <>
                        <PencilLineIcon
                          style={{
                            marginRight: "5px",
                            marginTop: "-4px",
                          }}
                        />
                        Edit Sheet
                      </>
                    )}
                  </>
                ),
                onClick: () => {
                  setIsPreview(false);
                },
                style: {
                  height: "50px",
                  width: "fit-content",
                },
              }}
            />
          ) : (
            <div>
              <PrimaryButton
                value={{
                  onClick: () => {
                    onFinishAuthoring();
                  },
                  type: "button",
                  child: "Publish Now",
                  style: { height: "50px", width: "157px" },
                }}
              />
            </div>
          )}
        </div>
      </div>
      {sheet.id ? (
        <DndProvider backend={HTML5Backend}>
          <div
            style={{
              border: "1px solid #DADADA",
              marginTop: "21px",
              borderRadius: "11px",
            }}
            className="d-flex bg-white"
          >
            {!isPreview ? (
              <div
                style={{ borderRight: "1px solid #DADADA" }}
                className="col-2"
              >
                <div
                  style={{
                    borderBottom: "1px solid #DADADA",
                    padding: "15px 0",
                  }}
                  className="d-flex justify-content-center"
                >
                  <PencilLineIcon color="#7D7676" />
                  <b className="boldFont" style={{ marginLeft: "9px" }}>
                    Fields
                  </b>
                </div>
                <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
                  {fieldTypes.map((fieldType, index) => {
                    return (
                      <div key={index}>
                        <div
                          style={{
                            padding: "11px 16px",
                            background: "rgba(229, 229, 229, 0.49)",
                            borderBottom: "1px solid #DADADA",
                          }}
                          className="d-flex"
                        >
                          {fieldType}
                        </div>
                        <div>
                          {fields[fieldType].map((field, index) => {
                            return (
                              <Field
                                key={index}
                                value={{ field: field, sheetId: sheet.id }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className={isPreview ? "col-12" : "col-10"}>
              <div
                style={{ borderBottom: "1px solid #DADADA", padding: "15px 0" }}
                className="d-flex justify-content-center"
              >
                <LayoutTopIcon color="#7D7676" />
                <b className="boldFont" style={{ marginLeft: "9px" }}>
                  {isPreview ? "Preview" : "Canvas"}
                </b>
              </div>
              <Canvas
                value={{
                  isPreview: isPreview,
                  sections: sheet.sections ? sheet.sections : [],
                  sheetId: sheet.sheet_id ? sheet.sheet_id : sheet.id,
                  sheetEdits: sheetEdits,
                }}
              />
            </div>
          </div>
        </DndProvider>
      ) : null}
    </div>
  );
}
