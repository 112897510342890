import React from "react";
import styles from "../styles/userDetails.module.css";
import UserDetailsTable from "../components/userDetailsTable";
import User3LineIcon from "remixicon-react/User3LineIcon";

const UserDetailsSection = ({ title, icon, component }) => (
  <div id={styles.linkedContainer}>
    <table className={styles.openTableStyle}>
      <tbody>
        <tr className={styles.openTableRow}>
          <th className={"col-md-6"}>
            <div id={styles.flexDiv}>
              {icon}
              <span className="boldFont" id={styles.linkedText}>
                {title}
              </span>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
    {component}
  </div>
);

export default function UserDetails() {
  const userDetailSections = [
    {
      title: "Basic user details",
      icon: <User3LineIcon style={{ color: "#7D7676" }} />,
      component: <UserDetailsTable />,
    },
  ];

  return (
    <div className="p-3">
      {userDetailSections.map((section, index) => (
        <UserDetailsSection key={index} {...section} />
      ))}
    </div>
  );
}
