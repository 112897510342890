import styles from "../../../styles/stationCard.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import defaultStationImage from "../../../../../assets/icons/defaultStationImage.svg";

export default function StationCard(props) {
  let station = props.value.station;

  return (
    <div id={styles.card}>
      <Row>
        <Col md={4}>
          {station?.meta?.images ? (
            <img
              style={{
                border: "1px solid #dadada",
                borderRadius: "10px",
              }}
              src={station?.meta?.images[0]}
              width="100%"
              height="120px"
              alt={station.name}
            />
          ) : (
            <div
              style={{
                background: "#E5E5E5",
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <img src={defaultStationImage} />
            </div>
          )}
        </Col>
        <Col>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div
                className="boldFont"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "5px",
                  letterSpacing: "-0.4px",
                }}
              >
                {station.name}
              </div>
              <div> {station?.description}</div>
            </div>
            {station?.code ? (
              <div>
                <span
                  style={{
                    border: "1px solid #dadada",
                    padding: "4px 10px 4px 10px",
                    borderRadius: "5px",
                    color: "#7D7676;",
                  }}
                >
                  {station?.code}
                </span>
              </div>
            ) : null}
          </div>
          <hr />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              Linked Workforce:
              <span className={styles.count}>0</span>
            </div>
            <div>
              Linked Machines:
              <span className={styles.count}>
                {station?.stationMachines?.length || 0}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
