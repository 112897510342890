import { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getApplicationDetailsAPI } from "../../common/apis/commonApis";
import { allApplicationRoutes } from "./routes/routes";
import { AppContext } from "../../utils/context/appContext";
import SideNavRoutes from "./routes/sideNavRoutes";
import { showToastError } from "../../common/toasters/toasterMessages";
import { Result } from "antd";

const ApplicationBuilder = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const context = useContext(AppContext);

    const { addDynamicRoute, addDynamicSideNavRoute } = context;

    const { appId } = useParams();

    const fetchApplicationDetails = async (appId) => {
        const response = await getApplicationDetailsAPI(appId);
        if (response.isError) {
            showToastError(response.displayableMessage);
            return;
        }
        return response?.data
    }

    const buildApplicationRoutes = async (appId) => {
        let applicationDetails = await fetchApplicationDetails(appId);
        if (!applicationDetails) {
            return;
        }

        let enabledFeatures = []
        applicationDetails?.meta?.features?.map((feature) => {
            if (feature.key != null) {
                enabledFeatures = [...enabledFeatures, feature.key]
            }
            return feature;
        })
        // Add and make changes based on the backend object - applicationDetails
        let effectiveApplicationRoutes = []
        allApplicationRoutes
            .filter((route) => enabledFeatures.includes(route.feature))
            .map(route => {
                effectiveApplicationRoutes = [...effectiveApplicationRoutes, route]
                addDynamicRoute(route)
                return route;
            })
        let allSideNavRoutes = SideNavRoutes({ appId })
        let effectiveSideNavRoutes = allSideNavRoutes
            .filter((route) => enabledFeatures.includes(route.feature))
            .map(route => {
                return route
            })
        if (effectiveApplicationRoutes?.length > 0) {
            addDynamicSideNavRoute(effectiveSideNavRoutes);
            const eligiblePath = isEligiblePath(location.pathname, effectiveApplicationRoutes)
            if (eligiblePath) {
                navigate(location.pathname, { replace: true });
            } else {
                let exactPath = effectiveApplicationRoutes[0]?.path.split('/')[3];
                navigate(`/application/${appId}/${exactPath}`, { replace: true });
            }
        }
    }

    const isEligiblePath = (path, routes) => {
        const keyPath = path.split('/')[3];
        if (keyPath === undefined) {
            return false
        }
        const routeExists = routes.find(route => route.feature === keyPath)
        if (routeExists === undefined) {
            return false
        }
        return true
    }

    useEffect(() => {
        if (appId?.length > 0) {
            void buildApplicationRoutes(appId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId]);

    return (
    <Result
        status="404"
        title="No Applications, yet."
        subTitle="Kindly, contact your provider for the applications setup."
    />);
};

export default ApplicationBuilder;