import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  DEFAULT_OFFSET,
  PAGE_SIZE,
} from "../../../../clients/api-constants.js";
import InstrumentsHeader from "./components/header.js";
import CalibrationBody from "./components/body.js";
import { useNavigate, useParams } from "react-router-dom";
import { getEquipmentsApi } from "../../apiCalls/calibrationApis.js";

export default function CalibrationTable() {
  const params = useParams();
  const navigate = useNavigate();

  const [calibrationItem, setCalibrationItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [calibrationList, setCalibrationList] = useState([]);
  const getEquipmentList = async (
    value = "",
    offset = DEFAULT_OFFSET,
    limit = PAGE_SIZE
  ) => {
    setIsLoading(true);
    let response = await getEquipmentsApi(value,offset,limit)
    if (!response.isError) {
      setCalibrationList(response?.data?.instruments);
      setTotalCount(response?.totalCount);
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      getEquipmentList("", 0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      getEquipmentList("", currentPageSize * (page - 1), currentPageSize);
    }
  };

  let search;
  const searchInstruments = (value) => {
    value = value.trimStart();
    clearTimeout(search);
    search = setTimeout(() => {
      void getEquipmentList(value);
    }, 500);
  };

  const handleCalibrationClick = (record) => {
    setCalibrationItem(record?.instrument);
  };

  const onRefresh = () => {
    getEquipmentList("", offset, currentPageSize);
  };

  useEffect(() => {
    void getEquipmentList("", DEFAULT_OFFSET, PAGE_SIZE);
  }, [calibrationItem]);

  return (
    <Container fluid={true}>
      <>
        <InstrumentsHeader searchInstruments={searchInstruments} />
        <hr />
        <CalibrationBody
          params={params}
          navigate={navigate}
          calibrationData={calibrationList}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          onCalibrationClick={handleCalibrationClick}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
        />
      </>
    </Container>
  );
}
