import styles from "../../../styles/workroomCard.module.css";
import { useState, useEffect } from "react";
import { ApiClientType } from "../../../../../clients/api-client.js";
import { ApiClient } from "../../../../../clients/api-client.js";
import mainLogo from "../../../../../assets/icons/mainLogo.svg";
import ApplicationIcon from "remixicon-react/Building3LineIcon";
import { useNavigate } from "react-router-dom";

export default function WorkroomCard(props) {
  let workroom = props.value.workroom;

  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    async function fetchApps() {
      let response = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_MASTER_BASE_URL,
        `/applications?includeWorkroom=false&name=&workroomId=${workroom?.id}`,
        {}
      );
      if (response) {
        setApplications(response.data);
      }
    }
    fetchApps();
  }, [workroom]);

  return (
    <div id={styles.workroomCard}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          flexWrap: "wrap",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img src={mainLogo} />
          </div>
          <b
            style={{
              fontSize: "17px",
              color: "black",
              textShadow: "0.7px 0 black",
              letterSpacing: "0.7px",
              textTransform: "capitalize",
            }}
          >
            {workroom?.name}
          </b>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
        </div>
      </div>
      <hr style={{ margin: "0 -15px" }} />
      <div style={{ marginTop: "20px" }}>
        {applications?.length !== 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: "50px",
            }}
          >
            <>
              {applications?.map((item, index) => {
                return (
                  <div key={item.id} style={{ maxWidth: "120px" }}>
                    <button
                      type="button"
                      id={styles.applicationCard}
                      onClick={() => navigate(`/app-builder/${item.id}/`)}
                    >
                      <ApplicationIcon color="#7D7676" />
                    </button>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#7D7676",
                        marginTop: "5px",
                        textShadow: "0.7px 0 #7D7676",
                        letterSpacing: "0.7px",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </>{" "}
          </div>
        ) : (
          <div style={{ color: "grey", minHeight: "113px" }}>
            No Applications in the Workroom
          </div>
        )}
      </div>
    </div>
  );
}
