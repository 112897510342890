import React, {useEffect, useState} from "react";
import {getSuppliersAPI} from "../../../common/apis/commonApis";
import {showToastError} from "../../../common/toasters/toasterMessages";
import styles from "../components/sheetTable/styles/sheetTable.module.css";
import SearchSelect from "../components/sheetTable/components/searchSelect";

export default function SupplierSearchSelect(props) {
    const SEARCH_MORE_TYPE = "searchMore";

    const {onFilterChange, selected} = props;

    const [supplier, setSupplier] = useState(selected);
    const [supplierListLoading, setSupplierListLoading] = useState(false);
    const [supplierList, setSupplierList] = useState([]);
    const [supplierListCount, setSupplierListCount] = useState(0);

    const handleSupplierChange = (supplier) => {
        setSupplier(supplier);
        onFilterChange(supplier);
    };

    const handleSupplierSearch = async (searchVal) => {
        setSupplierList([]);
        await fetchSuppliers(searchVal);
    }

    const fetchSuppliers = async (value, type = "") => {
        setSupplierListLoading(true);
        let suppliersResponse = await getSuppliersAPI(type === SEARCH_MORE_TYPE ? supplierList.length : 0, value, 10);
        if (suppliersResponse.isError) {
            showToastError(suppliersResponse.displayableMessage);
        } else {
            setSupplierListCount(suppliersResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? supplierList : [];

            suppliersResponse.data.forEach(supplier => {
                dataList.push({value: supplier.id, label: supplier.name});
            });
            setSupplierList(dataList);
        }
        setSupplierListLoading(false);
    };

    const fetchMoreSuppliers = async () => {
        void fetchSuppliers("", SEARCH_MORE_TYPE);
    }

    useEffect(() => {
        void fetchSuppliers();
    }, []);

    return (<SearchSelect className={styles.headerRow2Col1Item1}
                          showSearch
                          allowClear
                          loading={supplierListLoading}
                          value={supplier}
                          placeholder="Supplier"
                          onChange={handleSupplierChange}
                          options={supplierList}
                          filterOption={false}
                          onSearch={handleSupplierSearch}
                          onClear={fetchSuppliers}
                          loadMore={fetchMoreSuppliers}
                          hasMoreData={supplierListCount > supplierList.length}
    />)
}