import { ApiClient, ApiClientType, ApiFileClientWithSource } from "../../../clients/api-client";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";

export const createInspectionAPI = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections`,
    body
  );
};

export const getInspectionAPI = async (
  id,
  includeQualityInspectionAssignees = true,
  includeSupplier = true,
  includeIncomingPart = true,
  includeSamples = true,
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}?includeQualityInspectionAssignees=${includeQualityInspectionAssignees}&includeSupplier=${includeSupplier}&includeIncomingPart=${includeIncomingPart}&includeSamples=${includeSamples}`,
    {}
  );
};

export const getInspectionByKeyAPI = async (
  key,
  includeQualityInspectionAssignees = true,
  includeSupplier = true,
  includeIncomingPart = true,
  includeSamples = false,
  includeInspectionStats = true,
) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections?key=${key}&includeInspectionStats=${includeInspectionStats}&includeQualityInspectionAssignees=${includeQualityInspectionAssignees}&includeSupplier=${includeSupplier}&includeIncomingPart=${includeIncomingPart}&includeSamples=${includeSamples}`,
    {}
  );
};

export const closeInspectionAPI = async (id) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}/close`,
    {}
  );
};

export const startInspectionAPI = async (id) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}/start`,
    {}
  );
};

export const getInspectionBySupplierId = async (
  id,
  {
    inspectionKey = "",
    verdictStatusValue = "",
    grnValue = "",
    inspectionStatusValue = "",
    icPartValue = "",
    activeValue = true,
    createdAt = "", startedAt = "", closedAt = "",
    includeQualityInspectionAssignees = true,
    includeSupplier = true,
    includeIncomingPart = true,
    includeSamples = false,
    includeInspectionStats = true,
    pageOffset = DEFAULT_OFFSET,
    limit = PAGE_SIZE,
  }
) => {
  let apiUrl = `/incoming-quality-inspections?offset=${pageOffset}&limit=${limit}&includeInspectionStats=${includeInspectionStats}&isActive=${activeValue}&supplierId=${id}&incomingPartId=${icPartValue}&includeQualityInspectionAssignees=${includeQualityInspectionAssignees}&includeSupplier=${includeSupplier}&includeIncomingPart=${includeIncomingPart}&includeSamples=${includeSamples}`;
  if (inspectionKey) {
    apiUrl += `&key=${inspectionKey.toUpperCase()}`;
  }
  if (createdAt) {
    apiUrl += `&createdAt=${JSON.stringify(createdAt)}`;
  }
  if (startedAt) {
    apiUrl += `&startedAt=${JSON.stringify(startedAt)}`;
  }
  if (closedAt) {
    apiUrl += `&closedAt=${JSON.stringify(closedAt)}`;
  }
  if (verdictStatusValue) {
    apiUrl += `&verdict=${verdictStatusValue}`;
  }
  if (grnValue) {
    apiUrl += `&grnNumber=${grnValue}`;
  }

  if (inspectionStatusValue) {
    apiUrl += `&status=${inspectionStatusValue}`;
  }

  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    apiUrl,
    {}
  );
};

export const updateInspectionAPI = async (id, body) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}`,
    body
  );
};

export const createSamplingAPI = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans`,
    body
  );
};

export const getSamplingAPI = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans/${id}`
  );
};

export const getSamplingPlanByIncomingPartId = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans?incomingPartId=${id}`
  );
};

export const updateSamplingAPI = async (id, reqBody) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans/${id}`,
    reqBody
  );
};

export const deleteSamplingAPI = async (id) => {
  return await ApiClient(
    ApiClientType.delete,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans/${id}`
  );
};

export const getSuppliersAPI = async (value) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers?code=&name=${value || ""}`
  );
};

export const searchIncomingPartsAPI = async (supplierId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts?supplierId=${supplierId}`
  );
};

export const getIncomingPartsAPI = async (supplierId, offset = 0, limit = 5, name = '') => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts?includeSuppliers=true&limit=${limit}&offset=${offset}&supplierId=${supplierId}&name=${name}`
  );
};

export const getSamplingsBySupplierId = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sampling-plans?supplierId=${id}&includeIncomingPartSuppliers=false&includeIncomingPart=true`
  );
};

export const getInspectionScarsAPI = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars?source=INSPECTION&sourceId=${id}&includeSupplier=true`
  );
};

export const verdictApi = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/verdict`
  );
};

export const updateVerdictApi = async (id, body) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}/verdict`,
    body
  );
};

export const statusAPI = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/status`
  );
};

export const statusScarAPI = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/status`
  );
};

export const deleteScarAPI = async (id) => {
  return await ApiClient(
    ApiClientType.delete,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${id}`
  );
};

export const deleteInspectionAPI = async (id) => {
  return await ApiClient(
    ApiClientType.delete,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}`
  );
};

export const getSampleDataSheetOrderAPI = async (id, sheetId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheet-orders?source=QUALITY_INSPECTION_SAMPLE&sourceId=${id}&sheetId=${sheetId}`
  );
};

export const getInspectionSampleVerdictsAPI = async (id) => {
  let response = await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${id}/sample/verdict`
  );
  return response;
};

export const getInspectionSampleDataAPI = async (inspectionId, sheetId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-quality-inspections/${inspectionId}/sample/data?sheetId=${sheetId}`
  );
};

export const fetchIncomingPartAPI = async (icPartId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/incoming-parts/${icPartId}`
  );
};

export const closeScarAPI = async (id) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${id}/close`
  );
};

export const startScarAPI = async (id) => {
  return await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_MASTER_BASE_URL,
      `/scars/${id}/start`
  );
};

export const getInspections = async (
  {
    inspectionKey = "",
    verdictStatusValue = "",
    inspectionStatusValue = "",
    icPartValue = "",
    createdAt = "", startedAt = "", closedAt = "",
    includeQualityInspectionAssignees = true,
    includeSupplier = true,
    includeIncomingPart = true,
    includeSamples = false,
    includeInspectionStats = true,
    pageOffset = DEFAULT_OFFSET,
    limit = PAGE_SIZE,
  }
) => {
  let apiUrl = `/incoming-quality-inspections?offset=${pageOffset}&limit=${limit}&includeInspectionStats=${includeInspectionStats}&incomingPartId=${icPartValue}&includeQualityInspectionAssignees=${includeQualityInspectionAssignees}&includeSupplier=${includeSupplier}&includeIncomingPart=${includeIncomingPart}&includeSamples=${includeSamples}`;
  if (inspectionKey) {
    apiUrl += `&key=${inspectionKey.toUpperCase()}`;
  }
  if (createdAt) {
    apiUrl += `&createdAt=${JSON.stringify(createdAt)}`;
  }
  if (startedAt) {
    apiUrl += `&startedAt=${JSON.stringify(startedAt)}`;
  }
  if (closedAt) {
    apiUrl += `&closedAt=${JSON.stringify(closedAt)}`;
  }
  if (verdictStatusValue) {
    apiUrl += `&verdict=${verdictStatusValue}`;
  }

  if (inspectionStatusValue) {
    apiUrl += `&status=${inspectionStatusValue}`;
  }

  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    apiUrl,
    {}
  );
};

export const downloadScarReportAPI = async (source, id) => {
  return await ApiFileClientWithSource(
    source,
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${id}/pdf`,
    {}, {
    'Content-Type': 'application/pdf',
  },
    'arraybuffer'
  );
};