import {ApiClient, ApiClientType} from "../../../clients/api-client";
import {DEFAULT_OFFSET, PAGE_SIZE} from "../../../clients/api-constants";

export const getSheetList = async ({
                                       supplierId = undefined,
                                       icPartId = undefined,
                                       productId = undefined,
                                       stationId = undefined,
                                       pageOffset = DEFAULT_OFFSET,
                                       limit = PAGE_SIZE,
                                   }) => {
    let apiUrl = `/sheets?offset=${pageOffset}&limit=${limit}`;
    if (supplierId) {
        apiUrl += `&supplierId=${supplierId}`;
    }
    if (icPartId) {
        apiUrl += `&incomingPartId=${icPartId}`;
    }
    if (productId) {
        apiUrl += `&productId=${productId}`;
    }
    if (stationId) {
        apiUrl += `&stationId=${stationId}`;
    }
    return await ApiClient(ApiClientType.get, process.env.REACT_APP_MASTER_BASE_URL, apiUrl, {});
};

export const createSheetAPI = async (body) => {
    return await ApiClient(ApiClientType.post, process.env.REACT_APP_MASTER_BASE_URL, `/sheets`, body);
};
