import { useEffect, useState } from "react";
import { addStylesFromData, generateVerdictData, generateVerdictsFromConfig } from "../../utils/helperUtils";
import { Space } from "antd";


export default function SummaryGroupVerdictView(props) {
    const { ratingConfig, ratingData, summaryGroup } = props
    const [verdictData, setVerdictData] = useState(undefined);
    const getVerdictData = (config, data) => {
        const verdictCriterias = generateVerdictsFromConfig(config, summaryGroup.entity);
        if (verdictCriterias && verdictCriterias.length > 0) {
            const verdictDataList = generateVerdictData(data, summaryGroup.entity, verdictCriterias?.map(verdictCriteria => verdictCriteria.id));
            setVerdictData(verdictDataList)
        }
    };

    useEffect(() => {
        getVerdictData(ratingConfig, ratingData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingConfig, ratingData])

    return (
        <>
            <div>
                {
                    verdictData && verdictData.map(verdict => (
                        <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                            <Space>
                                <span style={{ fontWeight: "bold" }}>{verdict?.entityTitle}:</span>
                                {
                                    verdict?.dataType === "object" ?
                                        (<span style={addStylesFromData({ fontWeight: "bold", color: "darkgray" }, verdict?.data?.value?.styles)}>{verdict?.data?.value?.value}</span>)
                                        : (<span style={{ fontWeight: "bold", color: "darkgray" }}>{verdict?.data?.value}</span>)
                                }

                                {/* {verdictData?.data && (<Tag color="#3dcc63">#87d068</Tag>)} */}
                            </Space>
                        </div>
                    ))
                }

            </div>
        </>
    );
}
