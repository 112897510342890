export const dateTimeFormat = (date) => {
  if (!date) return;

  const timeString12hr = new Date(date)?.toLocaleTimeString("en-US", {
    timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return timeString12hr + ` , ` + onlyDate(date);
};

export const onlyDate = (date) => {
  if (!date) {
    return;
  }

  return new Date(date).toLocaleDateString()?.split('/').join('-')
}

export const dateTimeNow = new Date().toISOString();
