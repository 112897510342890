import { Button } from "antd";

export const supplierTableCols = (onSupplierClicked, setShowModal) => [
  {
    title: "Supplier Name",
    dataIndex: ["name"],
    key: "name",
    width: 120,
    minWidth: 100,
    render: (text, record) => (
      <Button
        type="link"
        onClick={() => {
          onSupplierClicked(record?.id);
          setShowModal(true);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: "Supplier Code	",
    dataIndex: ["code"],
    key: "code",
    width: 100,
    minWidth: 100,
  },
  {
    title: "Location",
    dataIndex: ["meta", "locations"],
    key: "locations",
    width: 100,
    minWidth: 100,
  },
];
