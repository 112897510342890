import Dropdown from "react-bootstrap/Dropdown";
import styles from "./dropdownStyle.module.css";

export default function DatasourceSelectDropdown(props) {
  let state = props.value.state;
  return (
    <div className="relative">
        <div className="disabledLayover"></div>
      <Dropdown id={styles.dropdown}>
        <Dropdown.Toggle disabled={true} id={styles.button} style={props.value.buttonStyle}>
          {props.value.buttonText}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{padding: "0rem"}}>
          {state.loading ? (
            <Dropdown.Item>...loading</Dropdown.Item>
          ) : (
            <>
              <Dropdown.Item style={{cursor: "default",  backgroundColor: "#d1cece"}} onClick={() => props.value.selectValue({})}>
                DataSources
              </Dropdown.Item>
              {state?.map((item, index) => {
                let data = props.value.extractDataFromList(item);
                return (
                  <Dropdown.Item
                    key={data.id}
                    onClick={() => props.value.selectValue(data)}
                    style={{display: "flex", justifyContent: "space-between"}}
                  >
                    <div>
                      {data.name}
                    </div>
                    <div>
                      11
                    </div>
                  </Dropdown.Item>
                );
              })}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
