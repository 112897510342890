import { useState } from "react";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import styles from "./styles/styles.expandCollapse.module.css";

const ExpandCollapse = (props) => {
  const { expand, setExpand } = props;
  return (
    <button
      id={styles.expandCollapseButton}
      style={props?.value?.style}
      onClick={() => setExpand(!expand)}
    >
      {!expand ? (
        <ArrowRightSLineIcon
          style={{ color: "#7D7676", marginLeft: ".5px", marginBottom: "1px" }}
        />
      ) : (
        <ArrowLeftSLineIcon
          style={{ color: "#7D7676", marginLeft: ".5px", marginBottom: "1px" }}
        />
      )}
    </button>
  );
};

export default ExpandCollapse;
