import { Col, Row } from "react-bootstrap";
import defaultImageLarge from "../../../assets/icons/defaultImageLarge.svg";
import styles from "../styles/tablesStyling.module.css";

const ProductsList = (props) => {
  const { productsList, setEditProductModal, showProcessModal } = props;

  const handleProductClick = (id) => {
    showProcessModal(id);
    setEditProductModal(true);
  };

  return productsList?.map((item, key) => {
    return (
      <div
        className={styles.productListItem}
        key={key}
        onClick={() => handleProductClick(item.id)}
      >
        <Row>
          <Col md={5} style={{ padding: "1rem", display: "flex" }}>
            <div className={styles.productInfo}>
              <div>
                <img
                  style={{
                    border: item?.meta?.images ? "1px solid #dadada" : "none",
                    borderRadius: "10px",
                    width: "120px",
                    height: "100px",
                  }}
                  src={
                    item?.meta?.images ? item.meta.images[0] : defaultImageLarge
                  }
                  alt={item?.meta?.images ? "Product Image" : "Default Image"}
                />
              </div>
              <div className={styles.productName}>
                <span className="boldFont">
                  {item ? item.name : "Product Name"}
                </span>
              </div>
            </div>
          </Col>
          <Col md={7} style={{ display: "table" }}>
            <div
              className={styles.textColor + " " + styles.productId}
              style={{
                padding: "0px",
                textAlign: "right",
              }}
            >
              <span>
                Product ID:
                <span
                  className={styles.productCode}
                  style={{ marginRight: "40px" }}
                >
                  {item.code}
                </span>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  });
};

export default ProductsList;
