import { Descriptions } from "antd";
import { dateTimeFormat } from "../../../../../../../utils";

export default function SCAROtherDetails(props) {
    const { scarDetails } = props;
    const items = [
        {
            key: 'createdAt',
            label: ' Created At',
            children: <span>{dateTimeFormat(scarDetails?.trailMeta?.createdAt)}</span>,
            span: 3,
        },
        {
            key: 'startedAt',
            label: ' Started At',
            children: <span>{dateTimeFormat(scarDetails?.trailMeta?.startedAt)}</span>,
            span: 3,
        },
        {
            key: 'closedAt',
            label: ' Closed At',
            children: <span>{dateTimeFormat(scarDetails?.trailMeta?.closedAt)}</span>,
            span: 3,
        }
    ];
    return (
        <>
            <Descriptions bordered items={items} />
        </>
    );
}