import Calibration from "../../../features/calibration/ui/calibration";
import Dashboard from "../../../features/dashboard/ui/dashboard";
import SheetList from "../../../features/sheetList/ui/sheetList";
import Sheets from "../../../features/sheets/ui/sheets";
import SupplierRatingDetailedView from "../../../features/supplierRatings/components/supplierRatingDetails/supplierRatingDetailedView";
import SupplierRating from "../../../features/supplierRatings/ui/supplierRating";
import InspectionDetailedView from "../../IQCActivity/components/inspectionDetailedView/inspectionDetailedView";
import IQCActivity from "../../IQCActivity/ui/IQCActivity";
import CalibrationItem from "../../../features/calibration/components/calibrationItem/calibrationItem";
import SCAR from "../../Scar/ui/scar";
import AFR from "../../../features/afr/ui/afr";
import ScarOverview from "../../Scar/ui/newScar";
import SCARDetailedView from "../../Scar/ui/components/detailsView/scarDetailedView";

export const allApplicationRoutes = [
  {
    path: "/application/:appId/dashboard",
    name: "Dashboard",
    component: Dashboard,
    feature: "dashboard",
    key: "dashboard/1",
  },
  {
    path: "/application/:appId/inspections",
    name: "Inspections",
    component: IQCActivity,
    feature: "inspections",
    key: "inspections/1",
  },
  {
    path: "/application/:appId/sheets",
    name: "Sheet List",
    component: SheetList,
    feature: "sheets",
    key: "sheets/1",
  },
  {
    path: "/application/:appId/sheets/:sheetId",
    name: "Sheets",
    component: Sheets,
    withoutNav: true,
    feature: "sheets",
    key: "sheets/2",
  },
  {
    path: "/application/:appId/inspections/:inspectionKey/:activeKey",
    name: "Inspections",
    component: InspectionDetailedView,
    feature: "inspections",
    key: "inspections/2",
  },
  {
    path: "/application/:appId/scars",
    name: "SCARs",
    component: ScarOverview,
    feature: "scars",
    key: "scars/1",
  },
  {
    path: "/application/:appId/scars/:scarKey/:activeKey",
    name: "SCARs",
    component: SCARDetailedView,
    feature: "scars",
    key: "scars/2",
  },
  // {
  //   path: "/application/:appId/asp",
  //   name: "App Settings Page",
  //   component: AppSettings,
  // },
  {
    path: "/application/:appId/calibrations",
    name: "Calibrations",
    component: Calibration,
    feature: "calibrations",
    key: "calibrations/1",
  },

  {
    path: "/application/:appId/calibrations/:instrumentId",
    name: "Inspections",
    component: CalibrationItem,
    feature: "calibrations",
    key: "calibrations/2",
  },
  {
    path: "/application/:appId/supplier-ratings",
    name: "Supplier Ratings",
    component: SupplierRating,
    feature: "supplier-ratings",
    key: "supplier-ratings/1"
  },
  {
    path: "/application/:appId/supplier-ratings/:supplierId",
    name: "Supplier Ratings",
    component: SupplierRatingDetailedView,
    feature: "supplier-ratings",
    key: "supplier-ratings/2"
  },
  {
    path: "/application/:appId/afr",
    name: "AFR",
    component: AFR,
    feature: "afr",
    key: "afr/1"
  }
];