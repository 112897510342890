import { React, useEffect, useState } from "react";
import {
  DEFAULT_OFFSET,
  PAGE_SIZE,
} from "../../../../../clients/api-constants.js";
import { getSuppliersAPI } from "../../../apiCalls/suppliersApis.js";
import { getSupplierApi } from "../../../../../common/apis/commonApis.js";
import SupplierBody from "./components/body.js";
import { SupplierModal } from "../supplierModal/supplierModal.js";

export default function SupplierTable(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);

  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [supplierDetails, setSupplierDetails] = useState({});

  const fetchSuppliers = async (pageOffset = offset, pageLimit = PAGE_SIZE) => {
    setIsLoading(true);
    let response = await getSuppliersAPI(pageOffset, pageLimit);
    if (!response.isError) {
      setTotalCount(response?.totalCount);
      setSupplierList(response);
    }
    setIsLoading(false);
  };

  const onSupplierClick = async (id) => {
    let response = await getSupplierApi(id);
    if (!response?.isError) {
      setSupplierDetails(response?.data);
      setIsEdit(true);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchSuppliers(0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchSuppliers(currentPageSize * (page - 1), currentPageSize);
    }
  };

  const onRefresh = () => {
    fetchSuppliers(offset);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <>
      <SupplierModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        supplierDetails={supplierDetails}
        fetchSuppliersData={fetchSuppliers}
      />
      <div>
        <SupplierBody
          supplierListData={supplierList?.data}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          setShowModal={setShowModal}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
          onSupplierClicked={onSupplierClick}
          setIsEdit={setIsEdit}
        />
      </div>
    </>
  );
}
