import { Button, DatePicker, Form, Input, Modal } from "antd";
import dayjs from "dayjs";
import SearchSelect from "../../../../../../IQCActivity/components/inspectionTable/components/searchSelect";

function SCARTaskModal(props) {
    const { open, setOpen, createSCARTask, selectedTask, setSelectedTask,
        usersList, totalCountUsers, usersLoading, fetchUsers, editSCARTask,
        sheetList, totalCountSheet, sheetsLoading, fetchSheets } = props;

    const [form] = Form.useForm();

    if (selectedTask) {
        form.setFieldsValue({
            ...selectedTask,
            dueDateTime: selectedTask?.dueDateTime ? dayjs(selectedTask?.dueDateTime) : null,
            linkedSheet: selectedTask?.meta?.data?.sheetIds[0],
            assignee: selectedTask?.taskAssignees[0]?.assigneeId
        });
    }

    const handleOk = (values) => {
        setOpen(false);
        form.resetFields();
        const data = {
            ...values,
            data: {
                sheetIds: [values.linkedSheet],
            },
            taskAssignees: [values.assignee]
        };
        !selectedTask ? createSCARTask(data) : editSCARTask({ ...data, id: selectedTask?.id });
    };

    const handleCancel = () => {
        setSelectedTask(undefined);
        form.resetFields();
        setOpen(false);
    };

    return (
        <>
            <Modal
                title={selectedTask ? 'Update Task' : "New Task"}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[

                ]}
                styles={
                    { footer: { textAlign: 'center' } }
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOk}
                >
                    <Form.Item label="Task Name" name="title" required  rules={[
                                {
                                    required: true,
                                    message: 'Please enter task name!'
                                }
                            ]}>
                        <Input placeholder="Task Name" />
                    </Form.Item>
                    <Form.Item label="Assign to" name="assignee" required  rules={[
                                {
                                    required: true,
                                    message: 'Please select valid assignee!'
                                }
                            ]}>
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={usersLoading}
                            placeholder="Select Assignee"
                            options={usersList}
                            filterOption={false}
                            loadMore={fetchUsers}
                            hasMoreData={totalCountUsers > usersList?.length ? true : false}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item label="Due by" name="dueDateTime" initialValue={dayjs()} >
                        <DatePicker style={{ width: "100%" }} showTime/>
                    </Form.Item>
                    <Form.Item label="Linked Inspection Sheet(s)" name="linkedSheet" required rules={[{
                        required: true,
                        message: 'Please select inspection sheet!'
                    }]}>
                        <SearchSelect
                            showSearch
                            allowClear
                            loading={sheetsLoading}
                            placeholder="Select Inspection sheet"
                            options={sheetList}
                            filterOption={false}
                            loadMore={fetchSheets}
                            hasMoreData={totalCountSheet > sheetList?.length ? true : false}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button key="submit" type="primary" htmlType="submit" >
                            {selectedTask ? 'Update Task' : 'Create Task'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
export default SCARTaskModal;