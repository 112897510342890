import { Button } from "antd";
export const machineTableCols = (onMachineClick, setShowModal) => [
  {
    title: "Machine Name",
    dataIndex: ["name"],
    key: "name",
    width: 150,
    minWidth: 100,
    fixed: "left",
    ellipsis: true,
    render: (text, record) => (
      <Button
        type="link"
        onClick={() => {
          onMachineClick(record?.id);
          setShowModal(true);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: "Machine Code",
    dataIndex: ["code"],
    key: "code",
    width: 150,
    minWidth: 100,
    ellipsis: true,
  },
  {
    title: "Capability(s)",
    dataIndex: [""],
    key: "make",
    width: 120,
    minWidth: 100,
  },
  {
    title: "Linked Stations",
    dataIndex: [""],
    key: "name",
    width: 100,
    minWidth: 100,
  },
];
