import React from "react";
import styles from "../styles/instrumentsTable.module.css";
import SearchInstruments from "./searchInstruments";

export default function InstrumentsHeader(props) {
  const { searchInstruments } = props;

  return (
    <>
      <div>
        <div className={styles.headerRow2}>
          <div className={styles.headerRow2Col1}>
            <div className={styles.headerRow2Col1Container}>
              <SearchInstruments
                className={styles.headerRow2Col1Item1}
                showSearch
                allowClear
                searchInstruments={searchInstruments}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
