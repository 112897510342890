import { ConfigProvider, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  showToastError,
  showToastSuccess,
} from "../../../../../../common/toasters/toasterMessages";
import {
  updateMachine,
  createMachine,
} from "../../../../apiCalls/productsApis";
import SearchSelect from "../searchSelect";
import styles from "./styles/machineModal.module.css";

export const MachineModal = (props) => {
  const { showModal, setShowModal, fetchMachines, isEdit, machineDetails } =
    props;

  const SEARCH_MORE_TYPE = "searchMore";

  const [showButton, setShowButton] = useState(false);

  const [stationsData, setStationsData] = useState([]);
  const [totalStations, setTotalStations] = useState(0);
  const [stationsLoading, setStationsLoading] = useState(false);

  const [form] = Form.useForm();

  const createUpdateMachine = async (body) => {
    let response;
    if (isEdit) {
      response = await updateMachine(machineDetails?.id, body);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchMachines();
        showToastSuccess(response?.displayableMessage);
      }
    } else {
      response = await createMachine(body);
      if (response.isError) {
        showToastError(response?.displayableMessage);
      } else {
        fetchMachines();
        showToastSuccess(response?.displayableMessage);
      }
    }
  };

  const handleSave = async (values) => {
    setShowButton(false);
    let body = {
      name: values?.machineName,
      code: values?.machineCode,
    };
    await createUpdateMachine(body);
    setShowModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  /// stations api ///
  // const handleStationSearch = async (searchVal) => {
  //   setStationsData([]);
  //   await fetchStations(searchVal);
  // };

  // const fetchStations = async (value, type = "") => {
  //   setStationsLoading(true);
  //   let stationsResponse = await getStationsApi(
  //     type === SEARCH_MORE_TYPE ? stationsData.length : 0,
  //     value,
  //     10
  //   );
  //   if (stationsResponse.isError) {
  //     showToastError(stationsResponse.displayableMessage);
  //   } else {
  //     setTotalStations(stationsResponse.totalCount);
  //     let dataList = type === SEARCH_MORE_TYPE ? stationsData : [];
  //     stationsResponse.data.forEach((station) => {
  //       dataList.push({ value: station.id, label: station.name });
  //     });
  //     setStationsData(dataList);
  //   }
  //   setStationsLoading(false);
  // };

  // const fetchMoreStations = async () => {
  //   fetchStations("", SEARCH_MORE_TYPE);
  // };

  useEffect(() => {
    if (isEdit) {
      setShowButton(false);
      let values = {
        machineName: machineDetails?.name,
        machineCode: machineDetails?.code,
      };
      form.setFieldsValue(values);
    } else {
      form.resetFields();
      setShowButton(true);
    }
  }, [isEdit, machineDetails, showModal]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: "#7d7676",
          colorBorder: "#dadada",
        },
      }}
    >
      <Modal
        title={isEdit ? "Edit Machine" : "New Machine"}
        open={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        style={{ top: 20 }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: "none" },
        }}
        width={"640px"}
      >
        <Form
          onFinish={handleSave}
          form={form}
          layout="vertical"
          name="control-hooks"
        >
          <Form.Item
            className="mb-3"
            label="Machine Name"
            name="machineName"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Machine Name"
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          <Form.Item
            className="mb-3"
            name="machineCode"
            label="Machine Code"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Machine Code"
              onFocus={() => setShowButton(true)}
            />
          </Form.Item>
          {/* <Form.Item
            className="mb-3"
            name="location"
            label="Location"
            rules={[{ required: true }]}
          >
            <SearchSelect
              showSearch
              allowClear
              loading={stationsLoading}
              placeholder="Location"
              options={stationsData}
              filterOption={false}
              onSearch={handleStationSearch}
              onClear={fetchStations}
              loadMore={fetchMoreStations}
              hasMoreData={totalStations > stationsData.length}
              onFocus={() => setShowButton(true)}
            />
          </Form.Item> */}

          <Form.Item className={styles.btnFormItem}>
            {showButton && (
              <button type="submit" className={styles.createBtn}>
                {isEdit ? "Save changes" : "Create Machine"}
              </button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};
