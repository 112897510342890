import { dateTimeFormat } from "../../../../../utils";
import styles from "../styles/sheetTable.module.css";

export const sheetTableColumns = (params, navigate, suppliersData, productsData, icPartsData, stationsData) => [
    {
        title: 'Sheet Name',
        width: 150,
        minWidth: 110,
        dataIndex: ['name'],
        key: 'name',
        ellipsis: true,
        render: (text, record) => 
        <a className={styles.sheetNameCol} href={
                `/application/${params.appId}/sheets/${record.id}`
        }>
           {text}
        </a>,
    },
    {
        title: 'Sheet Code',
        width: 120,
        minWidth: 100,
        dataIndex: ['code'],
        key: 'code',
    },
    {
        title: 'Station',
        dataIndex: ['meta', 'stationId'],
        width: 120,
        minWidth: 120,
        key: 'station',
        ellipsis: true,
        render: (text) =>  stationsData[text]?.name,
    },
    {
        title: 'Supplier',
        dataIndex: ['meta', 'supplierId'],
        key: 'supplier',
        width: 120,
        minWidth: 120,
        ellipsis: true,
        render: (text) => suppliersData[text]?.name,
    },
    {
        title: 'Product',
        dataIndex: ['meta', 'productId'],
        key: 'product',
        width: 120,
        minWidth: 120,
        ellipsis: true,
        render: (text) => productsData[text]?.name,
    },
    {
        title: 'IC Part',
        dataIndex: ['meta', 'incomingPartId'],
        key: 'icPart',
        width: 120,
        minWidth: 120,
        ellipsis: true,
        render: (text) => icPartsData[text]?.name,
    },
    {
        title: 'Created At',
        dataIndex: ['created_at'],
        key: 'createdAt',
        width: 170,
        minWidth: 170,
        type: 'dateTime',
        render: (text) => text ? dateTimeFormat(text) : ""
    }
];