import React, { useEffect, useState } from "react";
import { getIncomingPartsAPI } from "../../../common/apis/commonApis";
import { showToastError } from "../../../common/toasters/toasterMessages";
import styles from "../components/sheetTable/styles/sheetTable.module.css";
import SearchSelect from "../components/sheetTable/components/searchSelect";

export default function ICPartSearchSelect(props) {
    const SEARCH_MORE_TYPE = "searchMore";

    const { onFilterChange, selected, supplierId, className } = props;
    const [selectedEntry, setSelectedEntry] = useState(selected);
    const [listLoading, setListLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listCount, setListCount] = useState(0);

    const handleChange = (record) => {
        setSelectedEntry(record);
        onFilterChange(record);
    };

    const handleSearch = async (searchVal) => {
        setList([]);
        await fetchList(searchVal);
    }

    const fetchList = async (value, type = "") => {
        setListLoading(true);
        let listResponse = await getIncomingPartsAPI(type === SEARCH_MORE_TYPE ? list.length : 0, value, 10, supplierId);
        if (listResponse.isError) {
            showToastError(listResponse.displayableMessage);
        } else {
            setListCount(listResponse.totalCount)
            let dataList = type === SEARCH_MORE_TYPE ? list : [];
            listResponse.data.forEach(entry => {
                dataList.push({ value: entry.id, label: entry.name });
            });
            setList(dataList);
        }
        setListLoading(false);
    };

    const fetchMoreList = async () => {
        void fetchList("", SEARCH_MORE_TYPE);
    }

    useEffect(() => {
        void fetchList();
    }, []);

    return (<SearchSelect className={className ? className : styles.headerRow2Col1Item1}
        showSearch
        allowClear
        loading={listLoading}
        value={selectedEntry}
        placeholder="IC Part"
        onChange={handleChange}
        options={list}
        filterOption={false}
        onSearch={handleSearch}
        onClear={fetchList}
        loadMore={fetchMoreList}
        hasMoreData={listCount > list.length}
    />)
}