import { useEffect, useState } from "react";
import EmptyTableMsgs from "../../../common/components/emptyTableMsgs/emptyTableMsgs";
import WorkroomPagination from "workroom-pagination";
import { PAGE_SIZE } from "../../../clients/api-constants";
import { Col, Row } from "react-bootstrap";
import StationCard from "../../stations/components/stationCard/ui/stationCard";

export default function StationsList(props) {
    const {
        data,
        getDetailedStation,
        setShowStationDetailsModal,
        getStationList,
        totalCount,
        loadingData,
    } = props;
    const [currentPage, setCurrentPage] = useState(1);


    // useEffect(() => {
    //     getStationList(0);
    // }, []);

    const handlePageChange = (pageNumber) => {
        getStationList((PAGE_SIZE * (pageNumber - 1)));
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {!loadingData &&
                (<div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                    <div style={{ flex: 1, overflowY: 'auto' }}>
                        <div style={{ maxHeight: '80vh' }}>
                            <Row>
                                {data?.length === 0 ? (
                                    <EmptyTableMsgs
                                        isTable={false}
                                        emptyMessage="No stations"
                                    />
                                ) : (
                                    data?.map((item, index) => (
                                        <Col
                                            onClick={() => {
                                                getDetailedStation(item);
                                                setShowStationDetailsModal(true);
                                            }}
                                            key={item.id}
                                            md={6}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <StationCard value={{ station: item }} />
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </div>
                    </div>
                    <div style={{ height: 'auto', position: 'sticky', bottom: 0, padding: '10px' }}>
                        <WorkroomPagination currentPage={currentPage} totalPages={Math.ceil(totalCount / PAGE_SIZE)} onPageChange={handlePageChange} />
                    </div>
                </div>)}
        </>
    );
}
