import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useRef, useReducer } from "react";
import PrimaryButton from "../../../../../common/crunches/primaryButton/primaryButton.js";
import ImageAddLineIcon from "remixicon-react/ImageAddLineIcon";
import { DataFetchReducers } from "../../../../../common/states/dataFetch/dataFetchReducers.js";
import { dataFetchActionType } from "../../../../../common/states/dataFetch/dataFetchActionType.js";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Image from "react-bootstrap/Image";
import Multiselect from "multiselect-react-dropdown";
import { uploadImage } from "../../../../../common/components/uploadImage/uploadImage.js";

export default function AddStationModal(props) {
  const stationMachines = props.value.machinesList;

  const inputRef = useRef(null);
  const [stationName, setStationName] = useState("");
  const [stationDescription, setStationDescription] = useState("");
  const [stationCode, setStationCode] = useState("");
  const [stationImage, setStationImage] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [uploadStatus, dispatchUploadStatus] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const onCloseModal = () => {
    setStationName("");
    setStationCode("");
    setStationDescription("");
    dispatchUploadStatus({
      type: dataFetchActionType.data,
      data: null,
    });
    setSelectedMachines([]);
    setStationImage([]);
    props.value.onHide();
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const uploadImages = async () => {
    const folderPath = "stations";
    uploadImage(inputRef, setStationImage, dispatchUploadStatus, folderPath);
  };

  return (
    <Modal
      show={props.value.show}
      onHide={onCloseModal}
      style={{ marginTop: "80px" }}
    >
      <Modal.Header closeButton style={{ border: "none" }}>
        <Modal.Title className="boldFont">
          <span className="modalHeading">New Station Details</span>
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.value.onSubmitModal({
            name: stationName,
            description: stationDescription,
            code: stationCode,
            images: stationImage,
            stationMachines: selectedMachines,
          });
          onCloseModal();
        }}
      >
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              onChange={(e) => setStationName(e.target.value)}
              placeholder="Station Name"
              required
            />
            <label htmlFor="floatingInputCustom" style={{ color: "#7D7676" }}>
              Station Name
            </label>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              onChange={(e) => setStationDescription(e.target.value)}
              placeholder="Station Description"
            />
            <label htmlFor="floatingInputCustom" style={{ color: "#7D7676" }}>
              Station Description
            </label>
          </Form.Floating>

          <Form.Floating className="mb-3">
            <Form.Control
              onChange={(e) => setStationCode(e.target.value)}
              placeholder="Station Code"
            />
            <label htmlFor="floatingInputCustom" style={{ color: "#7D7676" }}>
              Station Code
            </label>
          </Form.Floating>
          <div
            className="mb-3"
            style={{
              border: "1px solid #dadada",
              borderRadius: "7px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              ref={inputRef}
              onChange={uploadImages}
              className="d-none"
              type="file"
            />
            {uploadStatus.data ? (
              <div>
                <div
                  onClick={() => {
                    setStationImage([]);
                    dispatchUploadStatus({
                      type: dataFetchActionType.data,
                      data: null,
                    });
                  }}
                  style={{
                    marginBottom: "-13px",
                    zIndex: 10,
                    padding: "2.5px",
                    borderRadius: "50px",
                    background: "#dadada",
                    float: "right",
                    width: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseLineIcon size={16} style={{ marginBottom: "7px" }} />
                </div>

                <div>
                  <Image
                    onClick={handleUpload}
                    src={stationImage[0]}
                    rounded
                    style={{
                      maxHeight: "100%",
                      width: "200px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ) : (
              <button
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "10px",
                  padding: "10px",
                  background: "white",
                }}
                type="button"
                onClick={handleUpload}
                className="light"
              >
                {uploadStatus.loading ? (
                  <div>
                    <ImageAddLineIcon
                      style={{
                        marginRight: "10px",
                        marginBottom: "3px",
                        color: "#7D7676",
                      }}
                    />
                    Uploading...
                  </div>
                ) : (
                  <div style={{ color: "#7D7676" }}>
                    <ImageAddLineIcon
                      style={{
                        marginRight: "10px",
                        marginBottom: "3px",
                      }}
                    />
                    Upload Station Image
                  </div>
                )}
              </button>
            )}
          </div>
          <Multiselect
            style={{ padding: "1rem" }}
            options={stationMachines?.map((item, index) => {
              return { name: item.name, id: item.id };
            })}
            closeIcon="cancel"
            placeholder="Linked Machines"
            showArrow={true}
            closeOnSelect={false}
            displayValue="name"
            onRemove={(list, item) => {
              const index = selectedMachines?.indexOf(item.id);

              if (index > -1) {
                selectedMachines?.splice(index, 1);
              }
            }}
            onSelect={(list, item) => {
              setSelectedMachines([...selectedMachines, item.id]);
            }}
          />
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center", border: "none" }}>
          <PrimaryButton
            value={{
              type: "submit",
              child: "Create Station",
              buttonStyle: "modalCTA",
            }}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
