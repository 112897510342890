import { Dropdown } from "react-bootstrap";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import ArticleLineIcon from "remixicon-react/ArticleLineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import More2LineIcon from "remixicon-react/More2LineIcon";
import styles from "./styles/dotOptionsDropdown.module.css";

export default function DotOptionsDropDownCalibration(props) {
  const { status, show, showSettings } = props.values;

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-autoclose-true"
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          display: "table-cell",
          verticalAlign: "middle",
        }}
      >
        <div
          style={{
            background: "none",
            border: "none",
            color: "#7D7676",
          }}
        >
          <More2LineIcon />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1">
          <div style={{ cursor: "pointer" }} onClick={show}>
            <AddLineIcon size="20px" className={styles.iconStyling} />
            {props?.values?.title ? props.values.title : "Generate report"}
          </div>
        </Dropdown.Item>
        <hr style={{ margin: "10px" }} />
        <Dropdown.Item eventKey="2" disabled>
          <div
            className={status === "OPEN" && styles.genereteReport}
            style={{ cursor: "text" }}
            onClick={showSettings}
          >
            <ExternalLinkLineIcon
              size="20px"
              className={styles.iconStyling}
              style={{ color: "#c4c4c4" }}
            />
            {props?.values?.name ? props.values.name : "Generate report"}
          </div>
        </Dropdown.Item>
        <hr style={{ margin: "10px" }} />
        <Dropdown.Item eventKey="3" disabled>
          <div>
            <ArticleLineIcon
              size="20px"
              className={styles.iconStyling}
              style={{ color: "#c4c4c4" }}
            />
            Generate Report
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
