import styles from "./styles/inspectionDetails.module.css"
import { KeyDetailsContainer } from "./components/keyDetailsContainer";
import { OtherDetailsContainer } from "./components/otherDetailsContainer";
import { Skeleton } from 'antd';

export default function InspectionDetails (props) {

  const {isLoading, inspectionDetails, fetchInspectionDetailsByKey} = props.values;

  return (
    <>
      {
        isLoading 
        ? <Skeleton /> 
        : <div>
            <div className={styles.detailsContainer}>
              <KeyDetailsContainer 
                values={{
                  inspectionDetails,
                  fetchInspectionDetailsByKey
                }}
              />  
            </div>
            <div className={styles.detailsContainer}>
              <OtherDetailsContainer 
                inspectionDetails={inspectionDetails}
              />  
            </div>
          </div>
      }
    </>
  );
}