import React, { useState, useEffect, useReducer } from "react";
import PrimaryButton from "../../../common/crunches/primaryButton/primaryButton.js";
import Container from "react-bootstrap/Container";
import ProductTable from "../components/productTable/ui/productTable.js";
import AddProductCategoryModal from "../components/addProductModal/ui/addProductCategoryModal.js";
import AddProductModal from "../components/addProcessModal/ui/addProductModal.js";
import AddLineIcon from "remixicon-react/AddLineIcon";
import { DataFetchReducers } from "../../../common/states/dataFetch/dataFetchReducers.js";
import { dataFetchActionType } from "../../../common/states/dataFetch/dataFetchActionType.js";
import ToggleButtonMaster from "../components/toggleButtonMaster.js";
import AddStationModal from "../../stations/components/addStationModal/ui/addStationModal.js";
import StationDetailsModal from "../../stations/components/stationDetailsModal/ui/stationDetailsModal.js";
import {
  showToastError,
  showToastSuccess,
} from "../../../common/toasters/toasterMessages.js";
import Loader from "../../../common/loaders/clipLoader.js";
import EquipmentTable from "../components/equipmentTable/equipmentTable.js";
import MachineTable from "../components/machineTable/machineTable.js";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants.js";
import StationsList from "../components/stationsList.js";
import {
  createOperationApi,
  createProductApi,
  createProductCategoryApi,
  createStationApi,
  editOperationApi,
  editProductApi,
  editProductCategoryApi,
  getDetailedStationApi,
  getOperationDetailsApi,
  getOperationTypeApi,
  getOperationsApi,
  getProductCategoryDetailsApi,
  getProductCategoryListApi,
  getProductCategorySpecificDetailsApi,
  getProductDetailsApi,
  getProductListApi,
  getStationListApi,
  updateStationApi,
} from "../apiCalls/productsApis.js";

export default function Assets() {
  const [stations, setStations] = useState(false);
  const [machines, setMachines] = useState(false);
  const [operations, setOperations] = useState(false);
  const [products, setProducts] = useState(false);
  const [equipment, setEquipment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isEditProductCategoryModal, setIsEditProductCategoryModal] =
    useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  // const [editOperationModal, setEditOperationModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [selectedStation, setSelectedStation] = useState();
  const [showStationModal, setShowStationModal] = useState(false);
  const [showStationDetailsModal, setShowStationDetailsModal] = useState(false);
  const [machinesList, setMachinesList] = useState({});
  const [stationList, setStationList] = useState({});

  // const [stationList, dispatchStationList] = useReducer(DataFetchReducers, {
  //   data: null,
  //   loading: false,
  //   error: null,
  // });

  const [productCategoryList, dispatchProductCategoryList] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );
  const [productListDetails, dispatchProductListDetails] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );
  const [productDetails, dispatchProductDetails] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );
  const [productCategoryDetails, dispatchProductCategoryDetails] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );
  const [operationsList, dispatchOperationsList] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );
  const [operationTypesList, dispatchOperationTypesList] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );

  const [operationDetails, dispatchOperationDetails] = useReducer(
    DataFetchReducers,
    {
      data: null,
      loading: false,
      error: null,
    }
  );

  useEffect(() => {
    (async () => {
      await getProductCategoryList();
      await getOperationType();
      await getStationList();
    })();
  }, []);

  /* ----- PRODUCTS API CALLS ----- */
  const getProductCategoryList = async (value) => {
    let response = await getProductCategoryListApi(value);

    dispatchProductCategoryList({
      type: dataFetchActionType.data,
      data: response?.data,
    });

    return response[0];
  };

  const getProductList = async (value) => {
    let response = await getProductListApi(
      value,
      productCategoryDetails?.data?.id
    );

    dispatchProductListDetails({
      type: dataFetchActionType.data,
      data: response?.data,
    });
  };

  const getProductCategorySpecificDetails = async (id) => {
    let response = await getProductCategorySpecificDetailsApi(id);

    dispatchProductCategoryDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });
  };

  const createProductCategory = async (values) => {
    let response = await createProductCategoryApi(values);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const editProductCategory = async (values) => {
    let response = await editProductCategoryApi(
      values,
      productCategoryDetails?.data?.id
    );
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const getProductCategoryDetails = async (productId) => {
    dispatchProductListDetails({
      type: dataFetchActionType.loading,
    });
    let response = await getProductCategoryDetailsApi(productId);

    dispatchProductListDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });
  };

  const getProductDetails = async (id) => {
    dispatchProductDetails({
      type: dataFetchActionType.loading,
    });
    let response = await getProductDetailsApi(id);

    dispatchProductDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });
  };
  const editProduct = async (values) => {
    let body = {
      name: values.name,
      code: values.code,
      description: values.description,
      images: values.image,
      productCategoryId: productCategoryDetails?.data?.id,
    };

    let response = await editProductApi(body, productDetails?.data?.id);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const createProduct = async (values) => {
    let body = {
      name: values.name,
      code: values.code,
      description: values.description,
      images: values.image,
      productCategoryId: productCategoryDetails?.data?.id,
    };

    let response = await createProductApi(body);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  /* ----- OPERATIONS API CALLS ----- */
  const getOperations = async () => {
    let response = await getOperationsApi();
    dispatchOperationsList({
      type: dataFetchActionType.data,
      data: response?.data,
    });
  };

  const getOperationType = async () => {
    let response = await getOperationTypeApi();
    dispatchOperationTypesList({
      type: dataFetchActionType.data,
      data: response?.data,
    });
  };

  const createOperation = async (values) => {
    let typeId = operationTypesList.data.filter((item) => {
      if (item.name === values.operationType) return item.id;
    });
    let body = {
      name: values.name,
      operationTypeId: typeId[0].id,
    };
    let response = await createOperationApi(body);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const editOperation = async (values) => {
    let response = await editOperationApi(values, operationDetails?.data?.id);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const getOperationDetails = async (id) => {
    let response = await getOperationDetailsApi(id);

    dispatchOperationDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });
  };

  /* ----- STATIONS API CALLS ----- */
  const getStationList = async (
    pageOffset = DEFAULT_OFFSET,
    limit = PAGE_SIZE
  ) => {
    setIsLoading(true);
    let response = await getStationListApi(pageOffset, limit);
    setStationList(response);
    // dispatchStationList({
    //   type: dataFetchActionType.data,
    //   data: response.data,
    // });
    setIsLoading(false);
  };

  const getDetailedStation = async (item) => {
    let response = await getDetailedStationApi(item?.id);
    setSelectedStation(response.data);
  };

  const createStation = async (values) => {
    let response = await createStationApi(values);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const updateStation = async (values) => {
    let response = await updateStationApi(values, selectedStation.id);
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  return (
      <Container fluid={true}>
        <div
            style={{
              marginTop: "2.1rem",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
        >
          <ToggleButtonMaster
              setStations={setStations}
              setMachines={setMachines}
              setOperations={setOperations}
              setProducts={setProducts}
              setEquipment={setEquipment}
          />
          {(products || stations) && (
              <PrimaryButton
                  value={{
                    child: (
                        <>
                          <AddLineIcon
                              style={{
                                marginRight: "5px",
                                marginTop: "-4px",
                              }}
                          />
                          {(() => {
                            if (products) return "New Category";
                            else if (operations) return "New Operation";
                            else if (stations) return "New Station";
                          })()}
                        </>
                    ),

                    onClick: () => {
                      if (products) return setShowProductModal(true);
                      else if (operations) return setShowOperationModal(true);
                      else if (stations) return setShowStationModal(true);
                    },
                  }}
              />
          )}
        </div>
        <hr/>
        <div>{isLoading && <Loader/>}</div>
        {products && (
            <>
              <AddProductCategoryModal
                  value={{
                    productCategoryDetails: productCategoryDetails.data,
                    isEdit: isEditProductCategoryModal,
                    onEditModal: async (values) => {
                      await editProductCategory(values);
                      await getProductCategoryList();
                      await getProductCategoryDetails(
                          productCategoryDetails?.data?.id
                      );
                      await getProductCategorySpecificDetails(
                          productCategoryDetails?.data?.id
                      );
                    },
                    onSubmitModal: async (values) => {
                      await createProductCategory(values);
                      await getProductCategoryList();
                    },
                    show: showProductModal,
                    onHide: () => {
                      setShowProductModal(false);
                    },
                    removeEdit: () => {
                      setIsEditProductCategoryModal(false);
                    },
                  }}
              />
              <AddProductModal
                  value={{
                    isEdit: editProductModal,
                    productDetails: productDetails.data,
                    onSubmitModal: async (values) => {
                      await createProduct(values);
                      setShowProcessModal(false);
                      await getProductCategoryDetails(
                          productCategoryDetails?.data?.id
                      );
                    },
                    onEditModal: async (values) => {
                      await editProduct(values);
                      setShowProcessModal(false);
                      await getProductCategoryDetails(
                          productCategoryDetails?.data?.id
                      );
                    },
                    show: showProcessModal,
                    onHide: () => {
                      setShowProcessModal(false);
                    },
                    removeEdit: () => {
                      setEditProductModal(false);
                    },
                    stationList: stationList,
                  }}
              />
              {!isLoading && (
                  <ProductTable
                      setEditProductModal={setEditProductModal}
                      value={{
                        productCategoryList: productCategoryList,
                        productListDetails: productListDetails.data,
                        productCategoryDetails: productCategoryDetails.data,
                        onSelectProductCategory: (id) => {
                          getProductCategoryDetails(id);
                          getProductCategorySpecificDetails(id);
                        },
                        onShowProcessModal: (id) => {
                          setShowProcessModal(true);
                          getProductDetails(id);
                        },
                        onShowProductModal: () => {
                          setShowProductModal(true);
                          setIsEditProductCategoryModal(true);
                        },
                        searchProductCategory: (value) => {
                          getProductCategoryList(value);
                        },
                        searchProduct: (value) => {
                          getProductList(value);
                        },
                      }}
                  />
              )}
            </>
        )}
        {stations && (
            <>
              <AddStationModal
                  value={{
                    machinesList: machinesList?.data,
                    onSubmitModal: async (values) => {
                      await createStation(values);
                      setShowStationModal(false);
                      await getStationList();
                    },
                    show: showStationModal,
                    onHide: () => {
                      setShowStationModal(false);
                    },
                  }}
              />
              <StationDetailsModal
                  value={{
                    machinesList: machinesList?.data,
                    selectedStation: selectedStation,
                    onSubmitModal: async (values) => {
                      await updateStation(values);
                      await getStationList();
                    },
                    show: showStationDetailsModal,
                    onHide: () => {
                      setShowStationDetailsModal(false);
                    },
                  }}
              />
              <StationsList
                  data={stationList?.data}
                  getDetailedStation={getDetailedStation}
                  setShowStationDetailsModal={setShowStationDetailsModal}
                  getStationList={getStationList}
                  totalCount={stationList?.totalCount}
                  loadingData={isLoading}
              />
            </>
        )}
        {/*{operations && (*/}
        {/*  <>*/}
        {/*    <OperationsTable*/}
        {/*      getOperationAPI={getOperations}*/}
        {/*      getOperationTypeAPI={getOperationType}*/}
        {/*      getOperationDetails={getOperationDetails}*/}
        {/*      data={operationsList.data}*/}
        {/*      setEditOperationModal={setEditOperationModal}*/}
        {/*    />*/}
        {/*    <AddOperationsModal*/}
        {/*      value={{*/}
        {/*        operationDetails: operationDetails.data,*/}
        {/*        isEdit: editOperationModal,*/}
        {/*        onSubmitModal: async (values) => {*/}
        {/*          await createOperation(values);*/}
        {/*          await getOperations();*/}
        {/*        },*/}
        {/*        show: showOperationModal,*/}
        {/*        setShowOperationModal: setShowOperationModal,*/}
        {/*        typesList: operationTypesList?.data,*/}
        {/*        onEditModal: async (values) => {*/}
        {/*          await editOperation(values);*/}
        {/*          setShowOperationModal(false);*/}
        {/*          await getOperations();*/}
        {/*        },*/}
        {/*        onHide: () => {*/}
        {/*          setShowOperationModal(false);*/}
        {/*          setEditOperationModal(false);*/}
        {/*        },*/}
        {/*        removeEdit: () => {*/}
        {/*          setEditOperationModal(false);*/}
        {/*        },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}
        {machines && <MachineTable/>}

        {equipment && <EquipmentTable/>}
      </Container>
  );
}
