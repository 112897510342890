import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import EyeLineComponent from "./eyeLine/eyeline";
import { useState, useEffect } from "react";
import PrimaryButton from "../../../common/crunches/primaryButton/primaryButton";

export default function ChangePassword(props) {
  const { show, setShowIncomingPartModel } = props.value;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [closeModal, setCloseModal] = useState(false);

  const onCloseModal = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setCloseModal(true);
    setShowIncomingPartModel(false);
  };

  useEffect(() => {
    setShowButton(true);
  }, [show]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
      props.value.onSubmitModal({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
      onCloseModal();
    }
  };

  return (
    <Modal show={show} onHide={onCloseModal} style={{ marginTop: "150px" }}>
      <Modal.Header closeButton style={{ border: "none" }}>
        <Modal.Title className="boldFont">
          <span className="modalHeading">Change password</span>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current password"
              required
              type={showPasswordOld ? "text" : "password"}
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Current password
            </label>
          </Form.Floating>
          <EyeLineComponent
            showPassword={showPasswordOld}
            setShowPassword={setShowPasswordOld}
            top="13.5%"
            right="35"
          />
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New password"
              required
              type={showPasswordNew ? "text" : "password"}
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              New password
            </label>
          </Form.Floating>
          <EyeLineComponent
            showPassword={showPasswordNew}
            setShowPassword={setShowPasswordNew}
            top="42.5%"
            right="35"
          />
          <Form.Floating className="mb-3">
            <Form.Control
              onFocus={() => setShowButton(true)}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              required
              type={showPasswordConfirm ? "text" : "password"}
            />
            <label
              htmlFor="floatingInputCustom"
              style={{ color: "#7D7676", width: "115%" }}
            >
              Confirm new password
            </label>
          </Form.Floating>
          <EyeLineComponent
            showPassword={showPasswordConfirm}
            setShowPassword={setShowPasswordConfirm}
            top="70.5%"
            right="35"
          />
        </Modal.Body>
        {!passwordsMatch && (
          <p style={{ color: "red", textAlign: "center" }}>
            New password and confirm password do not match.
          </p>
        )}
        {showButton ? (
          <Modal.Footer style={{ justifyContent: "center", border: "none" }}>
            <PrimaryButton
              value={{
                type: "submit",
                child: "Submit ",
                buttonStyle: "modalCTA",
              }}
            />
          </Modal.Footer>
        ) : null}
      </Form>
    </Modal>
  );
}
