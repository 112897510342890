import { DatePicker, Divider, Table } from "antd";
import SearchSelect from "../../../../templates/IQCActivity/components/inspectionTable/components/searchSelect";
import { useEffect, useState } from "react";
import { getSuppliersAPI } from "../../../../common/apis/commonApis";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import styles from "../../styles/supplierRating.module.css";

export default function SupplierRatingFilter(props) {

  const { setSelectedSupplierId, handleClear } = props;
  const SEARCH_MORE_TYPE = "searchMore";

  const [suppliersLoading, setSuppliersLoading] = useState(false);
  const [suppliersData, setSuppliersData] = useState([]);
  const [totalSuppliers, setTotalSuppliers] = useState(0);


  const fetchSuppliers = async (value, type = "") => {
    setSuppliersLoading(true);
    let suppliersResponse = await getSuppliersAPI(type === SEARCH_MORE_TYPE ? suppliersData.length : 0, value, 10);
    if (suppliersResponse.isError) {
      showToastError(suppliersResponse.displayableMessage);
    } else {
      setTotalSuppliers(suppliersResponse.totalCount)
      let dataList = type === SEARCH_MORE_TYPE ? suppliersData : [];
      suppliersResponse.data.forEach(supplier => {
        dataList.push({ value: supplier.id, label: supplier.name });
      });
      setSuppliersData(dataList);
    }
    setSuppliersLoading(false);
  };
  const handleSupplierSearch = async (searchVal) => {
    setSuppliersData([]);
    await fetchSuppliers(searchVal);
  }
  const fetchMoreSuppliers = async () => {
    fetchSuppliers("", SEARCH_MORE_TYPE);
  }

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <SearchSelect
          className={styles.supplierDropDown}
          showSearch
          allowClear
          loading={suppliersLoading}
          placeholder={`Select supplier`}
          onChange={(value) => {
            setSelectedSupplierId(value)
          }}
          options={suppliersData}
          filterOption={false}
          onSearch={handleSupplierSearch}
          onClear={() => {
            handleClear();
          }}
          loadMore={fetchMoreSuppliers}
          hasMoreData={totalSuppliers > suppliersData.length}
        />
        <Divider type="vertical" />
        <DatePicker picker={"month"} />
      </div>
    </>
  );
}
