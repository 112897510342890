import React, { useState } from "react";
import { Button, Table, Tooltip } from "antd";
import styles from "../styles/instrumentsTable.module.css";
import { SyncOutlined } from "@ant-design/icons";
import { instrumentsTableCols } from "../utils/instrumentsTableUtils";
import ResizableTitle from "./resize";

export default function CalibrationBody(props) {
  const {
    params,
    navigate,
    calibrationData,
    loading,
    totalRecords,
    onTableChange,
    currentPage,
    onRefresh,
    onCalibrationClick,
    currentPageSize,
    onPaginationChange,
  } = props;

  const [columns, setColumns] = useState(
    instrumentsTableCols(onCalibrationClick, params, navigate)
  );

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumns(newColumns);
    };
  const mergeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
      minWidth: column.minWidth,
    }),
  }));

  return (
    <>
      <div>
        <div className={styles.containerHeader}>
          <div className={styles.containerHeaderCol1}>
            <span style={{ fontWeight: "bold" }}>Instruments</span>{" "}
            <span className={styles.containerHeaderCol1Span}>
              ({totalRecords})
            </span>
          </div>
          <div className={styles.containerHeaderCol2}>
            <Tooltip title="Refresh">
              <Button
                icon={<SyncOutlined spin={loading} tool />}
                onClick={onRefresh}
                disabled={loading}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.containerBody}>
          <Table
            size={"small"}
            bordered
            loading={loading}
            onChange={onTableChange}
            columns={mergeColumns}
            dataSource={calibrationData}
            pagination={{
              pageSize: currentPageSize,
              total: totalRecords,
              current: currentPage,
              showSizeChanger: true,
              onChange: onPaginationChange,
            }}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            scroll={{ x: 1200, y: 500 }}
          />
        </div>
      </div>
    </>
  );
}
