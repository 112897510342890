import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServerIcon from "remixicon-react/ServerLineIcon";
import { useNavigate } from "react-router-dom";
import { ApiClientType } from "../../../clients/api-client.js";
import { ApiClient } from "../../../clients/api-client.js";
import WorkroomCard from "../components/workroomCard/ui/workroomCard.js";
import SecondaryButton from "../../../common/crunches/secondaryButton/secondaryButton";
import EmptyTableMsgs from "../../../common/components/emptyTableMsgs/emptyTableMsgs";
import styles from "../styles/workroom.module.css";
import Loader from "../../../common/loaders/clipLoader";
import { useReducer } from "react";
import { DataFetchReducers } from "../../../common/states/dataFetch/dataFetchReducers";
import { dataFetchActionType } from "../../../common/states/dataFetch/dataFetchActionType";
import { getUserDetailsAPI } from "../../../common/apis/commonApis";
import { GetUserId } from "../../../utils/getUserId";

export default function Workroom() {
  let navigate = useNavigate();
  const [workroomData, setWorkroomData] = useState([]);
  const [userGreetingName,setUserGreetingName] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const userId = GetUserId();
  const [userDetails, dispatchUserDetails] = useReducer(DataFetchReducers, {
    data: null,
    loading: false,
    error: null,
  });

  const getUserDetails = async (id) => {
    let response = await getUserDetailsAPI(id);
    dispatchUserDetails({
      type: dataFetchActionType.data,
      data: response.data,
    });

    setUserGreetingName(`Hi, ${response?.data?.name}`)
  };


  useEffect(() => {
    getWorkrooms();
    getUserDetails(userId);
  }, []);

  const getWorkrooms = async () => {
    setIsLoading(true);
    let response = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_MASTER_BASE_URL,
      `/workrooms?name=`,
      {}
    );
    if (response) {
      setWorkroomData(response.data);
    }
    setIsLoading(false);
  };

  return (
    <Container fluid={true} style={{ padding: "40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <b
          style={{
            fontSize: "24px",
            color: "black",
            textShadow: "1px 0 black",
          }}
        >
      {userGreetingName}
        </b>
        <SecondaryButton
          value={{
            onClick: () => {
              navigate("/masterData/assets");
            },
            child: (
              <div id={styles.masterData}>
                <ServerIcon
                  size={25}
                  style={{
                    color: "#7D7676",
                    marginTop: "-5px",
                    marginRight: "10px",
                  }}
                />
                Master Data
              </div>
            ),
          }}
        />
      </div>
      {isLoading && <Loader />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
      </div>
      <Row xs={1} md={1} lg={2}>
        {!isLoading && (
          <>
            {workroomData?.length === 0 ? (
              <EmptyTableMsgs isTable={false} emptyMessage="No Workrooms" />
            ) : (
              workroomData?.map((item, index) => (
                <Col style={{ padding: "0.25rem 0.5rem" }} key={item.id}>
                  <WorkroomCard
                    value={{ workroom: item }}
                  />
                </Col>
              ))
            )}
          </>
        )}
      </Row>
    </Container>
  );
}
