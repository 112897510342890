import dayjs from "dayjs";

export const FILTER_TAGS_VAL_MAPPING = { 'Created at': 'createdAt', 'Started at': 'startedAt', 'Closed at': 'closedAt' };
export const TAGS_IN_DATE_FILTER = ['Created at', 'Started at', 'Closed at'];
export const DATE_PRESET_RANGES = [
    {
        label: 'Today',
        value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
        label: 'Yesterday',
        value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd').endOf('day')],
    },
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
];
// Function to replace \" with "
const replaceEscapedQuotes = (str) => {
    if (typeof str === 'string') {
        return str.replace('"', "''");
    }
    return str;
};

export const flattenObject = (obj, parentKey = '') => {
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            return { ...acc, ...flattenObject(obj[key], newKey) };
        } else {
            return { ...acc, [newKey]: replaceEscapedQuotes(obj[key]) };
        }
    }, {});
};