import "./App.css";
import * as reactRouterDom from "react-router-dom";
import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./containers/Layout";
import allRoutes from "./routes/allRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import CustomNavbar from "./common/components/navbar/ui/navbar.js";
import SideNav from "./common/components/sideNav/ui/sideNav.js";
import Container from "react-bootstrap/Container";
import SideNavRoutes from "./utils/sideNavRoutes.js";
import { AppContext } from "./utils/context/appContext.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { GetUserId } from "./utils/getUserId";
import CustomToastContainer from "./common/toasters/customToastContainer";
import { uniqueObjectsByKey } from "./utils/utils.js";

export default function App() {
  let location = useLocation();
  const userId = GetUserId();
  const currentRoute = location.pathname;
  const [sideNavRoutes, setSideNavRoutes] = useState(SideNavRoutes());
  const [routes, setRoutes] = useState(allRoutes);

  const addDynamicSideNavRoute = (routes) => {
    setSideNavRoutes((prevRoutes) => {
      return uniqueObjectsByKey([...prevRoutes, ...routes], "key")
    });
  };

  const addDynamicRoute = (route) => {
    setRoutes((prevRoutes) => {
      return uniqueObjectsByKey([route, ...prevRoutes], "key")
    });
  };

  const routesWithSideNavigation = routes
    .filter((route) => {
      if (route?.withoutNav) {
      } else {
        return route.path;
      }
    })
    .map((route) => {
      return route.path;
    });

  return (
    <div>
      <CustomToastContainer />
      {userId !== "" ? (
        <SessionAuth>
          <CustomNavbar />
        </SessionAuth>
      ) : (
        <CustomNavbar />
      )}
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: checkRouteWithNav(routesWithSideNavigation, currentRoute)
              ? "block"
              : "none",
          }}
        >
          <SideNav routes={sideNavRoutes} />
        </div>
        <Container fluid={true} style={{ width: '93%' }}>
          <AppContext.Provider value={{ addDynamicRoute, addDynamicSideNavRoute }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Routes>
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                  EmailPasswordPreBuiltUI,
                ])}
                {routes.map((route, idx) => {
                  return (
                    <Route
                      key={idx}
                      path={route.path}
                      name={route.name}
                      element={
                        <SessionAuth>
                          <Layout
                            component={route.component}
                          />
                        </SessionAuth>
                      }
                    />
                  );
                })}

              </Routes>
            </LocalizationProvider>
          </AppContext.Provider>
        </Container>
      </div>
    </div>
  );
}

const checkRouteWithNav = (routeList, currentRoute) => {
  let isWIthNav = false;
  routeList.map((route) => {
    const match = matchPath({ path: route }, currentRoute);
    if (match) {
      isWIthNav = true;
    }
  });
  return isWIthNav;
};