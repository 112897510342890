import "./emptyTableStyling.css";

const EmptyTableMsgs = (props) => {

  if (!props.isTable && props.isTable != undefined) {
    return <div className="emptyDivContainer">{props.emptyMessage}</div>;
  }
  return (
    <tr>
      <td colSpan={12} className="tableEmptyMsgs">
        <p>{props.emptyMessage}</p>
      </td>
    </tr>
  );
};

export default EmptyTableMsgs;
