import styles from "../../../styles/fieldProperties.module.css";
import { useState, useReducer } from "react";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import IndeterminateCircleLineIcon from "remixicon-react/IndeterminateCircleLineIcon";
import { Button, Form, Collapse, InputGroup } from "react-bootstrap";
import DatasourceSelectDropdown from "./datasourceSelectDropdown";

export default function FieldProperties(props) {

  const [count, setCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleIncrement = () => {
    props.value.setMinimumReadingCount(props.value.minimumReadingCount + 1);
  };

  const handleDecrement = () => {
    if (props.value.minimumReadingCount > 0) {
      props.value.setMinimumReadingCount(props.value.minimumReadingCount - 1);
    }
  };

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    props.value.setMinimumReadingCount(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <div id={styles.fieldProperties}>
      {
        props.value.fieldProperties?.length > 0 &&
        <>
          <div className={styles.optionsDiv}>
          <p
            style={{
              color: "#7D76760",
              lineHeight: "1",
              marginTop: "10px",
            }}
          >
            Field Options
          </p>
          <div style={{marginBottom: "1rem"}}>
          <DatasourceSelectDropdown
              value={{
                buttonStyle: {
                  borderRadius: "11px",
                },
                state: [
                  {
                    id: 1,
                    name: "Products"
                  },
                  {
                    id: 2,
                    name: "Machines"
                  },
                  {
                    id: 3,
                    name: "Operations"
                  },
                ],
                buttonText: 'Manual options',
                selectValue: (value) => {
                },
                extractDataFromList: (item) => {
                  return { id: item.id, name: item.name };
                },
              }}
            />
          </div>
          </div>

          <Form>
            {props.value.fieldProperties?.map((item, index) => {
              return (
                <InputGroup key={`${index}-${item}`} className="mb-3">
                  <InputGroup.Text>{index + 1}.</InputGroup.Text>
                  <Form.Control
                    defaultValue={item}
                    onChange={(e) => {
                      props.value.onEditProperty(index, e.target.value);
                    }}
                    placeholder="Enter Property"
                    aria-label="Field Name"
                  />
                  <InputGroup.Text>
                    <IndeterminateCircleLineIcon
                      onClick={() => props.value.onRemoveProperty(item)}
                      color="#7D7676"
                      style={{ cursor: "pointer" }}
                    />
                  </InputGroup.Text>
                </InputGroup>
              );
            })}
          </Form>
        </>
      }
      <button
        type="button"
        style={{
          color: "#7D76760",
          border: "none",
          background: "none",
          marginTop: "10px",
        }}
        onClick={() => props.value.onAddProperty()}
      >
        <AddCircleLineIcon
          color="#7D7676"
          style={{ marginTop: "-5px", marginRight: "2px" }}
        />
        Add an Options
      </button>
      <p
        style={{
          color: "#7D76760",
          lineHeight: "1",
          marginTop: "30px",
        }}
      >
        Other Properties
      </p>
      <Form>
        <div id={styles.otherPropertiesContainer}>
          <Form.Check
            inline
            label="Mark as Mandatory Field"
            checked={props.value.isMandatory}
            onChange={(e) => props.value.setIsMandatory(e.target.checked)}
          />
        </div>
        <div id={styles.otherPropertiesContainer}>
          <Form.Check
            inline
            label="Required Evidence Image"
            checked={props.value.isAttachment}
            onChange={(e) => props.value.setIsAttachment(e.target.checked)}
          />
        </div>
        <div id={styles.otherPropertiesContainer}>
          <Form.Check
            inline
            label="Required Observation"
            checked={props.value.isObservation}
            onChange={(e) => props.value.setIsObservation(e.target.checked)}
          />
        </div>
      </Form>
      <div id={styles.otherPropertiesContainer}>
        <div className="self-center">
          Readings Count
        </div>
        <div className="mx-2">
          <button className={styles.decrementBtn} disabled={true} onClick={handleDecrement}>-</button>
          <input type="number" 
            className={styles.countField} 
            disabled={true} 
            value={props.value.minimumReadingCount} 
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
            onScroll={(e) => e.target.blur()}
          />
          <button className={styles.incrementBtn} disabled={true} onClick={handleIncrement}>+</button>
        </div>
        <div className="disabledLayover"></div>
      </div>
    </div>
  );
}
