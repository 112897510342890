import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return Session.attemptRefreshingSession().then((_) => {
                return axios(originalRequest)
            }).catch((err) => {
                signOut();
            })
        }
        return Promise.reject(error);
    });

export const axiosPrivate = axios;