import { Collapse } from "antd";
import DocumentFileUpload from "./documentsFileUpload";

export default function SCARDetailTasksTab(props) {
  const { taskList } = props;

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Collapse.Panel header={<span style={{ fontWeight: "bold" }}>Task Docs</span>}    key="1">
        {taskList.map((task) => (
          <DocumentFileUpload
            fileList={task.meta?.media || []}
            onChangeFileList={null}
            customRequest={null}
            showButton={false}
            showUploadList={{ showRemoveIcon: false }}
          />
        ))}
      </Collapse.Panel>
    </Collapse>
  );
}
