import { ApiClient, ApiClientType } from "../../../clients/api-client";
import { DEFAULT_OFFSET, PAGE_SIZE } from "../../../clients/api-constants";
import { showToastError, showToastSuccess, } from "../../../common/toasters/toasterMessages";

export const createScarAPI = async (body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars`,
    body
  );
};

export const getScarsAPI = async (supplierId = "", inspection = "", customer = "", pageOffset = DEFAULT_OFFSET, limit = PAGE_SIZE) => {
  let urlExtension = `/scars?offset=${pageOffset}&limit=${limit}&includeSupplier=true&includeQualityInspection=true&includeIncomingPart=true`
  if (supplierId.length > 0) {
    urlExtension += `&supplierId=${supplierId}`
  }
  if (inspection.length > 0) {
    urlExtension += `&qualityInspectionId=${inspection}`
  }
  if (customer.length > 0) {
    urlExtension += `&customerId=${customer}`
  }
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    urlExtension
  );
};

export const getScarDetailsAPI = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${id}?includeSupplier=true&includeQualityInspection=true`
  );
};

export const updateScarAPI = async (id, body) => {
  return await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${id}`,
    body
  );
};

export const getSuppliersAPI = async (offset = 0, name = '', limit = 5) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/suppliers?name=${name}&limit=${limit}&offset=${offset}`
  );
};

export const createScarTaskAPI = async (scarId, body) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${scarId}/tasks`,
    body
  );
};

export const getScarTasksAPI = async (scarId) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${scarId}/tasks`
  );
};

export const getTaskDetailsAPI = async (id) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/tasks/${id}?includeTaskAssignees=true`
  );
};

export const updateScarTaskAPI = async (scarId, id, body) => {
  let response = await ApiClient(
    ApiClientType.patch,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${scarId}/tasks/${id}`,
    body
  );
  if (!response.isError) {
    showToastSuccess(response.displayableMessage);
  } else {
    showToastError(response.displayableMessage);
  }
  return response;
};

export const deleteTaskAPI = async (scarId, id) => {
  return await ApiClient(
    ApiClientType.delete,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/${scarId}/tasks/${id}`
  );
};

export const getSheetOrdersForTaskAPI = async (id, sheetId = '') => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheet-orders?source=TASK&sourceId=${id}&sheetId=${sheetId}`
  );
};

export const getScarStatusAPI = async () => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars/status`
  );
};

export const getScarDetailsByKeyAPI = async (key) => {
  return await ApiClient(
    ApiClientType.get,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/scars?key=${key}&includeSupplier=true&includeQualityInspection=true&includeIncomingPart=true&includeCustomer=true`
  );
};

export const submitSheetOrdersForTaskAPI = async (sheetOrderId, data) => {
  return await ApiClient(
    ApiClientType.post,
    process.env.REACT_APP_MASTER_BASE_URL,
    `/sheet-orders/${sheetOrderId}/submit`,
    data
  );
};