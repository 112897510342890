import { Button } from "antd";
export const equipmentTableCols = (onEquipmentClick, setShowModal) => [
  {
    title: "Instrument Name",
    dataIndex: ["instrument", "name"],
    key: "name",
    width: 150,
    minWidth: 100,
    fixed: "left",
    ellipsis: true,
    render: (text, record) => (
      <Button
        type="link"
        onClick={() => {
          onEquipmentClick(record?.instrument?.id);
          setShowModal(true);
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: "Serial Number",
    dataIndex: ["instrument", "code"],
    key: "code",
    width: 150,
    minWidth: 100,
    ellipsis: true,
  },
  {
    title: "Make",
    dataIndex: ["instrument", "make"],
    key: "make",
    width: 120,
    minWidth: 100,
  },
  {
    title: "Location",
    dataIndex: ["instrument", "station", "name"],
    key: "name",
    width: 100,
    minWidth: 100,
  },
];
