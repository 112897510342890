import ClipLoader from "react-spinners/ClipLoader";

function Loader() {
  const override = {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "48%",
    margin: "0 auto",
    zIndex: "55",
    backgroundColor: "#F7F8FA",
    borderColor: "#0099ffc5",
    boxShadow: "0px 1px 10px 0px #009AFF",
  };

  return (
    <div>
      <div className="sweet-loading">
        <ClipLoader
          size={60}
          cssOverride={override}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default Loader;
