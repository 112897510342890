import { Dropdown, Space } from 'antd';
import CheckDoubleFillIcon from 'remixicon-react/CheckDoubleFillIcon';
import DeleteBin6LineIcon from 'remixicon-react/DeleteBin6LineIcon';
import More2LineIcon from 'remixicon-react/More2LineIcon';
import PencilLineIcon from 'remixicon-react/PencilLineIcon';
import PlayLineIcon from 'remixicon-react/PlayLineIcon';

export const MoreActionsToggle = (props) => {

    const { status, closeInspectionHandler, deleteInspectionHandler, startInspectionHandler, showInspectionModal} = props.values;

    const items = [
        {
            key: '1',
            label: "Start Inspection",
            icon: <PlayLineIcon size="20px" color={status !== 'OPEN' ? '#00000040' :'#7d7676'} />,
            disabled: status !== 'OPEN' && true,
            onClick: startInspectionHandler,
        },
        {
            key: '2',
            label: "Close Inspection",
            icon: <CheckDoubleFillIcon size="20px" color={status === 'CLOSED' ? '#00000040' :'#7d7676'} />,
            disabled: status === 'CLOSED' && true,
            onClick: closeInspectionHandler,
        },
        {
            key: '3',
            label: "Edit Inspection",
            icon: <PencilLineIcon size="20px" color={status === 'CLOSED' ? '#00000040' :'#7d7676'} />,
            disabled: status === 'CLOSED' && true,
            onClick: showInspectionModal,
        },
        {
            key: '4',
            danger: true,
            label: "Delete Inspection",
            icon: <DeleteBin6LineIcon size="20px"/>,
            onClick: deleteInspectionHandler,
        },
    ];

    return (
        <Dropdown
            menu={{
            items,
            }}
        >
            <Space>
                <More2LineIcon style={{cursor: "pointer"}}/>
            </Space>
        </Dropdown>
        
    )
}
