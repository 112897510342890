import { React, useEffect, useState } from "react";
import { showToastError } from "../../../../common/toasters/toasterMessages.js";
import {
  DEFAULT_OFFSET,
  PAGE_SIZE,
} from "../../../../clients/api-constants.js";
import { getMachines, getMachineDetails } from "../../apiCalls/productsApis.js";
import MachineBody from "./components/body.js";
import { MachineModal } from "./components/machineModal/machineModal.js";

export default function MachineTable() {
  const [machinesData, setMachinesData] = useState([]);
  const [machineData, setMachineData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);

  const [showModal, setShowModal] = useState(false);
  let [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    void fetchMachines(offset);
  }, []);

  const fetchMachines = async (pageOffset = offset, pageLimit = PAGE_SIZE) => {
    setIsLoading(true);
    let response = await getMachines({
      pageOffset,
      limit: pageLimit,
    });
    if (response.isError) {
      showToastError(response.displayableMessage);
    } else {
      setMachinesData(response.data);
      setTotalCount(response.totalCount);
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPage(1);
      setOffset(0);
      setCurrentPageSize(pageSize);
      fetchMachines(0, pageSize);
    } else {
      setOffset(currentPageSize * (page - 1));
      setCurrentPage(page);
      fetchMachines(currentPageSize * (page - 1), currentPageSize);
    }
  };

  const onRefresh = () => {
    fetchMachines(offset);
  };

  const handleMachinesClick = async (id) => {
    let response = await getMachineDetails(id);
    if (!response?.isError) {
      setMachineData(response?.data);
    }
    setIsEdit(true);
  };

  return (
    <>
      <MachineModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        fetchMachines={fetchMachines}
        machineDetails={machineData}
      />
      <div>
        <MachineBody
          machinesData={machinesData}
          loading={isLoading}
          totalRecords={totalCount}
          currentPage={currentPage}
          setShowModal={setShowModal}
          setIsEdit={setIsEdit}
          currentPageSize={currentPageSize}
          onRefresh={onRefresh}
          onMachineClick={handleMachinesClick}
          onPaginationChange={handlePaginationChange}
          setLoading={setIsLoading}
        />
      </div>
    </>
  );
}
