import { Card, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { EDITABLE_SOURCE_TYPE, generateColsFromConfig, generateSummaryGroupData } from "../../utils/helperUtils";
import { EditableCell, EditableRow } from "../common/editableRowCell";
import styles from "../../styles/supplierRating.module.css";
import SummaryGroupDetailFilters from "./summaryGroupDetailFilters";
import SummaryGroupVerdictView from "./summryGroupVerdictView";

export default function SummaryGroupDetailedView(props) {
    const { ratingConfig, ratingData, summaryGroup, dataLoading, isEditingData, editedDataMap, setEditedDataMap } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [summaryGroupData, setSummaryGroupData] = useState([]);
    const [cols, setCols] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(undefined);

    const handleSave = async (row, dataIndexKey) => {
        // Create a new array with the updated item
        const newData = summaryGroupData.map((item) => {
            if (item.entityId === row.entityId) {
                // Update the matching item
                editedDataMap.set(row[dataIndexKey].id, row[dataIndexKey]);
                setEditedDataMap(editedDataMap);
                return { ...item, ...row };
            }
            return item; // Keep other items unchanged
        });

        setSummaryGroupData(newData);
    };
    const generateSummaryGroupCols = async (configDetails, summaryGroupConfig) => {
        setIsLoading(true);
        const summaryGroupCols = generateColsFromConfig(configDetails, summaryGroupConfig.entity);
        const columns = summaryGroupCols.map((col) => {
            if (dataLoading) {
                return {
                    ...col
                };
            }
            return {
                ...col,
                onCell: (record) => {
                    const entityId = record[col.key]?.entityId;
                    const overrides = entityId ? col?.overrides ? col?.overrides[entityId] : undefined : undefined;
                    return overrides ? {
                        record,
                        editable: overrides?.sourceType === EDITABLE_SOURCE_TYPE && isEditingData,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        type: overrides?.dataType,
                        meta: overrides?.meta,
                        handleSave,
                    } : {
                        record,
                        editable: col.editable && isEditingData,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        type: col.dataType,
                        meta: col.meta,
                        handleSave,
                    }
                },
            };
        });
        setCols(columns);
        setIsLoading(false);
    }

    const generateSummaryGroupDataDetails = async (configData, configDetails, summaryGroupConfig) => {
        setIsLoading(true);
        const generatedData = generateSummaryGroupData(configData, configDetails, summaryGroupConfig.entity);
        if (selectedFilters) {
            setSummaryGroupData(generatedData?.filter(group => group?.entityId === selectedFilters));
        } else {
            setSummaryGroupData(generatedData);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (ratingData && ratingConfig && summaryGroup)
            generateSummaryGroupDataDetails(ratingData, ratingConfig, summaryGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, ratingConfig, ratingData]);

    useEffect(() => {
        if (ratingConfig && summaryGroup)
            generateSummaryGroupCols(ratingConfig, summaryGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoading, isEditingData]);

    return (
        <>
            <Card key={'Card' + summaryGroup.name} type="inner" title={summaryGroup.name}>
                <Space key={'Space' + summaryGroup.name} direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {summaryGroup?.filters?.length > 0 && (
                        <div key={'div1' + summaryGroup.name} style={{ display: "flex" }}>
                            <div key={'div2' + summaryGroup.name} style={{ flex: "1" }}>
                                <SummaryGroupDetailFilters
                                    key={'filter' + summaryGroup.name}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    summaryGroup={summaryGroup}
                                />
                            </div>
                        </div>
                    )}
                    <div key={'div3' + summaryGroup.name}>
                        <Table
                            key={'Table' + summaryGroup.name}
                            size="small"
                            loading={isLoading}
                            className={styles.table}
                            columns={cols}
                            dataSource={summaryGroupData}
                            pagination={false}
                            footer={() => <SummaryGroupVerdictView ratingConfig={ratingConfig} ratingData={ratingData} summaryGroup={summaryGroup} />}
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                },
                            }}
                            rowClassName={() => 'editable-row'}
                            scroll={{ x: 1000, y: 500 }}
                        />
                    </div>
                </Space>
            </Card>
        </>
    );
}
