import styles from "./styles/calibrationItem.module.css";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import PictureInPictureLineIcon from "remixicon-react/PictureInPictureLineIcon";
import { Check } from "@mui/icons-material";
import StatusButtons from "../calibrationFilters/statusButtons/statusButtons";
import { Form } from "react-bootstrap";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import dayjs from "dayjs";
import axios from "axios";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import { DatePicker } from "@mui/x-date-pickers";
import DotOptionsDropDownCalibration from "../../../../common/components/dotOptionsCalibration/dotOptionsDropDown.Calibration";
import DotOptionsSideBar from "../../../../common/components/dotOptionsSidebar/dotOptionsSidebar";
import { Row, Col } from "react-bootstrap";
import CalibrationSettingsModal from "../CalibrationSettingsModal/CalibrationSettingsModal";
import CalibrationModal from "../calibrationModal/calibrationModal";
import { useState, useRef, useEffect } from "react";
import DetailedCalibration from "../detailedCalibrationTable/detailedCalibration";
import Loader from "../../../../common/loaders/clipLoader";
import {
  showToastError,
  showToastSuccess,
} from "../../../../common/toasters/toasterMessages";
import { FileUploader } from "../../../../common/components/fileUploader/fileUploader";
import { getDateFun } from "../../../../common/components/getDateFun/getDateFun";
import { useParams, useNavigate } from "react-router-dom";
import { fileUploadApi,calibrationStatusApi,updateCalibrationApi,createCalibrationApi, deleteCalibrationApi, closeCalibrationApi, getCalibrationItemApi, getCalibrationItemDetailsApi, getEquipmentByIdApi } from "../../apiCalls/calibrationApis";

export default function CalibrationItem(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [calibrationItem, setCalibrationItem] = useState(false);
  const [calibrationDetailedList, setCalibrationDetailedList] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [imagesList, setImagesList] = useState([]);

  const [calibrationStatus, setCalibrationStatus] = useState({});
  const [clickedRow, setClickedRow] = useState(null);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [colSize, setColSize] = useState(4);
  const [calibrationState, setCalibrationState] = useState({
    title: "All",
    displayTitle: "All",
    color: "#F5F5F5",
  });

  const params = useParams();
  let navigate = useNavigate();
  const instrumentId = params.instrumentId;

  const [dueDate, setDueDate] = useState(new Date("16/10/2023"));
  const [appNameStyle, setAppNameStyle] = useState({});
  const [agencyName, setAgencyName] = useState("");
  const [isReadInput, setIsReadInput] = useState(true);
  const inputRef = useRef(null);
  const [showCalibrationModal, setShowAddCalibrationModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCalibrationSettings, setShowCalibrationSettings] = useState(false);

  const fileUpload = async ({ file, onSuccess, onError }) => {
    let urlExtension = `/instrument-calibrations/${clickedRow?.id}/upload`;

    let response = await fileUploadApi(urlExtension)

    if (!response.isError) {
      await axios
        .put(response?.data?.uploadLink?.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((res) => {
          onSuccess(response, file);
        })
        .catch((err) => {
          onError(err, file);
        });
    } else {
      showToastError("Error in uploading media");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setIsReadInput(false);
    inputRef.current.focus();
    setAppNameStyle({ background: "#D0EBFFA6", border: "1px solid #009AFF" });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setIsReadInput(true);
    setAppNameStyle("");
    let response = updateCalibrationDetails(
      clickedRow,
      dueDate,
      agencyName,
      imagesList
    ).then(() => {
      void getCalibrationItemDetails(instrumentId);
    });
    if (!response.isError) {
      showToastSuccess(response.message);
    } else {
      showToastError(response.message);
    }
  };

  const handleSettings = () => {
    setShowCalibrationSettings(true);
  };

  const statusCalibrationAPI = async () => {
    let response = await calibrationStatusApi()
    if (!response.isError) {
      setCalibrationStatus(response?.data);
    }
  };

  const updateCalibrationDetails = async (
    clickedRow,
    dueDate,
    agencyName,
    imagesList
  ) => {
    let body = {
      dueDateTime: dueDate,
      agency: agencyName,
      media: imagesList?.[0]?.media,
    };
    if (clickedRow?.id) {
      let response = await updateCalibrationApi(body,clickedRow?.id)
      if (!response.isError) {
        showToastSuccess(response.displayableMessage);
      } else {
        showToastError(response.displayableMessage);
      }
    }
  };

  const handleEditModal = () => {
    setShowAddCalibrationModal(true);
  };

  const createCalibration = async (values) => {
    let body = {
      instrumentId: instrumentId,
      dueDateTime: values?.dueDateTime,
      agency: values?.agency,
    };
    let response = await createCalibrationApi(body)
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
      response.data.isNew = true;
      let updatedCalibrationList = [response.data, ...calibrationDetailedList];
      setCalibrationDetailedList(updatedCalibrationList);
    } else {
      showToastError(response.displayableMessage);
    }
  };

  const getCalibrationItemDetails = async (id, status = "") => {
    let urlExtension = `/instrument-calibrations?instrumentId=${id}`;
    if (!(status === "" || status === "All")) {
      urlExtension += `&status[]=${status}`;
    }
    let response = await getCalibrationItemDetailsApi(urlExtension)
    if (!response.isError) {
      setCalibrationDetailedList(response?.data);
    }
    setIsLoading(false);
  };

  const deleteCalibration = async () => {
    let response = await deleteCalibrationApi(clickedRow?.id)
    if (!response.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
    getCalibrationItemDetails(instrumentId);
    setSideNavOpen(false);
  };

  const closeCalibration = async () => {
    
    let response = await closeCalibrationApi(clickedRow?.id)
    if (!response?.isError) {
      showToastSuccess(response.displayableMessage);
    } else {
      showToastError(response.displayableMessage);
    }
    getCalibrationItemDetails(instrumentId);
    setSideNavOpen(false);
  };

 

  const getCalibrationItem = async (id) => {
    let response = await getCalibrationItemApi(id)
    let updatedMediaArray = [];
    if (!response.isError) {
      if (response)
        response?.data?.meta?.media?.map((ele) => {
          ele["status"] = "done";
          updatedMediaArray.push(ele);
        });
    }
    setFileList(updatedMediaArray);
  };

  const convertToBackendMediaObject = (newFileList) => {
    let formattedFileList = [];
    newFileList?.map((ele) => {
      let fileObj = {};
      if (ele.status === "done" && ele.response?.data?.url) {
        fileObj = {
          name: ele.name,
          type: ele.type,
          size: ele.size,
          url: ele.response?.data?.url,
        };
        formattedFileList.push(fileObj);
      } else if (ele.url !== "") {
        formattedFileList.push(ele);
      }
    });
    return {
      media: formattedFileList,
    };
  };

  const isInUploadingState = (item) => {
    return item?.status === "uploading";
  };
  const onChangeFileList = (newFileList) => {
    setFileList(newFileList);

    let isUploadingState = false;
    newFileList?.map((item) => {
      let isUploading = isInUploadingState(item);
      if (isUploading) {
        isUploadingState = true;
      }
    });
    if (isUploadingState) {
      return;
    }
    let metaObj = convertToBackendMediaObject(newFileList);
    setImagesList([metaObj]);
    void updateCalibrationDetails(clickedRow, dueDate, agencyName, [metaObj]);
  };

  const getEquipmentList = async (id = "") => {
    setIsLoading(true);
    let response = await getEquipmentByIdApi(id)
    if (!response.isError) {
      setCalibrationItem(response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (clickedRow?.id) {
      void getCalibrationItem(clickedRow?.id);
    }
  }, [clickedRow]);

  useEffect(() => {
    void getCalibrationItemDetails(instrumentId, calibrationState?.title);
  }, [calibrationState]);

  useEffect(() => {
    setIsLoading(true);
    getEquipmentList(instrumentId);
    void getCalibrationItemDetails(instrumentId);
    void statusCalibrationAPI();
  }, [instrumentId]);

  return (
    <div id={styles.container}>
      <CalibrationModal
        values={{
          show: showCalibrationModal,
          handleClose: () => setShowAddCalibrationModal(false),
          onSubmitModal: async (body) => {
            await createCalibration(body);
          },
          onHide: () => setShowAddCalibrationModal(false),
        }}
      />
      <CalibrationSettingsModal
        values={{
          show: showCalibrationSettings,
          handleClose: () => setShowCalibrationSettings(false),
          onHide: () => setShowCalibrationSettings(false),
        }}
      />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.table}>
          <Row className={styles.topRow}>
            <Col
              md={3}
              className={`${styles.topRowColName} ${styles.backButtonCol}`}
            >
              <div>
                <div className="flex-center">
                  <ArrowLeftLineIcon
                    style={{
                      marginRight: "1rem",
                      cursor: "pointer",
                      marginLeft: "0.5rem",
                    }}
                    onClick={() => {
                      navigate(`/application/${params.appId}/calibrations`);
                    }}
                  />
                  <PictureInPictureLineIcon
                    style={{
                      marginRight: "0.5rem",
                      color: "#7D7676",
                      minWidth: "20px",
                    }}
                  />
                  <span className={`${styles.title} boldFont`}>
                    {calibrationItem?.name}
                  </span>
                </div>
              </div>
            </Col>
            <Col md={2} className={styles.topRowCol}>
              <div>
                <p
                  className={`${styles.hoverableTd} ${styles.hoverableTdCode}`}
                  id={styles.detailedInstrumentCapping}
                >
                  {calibrationItem?.code}
                </p>
              </div>
            </Col>
            <Col md={1} className={styles.topRowCol}>
              <div>
                <p
                  className={styles.hoverableTd}
                  id={styles.detailedInstrumentCapping}
                >
                  {calibrationItem?.make}
                </p>
              </div>
            </Col>
            <Col md={2} className={styles.topRowCol}>
              <div>
                <span
                  className={`${styles.hoverableTd} ${styles.hoverableTdLocation}`}
                  id={styles.detailedInstrumentCapping}
                >
                  {calibrationItem?.station?.name}
                </span>
              </div>
            </Col>
            <Col md={1} className={styles.topRowCol}>
              <div>
                <span
                  className={`${styles.hoverableTd} ${styles.hoverableTdFrequency}`}
                >
                  1 year
                </span>
              </div>
            </Col>
            <Col
              md={2}
              className={styles.topRowCol}
              style={{ whiteSpace: "nowrap" }}
            >
              <div>
                <span
                  className={`${styles.hoverableTd} ${styles.hoverableTdLastCalibrated}`}
                >
                  {calibrationItem?.lastCalibratedAt
                    ? getDateFun(calibrationItem?.lastCalibratedAt)
                    : "Not available"}
                </span>
              </div>
            </Col>
            <Col className={styles.topRowCol}>
              <DotOptionsDropDownCalibration
                values={{
                  show: handleEditModal,
                  name: "Calibration Settings",
                  title: "New calibration",
                  showSettings: handleSettings,
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: "0px" }}>
            <Col
              md={sideNavOpen ? 9 : 12}
              style={{
                padding: "0px",
                transition: "width 0.2s",
                width: sideNavOpen ? "72%" : "100%",
              }}
            >
              <Row className={styles.filtersRow}>
                <StatusButtons
                  values={{
                    statusLabel: "Status",
                    styling: { marginLeft: "1.5rem" },
                    statusList: calibrationStatus,
                    setCalibrationState: setCalibrationState,
                    calibrationState: calibrationState,
                  }}
                />
              </Row>
              <Row className={styles.filtersRowTableHeading}>
                <Col md={colSize} className={styles.topRowCol}>
                  <div>
                    <span className={styles.lable}>Status</span>
                  </div>
                </Col>
                <Col md={colSize} className={styles.topRowCol}>
                  <div>
                    <span className={styles.lable}> Due Date </span>
                  </div>
                </Col>
                <Col md={colSize} className={styles.topRowCol}>
                  <div>
                    <span className={styles.lable}>Agency</span>
                  </div>
                </Col>
              </Row>
              <div style={{}} className={styles.calibrationListOverFlow}>
                <DetailedCalibration
                  values={{
                    setSideNavOpen: setSideNavOpen,
                    setColSize: setColSize,
                    colSize: colSize,
                    calibrationDetailedList: calibrationDetailedList,
                    sideNavOpen: sideNavOpen,
                    setClickedRow: setClickedRow,
                    setAgencyName: setAgencyName,
                    clickedRow: clickedRow,
                    setDueDate: setDueDate,
                  }}
                />
              </div>
            </Col>
            {sideNavOpen && (
              <Col
                className={styles.sideNavStyle}
                md={3}
                style={{
                  width: "28%",
                }}
              >
                <Row
                  style={{
                    margin: "0px",
                  }}
                >
                  <div className="flex-between mt-3">
                    <div
                      style={{ zIndex: "100" }}
                      className={
                        clickedRow?.status === "OPEN"
                          ? styles.openStatusSideNav
                          : styles.closeStatusSideNav
                      }
                    >
                      {clickedRow?.status}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DotOptionsSideBar
                        values={{
                          show: handleEditModal,
                          name: "Generate Report",
                          title: "New calibration",
                          status: clickedRow?.status,
                          closeCalibration: closeCalibration,
                          deleteCalibration: deleteCalibration,
                        }}
                      />
                      <CloseLineIcon
                        style={{ cursor: "pointer", zIndex: "100" }}
                        onClick={() => {
                          setSideNavOpen(false);
                          setColSize(4);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="flex-between"
                    style={{ marginTop: "2rem", maxHeight: "100px" }}
                  >
                    <span
                      className={`${styles.lable}`}
                      style={{
                        marginTop: "1rem",
                        whiteSpace: "nowrap",
                        marginRight: "0.5rem",
                      }}
                    >
                      Due Date:
                    </span>
                    <DatePicker
                      className="mb-3"
                      style={{ width: "100%" }}
                      onChange={(newValue) => {
                        const formattedDate = newValue
                          .toISOString()
                          .toLocaleString(undefined, {
                            timeZone: "Asia/Kolkata",
                          });
                        setDueDate(formattedDate);
                        updateCalibrationDetails(
                          clickedRow,
                          formattedDate,
                          agencyName,
                          imagesList
                        ).then(() => {
                          void getCalibrationItemDetails(calibrationItem?.id);
                        });
                      }}
                      format="DD/MM/YYYY"
                      value={dayjs(dueDate)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className={`${styles.lable}`}
                      style={{ marginRight: "1.3rem" }}
                    >
                      Agency:
                    </span>
                    <Form.Floating style={{ width: "100%" }}>
                      <input
                        className={`${styles.appNameEdit}`}
                        onChange={(e) => {
                          setAgencyName(e.target.value);
                        }}
                        style={{ width: "100%", ...appNameStyle }}
                        readOnly={isReadInput}
                        ref={inputRef}
                        value={agencyName}
                      />
                      <label
                        htmlFor="floatingInputCustom"
                        style={{
                          color: "#7D7676",
                          width: "115%",
                          cursor: "pointer",
                        }}
                      ></label>
                      {isEditing ? (
                        <Check
                          style={{
                            float: "right",
                            position: "absolute",
                            bottom: "13",
                            left: "84%",
                            zIndex: "100",
                            cursor: "pointer",
                            color: "#7D7676",
                          }}
                          onClick={handleSaveClick}
                        />
                      ) : (
                        <PencilLineIcon
                          style={{
                            float: "right",
                            position: "absolute",
                            bottom: "13",
                            left: "84%",
                            zIndex: "100",
                            cursor: "pointer",
                            color: "#7D7676",
                          }}
                          onClick={handleEditClick}
                        />
                      )}
                    </Form.Floating>
                  </div>
                  <div
                    className="mt-3"
                    style={{ overflowY: "auto", height: "300px" }}
                  >
                    <span
                      className={`${styles.lable}`}
                      style={{ marginRight: "0.7rem" }}
                    >
                      Certificate:
                    </span>
                    <FileUploader
                      listType={"picture"}
                      fileList={fileList}
                      onChangeFileList={onChangeFileList}
                      customRequest={fileUpload}
                    />
                  </div>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
}
