import { Button, Card, DatePicker, Dropdown, Menu, Space, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import { getSupplierApi } from "../../../../common/apis/commonApis";
import { useEffect, useState } from "react";
import { bulkUpdateRatingData, createRatingData, deleteRatingData, downloadRatingData, fetchRatingConfig, fetchRatingData } from "../../apis/apis";
import dayjs from "dayjs";
import { showToastError } from "../../../../common/toasters/toasterMessages";
import { DownloadOutlined, EditOutlined, PlusOutlined, SaveOutlined, SyncOutlined, DeleteOutlined } from "@ant-design/icons";
import SummaryGroupDetailedView from "../summaryGroupDetails/summaryGroupDetailedView";
import axios from "axios";
import More2LineIcon from "remixicon-react/More2LineIcon";
import { saveAs } from 'file-saver';

export default function SupplierRatingDetailedView() {
    const params = useParams();
    const navigate = useNavigate();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [supplierDetails, setSupplierDetails] = useState(undefined);
    const [ratingConfigDetails, setRatingConfigDetails] = useState(undefined);
    const [ratingData, setRatingData] = useState(undefined);
    const [selectedInterval, setSelectedInterval] = useState(dayjs());
    const [source, setSource] = useState(undefined);
    const [editing, setEditing] = useState(false);
    const [editedData, setEditedData] = useState(new Map());

    const getSupplierDetails = async (supplierId) => {
        setIsLoading(true);
        let response = await getSupplierApi(supplierId)
        if (!response.isError) {
            setSupplierDetails(response?.data);
        }
        setIsLoading(false);
    }

    const getRatingConfig = async () => {
        setIsLoading(true);
        setIsDataLoading(true);
        let response = await fetchRatingConfig(source, 'SUPPLIER', true);
        if (!response.isError) {
            setRatingConfigDetails(response?.data);
        }
        setIsDataLoading(false);
        setIsLoading(false);
    }

    const updateRatingEntityData = async (entity, updateData) => {
        setIsLoading(false);
        let response = await bulkUpdateRatingData(source, entity, updateData);
        if (response.isError) {
            showToastError(response?.displayableMessage);
        } else {
            getRatingConfig();
        }
        setIsLoading(false);
    }

    const getRatingData = async (entity, entityId, intervalType, interval) => {
        setIsLoading(true);
        let response = await fetchRatingData(source, entity, entityId, intervalType, { "year": interval.$y, "month": interval.$M + 1 });
        if (!response.isError) {
            setRatingData(response?.data);
        } else {
            showToastError(response.displayableMessage);
        }
        setIsLoading(false);
    }

    const handleDateChange = (date, dateString) => {
        if (date)
            setSelectedInterval(date)
    };

    const onRefresh = () => {
        getRatingConfig();
    };

    const onEdit = () => {
        setEditing(true);
    };

    const onSave = () => {
        let editData = {};
        editedData.forEach((value, key) => {
            const { id, entity, data } = value;
            editData[entity] = editData[entity] || [];
            editData[entity].push({
                id: id,
                data: data?.value
            });

        });
        setEditedData(new Map());
        Object.entries(editData).forEach(([entity, values]) => {
            updateRatingEntityData(entity, values);
        });
        setEditing(false);
    };

    const onDelete = async (entity, entityId, intervalType, interval) => {
        setIsLoading(true);
        let response = await deleteRatingData(source, entity, entityId, intervalType, { "year": interval.$y, "month": interval.$M + 1 });
        if (!response.isError) {
            getRatingData(ratingConfigDetails?.entity, supplierDetails?.id, ratingConfigDetails?.intervalType, selectedInterval);
        } else {
            showToastError(response.displayableMessage);
        }
        setIsLoading(false);
    }

    const handleGenerateData = async () => {
        setIsLoading(true);
        const data = {
            "entity": ratingConfigDetails?.entity,
            "entityId": supplierDetails?.id,
            "intervalType": ratingConfigDetails?.intervalType,
            "interval": { "year": selectedInterval.$y, "month": selectedInterval.$M + 1 }
        }
        let response = await createRatingData(source, ratingConfigDetails?.entity, data);
        if (!response.isError) {
            getRatingData(ratingConfigDetails?.entity, supplierDetails?.id, ratingConfigDetails?.intervalType, selectedInterval);
        } else {
            showToastError(response.displayableMessage);
            setIsLoading(false);
        }
    }

    const pdfDownload = async () => {
        setIsLoading(true);
        try {
            const response = await downloadRatingData(source, ratingConfigDetails?.entity, supplierDetails?.id,
                ratingConfigDetails?.intervalType, { "year": selectedInterval.$y, "month": selectedInterval.$M + 1 });

            saveAs(new Blob([response.data], { type: 'application/pdf' }), `${supplierDetails.name}-${selectedInterval.$y}-${selectedInterval.$M + 1}-rating`);
        } catch (error) {
            showToastError("Unable to download rating data.", error);
        }
        setIsLoading(false);
    }

    const onMenuClick = ({ key }) => {
        if (key === "download") {
            pdfDownload();
        }
        if (key === "edit") {
            onEdit();
        }
        if (key === "delete") {
            onDelete(ratingConfigDetails?.entity, supplierDetails?.id, ratingConfigDetails?.intervalType, selectedInterval);
        }
    };


    useEffect(() => {
        if (ratingConfigDetails && supplierDetails) {
            getRatingData(ratingConfigDetails?.entity, supplierDetails?.id, ratingConfigDetails?.intervalType, selectedInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInterval]);

    useEffect(() => {
        if (ratingConfigDetails && supplierDetails)
            getRatingData(ratingConfigDetails?.entity, supplierDetails?.id, ratingConfigDetails?.intervalType, selectedInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingConfigDetails, supplierDetails]);

    useEffect(() => {
        // Create a CancelToken source
        const axiosSource = axios.CancelToken.source();
        setSource(axiosSource);
        getSupplierDetails(params.supplierId);
        getRatingConfig();
        return () => axiosSource.cancel('Component unmounted');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuItems =
        [
            {
                key: 'download',
                label: 'Download',
                icon: <DownloadOutlined />,
            },
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                disabled: editing,
            },
            {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                disabled: editing,
                danger: true
            },
        ];

    return (
        <>
            <div key={'div1' + params.supplierId} style={{ marginTop: "15px" }}>
                <Card
                    loading={isLoading && (!ratingData || ratingData?.length === 0)}
                    style={{ height: '100%' }}
                    bodyStyle={{ height: '100%' }}
                    key={params.supplierId}
                    title={
                        <div key={'div2' + params.supplierId} style={{ display: 'flex' }}>
                            <Space key={'Space1' + params.supplierId} size="middle">
                                <ArrowLeftLineIcon style={{ cursor: "pointer" }} color="#7D7676" onClick={() => navigate(-1)} />
                                <div key={'div3' + params.supplierId}>{supplierDetails?.name}</div>
                            </Space>
                        </div>
                    }
                    extra={
                        <>
                            {ratingData?.length !== 0 && (<Dropdown
                                key={'Dropdown' + params.supplierId}
                                disabled={isLoading}
                                menu={{
                                    items: menuItems,
                                    onClick: onMenuClick,
                                }}
                            >
                                <More2LineIcon />
                            </Dropdown>)}
                        </>

                        // <More2LineIcon style={{ cursor: "pointer" }} />
                    }
                >
                    <Space key={'Space2' + params.supplierId} direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div key={'div4' + params.supplierId} style={{ display: 'flex' }}>
                            <div key={'div5' + params.supplierId} style={{ flex: '1' }}>
                                <DatePicker disabled={isLoading} picker={"month"} defaultValue={dayjs(selectedInterval)} onChange={handleDateChange} allowClear={false} />
                            </div>
                            <div key={'div6' + params.supplierId}>
                                <Space size="middle" style={{ display: 'flex' }} key={'Space3' + params.supplierId}>
                                    {editing && (<Tooltip title="Save" key={'Tooltip1' + params.supplierId}>
                                        <Button key={'Button1' + params.supplierId} icon={<SaveOutlined />} onClick={onSave} disabled={isLoading} />
                                    </Tooltip>)}
                                    {!editing && (<Tooltip key={'Tooltip2' + params.supplierId} title="Refresh">
                                        <Button key={'Button2' + params.supplierId} icon={<SyncOutlined spin={isLoading} />} onClick={onRefresh} disabled={isLoading} />
                                    </Tooltip>)}
                                </Space>
                            </div>
                        </div>
                        {
                            ratingData?.length > 0 && ratingConfigDetails?.meta?.summaryGroups.map(
                                summaryGroup => (<SummaryGroupDetailedView
                                    key={summaryGroup.name + "-" + summaryGroup.entity}
                                    ratingConfig={ratingConfigDetails}
                                    ratingData={ratingData}
                                    summaryGroup={summaryGroup}
                                    isEditingData={editing}
                                    editedDataMap={editedData}
                                    setEditedDataMap={setEditedData}
                                    dataLoading={isDataLoading || isLoading} />))
                        }
                    </Space>
                    {
                        !isLoading && ratingData?.length === 0 && (
                            <div key={'div7' + params.supplierId} style={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'
                            }}>
                                <Button key={'Button3' + params.supplierId} size="large" type="primary" icon={<PlusOutlined />} onClick={handleGenerateData} loading={isLoading}>
                                    Generate Data for this interval
                                </Button>
                            </div>
                        )
                    }
                </Card>
            </div >

        </>
    );
}
